import { Stack } from '@mui/material'
import VersionIcon from '../../Assets/Images/svgs/version-icon'

/**
 * AssetVersion Component
 *
 * Renders a version count with count.
 *
 * @component
 * @param index - The index of the asset version.
 * @param assetDetails - The details of the asset.
 * @param count - The version count.
 * @param onClickAssetVersion - The click handler for asset version.
 * @returns {React.JSX.Element | null} JSX Element representing the AssetVersion component.
 */

const AssetVersion = ({
  index = 0,
  assetDetails = {},
  count = '',
  onClickAssetVersion = () => {
    return null
  },
}: {
  index?: number
  assetDetails?: Record<string, any>
  count?: string | number
  onClickAssetVersion?: (assetDetails: Record<string, any>, index: number) => void
}): React.JSX.Element | null => {
  return (
    <Stack
      position={'relative'}
      sx={{
        width: '40px',
        height: '40px',
        isplay: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        key={index}
        data-testid='version_icon'
        className='list-icon'
        onClick={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          onClickAssetVersion(assetDetails, index)
        }}
      >
        <VersionIcon />
      </Stack>
      {count && (
        <Stack
          sx={{
            position: 'absolute',
            height: '12px',
            width: '12px',
            background: '#D4351C',
            color: '#fff',
            right: '0px',
            textAlign: 'center',
            borderRadius: '50%',
            top: '0px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Inter',
              fontSize: '8px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0em',
              textAlign: 'left',
            }}
          >
            {count}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

export default AssetVersion
