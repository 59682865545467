import Menu from '@mui/material/Menu'
import { MUIButton, MUICheckbox } from '../Shared'

interface HeadersColMenuProps {
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
  headersColListTemp: Array<{ label: string; select: boolean }>
  setHeadersColListTemp: (arg: any) => void
  setHeadersColList: (arg: any) => void
  showLoaderFn: (arg: any) => void
  columnDefaultOrder?: Array<any>
}

const HeadersColMenu = ({
  anchorEl,
  open,
  onClose,
  headersColListTemp,
  setHeadersColListTemp,
  setHeadersColList,
  showLoaderFn,
}: HeadersColMenuProps) => {
  return (
    <Menu
      className='border-12 '
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{ minWidth: '268px', maxHeight: '400px' }}
    >
      <div style={{ maxHeight: '200px' }} className='scroll-y'>
        {headersColListTemp?.map((column: any, indx: number) => (
          <div
            key={column.id}
            className='d-flex justify-content-start align-items-center p-8 scroll-x view__role-list '
            style={{ borderBottom: '1px solid #F3F4F4', padding: '12px' }}
          >
            <MUICheckbox
              data-testid='check-box'
              handleChange={() => {
                headersColListTemp[indx].select = !headersColListTemp[indx].select
                setHeadersColListTemp([...headersColListTemp])
              }}
              checked={column?.select || false}
            />
            <div>{column?.label}</div>
          </div>
        ))}
      </div>
      <div
        className='d-flex justify-content-end align-items-center p-6'
        style={{ padding: '12px 14px 12px 12px', borderTop: '1px solid #F3F4F4' }}
      >
        <MUIButton
          textTransform={'capitalize'}
          variant='outlined'
          size='large'
          color={'secondary'}
          type='button'
          label={'Cancel'}
          width={'20%'}
          handleClickCB={() => {
            onClose()
            setHeadersColListTemp([])
          }}
        />
        &nbsp; &nbsp;&nbsp;
        <MUIButton
          type='button'
          textTransform={'capitalize'}
          variant={'contained'}
          size='large'
          handleClickCB={() => {
            showLoaderFn(true)
            onClose()
            setHeadersColList([...headersColListTemp])
            setHeadersColListTemp([])
            setTimeout(() => {
              showLoaderFn(false)
            }, 1000)
          }}
          label={'Submit'}
          width={'20%'}
        />
      </div>
    </Menu>
  )
}

export default HeadersColMenu
