import React from 'react'
import { useParams } from 'react-router-dom'
import { EntitlementPackages as EntitlementPackagesEditor } from '../../../../Components/Organisms/Masters'

const EntitlementPackagesAddEdit = () => {
  const { entitlementPackagesPageType, entitlementPackagesId } = useParams()

  return (
    <EntitlementPackagesEditor
      mastersPageType={entitlementPackagesPageType}
      mastersId={entitlementPackagesId}
    />
  )
}

export default React.memo(EntitlementPackagesAddEdit)
