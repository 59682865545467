function EyeIcon() {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Component 13' clip-path='url(#clip0_12554_32178)'>
        <path
          id='Vector'
          d='M8.23342 12.168C5.18309 12.168 2.566 10.3135 1.44037 7.66797C2.566 5.02243 5.18309 3.16797 8.23342 3.16797C11.2837 3.16797 13.9008 5.02243 15.0265 7.66797C13.9008 10.3135 11.2837 12.168 8.23342 12.168ZM4.40009 7.66797C4.40009 9.78411 6.11728 11.5013 8.23342 11.5013C10.3496 11.5013 12.0668 9.78411 12.0668 7.66797C12.0668 5.55183 10.3496 3.83464 8.23342 3.83464C6.11728 3.83464 4.40009 5.55183 4.40009 7.66797ZM6.73342 7.66797C6.73342 6.83744 7.40289 6.16797 8.23342 6.16797C9.06394 6.16797 9.73342 6.83744 9.73342 7.66797C9.73342 8.49849 9.06394 9.16797 8.23342 9.16797C7.40289 9.16797 6.73342 8.49849 6.73342 7.66797Z'
          fill='#402080'
          stroke='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_12554_32178'>
          <rect width='16' height='16' fill='white' transform='translate(0.233429)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default EyeIcon
