import { Typography } from '@mui/material'

const styles = {
  statusActive: {
    display: 'inline-flex',
    height: '24px',
    width: 'auto',
    padding: '0px 9px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: '#005a30',
    backgroundColor: '#e6efea',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    borderRadius: '4px',
  },
  statusInactive: {
    display: 'inline-flex',
    height: '24px',
    width: 'auto',
    padding: '0px 9px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: '#3e3f40',
    backgroundColor: '#ddddde',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    borderRadius: '4px',
  },
}

const getStatus = (status: boolean) => {
  return (
    <Typography sx={status ? styles.statusActive : styles.statusInactive}>
      {status ? 'Active' : 'Inactive'}
    </Typography>
  )
}

export default getStatus
