function Edit() {
  return (
    <svg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='ic contact edit'>
        <path
          id='Vector'
          d='M9.38259 1.96384C9.16938 1.76571 8.88667 1.65959 8.59574 1.66849C8.30482 1.67738 8.02912 1.80057 7.8284 2.01135L1.04152 8.79823L0.831127 12.0967C0.829139 12.1284 0.833922 12.1601 0.845156 12.1899C0.85639 12.2196 0.87382 12.2466 0.896287 12.269C0.918754 12.2915 0.945745 12.3089 0.975465 12.3202C1.00519 12.3314 1.03696 12.3362 1.06867 12.3342L4.36709 12.1238L11.154 5.33692C11.3648 5.13621 11.4879 4.86051 11.4968 4.56958C11.5057 4.27865 11.3996 3.99594 11.2015 3.78273L9.38259 1.96384Z'
          stroke='#402080'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export default Edit
