import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Tooltip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { BreadcrumbsPropsModel, BreadcrumsModel } from '../../../Models'

const Breadcrumbs: React.FC<BreadcrumbsPropsModel> = ({ breadcrums }) => {
  const renderLinkElement = (item: BreadcrumsModel, index: number) => {
    return (
      <Link
        key={item.label}
        to={index < breadcrums.length - 1 ? item.path : {}}
        className={` breadcrums__link ${item.active ? 'active font-bold' : ''}`}
      >
        <div className='d-flex align-items-center justify-content-center'>
          <div className='capitalize'>{item.label}</div>{' '}
          {!item.active && (
            <div className='d-flex justify-content-center align-items-center'>
              <ChevronRightIcon sx={{ fontSize: '16px' }} />
            </div>
          )}
        </div>
      </Link>
    )
  }
  return (
    <>
      <div className='d-flex align-items-center pr-3'>
        {breadcrums.map((item, index) => {
          return item?.showTooltip ? (
            <Tooltip
              data-testid='tooltip-breadcrums'
              title={item.tooltipText}
              key={item.label}
              placement='bottom'
              arrow
            >
              {renderLinkElement(item, index)}
            </Tooltip>
          ) : (
            renderLinkElement(item, index)
          )
        })}
      </div>
    </>
  )
}

export default Breadcrumbs
