const PinIcon = () => {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        d='M10.3253 6.43933C10.2666 6.414 10.2286 6.35667 10.2286 6.29267V5.22533C10.2286 5.15133 10.2673 5.08667 10.3013 5.07533C11.1633 4.79133 11.6833 3.93067 11.5373 3.02933C11.3953 2.15733 10.5873 1.5 9.65728 1.5H7.13328C6.20328 1.5 5.39528 2.15733 5.25328 3.02933C5.10661 3.93133 5.62661 4.792 6.48927 5.07533C6.52328 5.08667 6.56194 5.15067 6.56194 5.22533V6.29267C6.56194 6.35667 6.52394 6.414 6.46461 6.44C5.28794 6.95333 4.54128 8.10933 4.56194 9.38667C4.57261 10.0007 5.09394 10.5007 5.72528 10.5007H7.89528V14.0007C7.89528 14.2767 8.11928 14.5007 8.39528 14.5007C8.67128 14.5007 8.89528 14.2767 8.89528 14.0007V10.5007H10.6299C12.0959 10.5007 12.2239 9.648 12.2286 9.38667C12.2493 8.10933 11.5026 6.95333 10.3253 6.43933ZM10.6299 9.50067H5.72594C5.64194 9.50067 5.56394 9.438 5.56261 9.37C5.54861 8.498 6.05928 7.708 6.86394 7.35733C7.28861 7.17333 7.56261 6.75533 7.56261 6.29267V5.22533C7.56261 4.71667 7.25728 4.27467 6.80194 4.12533C6.46527 4.01533 6.16461 3.658 6.24061 3.19C6.30461 2.79667 6.68861 2.50067 7.13328 2.50067H9.65728C10.1019 2.50067 10.4859 2.79667 10.5499 3.19C10.6259 3.658 10.3253 4.01533 9.98927 4.12533C9.53394 4.27533 9.22861 4.71733 9.22861 5.22533V6.29267C9.22861 6.75467 9.50261 7.17267 9.92594 7.35667C10.7313 7.70733 11.2426 8.498 11.2286 9.37867C11.2279 9.38 11.1199 9.50067 10.6293 9.50067H10.6299Z'
        fill='#383F43'
      />
    </svg>
  )
}

export default PinIcon
