const BoxCrossIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_4314_32510)'>
        <path
          d='M3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5ZM16.3 16.29C15.91 16.68 15.28 16.68 14.89 16.29L12 13.41L9.11 16.3C8.72 16.69 8.09 16.69 7.7 16.3C7.31 15.91 7.31 15.28 7.7 14.89L10.59 12L7.7 9.11C7.31 8.72 7.31 8.09 7.7 7.7C8.09 7.31 8.72 7.31 9.11 7.7L12 10.59L14.89 7.71C15.28 7.32 15.91 7.32 16.3 7.71C16.69 8.1 16.69 8.73 16.3 9.12L13.41 12L16.3 14.88C16.68 15.27 16.68 15.91 16.3 16.29Z'
          fill='#D4351C'
        />
      </g>
      <defs>
        <clipPath id='clip0_4314_32510'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default BoxCrossIcon
