import React from 'react'
import { DialogHeaderIconModal } from '../../../Models'

const DialogHeaderIcon: React.FC<DialogHeaderIconModal> = ({ Icon, type = 'primary' }) => {
  return (
    <div className={`circle__icon-wrapper ${type}`}>
      <div className={`circle__icon-inner ${type}`}>{Icon && <Icon />}</div>
    </div>
  )
}

export default DialogHeaderIcon
