import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SYSTEM_CONFIG_APIS } from '../../Constants/ApiDefinations'
import { TypeObject } from '../../Models'
import mainApiService from '../../Services'

export const appConfigInitialState = {
  appLabels: <TypeObject>{},
  contentCategories: [],
  auditTrails: [],
  status: 'idle',
  error: '',
}

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: appConfigInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppConfig.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getAppConfig.fulfilled, (state, action) => {
        state.status = 'succeeded'
        if (action?.payload?.data?.length) {
          action.payload.data.forEach((data: TypeObject) => {
            data.configValue?.forEach((item: TypeObject) => {
              state.appLabels = { ...state.appLabels, [`${item.key}`]: item.value }
            })
            if (data.configKey === 'AUDIT_TRAILS') {
              state.auditTrails = data.configValue
            } else if (data.configKey === 'CONTENT_CATEGORY') {
              state.contentCategories = data.configValue
            }
          })
        }
      })
      .addCase(getAppConfig.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || ''
      })
  },
})

export default appConfigSlice

export const getAppConfig = createAsyncThunk('appConfig/getAppConfig', async () => {
  const requestedData = SYSTEM_CONFIG_APIS.LIST_MASTERS('CONTENT_CATEGORY,AUDIT_TRAILS')
  const data = await mainApiService(requestedData)
  return data
})
