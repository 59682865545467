import { Stack } from '@mui/material'
import AssetClone from './AssetClone'
import AssetVersion from './AssetVersion'

/**
 * AssetCloneVersion Component
 *
 * Renders Asset Clone and Count components.
 *
 * @component
 * @param isChild - For identify child element
 * @param index - The index of the asset version.
 * @param assetDetails - The details of the asset.
 * @param onClickAssetVersion - The click handler for asset version.
 * @param isAnyMetaPermission - For isAnyMetaPermission on the click on clone.
 * @param onClickClone - The click handler for asset clone.
 * @param showClone -boolean value to show and hide clone button
 * @returns {React.JSX.Element | null} JSX Element representing the AssetCloneVersion component.
 */

const AssetCloneVersion = ({
  isVersionIcon = false,
  isChild = false,
  index = 0,
  assetDetails = {},
  onClickAssetVersion,
  isAnyMetaPermission = true,
  onClickClone,
  showClone = true,
}: {
  isVersionIcon?: boolean
  isChild?: boolean
  index: number
  assetDetails: Record<string, any>
  onClickAssetVersion: (assetDetails: Record<string, any>, index: number) => void
  isAnyMetaPermission: boolean
  showClone?: boolean
  onClickClone?: (assetDetails: Record<string, any>, index: number) => void
}): React.JSX.Element | null => {
  return (
    <Stack key={`item_${index}${isChild ? '_child' : ''}`}>
      {!isChild ? (
        <Stack
          direction={'row'}
          alignItems='center'
          justifyContent={showClone ? 'right' : 'left'}
          gap={showClone && assetDetails?.versionCount > 1 ? 2.5 : 0}
        >
          {isVersionIcon && assetDetails.versionCount > 1 && (
            <AssetVersion
              index={index}
              assetDetails={assetDetails}
              count={assetDetails.versionCount || ''}
              onClickAssetVersion={onClickAssetVersion}
            />
          )}

          {showClone && (
            <AssetClone
              disabled={!isAnyMetaPermission}
              index={index}
              assetDetails={assetDetails}
              onClickClone={onClickClone}
            />
          )}
        </Stack>
      ) : (
        <Stack>
          <span className='dashLine'></span>
        </Stack>
      )}
    </Stack>
  )
}

export default AssetCloneVersion
