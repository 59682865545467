import React from 'react'

const PreviewImageIcon: React.FC = () => {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4' y='4' width='48' height='48' rx='24' fill='#ECE9F2' />
      <rect x='4' y='4' width='48' height='48' rx='24' stroke='#E2DEEC' strokeWidth='8' />
      <g clipPath='url(#clip0_2091_24475)'>
        <path
          d='M37 35V21C37 19.9 36.1 19 35 19H21C19.9 19 19 19.9 19 21V35C19 36.1 19.9 37 21 37H35C36.1 37 37 36.1 37 35ZM24.9 29.98L27 32.51L30.1 28.52C30.3 28.26 30.7 28.26 30.9 28.53L34.41 33.21C34.66 33.54 34.42 34.01 34.01 34.01H22.02C21.6 34.01 21.37 33.53 21.63 33.2L24.12 30C24.31 29.74 24.69 29.73 24.9 29.98Z'
          fill='#323232'
        />
      </g>
      <defs>
        <clipPath id='clip0_2091_24475'>
          <rect width='24' height='24' fill='white' transform='translate(16 16)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PreviewImageIcon
