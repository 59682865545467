export const autoFormatTime = (value: any) => {
  // Split input by colon and format accordingly
  let parts = value.split(':')
  if (parts.length > 3) {
    parts = parts.slice(0, 3) // Restrict to max 3 parts
  }

  if (parts[0] && parts[0].length > 3) {
    parts[0] = parts[0].slice(0, 3) // Restrict hours to 3 digits
  }
  if (parts[1] && parts[1].length > 2) {
    parts[1] = parts[1].slice(0, 2) // Restrict minutes to 2 digits
  }
  if (parts[2] && parts[2].length > 2) {
    parts[2] = parts[2].slice(0, 2) // Restrict seconds to 2 digits
  }

  // Ensure valid ranges for each part
  if (parts[1] && parseInt(parts[1], 10) > 59) {
    parts[1] = '59'
  }
  if (parts[2] && parseInt(parts[2], 10) > 59) {
    parts[2] = '59'
  }

  return parts.join(':')
}

export const secondsToHMS = (seconds: any) => {
  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  // Format hours, minutes, and seconds to always display two digits
  const formattedHours = String(hours).padStart(3, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  // Return formatted string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const hmsToSeconds = (hms: any) => {
  // Split the input string into hours, minutes, and seconds
  const [hours = 0, minutes = 0, seconds = 0] = hms.split(':').map(Number)

  // Calculate total seconds
  const totalSeconds = hours * 3600 + minutes * 60 + seconds

  return totalSeconds
}

export const formatTimeToHMS = (inputTime: any) => {
  // Split the input time string by ':' and convert each part to a number
  const parts = inputTime.split(':').map((part: any) => parseInt(part || 0, 10))

  // Initialize hours, minutes, and seconds
  let hours = 0,
    minutes = 0,
    seconds = 0

  // Determine how many parts we have and assign them to hours, minutes, and seconds
  if (parts.length === 1) {
    // Case: only hours provided, e.g., '1'
    hours = parts[0]
  } else if (parts.length === 2) {
    // Case: hours and minutes provided, e.g., '1:2'
    hours = parts[0]
    minutes = parts[1]
  } else if (parts.length === 3) {
    // Case: hours, minutes, and seconds provided, e.g., '001:58:0'
    hours = parts[0]
    minutes = parts[1]
    seconds = parts[2]
  }

  // Format hours, minutes, and seconds to always display two digits
  const formattedHours = String(hours).padStart(3, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  // Return formatted string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const checkIsSelected = (
  value: string,
  compareValue: string,
  type: 'h' | 'm' | 's',
): boolean => {
  if (!compareValue) {
    return false
  }

  const parts = compareValue.split(':')

  if (type === 'h' && parts.length > 0) {
    // Compare hours
    return (
      parseInt(value, 10).toString().padStart(3, '0') ===
      parseInt(parts[0], 10).toString().padStart(3, '0')
    )
  } else if (type === 'm' && parts.length > 1) {
    // Compare minutes
    return (
      parseInt(value, 10).toString().padStart(2, '0') ===
      parseInt(parts[1], 10).toString().padStart(2, '0')
    )
  } else if (type === 's' && parts.length > 2) {
    // Compare seconds
    return (
      parseInt(value, 10).toString().padStart(2, '0') ===
      parseInt(parts[2], 10).toString().padStart(2, '0')
    )
  }

  return false
}
