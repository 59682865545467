import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  GeoPolicyCRUDAPIDefinitionModal,
  GeoPolicyEditorSubmitModal,
} from '../../Components/GeoPolicy/GeoPolicyEditor/GeoPolicyModal'
import { GEO_POLICY_CRUD } from '../../Constants/ApiDefinations'
import {
  GeoPolicyResponseModal,
  geoPolicyStatusChangeDefinitionModal,
  roleCRUDAPIDefinitionModal,
} from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface geoPolicyInitial {
  geoPolicy?: GeoPolicyResponseModal | null
  country?: any[]
}
const initialState: geoPolicyInitial = {
  geoPolicy: null,
  country: [],
}
const geoPolicySlice = createSlice({
  name: 'geoPolicy',
  initialState,
  reducers: {
    viewGeoPolicy: (state, action) => {
      state.geoPolicy = action.payload
    },
    setCountry: (state, action) => {
      state.country = action.payload
    },
  },
})
export const { setCountry, viewGeoPolicy } = geoPolicySlice.actions
export const viewgeoPolicyService = createAsyncThunk(
  'geoPolicy/viewpolicy',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = GEO_POLICY_CRUD.VIEW_GEO_POLICY_REPO(id)
    const data = await mainApiService(requestedData)

    dispatch(viewGeoPolicy(data.data))
    dispatch(setLoader(false))
  },
)
export const deleteGeoPolicy = createAsyncThunk(
  'geoPolicy/delete',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: roleCRUDAPIDefinitionModal = GEO_POLICY_CRUD.DELETE_GEO_POLICY(id)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)
export const changeGeoPolicyStatus = createAsyncThunk(
  'role/geoPolicyStatus',
  async (data: { geoPolicyId: string; geoPolicyStatus: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: geoPolicyStatusChangeDefinitionModal = GEO_POLICY_CRUD.CHANGE_STATUS(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)
export const createGeoPolicyService = createAsyncThunk(
  'geoPolicy/createPolicy',
  async (payload: GeoPolicyEditorSubmitModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: GeoPolicyCRUDAPIDefinitionModal = GEO_POLICY_CRUD.CREATE_POLICY(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)
export const editGeoPolicyService = createAsyncThunk(
  'geoPolicy/editPolicy',
  async (payload: GeoPolicyEditorSubmitModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: GeoPolicyCRUDAPIDefinitionModal = GEO_POLICY_CRUD.EDIT_POLICY(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)
export const getCountryService = createAsyncThunk(
  'country/getCountry',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData = GEO_POLICY_CRUD.GET_COUNTRY()
    const data = await mainApiService(requestedData)
    const tempCountry: any[] = []
    data.data.records.forEach((ele: any) => {
      tempCountry.push({ _id: ele?._id, key: ele.key, value: ele.value })
    })
    dispatch(setCountry(tempCountry))
    dispatch(setLoader(false))
  },
)
const geoPolicySelector = (state: { geoPolicy: geoPolicyInitial }) => state?.geoPolicy
export const selectGeoPolicy = createSelector(geoPolicySelector, (state) => state?.geoPolicy)
export const selectCountries = createSelector(geoPolicySelector, (state) => state?.country)

export default geoPolicySlice
