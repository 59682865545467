export const DePublishIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <circle cx='20' cy='20' r='20' fill='#4A130A' />
      <path
        d='M30.6708 25.6415L22.4269 11.2512C22.2127 10.8772 21.905 10.5654 21.534 10.3461C21.163 10.1268 20.7415 10.0077 20.3106 10.0004C19.8797 9.99303 19.4543 10.0977 19.076 10.3043C18.6978 10.5108 18.3797 10.812 18.1528 11.1784L9.37213 25.3739C9.14012 25.7489 9.01197 26.1789 9.0008 26.6198C8.98963 27.0606 9.09584 27.4965 9.30857 27.8828C9.52129 28.2691 9.83287 28.5919 10.2114 28.8182C10.5899 29.0445 11.0218 29.1661 11.4628 29.1705L28.4906 29.3662H28.5199C28.9553 29.3648 29.3827 29.2493 29.7593 29.031C30.136 28.8127 30.4489 28.4995 30.6666 28.1224C30.8843 27.7454 30.9993 27.3179 31 26.8825C31.0007 26.4472 30.8872 26.0192 30.6708 25.6415ZM29.3654 27.3792C29.2786 27.5307 29.1525 27.656 29.0005 27.7418C28.8484 27.8276 28.6761 27.8708 28.5015 27.8668L11.4736 27.6719C11.2985 27.6698 11.127 27.6212 10.9768 27.5312C10.8266 27.4411 10.7029 27.3127 10.6186 27.1592C10.5342 27.0057 10.4921 26.8325 10.4966 26.6574C10.5011 26.4823 10.552 26.3115 10.6441 26.1625L19.4248 11.9679C19.5115 11.8238 19.6341 11.7048 19.7808 11.6225C19.9274 11.5403 20.0929 11.4976 20.261 11.4987H20.2794C20.4512 11.4996 20.6196 11.5461 20.7675 11.6336C20.9153 11.721 21.0372 11.8462 21.1207 11.9963L29.3654 26.3866C29.4538 26.5371 29.5005 26.7084 29.5005 26.8829C29.5005 27.0574 29.4538 27.2288 29.3654 27.3792Z'
        fill='white'
        stroke='white'
        strokeWidth='0.15'
      />
      <path
        d='M19.9597 16.0234C19.76 16.0234 19.5686 16.1027 19.4275 16.2439C19.2863 16.385 19.207 16.5765 19.207 16.7761V21.664C19.1978 21.768 19.2104 21.8728 19.2439 21.9717C19.2774 22.0706 19.3311 22.1615 19.4016 22.2385C19.4721 22.3155 19.5579 22.377 19.6534 22.4191C19.749 22.4612 19.8523 22.4829 19.9567 22.4829C20.0612 22.4829 20.1644 22.4612 20.26 22.4191C20.3556 22.377 20.4414 22.3155 20.5119 22.2385C20.5824 22.1615 20.6361 22.0706 20.6696 21.9717C20.7031 21.8728 20.7156 21.768 20.7064 21.664V16.7727C20.7056 16.5747 20.6267 16.385 20.4869 16.2447C20.3471 16.1045 20.1577 16.025 19.9597 16.0234Z'
        fill='white'
        stroke='white'
        strokeWidth='0.15'
      />
      <path
        d='M19.9597 24.0469C19.7601 24.0469 19.5686 24.1262 19.4275 24.2673C19.2863 24.4085 19.207 24.5999 19.207 24.7995V24.8965C19.2236 25.0839 19.3098 25.2583 19.4485 25.3853C19.5873 25.5124 19.7686 25.5828 19.9567 25.5828C20.1449 25.5828 20.3262 25.5124 20.4649 25.3853C20.6037 25.2583 20.6899 25.0839 20.7064 24.8965V24.7995C20.7064 24.6009 20.628 24.4104 20.4881 24.2694C20.3482 24.1284 20.1583 24.0484 19.9597 24.0469Z'
        fill='white'
        stroke='white'
        strokeWidth='0.15'
      />
    </svg>
  )
}
