import { useCallback, useState } from 'react'
import CSVReader from 'react-csv-reader'
import { DragAndDropSolidIcon } from '../../Icons'

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  // transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_'),
}

interface CSVSelectorModel {
  disabled: boolean
  handleChange: (data: any, fileInfo: any, originalFile: any) => any
}

const CSVSelector: React.FC<CSVSelectorModel> = ({ disabled, handleChange }: CSVSelectorModel) => {
  const [isDragActive, setIsDragActive] = useState<boolean>(false)

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragActive(true)
  }, [])

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragActive(true)
  }, [])

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragActive(false)

    const droppedFiles = e.dataTransfer.files
    const changeInput: any = document?.getElementById('react-csv-reader-input')
    if (changeInput) {
      changeInput.files = droppedFiles

      const event = new Event('change', { bubbles: true })
      changeInput.dispatchEvent(event)
    }
  }, [])

  return (
    <>
      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        data-testid={'dropzone'}
      >
        <CSVReader
          accept='.csv'
          cssClass={`react-csv-input ${isDragActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
          disabled={disabled}
          cssLabelClass={'react-csv-input-label'}
          label={
            <>
              <DragAndDropSolidIcon />
              <p className={'dropzone__section_text'}>Drag & Drop or Upload File</p>
              <p>Supported formats {'.csv'}</p>
            </>
          }
          cssInputClass='file_input-class'
          onFileLoaded={handleChange}
          parserOptions={papaparseOptions}
        />
      </div>
    </>
  )
}

export default CSVSelector
