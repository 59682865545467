import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import * as React from 'react'
import WarningIcon from '../../Assets/Images/Featured_icon.png'
import { TypeObject } from '../../Models'
import { MUIButton, MUIDialog } from '../Shared'

interface PropTypes {
  handleClose?: () => void
  handleSuccess?: () => void
  open: boolean
  existingImages: TypeObject[]
}

const ExistingImageDialog: React.FC<PropTypes> = ({
  open,
  handleClose = () => {
    return
  },
  handleSuccess = () => {
    return
  },
  existingImages,
}: PropTypes) => {
  return (
    <>
      <div>
        <MUIDialog open={open} handleClose={handleClose}>
          <div className='' style={{ maxWidth: '400px' }}>
            <DialogTitle>
              <img src={WarningIcon} alt='' />
            </DialogTitle>
            <DialogContent className='p-0'>
              <DialogContentText component={'div'} id='alert-dialog-slide-description' className=''>
                <div className='col2 parag paragraph-bold-3 dialog__padding-horizontal mb-1'>
                  {'Replace Existing Image'}
                </div>
                <div className='col34 paragraph-regular-5 '>
                  <div className='dialog__padding-horizontal mb-1'>
                    Existing image files in the listed image attributes will be replaced upon
                    uploading new images.
                    <br />
                    List of Image attributes:
                  </div>

                  <div className='dialog__padding-horizontal dialog__overflow'>
                    {existingImages.map((content) => (
                      <div
                        key={content.imageName}
                        data-testid='content_existing_exist_list'
                        className='cursor-pointer col1 mb-1 word-break-all'
                      >
                        {content?.imageName}
                      </div>
                    ))}
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className='d-flex flex-wrap w-100 dialog-btns'>
                <div className='cancel-action-btn w-50 p-8'>
                  <MUIButton
                    textTransform={'capitalize'}
                    variant='outlined'
                    size='large'
                    color={'secondary'}
                    type='button'
                    label={'Cancel'}
                    width={'100%'}
                    handleClickCB={() => handleClose()}
                  />
                </div>
                <div className={'accept-action-btn w-50 p-8'}>
                  <MUIButton
                    textTransform={'capitalize'}
                    variant='contained'
                    size='large'
                    color='primary'
                    type='button'
                    handleClickCB={() => handleSuccess()}
                    label={'Proceed'}
                    width={'100%'}
                  />
                </div>
              </div>
            </DialogActions>
          </div>
        </MUIDialog>
      </div>
    </>
  )
}

export default ExistingImageDialog
