const SettingIcon = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M3.3204 3.06901V0.981982C3.3204 0.906472 3.30553 0.831702 3.27663 0.761939C3.24774 0.692177 3.20538 0.62879 3.15199 0.575396C3.09859 0.522003 3.03521 0.479648 2.96545 0.450752C2.89568 0.421855 2.82091 0.406982 2.7454 0.406982C2.66989 0.406982 2.59512 0.421855 2.52536 0.450752C2.4556 0.479648 2.39221 0.522002 2.33882 0.575396C2.28542 0.62879 2.24307 0.692178 2.21417 0.761939C2.18528 0.831701 2.1704 0.906472 2.1704 0.981982V3.06901C1.8132 3.18528 1.49873 3.40723 1.26929 3.70656C1.02023 4.03149 0.885254 4.42946 0.885254 4.83886C0.885254 5.24825 1.02023 5.64623 1.26929 5.97115C1.49873 6.27049 1.8132 6.49244 2.1704 6.60871V12.982C2.1704 13.1345 2.23098 13.2807 2.33882 13.3886C2.44665 13.4964 2.5929 13.557 2.7454 13.557C2.8979 13.557 3.04415 13.4964 3.15199 13.3886C3.25982 13.2807 3.3204 13.1345 3.3204 12.982V6.60871C3.67761 6.49244 3.99207 6.27049 4.22151 5.97115C4.47057 5.64623 4.60555 5.24825 4.60555 4.83886C4.60555 4.42946 4.47057 4.03149 4.22151 3.70656C3.99207 3.40723 3.67761 3.18528 3.3204 3.06901ZM2.74518 5.54973C2.60452 5.54984 2.46698 5.50823 2.34997 5.43018C2.23292 5.3521 2.14166 5.24106 2.08773 5.11111C2.0338 4.98116 2.01962 4.83813 2.04699 4.70012C2.07436 4.5621 2.14205 4.43531 2.2415 4.33578C2.34094 4.23624 2.46767 4.16844 2.60566 4.14095C2.74365 4.11345 2.88669 4.1275 3.01669 4.18131C3.14669 4.23513 3.25781 4.32629 3.33599 4.44327C3.41415 4.56021 3.45588 4.69771 3.4559 4.83837C3.45573 5.02687 3.38082 5.20762 3.24759 5.34097C3.11436 5.47432 2.93368 5.5494 2.74518 5.54973Z'
        fill='#402080'
        stroke='#402080'
        strokeWidth='0.25'
      />
      <path
        d='M12.7322 3.70583C12.5023 3.40644 12.1873 3.18468 11.8297 3.06886V0.981982C11.8297 0.829482 11.7691 0.683229 11.6613 0.575396C11.5534 0.467563 11.4072 0.406982 11.2547 0.406982C11.1022 0.406982 10.9559 0.467562 10.8481 0.575396C10.7403 0.68323 10.6797 0.829483 10.6797 0.981982V3.06901C10.3225 3.18528 10.008 3.40723 9.77857 3.70656C9.52951 4.03149 9.39453 4.42946 9.39453 4.83886C9.39453 5.24825 9.52951 5.64623 9.77857 5.97115C10.008 6.27049 10.3225 6.49244 10.6797 6.60871V12.982C10.6797 13.1345 10.7403 13.2807 10.8481 13.3886C10.9559 13.4964 11.1022 13.557 11.2547 13.557C11.4072 13.557 11.5534 13.4964 11.6613 13.3886C11.7691 13.2807 11.8297 13.1345 11.8297 12.982V6.60886C12.1873 6.49304 12.5023 6.27127 12.7322 5.97189C12.9817 5.64689 13.117 5.2486 13.117 4.83886C13.117 4.42911 12.9817 4.03083 12.7322 3.70583ZM11.2547 5.54973C11.114 5.54973 10.9765 5.50802 10.8595 5.42987C10.7426 5.35171 10.6514 5.24063 10.5976 5.11067C10.5437 4.9807 10.5297 4.83769 10.5571 4.69972C10.5845 4.56176 10.6523 4.43502 10.7518 4.33555C10.8512 4.23608 10.978 4.16834 11.1159 4.1409C11.2539 4.11346 11.3969 4.12754 11.5269 4.18137C11.6568 4.23521 11.7679 4.32637 11.8461 4.44333C11.9242 4.5603 11.9659 4.69781 11.9659 4.83848C11.9659 5.02712 11.891 5.20803 11.7576 5.34141C11.6242 5.4748 11.4433 5.54973 11.2547 5.54973Z'
        fill='#402080'
        stroke='#402080'
        strokeWidth='0.25'
      />
      <path
        d='M7.60946 7.35451V0.981982C7.60946 0.829483 7.54889 0.68323 7.44105 0.575396C7.33322 0.467562 7.18696 0.406982 7.03446 0.406982C6.88197 0.406982 6.73571 0.467563 6.62788 0.575396C6.52004 0.68323 6.45947 0.829483 6.45947 0.981982V7.35676C6.10226 7.47303 5.7878 7.69498 5.55835 7.99431C5.3093 8.31924 5.17432 8.71721 5.17432 9.12661C5.17432 9.53601 5.3093 9.93398 5.55835 10.2589C5.7878 10.5582 6.10226 10.7802 6.45947 10.8965V12.9842C6.45947 13.1367 6.52004 13.283 6.62788 13.3908C6.73571 13.4987 6.88197 13.5592 7.03446 13.5592C7.18696 13.5592 7.33322 13.4987 7.44105 13.3908C7.54889 13.283 7.60946 13.1367 7.60946 12.9842V10.8942C7.96667 10.7779 8.28113 10.556 8.51058 10.2567C8.75963 9.93173 8.89461 9.53375 8.89461 9.12436C8.89461 8.71496 8.75963 8.31699 8.51058 7.99206C8.28113 7.69273 7.96667 7.47078 7.60946 7.35451ZM7.03446 9.83523H6.97718C6.8566 9.82562 6.74011 9.78537 6.63896 9.71788C6.5219 9.63978 6.43064 9.5287 6.37673 9.39871C6.32282 9.26872 6.30868 9.12566 6.3361 8.98763C6.36352 8.8496 6.43128 8.72281 6.53079 8.6233C6.63029 8.52379 6.75708 8.45604 6.89511 8.42862C7.03314 8.4012 7.1762 8.41533 7.30619 8.46925C7.43618 8.52316 7.54726 8.61442 7.62536 8.73148C7.70347 8.84854 7.74509 8.98615 7.74496 9.12687L7.86996 9.12698L7.74497 9.12665C7.74446 9.31475 7.66938 9.49498 7.53619 9.62781C7.403 9.76064 7.22257 9.83523 7.03446 9.83523Z'
        fill='#402080'
        stroke='#402080'
        strokeWidth='0.25'
      />
    </svg>
  )
}

export default SettingIcon
