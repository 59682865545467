import { Grid } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { Control, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import MastersEditorLayout from '../../../../../Components/Organisms/Masters/Layouts/MastersEditorLayout'
import styles from '../../../../../Components/Organisms/Masters/Masters.module.scss'
import { MUIBox, MUIButton, MUIField } from '../../../../../Components/Shared'
import { BREADCRUMS, PAGES_ROUTES, VALIDATIONS } from '../../../../../Constants'
import {
  BreadcrumsModel,
  CreateMasterSystemConfigurationModal,
  MasterCreateSubmitModal,
} from '../../../../../Models'
import { useAppDispatch } from '../../../../../Store/Hooks'
import { showAlert } from '../../../../../Store/Slice/alert.slice'
import { createSystemConfigurationMaster } from '../../../../../Store/Slice/systemConfigurations.slice'

const CreateSystemConfiguration = () => {
  const dispatch = useAppDispatch()
  const [errorMessage, seterrorMessage] = useState({ masterName: null, value: '' })
  const onClickBackNavigation = (e: { preventDefault: () => void }) => {
    e?.preventDefault()
    navigate(`/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`)
  }

  const createMaster = async (data: MasterCreateSubmitModal) => {
    const reqData: CreateMasterSystemConfigurationModal = {
      displayName: data?.masterName as string,
    }

    const result = await dispatch(createSystemConfigurationMaster(reqData) as any)
    handleResponse(result)
  }

  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {
      masterName: '',
    },
  })
  const onSubmit = (data: any) => {
    createMaster(data)
  }

  const handleResponse = (result: any) => {
    const unwrappableRes = unwrapResult(result)

    if (unwrappableRes?.error) {
      if (unwrappableRes?.error.blitzCode === 'BLITZ_08016') {
        seterrorMessage({ masterName: unwrappableRes.error, value: getValues('masterName') })
      } else {
        dispatch(showAlert([true, unwrappableRes.error.message, 'error']))
      }
    } else if (unwrappableRes.responseCode === 200) {
      seterrorMessage({ masterName: null, value: '' })
      dispatch(showAlert([true, unwrappableRes.message, 'success']))
      setValue('masterName', '')
      navigate('/metamaster/masters')
    }
  }
  useEffect(() => {
    if (getValues('masterName') !== errorMessage.value) {
      seterrorMessage({ masterName: null, value: '' })
    }
  }, [getValues('masterName')])
  return (
    <MastersEditorLayout
      mastersPageType={'create'}
      pageText={''}
      onClickBackNavigation={onClickBackNavigation}
      breadcrumbs={BREADCRUMS.CREATE_MASTER_INFORMATION() as BreadcrumsModel[]}
    >
      <MUIBox>
        <div className={`${styles['master-form-wrapper']}`}>
          <div className={`heading-6 d-flex text-left ${styles.masters__box__header__padding}`}>
            Master Information
          </div>
          <div className={styles.hr}></div>
          <div className={styles.mastersbox__content_padding}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container columnSpacing={10} rowSpacing={4}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <MUIField
                    name='masterName'
                    label={'Master Name'}
                    required
                    type='text'
                    rules={{ required: VALIDATIONS.REQUIRED }}
                    control={control as Control | undefined}
                    error={Object.keys(errors).length ? errors : (errorMessage as any)}
                    maxLength={100}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Grid container spacing={10}>
                    <Grid item xs={6}></Grid>

                    <Grid item xs={6} className='d-flex justify-content-end'>
                      <MUIButton
                        disabled={!isValid}
                        size='large'
                        type={'submit'}
                        label={'Submit'}
                        variant='contained'
                        width={160}
                        height={'50px'}
                        className={styles['submit-button']}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </MUIBox>
    </MastersEditorLayout>
  )
}

export default CreateSystemConfiguration
