export const validationMessages = {
  regex: 'Invalid format',
  email: 'Invalid email address',
  required: 'This field is required',
  pastDate: 'Date must be in the past',
  futureDate: 'Date must be in the future',
  pastDateWithSameDay: 'Date must be in the past or today',
  futureDateWithSameDay: 'Date must be in the future or today',
  minValue: (minValue: string | number) => `Minimum value is ${minValue}`,
  maxValue: (maxValue: string | number) => `Maximum value is ${maxValue}`,
  minLength: (minLength: string | number) => `Minimum length is ${minLength} characters`,
  maxLength: (maxLength: string | number) => `Maximum length is ${maxLength} characters`,
  minDate: (minDate: string | number | Date) =>
    `Date must be after ${new Date(minDate).toLocaleDateString()}`,
  maxDate: (maxDate: string | number | Date) =>
    `Date must be before ${new Date(maxDate).toLocaleDateString()}`,
}

interface FieldConfig {
  id: number
  label: string
  fieldName: string
  fieldType: string
  dataType: string
  placeholder: string
  isValid: boolean
  errorMessage: string
  value: any
  validations: Record<string, any>
}

export const validationFunctions: Record<string, any> = {
  required: (value: any) => {
    if (Array.isArray(value)) {
      return value.length > 0 ? '' : validationMessages.required
    }
    return value !== undefined && value !== null && value !== '' ? '' : validationMessages.required
  },
  minLength: (value: string, minLength: number) =>
    value.length >= minLength ? '' : validationMessages.minLength(minLength),
  maxLength: (value: string, maxLength: number) =>
    value.length <= maxLength ? '' : validationMessages.maxLength(maxLength),
  minValue: (value: number, minValue: number) =>
    value >= minValue ? '' : validationMessages.minValue(minValue),
  maxValue: (value: number, maxValue: number) =>
    value <= maxValue ? '' : validationMessages.maxValue(maxValue),
  minDate: (value: string, minDate: string | Date) =>
    new Date(value) >= new Date(minDate) ? '' : validationMessages.minDate(minDate),
  maxDate: (value: string, maxDate: string | Date) =>
    new Date(value) <= new Date(maxDate) ? '' : validationMessages.maxDate(maxDate),
  email: (value: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // NOSONAR
    return emailPattern.test(value) ? '' : validationMessages.email
  },
  regex: (value: string, pattern: string) => {
    const regex = new RegExp(pattern)
    return regex.test(value) ? '' : validationMessages.regex
  },
  pastDate: (value: string) => {
    return new Date(value) < new Date() ? '' : validationMessages.pastDate
  },
  futureDate: (value: string) => {
    return new Date(value) > new Date() ? '' : validationMessages.futureDate
  },
  pastDateWithSameDay: (value: string) => {
    const today = new Date()
    const dateValue = new Date(value)
    today.setHours(0, 0, 0, 0) // Reset time part
    dateValue.setHours(0, 0, 0, 0) // Reset time part
    return dateValue <= today ? '' : validationMessages.pastDateWithSameDay
  },
  futureDateWithSameDay: (value: string) => {
    const today = new Date()
    const dateValue = new Date(value)
    today.setHours(0, 0, 0, 0) // Reset time part
    dateValue.setHours(0, 0, 0, 0) // Reset time part
    return dateValue >= today ? '' : validationMessages.futureDateWithSameDay
  },
}

export const validateField = (
  fieldConfig: FieldConfig,
  value: string | number | Date | null | undefined | Array<any>,
  trim = false,
): {
  value: string | number | Date | null | undefined | Array<any>
  isValid: boolean
  errorMessage: string
} => {
  let error = ''
  let trimmedValue = value
  // Trim the value if it is a string and trim is true
  if (typeof value === 'string' && trim) {
    trimmedValue = value.trim()
  }

  if (fieldConfig.validations) {
    for (const [key, validationValue] of Object.entries(fieldConfig.validations)) {
      const validationFunction = validationFunctions[key]
      if (validationFunction) {
        const validationError = validationFunction(trimmedValue, validationValue)
        if (validationError) {
          error = validationError
          break
        }
      }
    }
  }

  return {
    value: trimmedValue,
    isValid: !error,
    errorMessage: error,
  }
}

export const validateForm = (
  formConfig: FieldConfig[],
  trim = false,
): { formConfig: FieldConfig[]; isValid: boolean } => {
  const validatedFields = formConfig.map((field) => {
    const { value, isValid, errorMessage } = validateField(field, field.value, trim)
    return { ...field, isValid, errorMessage, value }
  })

  const isFormValid = validatedFields.every((field) => field.isValid)

  return {
    formConfig: validatedFields,
    isValid: isFormValid,
  }
}
