/* eslint-disable no-param-reassign */
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { ASSET_CURD_API, SYSTEM_CONFIG_APIS } from '../../Constants/ApiDefinations'
import { ViewMastersAPIDefinition } from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface VideoAssetGroupState {
  assetGroup: {
    uuid: string
    tmpId: string
    name: string
    audio: any
    video: any
    subTitles: any
    mediaProfile: string
    isIntegrated: boolean
    multiAsset: boolean
    veService: boolean
  }
  assetGroupType: string
  activeTab: number
  isFetchValidated: boolean
  isPreviewed: boolean
  isTranscoded: boolean
  existingSubtitle?: any
}

const initialState: VideoAssetGroupState = {
  assetGroup: {
    uuid: '',
    tmpId: '',
    name: '',
    audio: [],
    video: [],
    subTitles: [],
    mediaProfile: '',
    isIntegrated: false,
    multiAsset: false,
    veService: false,
  },
  assetGroupType: '',
  activeTab: 1,
  isFetchValidated: false,
  isPreviewed: false,
  isTranscoded: false,
}

const videoAssetGroupSlice = createSlice({
  name: 'videoAssetGroup',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetAssetGroupDetails: (state: any, _action: PayloadAction<any>) => {
      state.assetGroup = {
        ...state.assetGroup,
        uuid: '',
        tmpId: '',
        name: '',
        audio: [],
        video: [],
        subTitles: [],
        mediaProfile: '',
        isIntegrated: false,
        multiAsset: false,
        veService: false,
        assetType: '',
      }
      state.assetGroupType = ''
      state.activeTab = 1
      state.isFetchValidated = false
      state.isPreviewed = false
      state.isTranscoded = false
    },
    setAssetGroupDetails: (state: any, action: PayloadAction<any>) => {
      const { payload } = action
      state.assetGroup = {
        ...state.assetGroup,
        uuid: payload?._id || '',
        tmpId: payload?.assetGroupId || '',
        name: payload?.assetGroupName || '',
        audio: payload?.audio || [],
        video: payload?.video || [],
        subTitles: payload?.subTitles || [],
        mediaProfile: payload?.mediaProfile || '',
        isIntegrated: payload?.isIntegrated || false,
        veService: payload?.veService || false,
        multiAsset: payload?.multiAsset || false,
        assetType: payload?.assetType,
      }
    },
    setAssetGroupType: (state: any, action: PayloadAction<any>) => {
      const { payload = '' } = action
      state.assetGroupType = payload
    },

    setAssetGroupActiveTab: (state: any, action: PayloadAction<any>) => {
      const { payload = '' } = action
      state.activeTab = payload
    },
    setAssetGroupIsFetchValidate: (state: any, action: PayloadAction<any>) => {
      const { payload = false } = action
      state.isFetchValidated = payload
    },
    setAssetGroupIsPreviewed: (state: any, action: PayloadAction<any>) => {
      const { payload = false } = action
      state.isPreviewed = payload
    },
    setAssetGroupIsTranscoded: (state: any, action: PayloadAction<any>) => {
      const { payload = false } = action
      state.isTranscoded = payload
    },
    setExistingSubtitlesSlice: (state: any, action: PayloadAction<any>) => {
      state.existingSubtitle = action.payload
    },
  },
})

export const {
  resetAssetGroupDetails,
  setAssetGroupDetails,
  setAssetGroupType,
  setAssetGroupActiveTab,
  setAssetGroupIsFetchValidate,
  setAssetGroupIsPreviewed,
  setAssetGroupIsTranscoded,
  setExistingSubtitlesSlice,
} = videoAssetGroupSlice.actions

const videoAssetGroupSelector = (state: { videoAssetGroup: VideoAssetGroupState }) =>
  state.videoAssetGroup

export const selectAssetGroupDetails = createSelector(
  videoAssetGroupSelector,
  (state) => state.assetGroup,
)

export const selectAssetGroupType = createSelector(
  videoAssetGroupSelector,
  (state) => state.assetGroupType,
)

export const selectAssetGroupActiveTab = createSelector(
  videoAssetGroupSelector,
  (state) => state.activeTab,
)

export const selectAssetGroupIsFetchValidated = createSelector(
  videoAssetGroupSelector,
  (state) => state.isFetchValidated,
)

export const selectAssetGroupIsPreviewed = createSelector(
  videoAssetGroupSelector,
  (state) => state.isPreviewed,
)

export const selectAssetGroupIsTranscoded = createSelector(
  videoAssetGroupSelector,
  (state) => state.isTranscoded,
)
export const existingSubtitleRedux = createSelector(
  videoAssetGroupSelector,
  (state) => state.existingSubtitle,
)

export const getMediaProfileList = createAsyncThunk(
  'videoAssetGroup/getMediaProfileList',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: ViewMastersAPIDefinition = SYSTEM_CONFIG_APIS.LIST_MASTERS(
      'MEDIA_PROFILE,TRANSCODING_PROCESS_TYPE',
    )
    const result: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    const mediaProfileCopy: any = []
    const transcodingProcessCopy: any = []
    let tempObj: any = {}
    if (result?.responseCode === 200) {
      result?.data?.forEach((item: any) => {
        if (item.configKey === 'MEDIA_PROFILE') {
          item.configValue.forEach((ele: any) => {
            mediaProfileCopy.push({ key: ele?.key, value: ele?.value, id: ele?.key })
          })
        }
        if (item.configKey === 'TRANSCODING_PROCESS_TYPE') {
          item.configValue.forEach((obj: any) => {
            transcodingProcessCopy.push({ key: obj?.key, value: obj?.value, id: obj?.key })
          })
        }
      })
    }
    tempObj = { media: mediaProfileCopy, process: transcodingProcessCopy }
    return tempObj
  },
)

export const createAssetGroup = createAsyncThunk(
  'videoAssetGroup/createAssetGroup',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.CREATE_ASSET_GROUP(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getAssetGroupDetails = createAsyncThunk(
  'videoAssetGroup/getAssetGroup',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.GET_ASSET_DATA(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const getSubtitleDataService = createAsyncThunk(
  'videoAssetGroup/getSubtitle',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.GET_SUBTITLE_DATA(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const updateAssetGroup = createAsyncThunk(
  'videoAssetGroup/updateAssetGroup',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.UPDATE_ASSET_GROUP(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const updoadSubtitleService = createAsyncThunk(
  'videoAssetGroup/uploadSubtitle',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.UPLOAD_SUBTITLE(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const deleteGroupService = createAsyncThunk(
  'videoAssetGroup/deleteAssetGroup',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.DELETE_ASSET_GROUP(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const workflowStatusService = createAsyncThunk(
  'videoAssetGroup/workflowStatus',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.WORKFLOW_STATUS(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const fetchAndValidateService = createAsyncThunk(
  'videoAssetGroup/fetchValidate',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.FETCH_VALIDATE(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const previewService = createAsyncThunk(
  'videoAssetGroup/previewVideo',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.PREVIEW_VIDEO(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const transcodingService = createAsyncThunk(
  'videoAssetGroup/transcoding',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.TRANSCODING_VALIDATION(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getDRMPreviewURL = createAsyncThunk(
  'videoAssetGroup/getDRMPreviewURL',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.PREVIEW_URL_DRM_TYPE(payload)
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getDRMTypeService = createAsyncThunk(
  'videoAssetGroup/getDRMType',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.VIDEO_CONTENT_TYPE.DRM_TYPE(
      payload?.contentVersionId,
      payload?.queryParams,
    )
    const data: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export default videoAssetGroupSlice
