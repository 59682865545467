import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../Constants'
import { TIPS_MODULE } from '../../../Helpers/ManageTips'
import { getPageTitle } from '../../../Helpers/Util'
import { BreadcrumsModel } from '../../../Models'
import { useAppDispatch } from '../../../Store/Hooks'
import { clearAttributeData } from '../../../Store/Slice/attribute.slice'
import '../../MangeRole/ManageRole.scss'
import BackButton from '../../Shared/BackButton'
import Breadcrums from '../../Shared/Breadcrums'
import MUITipsCard from '../../Shared/MUI-Card'
import AttributeDetailForm from './AttributeDetailForm'
import AttributeInformation from './AttributeInformation'

const AttributeEditor: React.FC<{ id: string | undefined; attributeId: string | undefined }> = ({
  id,
  attributeId,
}: {
  id: string | undefined
  attributeId: string | undefined
}) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  const { MetaMaster, AttributeRepository, PageTypeView } = PAGES_ROUTES
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleBackButtonAction = () => {
    let url = ''
    if (id === CREATE || id === VIEW) {
      url = `/${MetaMaster}/${AttributeRepository}`
    }
    if (id === EDIT && attributeId) {
      url = `/${MetaMaster}/${AttributeRepository}/${PageTypeView}/${attributeId}`
    }
    if (url) {
      navigate(url)
    }
  }

  useEffect(() => {
    if (id === CREATE && id === VIEW) {
      dispatch(clearAttributeData())
    }
  }, [id])

  return (
    <>
      <div className='w-100'>
        <div className={`role__editor p-16 ${id !== VIEW ? 'pb-120' : ''}`}>
          <div className='p-8'>
            <Breadcrums
              breadcrums={
                BREADCRUMS.CREATE_ATTRIBUTE(id, getPageTitle(id as string)) as BreadcrumsModel[]
              }
            />
          </div>
          <div className='p-8'>
            <BackButton
              label={`${getPageTitle(id as string)} Attribute`}
              className='col73'
              handleOnClick={handleBackButtonAction}
            />
          </div>
          <div className={`role__editor-wrapper ${id !== VIEW && 'mt-1'}`}>
            <div className='role__editor-content p-8'>
              {id === CREATE && <AttributeDetailForm type={CONSTANTS.PAGE_TYPE.CREATE} />}
              {id === EDIT && !!attributeId && (
                <AttributeDetailForm type={CONSTANTS.PAGE_TYPE.EDIT} attributeId={attributeId} />
              )}
              {id === VIEW && (
                <div className='mt-1'>
                  <AttributeInformation attributeId={attributeId} />
                </div>
              )}
            </div>
            <div className={`p-8 ml-1 mt- 1 ${id === VIEW ? 'mt-1' : ''}`}>
              <MUITipsCard
                tipsArr={CONSTANTS.PLACEHOLDER_TIPS}
                module={TIPS_MODULE.ATTRIBUTE_REPOSITORY}
                pageType={id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(AttributeEditor)
