const EditIcon = () => {
  return (
    <svg width='23' height='22' viewBox='0 0 23 22' fill='none'>
      <path
        d='M12.7957 5.50154L14.9762 3.32108C16.0501 2.24714 17.7913 2.24714 18.8653 3.32108L19.1012 3.55699C20.1751 4.63094 20.1751 6.37214 19.1012 7.44608L16.9207 9.62654M12.7957 5.50154L2.98089 15.3164C2.80898 15.4883 2.7124 15.7215 2.7124 15.9646V19.7099H6.45771C6.70082 19.7099 6.93398 19.6133 7.10589 19.4414L16.9207 9.62654M12.7957 5.50154L16.9207 9.62654'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default EditIcon
