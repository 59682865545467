import { DeleteOutlineOutlined, EditOutlined, ExpandMore } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../../Helpers/Permission'
import { convertBooleanIntoYesAndNo, getValueByKey, isValidArray } from '../../../Helpers/Util'
import updateTagMetaData from '../../../Helpers/updateTagMetaData'
import { RoleConfirmationModal } from '../../../Models'
import { useAppDispatch, useAppSelector } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { getDataSource, selectDataSource } from '../../../Store/Slice/asset-definition.slice'
import {
  changeAttributeStatus,
  deleteAttribute,
  getAttributeService,
} from '../../../Store/Slice/attribute.slice'
import RoleConfirmation from '../../Dialogs/RoleConfirmation'
import ChipButton from '../../Shared/ChipButton'
import MUIBox from '../../Shared/MUI-Box'

const AttributeInformation = ({ attributeId = '' }: any) => {
  const accessWrite = checkPermission(PERMISSIONS.META_MASTER_ATTRIBUTE_REPO, [
    PERMISSION_TYPE.CAN_WRITE,
  ])

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const masterDataListStore: any[] = useAppSelector(selectDataSource)
  const [attribute, setAttribute] = useState<any>({})
  const [dialogProps, setDialogProps] = React.useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })

  useEffect(() => {
    if (attribute?.label) {
      updateTagMetaData({ title: `Attribute: ${attribute?.label as string}` })
    }
    return () => {
      updateTagMetaData({})
    }
  }, [attribute?.fieldName])

  const getAttributeData = async () => {
    const { payload = {} } = await dispatch(getAttributeService(attributeId as string) as any)
    const { data = {} } = payload
    const clone = { ...data }
    clone.additionalProperties = JSON.stringify(clone?.additionalProperties) || ''
    setAttribute(clone)
  }

  useEffect(() => {
    dispatch(getDataSource() as any)
    if (attributeId) {
      getAttributeData()
    }
  }, [])

  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
  }

  const handleStatusChange = async (type: string) => {
    const result = await dispatch(
      changeAttributeStatus({ attributeId: attributeId || '', status: type }) as any,
    )
    const unwrappableRes = unwrapResult(result)
    if (unwrappableRes?.error || !unwrappableRes) {
      handleClose()
      if (unwrappableRes?.error?.responseCode === 422) {
        setDialogProps({
          heading: CONSTANTS.CHANGE_ATTRIBUTE_STATUS,
          description: unwrappableRes.error.messaage,
          color: 'warning',
          Icon: 'DeleteRoleWarIcon',
          open: true,
          iconColor: 'warning',
          cancelButtonLabel: 'Okay',
        })
        return
      }
      dispatch(showAlert([true, unwrappableRes?.error?.message, 'error']))
      return
    }
    dispatch(showAlert([true, unwrappableRes.message, 'success']))
    getAttributeData()
    handleClose()
  }

  const handleDelete = async () => {
    const result = await dispatch(deleteAttribute(attributeId as string) as any)
    const unwrappableRes = unwrapResult(result)
    if (unwrappableRes?.error || !unwrappableRes) {
      if (unwrappableRes?.error?.blitzCode === CONSTANTS.BLITZ_ERROR_CODE_BLOCK_DELETE_ATTRIBUTE) {
        handleClose()
        setDialogProps({
          heading: CONSTANTS.DELETE_ATTRIBUTE_TITLE,
          description: `${CONSTANTS.ATTEIBUTE_DELETE_BLOCK_MESSAGE} `,
          color: 'warning',
          Icon: 'DeleteRoleWarIcon',
          open: true,
          iconColor: 'warning',
          cancelButtonLabel: null,
          successButtonLabel: 'Okay',
          handleSuccess: handleClose,
        })
        return
      }
      dispatch(
        showAlert([true, unwrappableRes?.error?.message || unwrappableRes?.message, 'error']),
      )
      return
    }
    dispatch(showAlert([true, unwrappableRes.message, 'success']))
    handleClose()
    navigate('/metamaster/attribute-repo')
  }

  const openDialog = (type: string) => {
    if (type === 'delete') {
      if (attribute?.isAssigned) {
        setDialogProps({
          heading: CONSTANTS.DELETE_ATTRIBUTE_TITLE,
          description: (
            <div>
              <p>
                Can not remove attribute, assigned to existing asset type and asset has been
                created.
              </p>
            </div>
          ),
          color: 'warning',
          Icon: 'DeleteRoleWarIcon',
          open: true,
          iconColor: 'warning',
          cancelButtonLabel: null,
          successButtonLabel: 'Okay',
          handleSuccess: handleClose,
        })
      } else {
        setDialogProps({
          heading: CONSTANTS.DELETE_ATTRIBUTE_TITLE,
          description: (
            <div>
              <p>
                {CONSTANTS.DELETE_ATTRIBUTE_DESCRIPTION1}
                <strong style={{ fontWeight: 'bold', color: '#3E3F40', display: 'inline-block' }}>
                  {attribute?.fieldName}
                </strong>
                {CONSTANTS.DELETE_ATTRIBUTE_DESCRIPTION2}
              </p>
            </div>
          ),
          color: 'error',
          Icon: 'DeleteRoleIcon',
          open: true,
          iconColor: 'error',
          successButtonLabel: 'Delete',
          cancelButtonLabel: 'Cancel',
          handleSuccess: handleDelete,
        })
      }
    }
    if (type === 'status-active') {
      setDialogProps({
        heading: CONSTANTS.ATTRIBUTE_STATUS_ACTIVE_TITLE,
        description: (
          <div>
            <p>
              Are you sure you want to activate the previously deactivated attribute{' '}
              <strong style={{ fontWeight: 'bold', color: '#3E3F40', display: 'inline-block' }}>
                {attribute?.fieldName}
              </strong>
              ?
            </p>
          </div>
        ),
        color: 'primary',
        Icon: 'ActiveIcon',
        open: true,
        iconColor: 'success',
        successButtonLabel: 'Activate',
        cancelButtonLabel: 'Cancel',
        handleSuccess: () => handleStatusChange('ACTIVE'),
      })
    }
    if (type === 'status-inactive') {
      if (attribute?.isAssigned) {
        setDialogProps({
          heading: CONSTANTS.ATTRIBUTE_STATUS_DEACTIVE_TITLE,
          description: (
            <div>
              <p>
                Can not deactivate attribute, assigned to existing asset type and asset has been
                created
              </p>
            </div>
          ),
          color: 'warning',
          Icon: 'DeleteRoleWarIcon',
          open: true,
          iconColor: 'warning',
          cancelButtonLabel: null,
          successButtonLabel: 'Okay',
          handleSuccess: handleClose,
        })
      } else {
        setDialogProps({
          heading: CONSTANTS.ATTRIBUTE_STATUS_DEACTIVE_TITLE,
          description: (
            <div>
              <p>
                Are you sure you want to deactivate attribute{' '}
                <strong style={{ fontWeight: 'bold', color: '#3E3F40', display: 'inline-block' }}>
                  {attribute?.fieldName}
                </strong>
                ?
              </p>
            </div>
          ),
          color: 'error',
          Icon: 'InactiveIcon',
          open: true,
          iconColor: 'error',
          successButtonLabel: 'Deactivate',
          cancelButtonLabel: 'Cancel',
          handleSuccess: () => handleStatusChange('INACTIVE'),
        })
      }
    }
  }

  const getPartnersFromArray = (partnersArr: any[]) => {
    let result = 'NA'
    if (isValidArray(partnersArr)) {
      result = ''
      partnersArr?.forEach((item: any, index: number) => {
        result = result + item + `${index + 1 === partnersArr?.length ? '' : ', '}`
      })
    }
    return result
  }

  const keyValueFormat = (key: any, value: any, isRequired?: boolean): ReactNode => {
    return (
      <Grid container>
        <Grid item xs={6} md={3}>
          <div className='sub-heading-6 view__role-list  text-left col34'>
            {key}
            {isRequired && <span style={{ color: '#D4351C' }}>*</span>}
          </div>
        </Grid>
        <Grid item xs={6} md={9}>
          <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>{value}</div>
        </Grid>
      </Grid>
    )
  }

  const isValidLabel = () => Array.isArray(attribute?.attributeRef?.map((item: any) => item?.label))

  const getLabelArray = () => attribute?.attributeRef.map((item: any) => item.label) || []

  return (
    <>
      <MUIBox>
        <div>
          <div className='heading-6 d-flex text-left rolebox__header-padding'>
            <div className='d-flex align-items-center'>Attribute Information</div>
            {accessWrite && (
              <div className='ml-auto d-flex'>
                <div className='mr-1'>
                  <ChipButton
                    type={attribute?.status === 'ACTIVE' ? 'success' : 'secondary'}
                    dropdown
                    Icon={ExpandMore}
                    label={
                      attribute?.status === 'ACTIVE'
                        ? CONSTANTS.STATUS_LABEL.ACTIVE
                        : CONSTANTS.STATUS_LABEL.INACTIVE
                    }
                    suffix
                    options={CONSTANTS.STATUS_LIST}
                    action={openDialog}
                    value={attribute?.status ?? 'ACTIVE'}
                  />
                </div>
                <div
                  className='mr-1'
                  onClick={() => openDialog('delete')}
                  data-testid='delete__chip-btn'
                >
                  <ChipButton type='danger' Icon={DeleteOutlineOutlined} label='Delete' />
                </div>
                <div
                  onClick={() => {
                    navigate(
                      `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.AttributeRepository}/edit/${attribute._id}`,
                    )
                  }}
                  data-testid='edit__chip-btn'
                >
                  <ChipButton type='primary' Icon={EditOutlined} label='Edit' />
                </div>
              </div>
            )}
          </div>
          <div className={'hr'}></div>
          <div className=''>
            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Field Name<span style={{ color: '#D4351C' }}>*</span>
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                {attribute?.fieldName ? (
                  <div
                    className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                    title={attribute?.fieldName?.length > 40 ? attribute?.fieldName : ''}
                  >
                    {attribute?.fieldName || 'NA'}
                  </div>
                ) : (
                  <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>NA</div>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Label<span style={{ color: '#D4351C' }}>*</span>
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                {attribute?.label ? (
                  <div
                    className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                    title={attribute?.label?.length > 40 ? attribute?.label : ''}
                  >
                    {attribute?.label}
                  </div>
                ) : (
                  <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>NA</div>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Field Type<span style={{ color: '#D4351C' }}>*</span>
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                {attribute?.fieldType ? (
                  <div
                    className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                    title={attribute?.fieldType?.length > 40 ? attribute?.fieldType : ''}
                  >
                    {attribute?.fieldType}
                  </div>
                ) : (
                  <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>NA</div>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Data Type<span style={{ color: '#D4351C' }}>*</span>
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                {attribute?.dataType ? (
                  <div
                    className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                    title={attribute?.dataType?.length > 40 ? attribute?.dataType : ''}
                  >
                    {attribute?.dataType || 'NA'}
                  </div>
                ) : (
                  <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>NA</div>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list text-left col34'>ES Field</div>
              </Grid>
              <Grid item xs={6} md={9}>
                {attribute?.esField ? (
                  <div
                    className='sub-heading-6 view__role-list text-left col36'
                    title={attribute?.esField?.length > 40 ? attribute?.esField : ''}
                  >
                    {attribute?.esField}
                  </div>
                ) : (
                  <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>NA</div>
                )}
              </Grid>
            </Grid>

            {keyValueFormat(
              'Required',
              typeof attribute?.isRequired === 'boolean'
                ? convertBooleanIntoYesAndNo(attribute?.isRequired)
                : attribute?.isRequired,
              true,
            )}

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Status<span style={{ color: '#D4351C' }}>*</span>
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                  {attribute?.status === 'ACTIVE' && (
                    <ChipButton type='success' label='Active' style={{ padding: '0rem 0.5rem' }} />
                  )}
                  {attribute?.status === 'INACTIVE' && (
                    <ChipButton
                      type='secondary'
                      label='In Active'
                      style={{ padding: '0rem 0.5rem' }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>

            {keyValueFormat(
              'Is Multilingual',
              typeof attribute?.isMultilingual === 'boolean'
                ? convertBooleanIntoYesAndNo(attribute?.isMultilingual)
                : attribute?.isMultilingual,
            )}

            {attribute?.fieldType !== 'COMPLEXTYPE' && (
              <Grid container>
                <Grid item xs={6} md={3}>
                  <div className='sub-heading-6 view__role-list  text-left col34'>
                    Placeholder<span style={{ color: '#D4351C' }}>*</span>
                  </div>
                </Grid>
                <Grid item xs={6} md={9}>
                  {attribute?.placeholder ? (
                    <div
                      className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                      title={attribute?.placeholder?.length > 40 ? attribute?.placeholder : ''}
                    >
                      {attribute?.placeholder}
                    </div>
                  ) : (
                    <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                      NA
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            {keyValueFormat(
              'B2B',
              typeof attribute?.isB2B === 'boolean'
                ? convertBooleanIntoYesAndNo(attribute?.isB2B)
                : attribute?.isB2B,
              true,
            )}

            {keyValueFormat(
              'Add More',
              typeof attribute?.addMore === 'boolean'
                ? convertBooleanIntoYesAndNo(attribute?.addMore)
                : attribute?.addMore,
            )}

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Description<span style={{ color: '#D4351C' }}>*</span>
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                {attribute?.description ? (
                  <div
                    className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                    title={attribute?.description?.length > 40 ? attribute?.description : ''}
                  >
                    {attribute?.description || 'NA'}
                  </div>
                ) : (
                  <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>NA</div>
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} md={3}>
                <div className='sub-heading-6 view__role-list  text-left col34'>
                  Additional Properties
                </div>
              </Grid>
              <Grid item xs={6} md={9}>
                {attribute?.additionalProperties && attribute?.additionalProperties !== 'null' ? (
                  <div
                    className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                    title={
                      attribute?.additionalProperties?.length > 40
                        ? attribute?.additionalProperties
                        : ''
                    }
                  >
                    {attribute?.additionalProperties}
                  </div>
                ) : (
                  <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>NA</div>
                )}
              </Grid>
            </Grid>

            {attribute?.fieldType !== 'COMPLEXTYPE' && (
              <Grid container>
                <Grid item xs={6} md={3}>
                  <div className='sub-heading-6 view__role-list text-left col34'>Default Value</div>
                </Grid>
                <Grid item xs={6} md={9}>
                  {attribute?.defaultValue ? (
                    <div
                      className='sub-heading-6 view__role-list text-left col36 word-wrap'
                      title={attribute?.defaultValue?.length > 40 ? attribute?.defaultValue : ''}
                    >
                      {attribute?.defaultValue}
                    </div>
                  ) : (
                    <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                      NA
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            {attribute?.fieldType !== 'COMPLEXTYPE' &&
              keyValueFormat(
                'Multiselect',
                typeof attribute?.isMultiple === 'boolean'
                  ? convertBooleanIntoYesAndNo(attribute?.isMultiple)
                  : attribute?.isMultiple,
                true,
              )}

            {attribute?.fieldType !== 'COMPLEXTYPE' && (
              <Grid container>
                <Grid item xs={6} md={3}>
                  <div className='sub-heading-6 view__role-list  text-left col34'>Master</div>
                </Grid>
                <Grid item xs={6} md={9}>
                  {attribute?.master ? (
                    <div
                      className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                      title={attribute?.master?.length > 40 ? attribute?.master : ''}
                    >
                      {getValueByKey(attribute?.master, masterDataListStore) || 'NA'}
                    </div>
                  ) : (
                    <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                      {attribute?.master === '' ? 'Custom Option' : 'NA'}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            {attribute?.fieldType !== 'COMPLEXTYPE' &&
              (attribute?.fieldType !== 'SELECT' || attribute?.dataType !== 'BOOLEAN') &&
              attribute?.master === '' && (
                <Grid container>
                  <Grid item xs={6} md={3}>
                    <div className='sub-heading-6 view__role-list text-left col34'>Options</div>
                  </Grid>
                  <Grid item xs={6} md={9}>
                    <div
                      className='sub-heading-6 view__role-list text-left col36'
                      title={attribute?.options?.length > 40 ? attribute?.options : ''}
                    >
                      {isValidArray(attribute?.options)
                        ? attribute?.options[0] !== ''
                          ? attribute?.options.join(',')
                          : 'NA'
                        : 'NA'}
                    </div>
                  </Grid>
                </Grid>
              )}
            {attribute?.fieldType === 'COMPLEXTYPE' && (
              <Grid container>
                <Grid item xs={6} md={3}>
                  <div className='sub-heading-6 view__role-list  text-left col34'>
                    Meta Attributes
                  </div>
                </Grid>
                <Grid item xs={6} md={9}>
                  {isValidLabel() &&
                  attribute?.attributeRef?.map((item: any) => item?.label)?.length > 0 ? (
                    <div
                      className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                      title={
                        attribute?.fieldName?.length > 40
                          ? getPartnersFromArray(getLabelArray())
                          : ''
                      }
                    >
                      {getPartnersFromArray(getLabelArray())}
                    </div>
                  ) : (
                    <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                      NA
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            {typeof attribute?.isB2B === 'boolean' && attribute?.isB2B && (
              <Grid container>
                <Grid item xs={6} md={3}>
                  <div className='sub-heading-6 view__role-list  text-left col34'>
                    Partner`s name<span style={{ color: '#D4351C' }}>*</span>
                  </div>
                </Grid>
                <Grid item xs={6} md={9}>
                  {attribute?.affiliatePartner ? (
                    <div
                      className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                      title={
                        attribute?.affiliatePartner?.length > 40
                          ? getPartnersFromArray(attribute.affiliatePartner ?? [])
                          : ''
                      }
                    >
                      {getPartnersFromArray(attribute.affiliatePartner ?? [])}
                    </div>
                  ) : (
                    <div className='sub-heading-6 view__role-list  text-left col36 word-wrap'>
                      NA
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </MUIBox>
      <RoleConfirmation {...dialogProps} handleClose={handleClose} />
    </>
  )
}

export default React.memo(AttributeInformation)
