import axios from 'axios'
import { ASSET_CURD_API } from '../Constants/ApiDefinations'
import { CONSTANTS } from '../Constants/Constant'
import { TypeObject } from '../Models'
import mainApiService from '../Services'

export const getMetaAttributesListItems = (props: {
  assets:
    | {
        fieldName: string
        isRequired: boolean
        isMultiple: boolean
        validations: {
          validateAspectRatio: { customValues: string }
          validateResolution: { customValues: string }
          validateFileType: { customValues: string }
        }
      }[]
    | undefined
  variantsList: Record<string, any[]> | undefined
}): {
  fieldName: string
  isRequired: boolean
  variantsData: any[]
  variantsDataPlaceholder: any
}[] => {
  const { assets, variantsList } = props

  const getFormattedImageUrl = (props: { url: string }): string => {
    return (
      CONSTANTS.CLOUDFRONT_BASE_URL +
        (props?.url ? 's3://' + props.url : '')?.slice(CONSTANTS.S3_BUCKET_BASE_URL?.length) || ''
    )
  }

  const imageData = (Array.isArray(assets) ? assets : []).map((item) => {
    const variantsDataPlaceholder: any = {
      ...CONSTANTS.IMAGE_CONTENT_TYPE_CARD_DETAILS,
      isPlaceholder: true,
      isRequired: item?.isRequired,
      isMultiple: item?.isMultiple,
      aspectRatio: item?.validations?.validateAspectRatio?.customValues || 'NA',
      imageResolution: item?.validations?.validateResolution?.customValues || 'NA',
      fileTypeAllowed:
        (item?.validations?.validateFileType?.customValues || '')
          .split(',')
          .map((type: string) => `image/${type?.toLowerCase()?.trim()}`)
          .join(',') || '',
    }

    const reqVariantsData = variantsList?.[item?.fieldName]
    const variantsData: any[] =
      Array.isArray(reqVariantsData) && reqVariantsData.length > 0
        ? reqVariantsData.map((variant) => ({
            ...variant,
            isPlaceholder: !variant?.imageKey?.length,
            isRequired: item?.isRequired,
            /**
             * Commneted below ratio to get latest updated values
             */
            // aspectRatio: variant?.aspectRatio || 'NA',
            // imageResolution: variant?.resolution || 'NA',
            aspectRatio: item?.validations?.validateAspectRatio?.customValues || 'NA',
            imageResolution: item?.validations?.validateResolution?.customValues || 'NA',

            fileTypeAllowed:
              (item?.validations?.validateFileType?.customValues || '')
                .split(',')
                .map((type: string) => `image/${type?.toLowerCase()?.trim()}`)
                .join(',') || '',
            imageUploadType: variant?.imageUploadType,
            title: variant?.title,
            newUrlForEditing: getFormattedImageUrl(variant),
            imageSrc: getFormattedImageUrl(variant),
            imageUrl: getFormattedImageUrl(variant),
          }))
        : [variantsDataPlaceholder]

    return {
      ...item,
      variantsData,
      variantsDataPlaceholder,
    }
  })

  return imageData
}

export const validateMarkAsDoneStatus = (
  metaAttributesListItems: {
    isRequired: boolean
    variantsData: {
      imageUrl?: string
      isVariantValid?: boolean
      errorType?: string
    }[]
  }[],
  allVariantsDetails:
    | {
        data?: {
          attributes?: Record<string, any>[]
        }
      }
    | undefined,
): boolean => {
  const checkVariantsData = (
    variantsData: {
      imageKey?: string
      isVariantValid?: boolean
      errorType?: string
    }[],
  ): boolean => {
    return Array.isArray(variantsData) && variantsData.some((variant) => !variant?.imageKey)
  }

  if (
    metaAttributesListItems.some(
      ({ isRequired, variantsData }) => isRequired && checkVariantsData(variantsData),
    )
  ) {
    return true
  }

  const checkAttributes = (attributes: Record<string, any>[]): boolean =>
    Object.values(attributes).some((reqObject) => {
      if (reqObject && typeof reqObject === 'object') {
        if (Array.isArray(reqObject)) {
          return checkAttributes(reqObject)
        }
        if (
          Object.prototype.hasOwnProperty.call(reqObject, 'isVariantValid') &&
          !reqObject?.isVariantValid
        ) {
          return true
        }
        if (reqObject.errorType === CONSTANTS.ERROR_TEXT) {
          return true
        }
      }
      return false
    })

  if (
    typeof allVariantsDetails?.data?.attributes === 'object' &&
    checkAttributes(allVariantsDetails.data.attributes)
  ) {
    return true
  }
  return false
}

export const acceptedFileTypes = (
  fileTypeAllowed: string,
): {
  acceptedFileExtensions: string
  acceptObject: Record<string, any> | null
} => {
  const fileTypeAllowedArray =
    fileTypeAllowed && typeof fileTypeAllowed === 'string' ? fileTypeAllowed?.split(',') : []

  const acceptedFileExtensionsArray: string[] = []
  const obj: Record<string, any[]> = {}

  if (Array.isArray(fileTypeAllowedArray) && fileTypeAllowedArray.length > 0) {
    for (let i = 0; i < fileTypeAllowedArray.length; i++) {
      if (
        fileTypeAllowedArray[i] === 'image/jpg' &&
        !Object.prototype.hasOwnProperty.call(obj, 'image/jpeg')
      ) {
        acceptedFileExtensionsArray.push(fileTypeAllowedArray[i].replace('image/jpg', '.jpeg'))
        obj['image/jpeg'] = []
      }
      acceptedFileExtensionsArray.push(fileTypeAllowedArray[i].replace('image/', '.'))
      obj[fileTypeAllowedArray[i]] = []
    }

    const acceptedFileExtensions = acceptedFileExtensionsArray.join(', ') ?? ''
    return { acceptedFileExtensions, acceptObject: obj }
  }

  return { acceptedFileExtensions: '', acceptObject: null }
}

export const removeDuplicatesByNameWithNumber = (
  array: Array<Record<string, any>>,
  key: string,
) => {
  const countMap = new Map()
  return array.map((item: Record<string, any>) => {
    const originalName = item[key]
    let newName = originalName
    let count = countMap.get(originalName) || 0

    while (count > 0) {
      newName = `${originalName} ${count + 1}`
      if (!array.some((item: Record<string, any>) => item[key] === newName)) {
        break
      }
      count++
    }

    countMap.set(originalName, count + 1)

    return {
      ...item,
      [key]: newName,
    }
  })
}

export const validateMediaImageForm = (props: {
  isFirstVariantSelected: boolean
  formData: Record<string, any>
  formDataErrors: Record<string, any>
  selectedFile: File | null
  addEditDetails: Record<string, any>
  isValidInputUrl: boolean
}) => {
  const { inputUrl, targeting, segmentId } = props.formData
  const variantData = props?.addEditDetails?.variantData || {}
  const varinatDataWithContent =
    props?.addEditDetails?.metaAttributesListItem?.variantsData?.filter((item: any) => item?.url)

  if (props.isFirstVariantSelected) {
    if (varinatDataWithContent?.length > 1) {
      if (!(props.selectedFile || inputUrl)) {
        return false
      }
      return true
    } else if (variantData.variantId && variantData.imageKey) {
      return true
    } else if (props.selectedFile || inputUrl) {
      return true
    }
  } else {
    if (
      (props.selectedFile || inputUrl) &&
      ((targeting && targeting.length) || (segmentId && segmentId.length))
    ) {
      return true
    }
  }
  return false
}

export const getValidInputUrl = (inputUrl: string) => {
  return CONSTANTS.CLOUDFRONT_BASE_URL + inputUrl?.slice(CONSTANTS.S3_BUCKET_BASE_URL?.length)
}

export const validatePreviewImageUrl = async (inputUrlWithBaseURL: any) => {
  try {
    if (!inputUrlWithBaseURL.length) return
    const result = await fetch(inputUrlWithBaseURL)
    if (!result?.ok) {
      return false
    } else {
      return true
    }
  } catch (_error) {
    return false
  }
}

export const createImageFileName = (
  contentId: string,
  contentMetaCategoryId: string,
  metaCategory: string,
  fieldName: string,
  file: Record<string, any>,
) => {
  const IMAGE_TEXT = 'CONTENT_MEDIA_IMAGE_VARIANT'
  return `${IMAGE_TEXT}_${contentMetaCategoryId}_${fieldName}_${contentId}_${metaCategory}_${Date.now()}_${file.path.replaceAll(
    ' ',
    '-',
  )}`
}

export const createImageFileName2 = (
  blitzId: string,
  fieldName: string,
  file: Record<string, any>,
) => {
  return `${
    CONSTANTS.S3_BUCKET_IMAGE_BASE_FOLDER
  }${blitzId}/${fieldName}/${Date.now()}_${file.path.replaceAll(' ', '_')}`
}

export const createBulkMediaImageReqData = (
  contentMetaCategoryData: TypeObject,
  imageData: TypeObject[],
) => {
  const fieldNameArr = []
  for (const i of imageData) {
    const imageKey = createImageFileName2(contentMetaCategoryData?.blitzId, i.fieldName, {
      ...i.file,
      path: i.file.name,
    })
    fieldNameArr.push({
      imageKey: imageKey,
      url: CONSTANTS.S3_BUCKET_BASE_URL + '/' + (i?.file?.path ?? i?.file?.name),
      file: i.file,
      fieldName: i.fieldName,
    })
  }
  return fieldNameArr
}

export const createMediaImageReqData = (
  formData: any,
  selectedFile: any,
  addEditDetails: any,
  markDoneStatusArray: any,
  contentMetaCategoryData: any,
  isFirstVariantSelected: boolean,
) => {
  const { metaCategoryKey: metaCategory, markDoneStatus } = markDoneStatusArray.filter(
    (item: any) => item.metaCategoryKey === CONSTANTS.CATEGORIES.CONTENT_MEDIA_IMAGE,
  )[0]

  const {
    metaAttributesListItem: { fieldName },
    title,
    variantData,
  } = addEditDetails
  const { aspectRatio, imageResolution, variantId, imageUploadType } = variantData
  const { targeting, segmentId, inputUrl } = formData
  const { contentId } = contentMetaCategoryData?.ContentMetaCategory?.[0] || {}
  const { _id } = contentMetaCategoryData

  let url = '',
    imageKey = '',
    uploadImageType = imageUploadType,
    isImageRemoved = false

  if (selectedFile) {
    uploadImageType = CONSTANTS.MEDIA_IMAGE.UPLOAD_IMAGE_TYPE
    if (selectedFile.path) {
      const { blitzId } = contentMetaCategoryData
      imageKey = createImageFileName2(blitzId, fieldName, selectedFile)
      url = CONSTANTS.S3_BUCKET_BASE_URL + '/' + imageKey
    } else {
      url = CONSTANTS.S3_BUCKET_BASE_URL + '/' + selectedFile.name
      imageKey = selectedFile.name
    }
  } else if (inputUrl) {
    uploadImageType = CONSTANTS.MEDIA_IMAGE.URL_IMAGE_TYPE
    url = CONSTANTS.S3_BUCKET_BASE_URL + inputUrl.slice(CONSTANTS.CLOUDFRONT_BASE_URL?.length)
    imageKey = inputUrl.slice(CONSTANTS.CLOUDFRONT_BASE_URL?.length + 1)
  }
  const targetingData = (targeting || []).map((item: any) => item.key)
  const segmentIdData = (segmentId || []).map((item: any) => item.key)

  let data: any = {}
  if (variantId) {
    if (isFirstVariantSelected && !imageKey) {
      isImageRemoved = true
    }

    const targetingCountries: any = isFirstVariantSelected
      ? CONSTANTS.MEDIA_IMAGE.ALL_COUNTRIES_VALUE
      : targetingData ?? []

    const segmentIdD: any = isFirstVariantSelected
      ? CONSTANTS.MEDIA_IMAGE.ALL_COUNTRIES_VALUE
      : segmentIdData ?? []

    data = {
      ...data,
      attributeName: fieldName,
      variantId,
      contentId: _id, // added content id as per optimization changes
      variantData: {
        title,
        targeting: targetingCountries,
        segmentId: segmentIdD,
        url,
        imageKey,
        aspectRatio,
        resolution: imageResolution,
        imageUploadType: uploadImageType,
        isTargetingSegmentChanged: false,
        isNewImage: Boolean(selectedFile?.path),
        ...(inputUrl || variantData?.newUrlForEditing
          ? {}
          : {
              isAspectRatioError: false,
              isImageValid: true,
              isResolutionError: false,
              message: '',
            }),
      },
    }
    const targetingDataOld = JSON.parse(JSON.stringify(addEditDetails?.variantData?.targeting))
    const segmentIdDataOld = JSON.parse(JSON.stringify(addEditDetails?.variantData?.segmentId))
    const sortedOldTargeting = (targetingDataOld || [])?.sort()
    const sortedOldSegmentId = (segmentIdDataOld || [])?.sort()
    let sortedNewTargeting = [],
      sortedNewSegmentId = []
    if (targetingCountries?.length) {
      sortedNewTargeting = targetingCountries?.sort()
    }
    if (segmentIdD?.length) {
      sortedNewSegmentId = segmentIdD?.sort()
    }

    if (
      JSON.stringify(sortedOldTargeting) !== JSON.stringify(sortedNewTargeting) ||
      JSON.stringify(sortedOldSegmentId) !== JSON.stringify(sortedNewSegmentId)
    ) {
      data.variantData.isTargetingSegmentChanged = true
      data.variantData.oldTargeting = sortedOldTargeting
      data.variantData.oldSegmentId = addEditDetails?.variantData?.segmentId
    }
  } else {
    data = {
      ...data,
      contentId: _id,
      metaCategory: metaCategory,
      markDoneStatus: markDoneStatus,
      attributes: {
        [fieldName]: [
          {
            title,
            targeting: isFirstVariantSelected
              ? CONSTANTS.MEDIA_IMAGE.ALL_COUNTRIES_VALUE
              : targetingData ?? [],
            segmentId: isFirstVariantSelected
              ? CONSTANTS.MEDIA_IMAGE.ALL_COUNTRIES_VALUE
              : segmentIdData ?? [],
            url,
            imageKey,
            aspectRatio,
            resolution: imageResolution,
            imageUploadType: uploadImageType,
          },
        ],
      },
    }
  }
  return { imageKey, data, isImageRemoved }
}

export const uploadBulkImageUsingPresignedUrl = async (imageKeysData: any) => {
  const payload = ASSET_CURD_API.IMAGE_CONTENT_TYPE.GENERATE_PRESIGNED_URL_BULK({
    keys: imageKeysData.map((item: any) => ({ key: item.imageKey })),
  })
  const result = await mainApiService(payload)

  const payloadDataArr = result?.data?.urls?.map((imgUrl: string, idx: number) => ({
    file: imageKeysData[idx].file,
    preSignedUrl: imgUrl,
    imageKey: imageKeysData[idx].imageKey,
  }))

  const bulkImgPromiseArr = []

  for (const i of payloadDataArr) {
    const initPromise = fetch(i.preSignedUrl, {
      method: 'PUT',
      body: i.file,
    })

    bulkImgPromiseArr.push(initPromise)
  }

  try {
    const result = await Promise.allSettled(bulkImgPromiseArr)
    if (result) {
      return result
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const uploadImageUsingPresignedUrl = async (fileNameUploaded: string, file: File) => {
  try {
    const requestedData: any = ASSET_CURD_API.IMAGE_CONTENT_TYPE.GENERATE_PRESIGNED_URL(
      `key=${fileNameUploaded}`,
    )
    const { error, data } = await mainApiService(requestedData)
    if (error) {
      return false
    }
    const presignedUrl = data?.url || ''

    const uploadResponse = await axios.put(presignedUrl, file, {
      headers: {
        upload: true,
        'Content-Type': 'application/png',
      },
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        // setUploadProgress(percentCompleted)
        // setCsvInfo((prev: any) => ({ ...csvInfo, status: CONSTANTS.FILE_STATUS.SUCCESS }))
        console.log(`Upload Progress: ${percentCompleted}%`)
      },
    })

    console.log(uploadResponse)

    return fileNameUploaded

    // const response = await fetch(presignedUrl, {
    //   method: 'PUT',
    //   body: file,
    // })
    // if (response.ok) {
    //   return fileNameUploaded
    // } else {
    //   return false
    // }
  } catch (_error) {
    return false
  }
}

export const getTextColorStyleBasedOnErrorType = (imageDetails: TypeObject, type: string) => {
  if (imageDetails?.url) {
    const borderCss = type === 'text' ? '' : '1px solid '
    switch (imageDetails?.errorType) {
      case CONSTANTS.WARNING_TEXT:
        return borderCss + '#BF8600'
      case CONSTANTS.ERROR_TEXT:
        return borderCss + '#D4351C'
      default:
        return borderCss + 'rgba(0, 0, 0, 0.16)'
    }
  }
}

export const removeVariantImageError = (
  dispatch: any,
  setMarkAsDoneErrors: any,
  errors: any,
  fieldName: string,
) => {
  if (errors?.length) {
    dispatch(setMarkAsDoneErrors(errors.filter((item: any) => !item.includes(`.${fieldName}[`))))
  }
}
