const AssetStatusIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <circle cx='12' cy='12' r='12' fill='#ECE9F2' />
      <path
        d='M10.9585 13.0443V15.5443H8.4585M10.7502 15.3174C9.41098 14.8121 8.4585 13.5186 8.4585 12.0026C8.4585 10.0466 10.0442 8.46094 12.0002 8.46094C12.52 8.46094 13.0138 8.57295 13.4585 8.77416'
        stroke='black'
        strokeWidth='0.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.729 15.439C12.729 15.6116 12.5891 15.7515 12.4165 15.7515C12.2439 15.7515 12.104 15.6116 12.104 15.439C12.104 15.2664 12.2439 15.1265 12.4165 15.1265C12.5891 15.1265 12.729 15.2664 12.729 15.439Z'
        fill='black'
      />
      <path
        d='M14.3501 14.6139C14.4364 14.7634 14.3851 14.9545 14.2357 15.0408C14.0862 15.1271 13.8951 15.0759 13.8088 14.9264C13.7225 14.777 13.7737 14.5858 13.9232 14.4995C14.0726 14.4132 14.2638 14.4645 14.3501 14.6139Z'
        fill='black'
      />
      <path
        d='M15.3413 13.0888C15.4908 13.1751 15.542 13.3662 15.4557 13.5157C15.3694 13.6652 15.1783 13.7164 15.0288 13.6301C14.8794 13.5438 14.8282 13.3527 14.9145 13.2032C15.0008 13.0537 15.1919 13.0025 15.3413 13.0888Z'
        fill='black'
      />
      <path
        d='M14.9252 10.1926C14.7758 10.2789 14.5847 10.2276 14.4984 10.0782C14.4121 9.92871 14.4633 9.73759 14.6127 9.65129C14.7622 9.565 14.9533 9.61621 15.0396 9.76568C15.1259 9.91514 15.0747 10.1063 14.9252 10.1926Z'
        fill='black'
      />
      <path
        d='M15.7498 11.5849C15.7498 11.7575 15.6099 11.8974 15.4373 11.8974C15.2647 11.8974 15.1248 11.7575 15.1248 11.5849C15.1248 11.4123 15.2647 11.2724 15.4373 11.2724C15.6099 11.2724 15.7498 11.4123 15.7498 11.5849Z'
        fill='black'
      />
      <circle cx='12.5' cy='12.5' r='11.5' fill='#ECE9F2' />
      <rect x='16' y='5' width='2' height='7' fill='#24BFF3' />
      <rect x='9' y='12' width='2' height='7' fill='#24BFF3' />
      <g clipPath='url(#clip0_12662_35078)'>
        <path
          d='M14.9167 17.8333C13.3084 17.8333 12 16.5249 12 14.9167C12 13.3084 13.3084 12 14.9167 12C16.5249 12 17.8333 13.3084 17.8333 14.9167C17.8333 16.5249 16.5249 17.8333 14.9167 17.8333ZM14.9167 12.5833C13.6298 12.5833 12.5833 13.6298 12.5833 14.9167C12.5833 16.2035 13.6298 17.25 14.9167 17.25C16.2035 17.25 17.25 16.2035 17.25 14.9167C17.25 13.6298 16.2035 12.5833 14.9167 12.5833Z'
          fill='#402080'
        />
        <path
          d='M18.7084 18.9976C18.6338 18.9976 18.5591 18.969 18.5019 18.9124L16.7123 17.1227C16.5985 17.009 16.5985 16.8241 16.7123 16.7103C16.826 16.5966 17.0109 16.5966 17.1247 16.7103L18.9143 18.5C19.0281 18.6137 19.0281 18.7986 18.9143 18.9124C18.8578 18.969 18.7831 18.9976 18.7084 18.9976Z'
          fill='#402080'
        />
        <path
          d='M7.04167 19H5.875C5.39258 19 5 18.6074 5 18.125V14.625C5 14.1426 5.39258 13.75 5.875 13.75H7.04167C7.52408 13.75 7.91667 14.1426 7.91667 14.625V18.125C7.91667 18.6074 7.52408 19 7.04167 19ZM5.875 14.3333C5.71458 14.3333 5.58333 14.464 5.58333 14.625V18.125C5.58333 18.286 5.71458 18.4167 5.875 18.4167H7.04167C7.20208 18.4167 7.33333 18.286 7.33333 18.125V14.625C7.33333 14.464 7.20208 14.3333 7.04167 14.3333H5.875Z'
          fill='#402080'
        />
        <path
          d='M10.5417 18.9974H9.375C8.89258 18.9974 8.5 18.6048 8.5 18.1224V12.2891C8.5 11.8066 8.89258 11.4141 9.375 11.4141H10.5417C11.0241 11.4141 11.4167 11.8066 11.4167 12.2891V18.1224C11.4167 18.6048 11.0241 18.9974 10.5417 18.9974ZM9.375 11.9974C9.21458 11.9974 9.08333 12.1281 9.08333 12.2891V18.1224C9.08333 18.2834 9.21458 18.4141 9.375 18.4141H10.5417C10.7021 18.4141 10.8333 18.2834 10.8333 18.1224V12.2891C10.8333 12.1281 10.7021 11.9974 10.5417 11.9974H9.375Z'
          fill='#402080'
        />
        <path
          d='M12.2917 11.4141C12.1307 11.4141 12 11.2834 12 11.1224V8.78906C12 8.30665 12.3926 7.91406 12.875 7.91406H14.0417C14.5241 7.91406 14.9167 8.30665 14.9167 8.78906V10.5391C14.9167 10.7001 14.786 10.8307 14.625 10.8307C14.464 10.8307 14.3333 10.7001 14.3333 10.5391V8.78906C14.3333 8.62806 14.2021 8.4974 14.0417 8.4974H12.875C12.7146 8.4974 12.5833 8.62806 12.5833 8.78906V11.1224C12.5833 11.2834 12.4527 11.4141 12.2917 11.4141Z'
          fill='#402080'
        />
        <path
          d='M18.125 12C17.964 12 17.8333 11.8693 17.8333 11.7083V5.875C17.8333 5.714 17.7021 5.58333 17.5417 5.58333H16.375C16.2146 5.58333 16.0833 5.714 16.0833 5.875V10.5417C16.0833 10.7027 15.9527 10.8333 15.7917 10.8333C15.6307 10.8333 15.5 10.7027 15.5 10.5417V5.875C15.5 5.39258 15.8926 5 16.375 5H17.5417C18.0241 5 18.4167 5.39258 18.4167 5.875V11.7083C18.4167 11.8693 18.286 12 18.125 12Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_12662_35078'>
          <rect width='14' height='14' fill='white' transform='translate(5 5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AssetStatusIcon
