import React from 'react'
import { useParams } from 'react-router-dom'
import { AffiliatePartners as AffiliatePartnersEditor } from '../../../../Components/Organisms/Masters'

const AffiliatePartnersAddEdit = () => {
  const { affiliatePartnersPageType, affiliatePartnersId } = useParams()

  return (
    <AffiliatePartnersEditor
      mastersPageType={affiliatePartnersPageType}
      mastersId={affiliatePartnersId}
    />
  )
}

export default React.memo(AffiliatePartnersAddEdit)
