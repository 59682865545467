import { useEffect, useState } from 'react'

const labelConfig = {
  in: {
    label: 'In Window',
    color: '#005A30',
    background: '#D9E6E0',
  },
  out: {
    label: 'Out Window',
    color: '#D4351C',
    background: '#F9E1DD',
  },
}

const InOutWindow = ({ assetOtherDetails }: any) => {
  const [windowConfig, setWindowConfig] = useState<any>(null)

  const { contractStartDate, contractEndDate } = assetOtherDetails
  useEffect(() => {
    if (contractStartDate && contractEndDate) {
      const startTime = new Date(contractStartDate).getTime()
      const endTime = new Date(contractEndDate).getTime()
      const currentTime = new Date().getTime()
      if (startTime <= currentTime && currentTime <= endTime) {
        setWindowConfig(labelConfig.in)
      } else {
        setWindowConfig(labelConfig.out)
      }
    }
  }, [contractStartDate, contractEndDate])

  if (!windowConfig?.label) return null

  return (
    <div
      style={{
        position: 'absolute',
        top: '-14px',
        right: '-12px',
        zIndex: 9,
      }}
    >
      <div
        style={{
          paddingLeft: '9px',
          paddingRight: '9px',
          background: windowConfig.background,
          borderRadius: '4px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          display: 'inline-flex',
        }}
      >
        <div
          style={{
            color: windowConfig.color,
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: 400,
            lineHeight: '24px',
            wordWrap: 'break-word',
          }}
        >
          {windowConfig.label}
        </div>
      </div>
      <svg
        style={{
          position: 'absolute',
          bottom: '-14px',
          right: 0,
        }}
        width='12'
        height='16'
        viewBox='0 0 12 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M0 15.5L12 0.5H0V15.5Z' fill={windowConfig.color} />
      </svg>
    </div>
  )
}

export default InOutWindow
