function Delete() {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Component 6' clipPath='url(#clip0_3283_21770)'>
        <path
          id='Vector'
          d='M10.1439 3.02022L10.2903 3.16667H10.4974H12.1641C12.2546 3.16667 12.3308 3.24281 12.3308 3.33333C12.3308 3.42386 12.2546 3.5 12.1641 3.5H4.1641C4.07358 3.5 3.99744 3.42386 3.99744 3.33333C3.99744 3.24281 4.07358 3.16667 4.1641 3.16667H5.83077H6.03788L6.18432 3.02022L6.65766 2.54689L6.3041 2.19333L6.65766 2.54689C6.68496 2.51958 6.73155 2.5 6.77077 2.5H9.55744C9.59666 2.5 9.64325 2.51958 9.67055 2.54689L10.0201 2.19731L9.67055 2.54689L10.1439 3.02022ZM5.49744 13.5C5.04025 13.5 4.6641 13.1239 4.6641 12.6667V6C4.6641 5.54281 5.04025 5.16667 5.49744 5.16667H10.8308C11.288 5.16667 11.6641 5.54281 11.6641 6V12.6667C11.6641 13.1239 11.288 13.5 10.8308 13.5H5.49744ZM10.1641 5.5H6.1641C5.52129 5.5 4.99744 6.02386 4.99744 6.66667V12C4.99744 12.6428 5.52129 13.1667 6.1641 13.1667H10.1641C10.8069 13.1667 11.3308 12.6428 11.3308 12V6.66667C11.3308 6.02386 10.8069 5.5 10.1641 5.5Z'
          fill='#D4351C'
          stroke='#D4351C'
        />
      </g>
      <defs>
        <clipPath id='clip0_3283_21770'>
          <rect width='16' height='16' fill='white' transform='translate(0.164062)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Delete
