const UmbrellaIcon = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        d='M11.0002 13.2891V17.9427C11.0002 18.9164 10.2109 19.7057 9.23723 19.7057C8.39683 19.7057 7.67327 19.1125 7.50845 18.2885L7.33357 17.4141M11.0002 2.28906V4.1224M11.0002 4.1224C6.92946 4.1224 3.48412 6.7572 2.32964 10.3881C2.15609 10.934 2.59089 11.4557 3.16366 11.4557H18.8368C19.4096 11.4557 19.8444 10.934 19.6708 10.3881C18.5164 6.7572 15.071 4.1224 11.0002 4.1224Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UmbrellaIcon
