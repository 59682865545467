import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LootieImage from '../../../Assets/Images/dashboardGif.gif'
import { PAGES_ROUTES } from '../../../Constants'
import { HOME_PAGE } from '../../../Constants/ApiDefinations'
import { RoleConfirmationModal } from '../../../Models'
import mainApiService from '../../../Services'
import { showAlert } from '../../../Store/Slice/alert.slice'
import RoleConfirmation from '../../Dialogs/RoleConfirmation'
import MyDashboardIcon from '../../Icons/MyDashboardIcon'
import PinIcon from '../../Icons/PinIcon'
import './MyDashboard.scss'

/**
 * Dashboard Component
 * @component
 * @returns {React.JSX.Element | null} JSX Element representing the AssetCloneVersion component.
 */

const MyDashboard = () => {
  const navigate = useNavigate()
  const [pinSearchData, setpinSearchData] = useState([])
  const getPinnedSearch = async () => {
    const requestedData: any = HOME_PAGE.GET_PINNED_SEARCH_LIST()
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      if (result?.data) {
        setpinSearchData(result?.data)
      } else {
        setpinSearchData([])
      }
    }
  }
  useEffect(() => {
    getPinnedSearch()
  }, [])
  const [dialogProps, setDialogProps] = useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const [selectedPinnedId, setSelectedPinnedId] = useState('')
  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
    setSelectedPinnedId('')
  }
  const handleUnpinClick = async () => {
    const requestedData = HOME_PAGE.DELETE_PINNED_SEARCH(selectedPinnedId, true)
    const result = await mainApiService(requestedData)
    if (result.message) {
      handleClose()
      showAlert([true, result?.message, 'success'])
      getPinnedSearch()
    } else {
      showAlert([true, result?.error?.message, 'error'])
    }
  }
  const openDialog = (name: string, id: any) => {
    setSelectedPinnedId(id)
    setDialogProps({
      heading: 'Unpin Widget',
      description: (
        <div>
          <p>Are you sure you want to unpin {name} widget?</p>
        </div>
      ),
      color: 'warning',
      Icon: 'DeleteRoleWarIcon',
      open: true,
      iconColor: 'warning',
      cancelButtonLabel: 'Close',
      successButtonLabel: 'Unpin',
      handleClose: handleClose,
    })
  }

  return pinSearchData.length ? (
    <div className='card-wrapper'>
      <h3 className='dashboard-header pb-1'>
        <MyDashboardIcon />
        My Dashboard
      </h3>
      <Grid container spacing={2} className='mb-1 mt-0'>
        {pinSearchData.map((item: any, index: any) => (
          <Grid
            item
            xs={4}
            key={index}
            className='cursor-pointer card-padding'
            data-testid={`pinned-card-${index}`}
            onClick={() => {
              navigate(`/home/asset-state-details/${PAGES_ROUTES.AdvancedSearch}?search`, {
                state: { id: item.id },
              })
            }}
          >
            <div className={'dashboard-card ' + `color${index}`}>
              <Box className='sub-heading-5 py-4 title d-flex justify-content-between allign-items-center'>
                {item.name}
                <div
                  data-testid={`pinnedId-${index}`}
                  className='icon'
                  onClick={(e: any) => {
                    e.stopPropagation()
                    openDialog(item.name, item.id)
                  }}
                >
                  <PinIcon />
                </div>
              </Box>
              <p className='heading-2 py-4'>{item.count[0]}</p>
            </div>
          </Grid>
        ))}
      </Grid>
      <RoleConfirmation
        handleSuccess={handleUnpinClick}
        {...dialogProps}
        handleClose={handleClose}
      />
    </div>
  ) : (
    <div className='dashboard-wrapper'>
      <h3>
        <MyDashboardIcon />
        My Dashboard
      </h3>
      <div className='d-flex justify-content-between align-items-center'>
        <img className='ml-auto mr-auto' src={LootieImage} height={238} />
      </div>
      <div className='lootie-text text-center'>Personalize your dashboard</div>
    </div>
  )
}

export default MyDashboard
