const RightArrowIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M6 2.66927L11.3333 8.00259L6 13.3359'
        stroke='#151D48'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default RightArrowIcon
