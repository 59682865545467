import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../../Constants'
import { MASTERS_MANAGEMENT } from '../../../../Constants/ApiDefinations'
import { getPageTitle, objectToQueryString } from '../../../../Helpers/Util'
import updateTagMetaData from '../../../../Helpers/updateTagMetaData'
import {
  AffiliatePartnersListApiResponse,
  AffiliatePartnersListResponse,
  AffiliatePartnersListingQueryParams,
  AffiliatePartnersPropsType,
  BreadcrumsModel,
  ViewMastersAPIDefinition,
} from '../../../../Models'
import mainApiService from '../../../../Services'
import { useAppDispatch, useAppSelector } from '../../../../Store/Hooks'
import {
  selectAffiliatePartnerData,
  setAffiliatePartnersData,
} from '../../../../Store/Slice/affiliate-partners.slice'
import MastersEditorLayout from '../Layouts/MastersEditorLayout'
import AffiliatePartnersCreateEdit from './AffiliatePartnersCreateEdit'

const AFFILIATED_PARTNERS_TEXT = 'Affiliated Partner'
const AffiliatePartnersEditor = ({ mastersPageType, mastersId }: AffiliatePartnersPropsType) => {
  const dispatch = useAppDispatch()
  const selectAffiliatePartner = useAppSelector(selectAffiliatePartnerData)
  const selectedAffiliatedPartner: AffiliatePartnersListResponse | null =
    mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE ? selectAffiliatePartner : null
  const navigate = useNavigate()
  useEffect(() => {
    if (
      mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE &&
      (!selectedAffiliatedPartner?._id || selectedAffiliatedPartner?._id !== mastersId)
    ) {
      ;(async () => {
        // Call list API
        const listData = await getAffiliatePartnersListingFunction({ page: 1, limit: 1000000 })
        // find selected record based on id
        if (mastersId) {
          const selectedAffiliatePartnerData = listData.find((item) => item?._id === mastersId)

          if (!selectedAffiliatePartnerData?._id) {
            // navigate to list
            navigate(
              `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.AffiliatePartners}`,
            )
          } else {
            dispatch(setAffiliatePartnersData(selectedAffiliatePartnerData))
          }
        }
      })()
    }
  }, [])

  const getAffiliatePartnersListingFunction = async (
    options: AffiliatePartnersListingQueryParams,
  ) => {
    const paramsData = objectToQueryString(options)
    const requestedData: ViewMastersAPIDefinition =
      MASTERS_MANAGEMENT.AFFILIATE_PARTNERS_CRUD.LIST_AFFILIATE_PARTNERS(paramsData)
    const result: any = await mainApiService(requestedData)
    const tempData: AffiliatePartnersListResponse[] = []

    if (result?.responseCode === 200) {
      result?.data?.records?.forEach((ele: AffiliatePartnersListApiResponse) => {
        const createdBy = ele?.createdBy?.userEmail
        const updatedBy = ele?.updatedBy?.userEmail
        tempData.push({
          ...ele,
          createdBy,
          updatedBy,
        })
      })
    }
    return tempData
  }

  const onClickBackNavigation = (e: { preventDefault: () => void }) => {
    e?.preventDefault()
    navigate(
      `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.AffiliatePartners}`,
    )
  }

  // useEffect(() => {
  //   updateTagMetaData({ title: mastersId ? `Manage Master: ${selectAffiliatePartner?.key}` : 'Create Master' })
  //   return (() => {
  //     updateTagMetaData({})
  //   })
  // }, [mastersId, selectAffiliatePartner?.key])

  useEffect(() => {
    updateTagMetaData({ title: `Manage Masters: ${AFFILIATED_PARTNERS_TEXT}` })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  return (
    <MastersEditorLayout
      mastersPageType={mastersPageType}
      pageText={AFFILIATED_PARTNERS_TEXT}
      onClickBackNavigation={onClickBackNavigation}
      breadcrumbs={
        BREADCRUMS.CREATE_AFFILIATE_PARTNERS(
          mastersPageType,
          getPageTitle(mastersPageType as string),
        ) as BreadcrumsModel[]
      }
    >
      {mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE && selectedAffiliatedPartner?._id && (
        <AffiliatePartnersCreateEdit mastersPageType={mastersPageType} />
      )}
      {mastersPageType === CONSTANTS.PAGE_TYPE.CREATE && (
        <AffiliatePartnersCreateEdit mastersPageType={mastersPageType} />
      )}
    </MastersEditorLayout>
  )
}

export default React.memo(AffiliatePartnersEditor)
