/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { REPORTS_CRUD } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

const initialState: any = {
  contentIngestionDetails: {
    show: false,
    data: {},
  },
  contentIngestionRetrigger: {
    show: false,
    data: {},
  },
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setContentIngestionDetails: (state, action) => {
      const { show = false, data = {} } = action.payload
      state.contentIngestionDetails = {
        ...state.contentIngestionDetails,
        show,
        data,
      }
    },
    setcontentIngestionRetrigger: (state, action) => {
      const { show = false, data = {} } = action.payload
      state.contentIngestionRetrigger = {
        ...state.contentIngestionRetrigger,
        show,
        data,
      }
    },
  },
})

export const { setContentIngestionDetails, setcontentIngestionRetrigger } = reportsSlice.actions

const reportsSelector = (state: { reports: any }) => state.reports

export const selectContentIngestionDetails = createSelector(
  reportsSelector,
  (state) => state.contentIngestionDetails,
)
export const selectContentIngestionRetrigger = createSelector(
  reportsSelector,
  (state) => state.contentIngestionRetrigger,
)

export default reportsSlice

export const getRowDetailById = createAsyncThunk(
  'reports/getRowDetailById',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = REPORTS_CRUD.REPORTS_GET_BY_ID(payload)
    const data = await mainApiService(requestedData)
    dispatch(setContentIngestionDetails({ show: true, data }) as any)
    dispatch(setLoader(false))
    return data
  },
)

export const reportsContentIngestionRetrigger = createAsyncThunk(
  'reports/reportsContentIngestionRetrigger',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = REPORTS_CRUD.REPORTS_CONTENT_INGESTION_RETRIGGER(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
