import { Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import VerticalDotEdit from '../Icons/VerticalDotEdit'
import { MUIButton } from '../Shared'

interface ImageDetailsProps {
  editAction: () => void
  removeAction: () => void
  imageData: Record<string, string>
  handleCallback: any
}

const ImageDetailsCard: React.FC<ImageDetailsProps> = ({
  editAction,
  removeAction,
  imageData,
  handleCallback,
}: ImageDetailsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className='bulk__image-card'>
        <div className='bulk__image-card-image' data-testid='bulk__image-card-image'>
          <img src={imageData.base64} alt='' />
          <div
            className='editIcon'
            id='menu_edit'
            data-testid='menu_edit'
            aria-controls={open ? 'menu_edit' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <VerticalDotEdit />
          </div>

          <Menu
            id='menu_edit'
            aria-labelledby='demo-positioned-button'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                editAction()
                handleClose()
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                removeAction()
                handleClose()
              }}
            >
              Remove
            </MenuItem>
          </Menu>

          <div className='overlay d-flex align-items-center justify-content-center'>
            <div className='preview__btn'>
              <MUIButton
                label='Preview'
                type='button'
                variant='contained'
                color='info'
                handleClickCB={() => handleCallback(imageData)}
              />
            </div>
          </div>
        </div>
        <div className='bulk__image-card-content'>
          <div className='paragraph-bold-5 col2'>{imageData.name}</div>
          <div className='d-flex pt-1 col35'>
            <div className='mr-1 paragraph-bold-6 whitespace__nowrap'>File Name</div>
            <div className='paragraph-bold-6 ml-auto'>{imageData.fileName}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImageDetailsCard
