import { MoreVertOutlined, SortOutlined } from '@mui/icons-material'
import { Menu, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CONSTANTS } from '../../../Constants/Constant'
import { CommonTableHeaderModel } from '../../../Models/CommonTable'
import MUIDateTimePicker from '../../MUIDateTimePicker'
import MUIButton from '../MUI-Button'
import SimpleDropdown from '../Simpledropdown'

/**
 * Function to identify icon for sort and filter
 * @param header
 * @param sortFilterKey
 * @param selectedFilters
 * @returns Filter Icon html element
 */
export const FilterSortIcon = ({
  header,
  sortFilterKey,
  selectedFilters,
}: {
  header: Record<string, any>
  sortFilterKey?: string
  selectedFilters?: {
    sort: string
    sortType: string
    filters: {
      filterBy: string
      filterValue: string | number
      filterCondition: string | number
    }[]
  }
}) => {
  const [isActive, setIsActive] = useState(false)
  const [isFilter, setIsFilter] = useState(false)

  useEffect(() => {
    if (selectedFilters && header && sortFilterKey) {
      const headerItem: any = header
      const isFilter = selectedFilters?.filters?.find(
        (item: any) => item.filterBy === headerItem[sortFilterKey] && headerItem.filter,
      )
      setIsFilter(Boolean(isFilter))
      setIsActive(Boolean(selectedFilters?.sort === headerItem[sortFilterKey] || isFilter))
    }
  }, [selectedFilters?.sort, selectedFilters?.filters])

  return isFilter ? (
    <SortOutlined style={{ fontSize: '18px', fill: isActive ? '#402080' : '#b1b4b6' }} />
  ) : (
    <MoreVertOutlined style={{ fontSize: '18px', fill: isActive ? '#402080' : '#b1b4b6' }} />
  )
}

export const FilterUi = ({
  header,
  filterOpen,
  anchorEl2,
  filterValue,
  setFilterValue,
  filterConOptions,
  handleFilterClose,
}: {
  header: CommonTableHeaderModel
  filterOpen: string | number | boolean
  anchorEl2: null | HTMLElement
  filterValue: {
    filterBy: string | number
    filterValue: string | number
    filterCondition: string | number
  }
  setFilterValue: any
  filterConOptions: Record<string, string | number | boolean | null>[]
  handleFilterClose: any
}) => {
  const valueField = () => {
    const { fieldName, filterValueType } = header
    if (filterValueType === CONSTANTS.FIELDTYPES.DATETIME) {
      return (
        <div
          onKeyDown={(e) => {
            e.preventDefault()
            if (e.key === 'Enter') {
              handleFilterClose(header, filterValue) // Apply filter on Enter key press
            }
          }}
        >
          <MUIDateTimePicker
            minutesStep={1}
            name={String(fieldName)}
            label={'Value'}
            type={String(filterValueType).toLowerCase()}
            onChange={(e: any) => {
              setFilterValue({ ...filterValue, filterValue: e })
            }}
            value={filterValue?.filterValue ? new Date(filterValue.filterValue) : null}
            error={{}}
          />
        </div>
      )
    } else if (filterValueType === CONSTANTS.FIELDTYPES.BOOLEAN) {
      return (
        <SimpleDropdown
          selectId='filter-option'
          handleChange={(e) => {
            setFilterValue({ ...filterValue, filterValue: e.target.value })
          }}
          fullWidth
          options={
            [
              { label: 'All', value: '' },
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ] as any
          }
          value={filterValue.filterValue}
        />
      )
    }
    return (
      <TextField
        onChange={(e) => {
          setFilterValue({
            ...filterValue,
            filterValue:
              header?.fieldType === 'NUMBER' && e.target.value
                ? Number(e.target.value)
                : e.target.value,
          })
        }}
        autoFocus={false}
        id='filterValue'
        label='Value'
        type={header?.fieldType === 'NUMBER' ? 'number' : 'text'}
        fullWidth
        value={filterValue.filterValue}
        onKeyDown={(e: any) => {
          e.stopPropagation()
        }}
      />
    )
  }

  const keyField = () => {
    const { filterValueCondition } = header
    let filterOptions: any = filterConOptions
    if (filterValueCondition?.length) {
      filterOptions = filterValueCondition
    }
    if (!filterValue.filterCondition) {
      setFilterValue({ ...filterValue, filterCondition: filterOptions[0]?.value })
    }
    return (
      <SimpleDropdown
        handleChange={(e) => {
          setFilterValue({ ...filterValue, filterCondition: e.target.value })
        }}
        fullWidth
        options={filterOptions}
        value={filterValue.filterCondition}
      />
    )
  }

  return filterOpen === header.id ? (
    <Menu
      autoFocus={false}
      disableAutoFocusItem={false}
      className={`border-12 c-sort${
        header?.fieldType === CONSTANTS.FIELDTYPES.DATETIME ||
        header?.fieldType === CONSTANTS.FIELDTYPES.NUMBER
          ? ' common-table-filter-date'
          : ''
      }`}
      anchorEl={anchorEl2}
      open={Boolean(anchorEl2)}
      onClose={() => handleFilterClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <form>
        <Typography
          style={{
            fontWeight: 600,
            paddingLeft: '0px',
            width: '150px',
            marginBottom: '10px',
          }}
        >
          Filter
        </Typography>
        <div className='pb-1 '>{keyField()}</div>
        {filterValue.filterCondition !== 'null' && <div className='pb-1 '>{valueField()}</div>}
        <MUIButton
          variant={'contained'}
          label='Apply'
          type='submit'
          size='small'
          width={'100%'}
          handleClickCB={() => {
            handleFilterClose(header, filterValue)
          }}
        />
      </form>
    </Menu>
  ) : (
    <></>
  )
}
