import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PARTNER_APIS } from '../../../Constants/ApiDefinations'
import { validateField, validateForm } from '../../../Helpers/FormRenderHelper'
import { getPartnerStatus, getStatusText } from '../../../Helpers/Util'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import FormRender from '../../FormRender'
import { MUIBox, MUIButton } from '../../Shared'
import { PARTNER_CONSTENT } from './PartnersConstent'

interface PartnersFormType {
  partnersData?: any
  id?: string
}
const PartnersForm: React.FC<PartnersFormType> = ({ partnersData, id }) => {
  const [partnersFormFields, setPartnersFormFields] = useState<any>(PARTNER_CONSTENT.initialField)
  const [mount, setmount] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setmount(true)
    if (id === 'edit' && partnersData?._id) {
      const updatedFields = partnersFormFields.map((field: any) => {
        if (field.fieldType === 'SELECT') {
          return { ...field, value: getPartnerStatus(partnersData[field.fieldName]) }
        } else {
          return { ...field, value: partnersData[field.fieldName] }
        }
      })
      setPartnersFormFields(updatedFields)
    }
  }, [partnersData])

  const handleChangeInput = (inputValue: any, index: number, field: any) => {
    const partnersFormFieldsCopy = JSON.parse(JSON.stringify(partnersFormFields))
    const { value, isValid, errorMessage } = validateField(
      partnersFormFieldsCopy[index],
      inputValue,
    )
    partnersFormFieldsCopy[index] = {
      ...partnersFormFieldsCopy[index],
      value,
      isValid,
      errorMessage,
    }
    setPartnersFormFields(partnersFormFieldsCopy)
  }

  const updatePartnerFunction = async () => {
    const payload = partnersFormFields.reduce(
      (acc: any, field: any) => {
        acc[field?.fieldName] =
          field.fieldType === 'SELECT'
            ? typeof field.value === 'string'
              ? getStatusText(field.value)
              : field.value.key
            : field.value
        return acc
      },
      { partnerId: partnersData?._id, resendEmail: false },
    )
    dispatch(setLoader(true))
    if (payload.contactPhone === '') {
      payload.contactPhone = null
    }
    const requestedData: any = PARTNER_APIS.EDIT_PARTNER(payload)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    if (result?.responseCode === 200) {
      dispatch(showAlert([true, result?.message, 'success']))
      setPartnersFormFields(PARTNER_CONSTENT.initialField)
      navigate(`/partners/view/${partnersData?._id}`)
    } else {
      dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
    }
  }
  const createPartnerFunction = async () => {
    const payload = partnersFormFields.reduce((acc: any, field: any) => {
      acc[field?.fieldName] = field.fieldType === 'SELECT' ? field.value.key : field.value
      return acc
    }, {})
    if (payload.contactPhone === '') {
      payload.contactPhone = null
    }
    dispatch(setLoader(true))
    const requestedData: any = PARTNER_APIS.CREATE_PARTNER(payload)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    if (result?.responseCode === 200) {
      dispatch(showAlert([true, result.message, 'success']))
      setPartnersFormFields(PARTNER_CONSTENT.initialField)
      navigate('/partners')
    } else {
      dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
    }
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const { isValid, formConfig } = validateForm(partnersFormFields, true)
    if (isValid) {
      if (id === 'create') {
        createPartnerFunction()
      } else {
        updatePartnerFunction()
      }
    } else {
      setPartnersFormFields(formConfig)
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <MUIBox>
        <div>
          <div className='heading-6 d-flex text-left rolebox__header-padding'>
            Partner Information
          </div>
          <div className={'hr'}></div>
          <div className='rolebox__content-padding'>
            <Grid container columnSpacing={10} rowSpacing={4}>
              <FormRender
                formFields={partnersFormFields}
                onChange={handleChangeInput}
                mount={mount}
              />

              <Grid item xs={12} className='d-flex justify-content-end'>
                <MUIButton
                  size='large'
                  type={'submit'}
                  label={'Submit'}
                  variant='contained'
                  width={200}
                  className='c-btn'
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </MUIBox>
    </form>
  )
}

export default PartnersForm
