import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from '../../Constants'
import { updateJobService } from '../../Helpers/BulkUpload'
import { TypeObject } from '../../Models'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import WatchPlusIcon from '../Icons/WatchPlusIcon'
import MUIDateTimePicker from '../MUIDateTimePicker'
import { MUIButton } from '../Shared'

interface ScheduleBulkUpdateProps {
  handleShow: (show: boolean) => void
  id: string
  jobDetails: TypeObject
  pathUrl?: string
  pageType: string
  handleDialog: any
  handleCloseDialog: any
  refresh: any
  assetCount?: number
}

const ScheduleBulkUpdate: React.FC<ScheduleBulkUpdateProps> = ({
  handleShow,
  jobDetails,
  pathUrl,
  id,
  pageType,
  handleDialog,
  handleCloseDialog,
  refresh,
  assetCount = 0,
}: ScheduleBulkUpdateProps) => {
  const [scheduledDate, setScheduledDate] = useState<any>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (jobDetails?.scheduleDate) setScheduledDate(new Date(jobDetails.scheduleDate))
  }, [jobDetails])

  const updateJob = async () => {
    if (new Date(scheduledDate) < new Date()) {
      dispatch(showAlert([true, 'Select future time', 'error']))
      handleCloseDialog()
      return
    }
    let requestedData: TypeObject = {
      jobId: id,
      filePath: pathUrl ?? jobDetails?.pathUrl,
      assetCount: assetCount,
    }

    if (scheduledDate) {
      requestedData = { ...requestedData, scheduleDate: new Date(scheduledDate).toISOString() }
    }

    const result = await updateJobService(requestedData)
    if (result && pageType === CONSTANTS.PAGE_TYPE.EDIT) {
      navigate('/bulk-update/view/' + id)
    }
    if (result && pageType === CONSTANTS.PAGE_TYPE.VIEW) {
      handleShow(false)
    }

    handleCloseDialog()
    refresh()
  }

  return (
    <>
      <div className={'hr mt-2'}></div>
      <div className='schedule__bulk-update'>
        <Grid container>
          <Grid item xs={12}>
            <div className='heading rolebox__header-padding'>
              <div className='icon'>
                <WatchPlusIcon />
              </div>
              <div className='text'>Schedule Update</div>
            </div>
            <div className='mt-1 rolebox__header-padding'>
              <MUIDateTimePicker
                label={'Update Date and Time'}
                type='datetime'
                views={CONSTANTS.DATE_TIME_FORMAT_VIEWS}
                name={'scheduledDate'}
                error={{}}
                value={scheduledDate}
                disablePast={true}
                onChange={(ev: any) => {
                  setScheduledDate(ev)
                }}
              />
            </div>
            <div className='rolebox__header-padding'>
              <Grid container spacing={4}>
                <Grid item xs={6} className=''>
                  <MUIButton
                    width={'100%'}
                    type='button'
                    label='Schedule Update'
                    variant='contained'
                    color='primary'
                    disabled={!scheduledDate}
                    height='50px'
                    handleClickCB={() =>
                      handleDialog(
                        CONSTANTS.BULK_UPDATE_CONFIRM_DIALOG_SCHEDULE,
                        CONSTANTS.BULK_UPDATE_CONFIRM_DIALOG_SCHEDULE_DESC(scheduledDate),
                        updateJob,
                        handleCloseDialog,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6} className=''>
                  <MUIButton
                    width={'100%'}
                    type='button'
                    label='Cancel'
                    data-testid='cancel'
                    handleClickCB={() => handleShow(false)}
                    variant='outlined'
                    color='primary'
                    height='50px'
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ScheduleBulkUpdate
