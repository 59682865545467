const WriteEditIcon = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        d='M3.21029 3.21094H18.7936M3.21029 6.8776H9.62695M3.2085 10.5443H6.87718M10.6923 19.7109H6.87695V15.8956L13.9873 8.78526C15.0409 7.73169 16.7491 7.73169 17.8026 8.78526C18.8562 9.83883 18.8562 11.547 17.8026 12.6006L10.6923 19.7109Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default WriteEditIcon
