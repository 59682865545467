import React from 'react'
import { useParams } from 'react-router-dom'
import { Languages as LanguagesEditor } from '../../../../Components/Organisms/Masters'

const LanguagesAddEdit = () => {
  const { languagesPageType, languagesId } = useParams()

  return <LanguagesEditor mastersPageType={languagesPageType} mastersId={languagesId} />
}

export default React.memo(LanguagesAddEdit)
