import React from 'react'

const CrossIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='18' viewBox='0 0 19 18' fill='none'>
      <g>
        <path
          d='M13.9604 4.28254C13.6679 3.99004 13.1954 3.99004 12.9029 4.28254L9.23539 7.94254L5.56789 4.27504C5.27539 3.98254 4.80289 3.98254 4.51039 4.27504C4.21789 4.56754 4.21789 5.04004 4.51039 5.33254L8.17789 9.00004L4.51039 12.6675C4.21789 12.96 4.21789 13.4325 4.51039 13.725C4.80289 14.0175 5.27539 14.0175 5.56789 13.725L9.23539 10.0575L12.9029 13.725C13.1954 14.0175 13.6679 14.0175 13.9604 13.725C14.2529 13.4325 14.2529 12.96 13.9604 12.6675L10.2929 9.00004L13.9604 5.33254C14.2454 5.04754 14.2454 4.56754 13.9604 4.28254Z'
          fill='#323232'
        />
      </g>
      <defs>
        <clipPath id='clip0_4186_27266'>
          <rect width='18' height='18' fill='white' transform='translate(0.235352)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CrossIcon
