import { useNavigate, useParams } from 'react-router-dom'
import { PartnersEditor } from '../../Components/Partners'
import CONSTANTS from '../../Constants'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../Helpers/Permission'

const CreatePartners: React.FC = () => {
  const navigate = useNavigate()
  const { CREATE } = CONSTANTS.PAGE_TYPE
  const accessRead = checkPermission(PERMISSIONS.PARTNER_MANAGEMENT, [PERMISSION_TYPE.CAN_READ])
  const accessWrite = checkPermission(PERMISSIONS.PARTNER_MANAGEMENT, [PERMISSION_TYPE.CAN_WRITE])

  const { partnerPageType, PartnersId } = useParams<{
    partnerPageType?: string | undefined
    PartnersId?: string | undefined
  }>()
  let pageType: any = partnerPageType
  if (!PartnersId) {
    pageType = CREATE
  }
  // useEffect(() => {
  //   if (!accessWrite && !accessRead) {
  //     navigate(`${PAGES_ROUTES.AccessDenied}`)
  //   }
  // }, [accessRead, accessWrite])
  return <PartnersEditor id={pageType} PartnersId={PartnersId} />
}

export default CreatePartners
