import React from 'react'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import PreviewImageIcon from '../Icons/PreviewImageIcon'
import { MUIButton, MUIDialog } from '../Shared'

// const iOSUser = [
//   'iPad Simulator',
//   'iPhone Simulator',
//   'iPod Simulator',
//   'iPad',
//   'iPhone',
//   'iPod',
// ].includes(navigator.platform)

const PreviewImagePopup = ({ isPreviewImageOpen, previewImageUrl, handlePreviewClose }: any) => {
  const dispatch = useAppDispatch()
  const downloadImage = () => {
    fetch(previewImageUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
        return response.blob()
      })
      .then((blob) => {
        // Create a link element
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)

        // Set the download attribute to specify the filename
        link.download = `image_${new Date().getTime()}`

        // Append the link to the document
        document.body.appendChild(link)

        // Trigger a click on the link to start the download
        link.click()

        // Remove the link from the document
        document.body.removeChild(link)
      })
      .catch((error) => {
        dispatch(showAlert([true, `'Error downloading image:' ${error}`, 'error']))
      })
  }

  return (
    <React.Fragment>
      <MUIDialog open={isPreviewImageOpen} handleClose={handlePreviewClose}>
        <div
          style={{
            padding: '24px 16px',
            fontFamily: 'Inter',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '28px',
            color: '#191D23',
            width: '420px',
          }}
        >
          <PreviewImageIcon />
          <h2
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              fontFamily: 'Inter',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '28px',
              color: '#191D23',
            }}
          >
            Preview Image
          </h2>
          <figure
            style={{
              height: '359px',
            }}
          >
            <img
              style={{
                height: '359px',
                width: '100%',
              }}
              src={previewImageUrl}
              alt='preview-image'
            />
          </figure>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              marginTop: '20px',
            }}
          >
            <div>
              <MUIButton
                variant='outlined'
                size='large'
                color='secondary'
                type='button'
                label={'Cancel'}
                height={'44px'}
                fontWeight={'bold'}
                fontSize={'16px'}
                width={'182px'}
                handleClickCB={handlePreviewClose}
              />
            </div>
            <div>
              <MUIButton
                type='button'
                className='heading-3'
                label={'Download Image'}
                size='large'
                variant='contained'
                height={'44px'}
                fontWeight={'bold'}
                fontSize={'16px'}
                handleClickCB={downloadImage}
              />
            </div>
          </div>
        </div>
      </MUIDialog>
    </React.Fragment>
  )
}

export default PreviewImagePopup
