import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DataMigrationIcon from '../../Components/Icons/DataMigrationIcon'
import { PAGES_ROUTES } from '../../Constants'
import updateTagMetaData from '../../Helpers/updateTagMetaData'

const ReportsList = () => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/${PAGES_ROUTES.Reports}/${PAGES_ROUTES.DataMigrationReports}`)
  }

  const handleNavigateContentIngestionReport = () => {
    navigate(`/${PAGES_ROUTES.Reports}/content-ingestion-report`)
  }

  useEffect(() => {
    updateTagMetaData({ title: 'Reports Listing' })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#f5f4f4',
        padding: '20px 24px',
        color: '#111827',
        fontFamily: 'Inter',
        fontStyle: 'normal',
      }}
    >
      <h1
        style={{
          fontSize: '20px',
          lineHeight: '24px',
          fontWeight: '600',
        }}
      >
        Reports
      </h1>
      <div style={{ marginTop: '34px', display: 'flex' }}>
        <div
          className='cursor-pointer'
          style={{
            marginRight: '3rem',
            padding: '18px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            maxWidth: '230px',
            // height: '120px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            border: '1px solid #402080',
            background: '#fff',
          }}
          onClick={handleNavigate}
        >
          <figure>
            <DataMigrationIcon />
          </figure>
          <p
            style={{
              color: '#383F43',
              fontSize: '14px',
              lineHeight: '26px',
            }}
          >
            Data Migration Report
          </p>
        </div>
        <div
          className='cursor-pointer'
          style={{
            padding: '18px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            maxWidth: '230px',
            // height: '120px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            border: '1px solid #402080',
            background: '#fff',
          }}
          onClick={handleNavigateContentIngestionReport}
        >
          <figure>
            <DataMigrationIcon />
          </figure>
          <p
            style={{
              color: '#383F43',
              fontSize: '14px',
              lineHeight: '26px',
            }}
          >
            Content Ingestion Report
          </p>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ReportsList)
