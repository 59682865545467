const DragAndDropSolidIcon = () => {
  return (
    <svg width='41' height='28' viewBox='0 0 41 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M32.75 10.7307C31.6167 4.98073 26.5667 0.664062 20.5 0.664062C15.6833 0.664062 11.5 3.3974 9.41667 7.3974C4.4 7.93073 0.5 12.1807 0.5 17.3307C0.5 22.8474 4.98333 27.3307 10.5 27.3307H32.1667C36.7667 27.3307 40.5 23.5974 40.5 18.9974C40.5 14.5974 37.0833 11.0307 32.75 10.7307ZM23.8333 15.6641V22.3307H17.1667V15.6641H12.1667L19.9167 7.91406C20.25 7.58073 20.7667 7.58073 21.1 7.91406L28.8333 15.6641H23.8333Z'
        fill='#402080'
      />
    </svg>
  )
}

export default DragAndDropSolidIcon
