import { CssBaseline } from '@mui/material'
import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { DialogProvider, Header, Loader } from '../Components/Shared'

const PlainLayout: React.FC = () => {
  return (
    <DialogProvider>
      <CssBaseline />
      <Header sidebar={false} />
      <div style={{ marginTop: '64px' }}>
        <Suspense fallback={<Loader />}>
          <div className='root'>
            <Loader />
            <Outlet />
          </div>
        </Suspense>
      </div>
    </DialogProvider>
  )
}

export default PlainLayout
