import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { Asterisk, CircleMinus, CirclePlus } from '../../Icons'
import { EditAssetVideoBtn } from '../../ManageContent/ManageAsset/AssetEditor/EditAssetBtn'

interface AccordionModal {
  errorMsg?: any
  title: any
  children: ReactNode
  callback?: any
  noPadding?: boolean
  open?: boolean
  isRequired?: boolean
  disabled?: boolean
  editVideoAction?: ((e: React.MouseEvent<HTMLElement>, title: string) => void) | null | undefined
}

const MUIAccordion: React.FC<AccordionModal> = ({
  errorMsg = '',
  title,
  children,
  callback = () => {
    return
  },
  open = false,
  noPadding = false,
  isRequired = false,
  disabled = false,
  editVideoAction,
}: AccordionModal) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const handleChange = () => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
    callback(title, isExpanded)
  }

  useEffect(() => {
    setExpanded(open)
  }, [open])

  return (
    <Accordion
      disabled={disabled}
      expanded={expanded}
      onChange={handleChange()}
      data-testid='accordion'
      className='custom_accordian'
      sx={{
        border: '1px solid #00000029',
        borderRadius: '8px',
        boxShadow: 'none',
        background: '#fff',
      }}
    >
      <AccordionSummary
        sx={{
          borderBottom: '1px solid #00000029',
        }}
        expandIcon={expanded ? <CircleMinus /> : <CirclePlus />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <div className='heading-6 '>{title}</div>
        {editVideoAction && (
          <div className='ml-auto mr-1'>
            <EditAssetVideoBtn onClickBtn={(e) => editVideoAction(e, title)} />
          </div>
        )}
        &nbsp;{isRequired && <Asterisk />}
        {errorMsg && (
          <ul
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginLeft: 'auto',
              marginRight: '12px',
              color: '#D4351C',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
            }}
          >
            <li>{errorMsg}</li>
          </ul>
        )}
      </AccordionSummary>
      {expanded && (
        <AccordionDetails sx={{ padding: noPadding ? 0 : 'auto' }}>{children}</AccordionDetails>
      )}
    </Accordion>
  )
}

export default MUIAccordion
