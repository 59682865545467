import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS } from '../../../Constants'
import { PARTNER_APIS } from '../../../Constants/ApiDefinations'
import { TIPS_MODULE } from '../../../Helpers/ManageTips'
import { getPageTitle } from '../../../Helpers/Util'
import updateTagMetaData from '../../../Helpers/updateTagMetaData'
import { BreadcrumsModel } from '../../../Models'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { BackButton, Breadcrumbs, MUITipsCard } from '../../Shared'
import PartnersForm from './PartnersForm'
import PartnersInformation from './PartnersInformation'

const PartnersEditor: React.FC<{
  id: string | undefined
  PartnersId: string | undefined
}> = ({ id, PartnersId }: { id: string | undefined; PartnersId: string | undefined }) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [partnerData, setpartnerData] = useState<any>({})
  const [viewData, setviewData] = useState<any>([])
  const getPartnerInformation = useCallback(async () => {
    if (id !== CREATE && (id || PartnersId)) {
      const _id = PartnersId || id
      dispatch(setLoader(true))
      const requestedData: any = PARTNER_APIS.VIEW_PARTNER(_id as any)
      const result = await mainApiService(requestedData)
      dispatch(setLoader(false))
      if (!result?.data?._id?.length) {
        dispatch(showAlert([true, result.message, 'error']))
        navigate('/partners')
      } else {
        setpartnerData(result.data)
        setviewData([
          { label: 'Partner Name', value: result?.data?.serviceName, isTitle: true },
          { label: 'Partner Alias', value: result?.data?.serviceAlias, isTitle: false },
          { label: 'Contact Email', value: result?.data?.contactEmail, isTitle: false },
          { label: 'Contact Phone', value: result?.data?.contactPhone, isTitle: false },
          { label: 'Status', value: result?.data?.status, isTitle: false },
          {
            label: 'Contract Start Date',
            value: result?.data.contractStartDate
              ? format(new Date(result?.data.contractStartDate), CONSTANTS.DATE_FORMAT_TIME)
              : 'NA',
            isTitle: false,
          },
          {
            label: 'Contract End Date',
            value: result?.data.contractEndDate
              ? format(new Date(result?.data.contractEndDate), CONSTANTS.DATE_FORMAT_TIME)
              : 'NA',
            isTitle: false,
          },
        ])
      }
    }
  }, [id, PartnersId, dispatch])
  useEffect(() => {
    if (id === CREATE) {
      updateTagMetaData({ title: 'Create Partner' })
    } else if (partnerData?.serviceName as any) {
      updateTagMetaData({ title: `Partner: ${partnerData?.serviceName}` })
    }
    return () => {
      updateTagMetaData({})
    }
  }, [partnerData?.serviceName, id])
  useEffect(() => {
    getPartnerInformation()
  }, [id])
  return (
    <>
      <div className={`role__editor p-16 ${id !== VIEW ? 'pb-120' : ''}`}>
        <div className='p-8'>
          <Breadcrumbs
            breadcrums={
              BREADCRUMS.CREATE_PARTNER(id, getPageTitle(id as string)) as BreadcrumsModel[]
            }
          />
        </div>
        <div>
          <div className='p-8'>
            <BackButton
              label={`${getPageTitle(id as string)} Partner`}
              className='col73'
              handleOnClick={(e: any) => {
                e.preventDefault()
                navigate('/partners')
              }}
            />
          </div>
          <div className='mt-1'>
            <div className='role__editor-wrapper'>
              <div className='role__editor-content p-8'>
                {id === VIEW ? (
                  <div className=''>
                    <PartnersInformation
                      view={partnerData}
                      viewData={viewData}
                      getPartnerInformation={getPartnerInformation}
                    />
                  </div>
                ) : (
                  <PartnersForm partnersData={partnerData} id={id} />
                )}
              </div>
              <div className=' p-8  ml-1 '>
                <div>
                  <MUITipsCard
                    tipsArr={CONSTANTS.PARTNER_TIPS}
                    module={TIPS_MODULE.MANAGE_PARTNER}
                    pageType={[VIEW, CREATE].includes(id as string) ? id : EDIT}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PartnersEditor
