import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { MASTERS_MANAGEMENT } from '../../Constants/ApiDefinations'
import { CreateMastersData, DownstreamPlatformsListResponse, MastersUpdate } from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface DownstreamPlatformsState {
  downstreamPlatformData: DownstreamPlatformsListResponse | null
}

const initialState: DownstreamPlatformsState = {
  downstreamPlatformData: null,
}

const downstreamPlatformsSlice = createSlice({
  name: 'downstreamPlatforms',
  initialState,
  reducers: {
    setDownstreamPlatformsData: (state, action) => {
      state.downstreamPlatformData = action?.payload ? action.payload : null
    },
  },
})

export const changeDownstreamPlatformStatus = createAsyncThunk(
  'downstreamPlatforms/changeStatus',
  async (data: { id: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.DOWNSTREAM_PLATFORMS_CRUD.UPDATE_DOWNSTREAM_PLATFORMS(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const { setDownstreamPlatformsData } = downstreamPlatformsSlice.actions

const downstreamPlatformsSelector = (state: any) => state.downstreamPlatforms

export const selectDownstreamPlatformData = createSelector(
  downstreamPlatformsSelector,
  (state) => state.downstreamPlatformData,
)

export const createDownstreamPlatformService = createAsyncThunk(
  'downstreamPlatforms/create',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.DOWNSTREAM_PLATFORMS_CRUD.CREATE_DOWNSTREAM_PLATFORMS(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const editDownstreamPlatformService = createAsyncThunk(
  'downstreamPlatforms/editDownstreamPlatform',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.DOWNSTREAM_PLATFORMS_CRUD.UPDATE_DOWNSTREAM_PLATFORMS(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export default downstreamPlatformsSlice
