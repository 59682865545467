const EditAudioContentIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M16.6666 3.33301H3.33329C2.41663 3.33301 1.67496 4.08301 1.67496 4.99967L1.66663 14.9997C1.66663 15.9163 2.41663 16.6663 3.33329 16.6663H16.6666C17.5833 16.6663 18.3333 15.9163 18.3333 14.9997V4.99967C18.3333 4.08301 17.5833 3.33301 16.6666 3.33301ZM3.33329 7.49967H12.0833V10.4163H3.33329V7.49967ZM3.33329 12.083H12.0833V14.9997H4.16663C3.70829 14.9997 3.33329 14.6247 3.33329 14.1663V12.083ZM15.8333 14.9997H13.75V7.49967H16.6666V14.1663C16.6666 14.6247 16.2916 14.9997 15.8333 14.9997Z'
        fill='#323232'
      />
    </svg>
  )
}

export default EditAudioContentIcon
