import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  ASSET_CREATION,
  ASSET_TYPE_DEFINITION_CRUD,
  WORKFLOW_API,
} from '../../Constants/ApiDefinations'
import { checkContentStateIsDraftReady } from '../../Helpers/Workflow'
import { TypeObject, viewRoleAPIDefinationModal } from '../../Models'
import { assetViewApiModal } from '../../Models/AssetModal'
import mainApiService from '../../Services'

export const assetConfigInitialState = {
  assetDetails: <TypeObject>{},
  assetDetailsStatus: 'idle',
  assetDetailsError: '',

  assetTypesData: [],
  assetTypesStatus: 'idle',
  assetTypesError: '',
  metaAttributesData: <TypeObject>{},

  versionDetails: <TypeObject>{},
  versionDetailsStatus: 'idle',
  versionDetailsError: '',
}

const assetConfigSlice = createSlice({
  name: 'assetConfig',
  initialState: assetConfigInitialState,
  reducers: {
    resetAssetDetails: (state: any) => {
      state.assetDetails = {}
    },
    resetAppConfig: (state: any) => {
      return {
        ...state,
        assetDetails: {},
        assetDetailsStatus: 'idle',
        assetDetailsError: '',
      }
    },
    resetAssetDefination: (state: any) => {
      return {
        ...state,
        assetTypesData: [],
        assetTypesStatus: 'idle',
        assetTypesError: '',
        metaAttributesData: {},
      }
    },
    resetAssetVersions: (state: any) => {
      return {
        ...state,
        versionDetails: {},
        versionDetailsStatus: 'idle',
        versionDetailsError: '',
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetConfig.pending, (state) => {
        state.assetDetailsStatus = 'loading'
      })
      .addCase(getAssetConfig.fulfilled, (state, action) => {
        state.assetDetailsStatus = 'succeeded'
        if (action?.payload?.data) {
          const {
            contractEndDate,
            contractStartDate,
            contentDetails,
            parentDetails,
            assetTitle,
            lastPublished = '',
          } = action.payload.data
          state.assetDetails = {
            ...contentDetails,
            assetTitle,
            parentDetails,
            lastPublished,
            contractEndDate,
            contractStartDate,
          }
        }
      })
      .addCase(getAssetConfig.rejected, (state, action) => {
        state.assetDetailsStatus = 'failed'
        state.assetDetailsError = action.error.message || ''
      })
      .addCase(getAssetDefination.pending, (state) => {
        state.assetTypesStatus = 'loading'
      })
      .addCase(getAssetDefination.fulfilled, (state, action) => {
        state.assetTypesStatus = 'succeeded'

        let assetTypesRes: any = [
          { assetCategory: 'GOB', assetType: [] },
          { assetCategory: 'BUNDLE', assetType: [] },
          { assetCategory: 'CONTENT_ITEM', assetType: [] },
        ]
        let metaAttributes = {}

        if (action?.payload?.data) {
          const {
            meta: { arg = {} },
            payload: {
              data: { records },
            },
          } = action
          if (records.length) {
            records.forEach((ele: any) => {
              if (ele.assetCategory === 'GOB') {
                assetTypesRes[0].assetType.push(ele.assetType)
              } else if (ele.assetCategory === 'BUNDLE') {
                assetTypesRes[1].assetType.push(ele.assetType)
              } else if (ele.assetCategory === 'CONTENT_ITEM') {
                assetTypesRes[2].assetType.push(ele.assetType)
              }
              if (
                ele?.contentCategory === arg?.conCategory &&
                ele?.assetCategory === arg?.assetCategory &&
                ele?.assetType === arg?.assetType
              ) {
                metaAttributes = ele.attributes.reduce(
                  (redResult: TypeObject, item: TypeObject) => {
                    const { metaAttributes, metaCategory } = item
                    redResult[metaCategory] = metaAttributes.map(
                      ({ fieldName, label }: TypeObject) => ({ fieldName, label }),
                    )
                    return redResult
                  },
                  {},
                )
              }
            })
            if (arg.assetCategory?.toUpperCase() === 'GOB') {
              assetTypesRes = [assetTypesRes[0], assetTypesRes[1], assetTypesRes[2]]
            } else if (arg.assetCategory?.toUpperCase() === 'BUNDLE') {
              assetTypesRes = [assetTypesRes[1], assetTypesRes[2]]
            }
            // Adding below condition for shorts requirement
            if (arg.assetCategory?.toUpperCase() === 'CONTENT_ITEM') {
              assetTypesRes = [assetTypesRes[2]]
            }
          }
        }
        state.assetTypesData = assetTypesRes.filter((item: any) => item.assetType.length)
        state.metaAttributesData = metaAttributes
      })
      .addCase(getAssetDefination.rejected, (state, action) => {
        state.assetTypesStatus = 'failed'
        state.assetTypesError = action.error.message || ''
      })
      .addCase(getAssetVersions.pending, (state) => {
        state.versionDetailsStatus = 'loading'
      })
      .addCase(getAssetVersions.fulfilled, (state, action) => {
        state.versionDetailsStatus = 'succeeded'
        let draft = null
        const scheduled: any = []
        if (action?.payload?.data) {
          const {
            meta: { arg = {} },
            payload: { data },
          } = action
          data.forEach((version: any) => {
            if (version._id !== arg.versionId) {
              if (version.schedule) {
                scheduled.push({
                  _id: version._id,
                  state: version.state,
                  blitzId: version.blitzId,
                  contentId: version.contentId,
                  assetType: version?.assetType,
                  title: version.schedule.title,
                  scheduleId: version.schedule._id,
                  updatedAt: version.updatedAt,
                  scheduleTime: version.schedule.scheduleTime,
                })
              } else if (checkContentStateIsDraftReady(version.state)) {
                draft = {
                  _id: version._id,
                  state: version.state,
                  blitzId: version.blitzId,
                  contentId: version.contentId,
                  updatedAt: version.updatedAt,
                  assetType: version?.assetType,
                }
              }
            }
          })
        }
        state.versionDetails = { scheduled, draft }
      })
      .addCase(getAssetVersions.rejected, (state, action) => {
        state.versionDetailsStatus = 'failed'
        state.versionDetailsError = action.error.message || ''
      })
  },
})

export const { resetAssetDetails } = assetConfigSlice.actions
export default assetConfigSlice

export const getAssetConfig = createAsyncThunk(
  'assetConfig/getAssetConfig',
  async (_payload?: TypeObject) => {
    const reqData: assetViewApiModal = ASSET_CREATION.VIEW_MARK_DONE_STATUS(
      _payload?.versionId as string,
    )
    const data = await mainApiService(reqData)
    return data
  },
)

export const getAssetDefination = createAsyncThunk(
  'assetConfig/getAssetDefination',
  async (_payload?: TypeObject) => {
    const requestedData: viewRoleAPIDefinationModal = ASSET_TYPE_DEFINITION_CRUD.LIST_ASSET_TYPE(
      _payload?.request as string,
    )
    const data: TypeObject = await mainApiService({
      ...requestedData,
      ...(_payload?.signal ? { signal: _payload.signal } : {}),
    })
    return data
  },
)

export const getAssetVersions = createAsyncThunk(
  'assetConfig/getAssetVersions',
  async (_payload?: TypeObject) => {
    const requestedData = WORKFLOW_API.GET_OTHER_VERSIONS(_payload?.versionId)
    const data: TypeObject = await mainApiService(requestedData)
    return data
  },
)
