import {
  getStatusBoxClass,
  getStatusLabelClass,
  getStatusMessage,
} from '../../../../../Helpers/Util'
import '../../../../ManageContent/ManageAsset/AssetEditor.scss'

const AVSInjestionStatus = ({ assetOtherDetails }: any) => {
  const status = assetOtherDetails?.avsContentStatus
  const statusMessage = getStatusMessage(status, assetOtherDetails?.action)

  if (!statusMessage) return null

  return (
    <div className='avs-status-wrapper'>
      <div className={`avs-status-box ${getStatusBoxClass(status)}`}>
        <div
          className={`avs-status-label ${getStatusLabelClass(status)}`}
          data-testid='avsStatusId'
        >
          {statusMessage}
        </div>
      </div>
    </div>
  )
}

export default AVSInjestionStatus
