import { Grid } from '@mui/material'
import React from 'react'
import { MUIGridModal } from '../../../Models/ComponentModel/index'

const MUIGrid: React.FC<MUIGridModal> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  spacing,
  rowSpacing,
  columnSpacing,
  columns,
  children,
  style,
  container = false,
  item = false,
  gap,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  display = '',
  key,
  id = '',
}) => {
  return (
    <Grid
      id={id}
      sx={style}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      container={container}
      item={item}
      className={className}
      spacing={spacing}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
      gap={gap}
      display={display}
      key={key}
      columns={columns}
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
      gridTemplateAreas={gridTemplateAreas}
    >
      {children}
    </Grid>
  )
}
export default MUIGrid
