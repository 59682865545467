function RightNavIcon() {
  return (
    <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_4186_27808)'>
        <circle cx='38' cy='18' r='18' fill='#E2DEEC' />
        <circle cx='38' cy='18' r='17.5' stroke='#402080' />
      </g>
      <path
        d='M40.4517 19.209H33.2057C32.5425 19.209 32 18.6628 32 17.9952C32 17.3276 32.5425 16.7814 33.2057 16.7814H40.4517V14.6088C40.4517 14.0626 41.1027 13.7955 41.4765 14.1839L44.8282 17.5704C45.0573 17.8132 45.0573 18.1894 44.8282 18.4322L41.4765 21.8187C41.1027 22.2071 40.4517 21.9279 40.4517 21.3938V19.209Z'
        fill='#402080'
      />
      <defs>
        <filter
          id='filter0_d_4186_27808'
          x='0'
          y='0'
          width='76'
          height='76'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_4186_27808'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='12' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.219608 0 0 0 0 0.247059 0 0 0 0 0.262745 0 0 0 0.08 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4186_27808' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4186_27808'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}

export default RightNavIcon
