const BulkImageUploadIconButton = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_10789_38177)'>
        <path
          d='M14.0133 3.33337H12.6667V1.98671C12.6667 1.62671 12.3733 1.33337 12.0133 1.33337H11.9933C11.6267 1.33337 11.3333 1.62671 11.3333 1.98671V3.33337H9.99333C9.63333 3.33337 9.34 3.62671 9.33333 3.98671V4.00671C9.33333 4.37337 9.62667 4.66671 9.99333 4.66671H11.3333V6.00671C11.3333 6.36671 11.6267 6.66671 11.9933 6.66004H12.0133C12.3733 6.66004 12.6667 6.36671 12.6667 6.00671V4.66671H14.0133C14.3733 4.66671 14.6667 4.37337 14.6667 4.01337V3.98671C14.6667 3.62671 14.3733 3.33337 14.0133 3.33337ZM10.6667 6.00671V5.33337H9.99333C9.64 5.33337 9.30667 5.19337 9.05333 4.94671C8.80667 4.69337 8.66667 4.36004 8.66667 3.98671C8.66667 3.74671 8.73333 3.52671 8.84667 3.33337H3.33333C2.6 3.33337 2 3.93337 2 4.66671V12.6667C2 13.4 2.6 14 3.33333 14H11.3333C12.0667 14 12.6667 13.4 12.6667 12.6667V7.14671C12.4667 7.26004 12.24 7.33337 11.9867 7.33337C11.26 7.32671 10.6667 6.73337 10.6667 6.00671ZM10.64 12.6667H4C3.72667 12.6667 3.56667 12.3534 3.73333 12.1334L5.05333 10.38C5.19333 10.1934 5.46667 10.2067 5.6 10.3934L6.66667 12L8.40667 9.68004C8.54 9.50671 8.8 9.50004 8.93333 9.67337L10.9 12.1267C11.0733 12.3467 10.92 12.6667 10.64 12.6667Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_10789_38177'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BulkImageUploadIconButton
