/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { ASSET_CREATION, ASSET_CURD_API, MEDIA_INFO } from '../../Constants/ApiDefinations'
import assetData from '../../Constants/MockAsset'
import {
  ViewAssetModal,
  assetCRUDAPIDefinitionModal,
  assetViewApiModal,
  createAssetPayloadModal,
} from '../../Models/AssetModal'
import mainApiService from '../../Services'
import { showAlert } from './alert.slice'
import { setLoader } from './loader.slice'

interface AssetState {
  asset: any
  imageVariantsData?: any
  viewAsset?: ViewAssetModal | null
  contentMetaCategoryData?: any
  addColumn?: any[]
  isAssetSaved?: boolean
  markAsDoneErrors?: string[]
  // viewAsset?: any | null
}

const initialState: AssetState = {
  asset: assetData,
  viewAsset: null,
  imageVariantsData: null,
  contentMetaCategoryData: null,
  addColumn: [],
  isAssetSaved: false,
  markAsDoneErrors: [],
}

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    setAsset: (state) => {
      state.asset = assetData
    },
    setViewAssetData: (state, action) => {
      state.viewAsset = action.payload
    },
    setImageVariantsData: (state, action) => {
      state.imageVariantsData = action.payload
    },
    setContentMetaCategoryData: (state, action) => {
      state.contentMetaCategoryData = action.payload
    },
    setAddAssetColumn: (state, action) => {
      state.addColumn = action.payload
    },
    setIsSaveAssetData: (state, action) => {
      state.isAssetSaved = action.payload
    },
    setMarkAsDoneErrors: (state, action) => {
      state.markAsDoneErrors = action.payload
    },
  },
})
export const {
  setAsset,
  setViewAssetData,
  setImageVariantsData,
  setContentMetaCategoryData,
  setAddAssetColumn,
  setIsSaveAssetData,
  setMarkAsDoneErrors,
} = assetSlice.actions
export const viewAssetService = createAsyncThunk(
  'asset/viewAssetByContentId',
  async (contentId: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetViewApiModal = ASSET_CREATION.VIEW_ASSET_REPO(contentId)
    const data = await mainApiService(requestedData)
    dispatch(setViewAssetData(data as any))
    dispatch(setLoader(false))
  },
)
export const deleteAssetService = createAsyncThunk(
  'asset/delete',
  async (contentId: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetViewApiModal = ASSET_CREATION.DELETE_ASSET_REPO(contentId)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)
export const createAssetService = createAsyncThunk(
  'attribute/create',
  async (payload: createAssetPayloadModal, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal = ASSET_CURD_API.CREATE_ASSET(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const markAsDoneService = createAsyncThunk(
  'attribute/markDone',
  async (payload: { contentId: string; metaCategory: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal = ASSET_CURD_API.MARK_DONE(payload)
    const result = await mainApiService(requestedData)

    dispatch(setLoader(false))

    return result
  },
)

export const createImageAssetVariantService = createAsyncThunk(
  'image/attribute/variant/create',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.IMAGE_CONTENT_TYPE.CREATE_IMAGE_VARIANT(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const updateImageAssetVariantService = createAsyncThunk(
  'image/attribute/variant/update',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.IMAGE_CONTENT_TYPE.UPDATE_IMAGE_VARIANT(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const generatePresignedUrlForImageAssetVariantService = createAsyncThunk(
  'image/attribute/variant/presignedUrl',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ASSET_CURD_API.IMAGE_CONTENT_TYPE.GENERATE_PRESIGNED_URL(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const mediaInfoAddService = createAsyncThunk(
  'assetGroup/mediaInfo',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MEDIA_INFO.ADD_MEDIA_INFO(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const mediaInfoUpdateService = createAsyncThunk(
  'assetGroup/updateMediaInfo',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MEDIA_INFO.UPDATE_MEDIA_INFO(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const deleteImageVariantService = createAsyncThunk(
  'image/attribute/variant/delete',
  async (
    payload: { attributeName: any; variantId: any; contentId: any; targeting: any; segmentId: any },
    { dispatch },
  ) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal =
      ASSET_CURD_API.IMAGE_CONTENT_TYPE.DELETE_IMAGE_VARIANT(
        payload?.attributeName,
        payload?.variantId,
        payload?.contentId,
        payload?.targeting,
        payload?.segmentId,
      )
    const result = await mainApiService(requestedData)

    if (result?.error) {
      dispatch(showAlert([true, result.error.errors.join('\n'), 'error']))
      dispatch(setLoader(false))
      return false
    }

    dispatch(setLoader(false))
    dispatch(showAlert([true, result.message, 'success']))
  },
)

export const getImageDetailsService = createAsyncThunk(
  'image/details',
  async (contentId: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetCRUDAPIDefinitionModal =
      ASSET_CURD_API.IMAGE_CONTENT_TYPE.GET_IMAGE_DETAILS(contentId)
    const result = await mainApiService(requestedData)
    if (result?.error) {
      // dispatch(showAlert([true, result.error.errors.join('\n'), 'error']))
      dispatch(setLoader(false))
      return
    }
    dispatch(setImageVariantsData(result))
    dispatch(setLoader(false))
    // dispatch(showAlert([true, result.message, 'success']))
  },
)

const assetSelector = (state: { asset: AssetState }) => state.asset

export const selectAsset = createSelector(assetSelector, (state) => state.asset)
export const viewSelectAsset = createSelector(assetSelector, (state) => state.viewAsset)
export const selectAddColumn = createSelector(assetSelector, (state) => state.addColumn)
export const isAssetSavedValue = createSelector(assetSelector, (state) => state.isAssetSaved)
export const selectImageVariantsData = createSelector(
  assetSelector,
  (state) => state.imageVariantsData,
)
export const selectContentMetaCategoryData = createSelector(
  assetSelector,
  (state) => state.contentMetaCategoryData,
)
export const selectMarkAsDoneErrors = createSelector(
  assetSelector,
  (state) => state.markAsDoneErrors,
)

export default assetSlice
