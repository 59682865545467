import { combineReducers } from '@reduxjs/toolkit'
import affiliatePartnersSlice from '../Slice/affiliate-partners.slice'
import alertSlice from '../Slice/alert.slice'
import appTooltipsSlice from '../Slice/app-tooltips.slice'
import appConfigSlice from '../Slice/appConfig.slice'
import assetDefinitionSlice from '../Slice/asset-definition.slice'
import assetSlice from '../Slice/asset.slice'
import assetConfigSlice from '../Slice/assetConfig.slice'
import attributeSlice from '../Slice/attribute.slice'
import auditLogsSlice from '../Slice/auditLogs.slice'
import bulkUploadSlice from '../Slice/bulkUpdate.slice'
import countriesSlice from '../Slice/countries.slice'
import downstreamPlatformsSlice from '../Slice/downstreamPlatforms.slice'
import entitlementPackagesSlice from '../Slice/entitlementPackages.slice'
import geoPolicySlice from '../Slice/geo-policy.slice'
import languagesSlice from '../Slice/languages.slice'
import loaderSlice from '../Slice/loader.slice'
import orchestrationSlice from '../Slice/orchestration.slice'
import orchestrationPersistSlice from '../Slice/orchestrationPersist.slice'
import reportsSlice from '../Slice/reports.slice'
import roleSlice from '../Slice/role.slice'
import systemConfigurationSlice from '../Slice/system-configuration.slice'
import systemConfigurationsSlice from '../Slice/systemConfigurations.slice'
import userSlice from '../Slice/user.slice'
import videoAssetGroupSlice from '../Slice/videoAssetGroup.slice'

export const rootReducer = combineReducers({
  [loaderSlice.name]: loaderSlice.reducer,
  [appConfigSlice.name]: appConfigSlice.reducer,
  [assetConfigSlice.name]: assetConfigSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [alertSlice.name]: alertSlice.reducer,
  [roleSlice.name]: roleSlice.reducer,
  [assetDefinitionSlice.name]: assetDefinitionSlice.reducer,
  [attributeSlice.name]: attributeSlice.reducer,
  [geoPolicySlice.name]: geoPolicySlice.reducer,
  [assetSlice.name]: assetSlice.reducer,
  [affiliatePartnersSlice.name]: affiliatePartnersSlice.reducer,
  [languagesSlice.name]: languagesSlice.reducer,
  [countriesSlice.name]: countriesSlice.reducer,
  [entitlementPackagesSlice.name]: entitlementPackagesSlice.reducer,
  [downstreamPlatformsSlice.name]: downstreamPlatformsSlice.reducer,
  [systemConfigurationsSlice.name]: systemConfigurationsSlice.reducer,
  [orchestrationSlice.name]: orchestrationSlice.reducer,
  [systemConfigurationSlice.name]: systemConfigurationSlice.reducer,
  [auditLogsSlice.name]: auditLogsSlice.reducer,
  [orchestrationPersistSlice.name]: orchestrationPersistSlice.reducer,
  [videoAssetGroupSlice.name]: videoAssetGroupSlice.reducer,
  [appTooltipsSlice.name]: appTooltipsSlice.reducer,
  [reportsSlice.name]: reportsSlice.reducer,
  [bulkUploadSlice.name]: bulkUploadSlice.reducer,
})
