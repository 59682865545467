import React from 'react'

const DataMigrationIcon: React.FC = () => {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_8987_35024)'>
        <path
          d='M7.5 5.5L10.5 6.5C14.2333 6.5 17.0556 11.5 18 14C17.2 17.6 15.3333 19.8333 14.5 20.5C6.5 24.5 2.83333 20.5 2 18L0.5 13L2.5 8.5L7.5 5.5Z'
          fill='#D9E6E0'
        />
        <path d='M40.5 19C34.1 21 18.1667 21.8333 11 22V41L40.5 40.5V19Z' fill='#FFF4D9' />
        <mask
          id='mask0_8987_35024'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='-1'
          y='-1'
          width='43'
          height='43'
        >
          <path d='M41.9995 -0.0078125H-0.000488281V41.9922H41.9995V-0.0078125Z' fill='white' />
          <path
            d='M20.5073 13.1172C20.9601 13.1172 21.3276 13.4847 21.3276 13.9375C21.3276 14.3903 20.9601 14.7578 20.5073 14.7578C20.0545 14.7578 19.687 14.3903 19.687 13.9375C19.687 13.4847 20.0545 13.1172 20.5073 13.1172Z'
            fill='white'
          />
          <path
            d='M20.5073 16.3984C20.9601 16.3984 21.3276 16.7659 21.3276 17.2188C21.3276 17.6716 20.9601 18.0391 20.5073 18.0391C20.0545 18.0391 19.687 17.6716 19.687 17.2188C19.687 16.7659 20.0545 16.3984 20.5073 16.3984Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_8987_35024)'>
          <mask
            id='mask1_8987_35024'
            style={{ maskType: 'luminance' }}
            maskUnits='userSpaceOnUse'
            x='-1'
            y='-1'
            width='43'
            height='43'
          >
            <path
              d='M-0.000976562 -0.0156212H41.999V41.9844H-0.000976562V-0.0156212Z'
              fill='white'
            />
          </mask>
          <g mask='url(#mask1_8987_35024)'>
            <path
              d='M20.5068 13.1094C20.9596 13.1094 21.3271 13.4769 21.3271 13.9297C21.3271 14.3825 20.9596 14.75 20.5068 14.75C20.054 14.75 19.6865 14.3825 19.6865 13.9297C19.6865 13.4769 20.054 13.1094 20.5068 13.1094Z'
              fill='#402080'
            />
            <path
              d='M20.5068 16.3906C20.9596 16.3906 21.3271 16.7581 21.3271 17.2109C21.3271 17.6638 20.9596 18.0312 20.5068 18.0312C20.054 18.0312 19.6865 17.6638 19.6865 17.2109C19.6865 16.7581 20.054 16.3906 20.5068 16.3906Z'
              fill='#402080'
            />
            <path
              d='M41.1787 9.00781H32.9756V0.804688'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M20.999 5.72656H29.6943'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M20.999 9.00781H29.6943'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M17.2256 13.9297C17.2256 17.8984 14.407 21.2091 10.6631 21.9688C10.1332 22.0762 9.58437 22.1328 9.02246 22.1328C4.49187 22.1328 0.819336 18.4603 0.819336 13.9297C0.819336 9.3991 4.49187 5.72656 9.02246 5.72656C9.58437 5.72656 10.1332 5.78317 10.6631 5.89063C14.407 6.65024 17.2256 9.96102 17.2256 13.9297Z'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.02246 18.8516C6.30805 18.8516 4.10059 16.6441 4.10059 13.9297'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.02246 5.72656V13.9297H17.2256'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M10.6631 21.9688V39.5234C10.6631 40.4299 11.3973 41.1641 12.3037 41.1641H39.5381C40.4445 41.1641 41.1787 40.4299 41.1787 39.5234V9.00781L32.9756 0.804688H12.3037C11.3973 0.804688 10.6631 1.53887 10.6631 2.44531V5.89062'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M17.2256 34.1094C17.2256 35.0158 16.4914 35.75 15.585 35.75C14.6785 35.75 13.9443 35.0158 13.9443 34.1094C13.9443 33.2029 14.6785 32.4688 15.585 32.4688C16.0378 32.4688 16.4479 32.6525 16.7449 32.9495C17.0418 33.2464 17.2256 33.6566 17.2256 34.1094Z'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M20.6594 28.7068C20.3625 28.4098 20.1787 27.9997 20.1787 27.5469C20.1787 26.6404 20.9129 25.9063 21.8193 25.9063C22.7258 25.9063 23.46 26.6404 23.46 27.5469C23.46 27.9997 23.2762 28.4098 22.9793 28.7068C22.6823 29.0037 22.2721 29.1875 21.8193 29.1875C21.3665 29.1875 20.9564 29.0037 20.6594 28.7068Z'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M29.2021 34.1094C29.2021 35.0158 28.468 35.75 27.5615 35.75C26.6551 35.75 25.9209 35.0158 25.9209 34.1094C25.9209 33.6566 26.1046 33.2464 26.4016 32.9495C26.6986 32.6525 27.1087 32.4688 27.5615 32.4688C28.468 32.4688 29.2021 33.2029 29.2021 34.1094Z'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M37.6514 24.2656C37.6514 25.1721 36.9172 25.9062 36.0107 25.9062C35.1043 25.9062 34.3701 25.1721 34.3701 24.2656C34.3701 23.3592 35.1043 22.625 36.0107 22.625C36.9172 22.625 37.6514 23.3592 37.6514 24.2656Z'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M16.7451 32.9531L20.6596 28.7105'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M22.9795 28.7105L26.4018 32.9531'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M28.4722 32.75L35.1003 25.6363'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M23.7881 13.9297H36.2568'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M23.7881 17.2109H36.2568'
              stroke='#402080'
              strokeWidth='1.64062'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_8987_35024'>
          <rect width='42' height='42' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DataMigrationIcon
