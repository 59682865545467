const CircleArrowIcon = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7Z'
        fill='#ECE9F2'
        stroke='#402080'
      />
      <path
        d='M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z'
        fill='#ECE9F2'
        stroke='#402080'
      />
      <path
        d='M3.74711 7.23313L8.7021 7.23313L6.80211 9.04013C6.75437 9.08561 6.72665 9.1482 6.72506 9.21412C6.72347 9.28004 6.74813 9.34389 6.79361 9.39163C6.83909 9.43937 6.90168 9.46708 6.9676 9.46868C7.03352 9.47027 7.09736 9.44561 7.1451 9.40013L9.3121 7.33613C9.40584 7.24237 9.4585 7.11521 9.4585 6.98263C9.4585 6.85005 9.40584 6.72289 9.3121 6.62913L7.14911 4.56913C7.10137 4.52364 7.03751 4.49899 6.9716 4.50058C6.90568 4.50218 6.84309 4.52989 6.79761 4.57763C6.75212 4.62537 6.72746 4.68922 6.72906 4.75514C6.73065 4.82106 6.75837 4.88364 6.80611 4.92913L8.71011 6.73713L3.74711 6.73713C3.6808 6.73713 3.61721 6.76347 3.57033 6.81035C3.52344 6.85724 3.49711 6.92083 3.49711 6.98713C3.49711 7.05344 3.52344 7.11702 3.57033 7.16391C3.61721 7.21079 3.6808 7.23713 3.74711 7.23713V7.23313Z'
        fill='#333333'
        stroke='#333333'
        strokeWidth='0.25'
      />
    </svg>
  )
}

export default CircleArrowIcon
