import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { ReactNode, useState } from 'react'
import { Control, FieldErrors } from 'react-hook-form'
import { FieldValidationRulesModal } from '../../../Models'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface MUIAutocompleteMultiSelectModal {
  usingFor?: string
  label: string
  LabelIcon?: ReactNode
  options: any
  labelKey: string
  control?: Control
  name?: string
  disabled?: boolean
  error?: FieldErrors
  onInputChange?: any
  rules?: FieldValidationRulesModal
  value?: any
  width?: string
  limitTags?: number
  required?: boolean
  highlighSelectedField?: boolean
  id?: string
  disabledLabelsArr?: any
  disabledOptions?: any
  disabledOptionsByLable?: any
  sx?: any
  multiple?: boolean
  selectAll?: boolean
}

const MUIAutocompleteMultiSelect: React.FC<MUIAutocompleteMultiSelectModal> = ({
  usingFor = '',
  label,
  LabelIcon,
  selectAll = false,
  options = [],
  labelKey,
  name = '',
  error,
  onInputChange,
  value,
  width = '100%',
  limitTags = 1,
  required = false,
  highlighSelectedField = true,
  id = 'checkboxes-tags-demo',
  disabledOptions = [],
  disabledOptionsByLable,
  sx = {},
  multiple = true,
  disabled = false,
}: MUIAutocompleteMultiSelectModal) => {
  let updatedOptions = [...options]
  if (multiple && selectAll) {
    updatedOptions = [{ [labelKey]: 'Select All' }, ...options]
  } else {
    updatedOptions = [...options]
  }

  const [inputValue, setInputValue] = useState('')
  const handleSearchChange = (_event: any, value: string, reason: string) => {
    if (reason !== 'reset') {
      setInputValue(value)
    } else {
      setInputValue('')
    }
  }

  const handleChange = (ev: any, value: any, reason: string) => {
    if (multiple && Array.isArray(value)) {
      const isSelectAll = value.some((item: any) => item[labelKey] === 'Select All')
      value = isSelectAll ? [...options] : value
    }
    onInputChange(ev, value, reason)
  }

  return (
    <Autocomplete
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        if (usingFor === 'metaAttributes') {
          return option.fieldName === value.fieldName
        }
        if (usingFor === 'attributePartnersName' || usingFor === 'assetTypeAllowedParents') {
          return option.key === value.key
        }
        if (usingFor === 'assetTypeTollgates') {
          return option.categoryName === value.categoryName
        }
        if (
          (labelKey && option && value && option[labelKey] === value[labelKey]) ||
          (!labelKey && option === value)
        ) {
          return true
        }
        return false
      }}
      {...(multiple
        ? {
            inputValue,
            onInputChange: handleSearchChange,
            onClose: () => {
              setInputValue('')
            },
          }
        : {
            onInputChange: onInputChange,
          })}
      multiple={multiple}
      id={id}
      options={updatedOptions}
      disableCloseOnSelect={multiple}
      limitTags={limitTags}
      getLimitTagsText={(more: number) => `+${more} other${more > 1 ? 's' : ''}`}
      getOptionDisabled={(option: any) => {
        return disabledOptions.includes(option?.[disabledOptionsByLable]) ? true : false
      }}
      getOptionLabel={(option: any) => option?.[labelKey] ?? option}
      value={value}
      onChange={handleChange}
      renderOption={(props, option, { selected }, ownerState) => {
        if (usingFor === 'assetTypeMetaCategory') {
          if (!selected) {
            const selectedValues = ownerState?.value?.map((item: any) => item?.fieldName)
            if (selectedValues?.includes(option?.fieldName)) {
              selected = true
            }
          }
        }
        return (
          <li
            {...props}
            key={option._id}
            style={highlighSelectedField ? {} : { backgroundColor: selected ? 'transparent' : '' }}
            title={option?.[labelKey] ?? option}
          >
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option?.[labelKey] ?? option}
          </li>
        )
      }}
      fullWidth
      sx={{ width: width, ...sx }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={!!error?.[name]}
          helperText={error?.[name]?.message as string}
          label={
            <div className='d-flex align-items-center'>
              {LabelIcon && <div className='d-flex align-items-center'>{LabelIcon}</div>}
              <div style={{ marginLeft: 5 }}>
                {' '}
                <span>
                  {label}
                  {required && <span style={{ color: '#D4351C' }}>*</span>}
                </span>
              </div>
            </div>
          }
        />
      )}
      popupIcon={<KeyboardArrowDownIcon />}
    />
  )
}

export default MUIAutocompleteMultiSelect
