import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material'
import { ChangeEvent } from 'react'

const MUISwitch: React.FC<{
  label?: string
  checked?: boolean
  required?: boolean
  name?: string
  error?: any
  onChange?: (evt: ChangeEvent) => void
}> = ({
  label,
  checked = false,
  required = false,
  onChange = () => {
    return
  },
  name = '',
  error = {},
}: {
  label?: string
  checked?: boolean
  required?: boolean
  name?: string
  error?: any

  onChange?: (evt: ChangeEvent) => void
}) => {
  return (
    <FormControl>
      <FormControlLabel
        control={<Switch inputProps={{ id: name }} checked={checked} onChange={onChange} />}
        label={
          <span>
            {label}
            {required && <span style={{ color: '#D4351C' }}>*</span>}
          </span>
        }
      />
      {!!error?.[name] && <FormHelperText>{error?.[name]?.message}</FormHelperText>}
    </FormControl>
  )
}

export default MUISwitch
