import {
  GeoPolicyCRUDAPIDefinitionModal,
  GeoPolicyEditorSubmitModal,
} from '../../Components/GeoPolicy/GeoPolicyEditor/GeoPolicyModal'
import {
  CreateMastersData,
  CreateUserRequestModal,
  TypeObject,
  UserCRUDAPIDefinitionModal,
  ViewMastersAPIDefinition,
  attributeStatusChangeDefinitionModal,
  auditLogsApiModal,
  createRoleReqData,
  geoPolicyStatusChangeDefinitionModal,
  partnerStatusChangeDefinitionModal,
  roleCRUDAPIDefinitionModal,
  roleStatusChangeDefinitionModal,
  userStatusChangeDefinitionModal,
  viewRoleAPIDefinationModal,
} from '../../Models'
import {
  AddAssetTypeAPIModal,
  AddAssetTypeModal,
  GetCreateAssetModal,
  StatusChangeAPIDefinitionModal,
  StatusChangeAPIPayloadModal,
  categoryListApiDefinitionModal,
} from '../../Models/AssetDefinitionModal'
import { assetCRUDAPIDefinitionModal } from '../../Models/AssetModal'
import {
  assetCloningApiModal,
  assetLinkApiModal,
  attributeCRUDAPIDefinitionModal,
  cloneData,
  createAttributeData,
  getCreateAssetApiModal,
  linkpayload,
} from '../../Models/AttributeModal'
import { CONSTANTS } from '../Constant'

export const AUTH_MANAGEMENT = {
  VALIDATE_CODE_LOCAL: (code: string) => ({
    url: '/sso/action/login',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {
      code: code,
    },
    headers: {
      'Dev-Mode': true,
    },
  }),
  VALIDATE_CODE: (code: string) => ({
    url: '/sso/action/login',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {
      code: code,
    },
  }),
  REFRESH_TOKEN: () => ({
    url: '/session/action/refreshToken',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {},
  }),
  RELEASE_INFO: () => ({
    url: '/application/buildVersion/action/get',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {},
  }),
  LOGOUT: () => ({
    url: '/session/action/logout',
    method: CONSTANTS.HTTP_METHOD.GET,
    params: {},
  }),
}

export const ROLE_MANAGEMENT_CRUD = {
  CREATE_ROLE: (data: createRoleReqData) =>
    ({
      url: '/userRole/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as roleCRUDAPIDefinitionModal,
  VIEW_ROLE: (id: string) =>
    ({
      url: '/userRole/action/detail/' + id,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  PERMISSIONS_LIST: () =>
    ({
      url: '/config/action/permissions',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  LIST_ROLE: (data: string) =>
    ({
      url: '/userRole/action/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  GET_ROLES: () =>
    ({
      url: '/userrole/action/list',
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  EDIT_ROLE: (data: createRoleReqData) =>
    ({
      url: '/userRole/action/update',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data: data,
    }) as roleCRUDAPIDefinitionModal,
  DELETE_ROLE: (id: string) =>
    ({
      url: '/userRole/action/delete/' + id,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as roleCRUDAPIDefinitionModal,
  CHANGE_STATUS: (data: { status: string; roleId: string }) =>
    ({
      url: '/userRole/action/updateRoleStatus',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data: data,
    }) as roleStatusChangeDefinitionModal,
}

export const USER_MANAGEMENT_CRUD = {
  CREATE_USER: (data: CreateUserRequestModal) =>
    ({
      url: '/user/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as UserCRUDAPIDefinitionModal,
  UPDATE_USER: (data: CreateUserRequestModal) =>
    ({
      url: '/user/action/update',
      method: 'patch',
      data: data,
    }) as UserCRUDAPIDefinitionModal,
  VIEW_USER: (id: string) =>
    ({
      url: `/user/action/list?page=1&filterBy=_id&filterValue=${id}&filterCondition=isEqualTo`,
      method: 'get',
      data: {},
    }) as roleCRUDAPIDefinitionModal,
  CHANGE_STATUS: (data: { userId: string; userStatus: string }) =>
    ({
      url: '/user/action/updateUserStatus',
      method: 'patch',
      data: data,
    }) as userStatusChangeDefinitionModal,
  LIST_USER: (data: string) =>
    ({
      url: '/user/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  SAVE_SEARCH_LIST: (data: string) =>
    ({
      url: '/saveSearch/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}

export const CATEGORY_APIS = {
  LIST_CATEGORIES: () =>
    ({
      url: '/attributeRepository/metaCategories/action/list',
      method: 'get',
      data: {},
    }) as categoryListApiDefinitionModal,
}

export const SYSTEM_CONFIG_APIS = {
  LIST_MASTERS: (key: string) =>
    ({
      url: '/attributeRepository/systemConfigurations/action/list?keyName=' + key,
      method: 'get',
      data: {},
    }) as categoryListApiDefinitionModal,
}

export const MASTERS = {
  LIST_MASTERS: (key: string) =>
    ({
      url: `/attributeRepository/${key}/action/list?page=1&limit=100000`,
      method: 'get',
      data: {},
    }) as categoryListApiDefinitionModal,
}

export const ASSET_TYPE_DEFINITION_CRUD = {
  LIST_ASSET_TYPE: (data: string) =>
    ({
      url: '/attributeRepository/assetDefinition/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  VIEW_ASSET_TYPE: (data: string) =>
    ({
      url: '/attributeRepository/assetDefinition/action/detail/' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  VIEW_ASSET_TYPE_BY_ASSET_TYPE: (data: any) =>
    ({
      url: `/attributeRepository/assetDefinition/action/list?assetType=${data?.assetType}&contentCategory=${data?.contentCategory}&assetCategory=${data?.assetCategory}&sortBy=createdAt&sortType=1&page=1&limit=10`,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  ADD_ASSET_TYPE_DEFINITION: (data: AddAssetTypeModal) =>
    ({
      url: '/attributeRepository/assetDefinition/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as AddAssetTypeAPIModal,
  UPDATE_ASSET_TYPE_DEFINITION: (data: AddAssetTypeModal) =>
    ({
      url: '/attributeRepository/assetDefinition/action/update',
      method: 'put',
      data: data,
    }) as AddAssetTypeAPIModal,
  UPDATE_ASSET_TYPE_DEFINITION_STATUS: (data: StatusChangeAPIPayloadModal) =>
    ({
      url: '/attributeRepository/assetDefinition/action/updateAssetDefinitionStatus',
      method: 'put',
      data: data,
    }) as StatusChangeAPIDefinitionModal,
  LIST_DATA_SOURCE: () => {
    return {
      url: '/attributeRepository/dataSource/action/list',
      method: 'get',
      data: {},
    } as viewRoleAPIDefinationModal
  },
  LIST_ASSET_VALIDATION: () => {
    return {
      url: '/attributeRepository/assetValidation/action/list',
      method: 'get',
      data: {},
    } as any
  },
}

export const ATTRIBUTE_MANAGEMENT_CRUD = {
  VIEW_USER: (id: string) =>
    ({
      url: `/attributeRepository/attributes/action/detail/${id}`,
      method: 'get',
      data: {},
    }) as any,
}

export const ATTRIBUTE_REPOSITORY_CRUD = {
  CREATE_ATTRIBUTES: (data: createAttributeData) =>
    ({
      url: '/attributeRepository/attributes/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as attributeCRUDAPIDefinitionModal,
  UPDATE_ATTRIBUTES: (data: createAttributeData) =>
    ({
      url: '/attributeRepository/attributes/action/update',
      method: 'patch',
      data: data,
    }) as attributeCRUDAPIDefinitionModal,
  LIST_ATTRIBUTE_REPO: (data: string) =>
    ({
      url: '/attributeRepository/attributes/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  VIEW_ATTRIBUTE_REPO: (id: string) => ({
    url: `/attributeRepository/attributes/action/detail/${id}`,
    method: 'get',
    data: {},
  }),
  CHANGE_STATUS: (data: { status: string; attributeId: string }) =>
    ({
      url: '/attributeRepository/attributes/action/updateAttributeStatus',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data: data,
    }) as attributeStatusChangeDefinitionModal,
  DELETE_ATTRIBUTE: (id: string) =>
    ({
      url: '/attributeRepository/attributes/action/delete/' + id,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as roleCRUDAPIDefinitionModal,
  FIELD_TYPE: () =>
    ({
      url: '/attributeRepository/systemConfigurations/action/list?keyName=FIELD_TYPE',
      method: 'get',
      data: {},
    }) as attributeCRUDAPIDefinitionModal,
}

export const MASTERS_MANAGEMENT = {
  AFFILIATE_PARTNERS_CRUD: {
    LIST_AFFILIATE_PARTNERS: (data: string) =>
      ({
        url: '/attributeRepository/affiliatePartners/action/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE_AFFILIATE_PARTNERS: (data: CreateMastersData) => ({
      url: '/attributeRepository/affiliatePartners/action/update',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
    CREATE_AFFILIATE_PARTNERS: (data: CreateMastersData) => ({
      url: '/attributeRepository/affiliatePartners/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
  LANGUAGES_CRUD: {
    LIST_LANGUAGES: (data: string) =>
      ({
        url: '/attributeRepository/languages/action/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE_LANGUAGES: (data: CreateMastersData) => ({
      url: '/attributeRepository/languages/action/update',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
    CREATE_LANGUAGES: (data: CreateMastersData) => ({
      url: '/attributeRepository/languages/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
  ENTITLEMENT_PACKAGES_CRUD: {
    LIST_ENTITLEMENT_PACKAGES: (data: string) =>
      ({
        url: '/attributeRepository/entitlementPackages/action/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE_ENTITLEMENT_PACKAGES: (data: CreateMastersData) => ({
      url: '/attributeRepository/entitlementPackages/action/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }),
    CREATE_ENTITLEMENT_PACKAGES: (data: CreateMastersData) => ({
      url: '/attributeRepository/entitlementPackages/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
  DOWNSTREAM_PLATFORMS_CRUD: {
    LIST_DOWNSTREAM_PLATFORMS: (data: string) =>
      ({
        url: '/attributeRepository/downstreamPlatforms/action/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE_DOWNSTREAM_PLATFORMS: (data: CreateMastersData) => ({
      url: '/attributeRepository/downstreamPlatforms/action/update',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
    CREATE_DOWNSTREAM_PLATFORMS: (data: CreateMastersData) => ({
      url: '/attributeRepository/downstreamPlatforms/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
  COUNTRIES_CRUD: {
    LIST_COUNTRIES: (data: string) =>
      ({
        url: '/attributeRepository/countries/action/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE_COUNTRIES: (data: CreateMastersData) => ({
      url: '/attributeRepository/countries/action/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }),
    CREATE_COUNTRIES: (data: CreateMastersData) => ({
      url: '/attributeRepository/countries/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
  SYSTEM_CONFIGURATION_CRUD: {
    LIST: (data: string) =>
      ({
        url: '/attributeRepository/systemConfigurations/action/list' + '?' + data,
        method: 'get',
        data: {},
      }) as ViewMastersAPIDefinition,
    UPDATE: (data: any) => ({
      url: '/attributeRepository/systemConfigurations/action/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }),
    CREATE: (data: any) => ({
      url: '/attributeRepository/systemConfigurations/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }),
  },
}

export const GEO_POLICY_CRUD = {
  LIST_GEO_POLICY: (data: string) =>
    ({
      url: '/attributeRepository/geoPolicy/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  VIEW_GEO_POLICY_REPO: (id: string) => ({
    url: `/attributeRepository/geoPolicy/action/detail?id=${id}`,
    method: 'get',
    data: {},
  }),
  DELETE_GEO_POLICY: (id: string) =>
    ({
      url: '/attributeRepository/geoPolicy/action/delete/' + id,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as roleCRUDAPIDefinitionModal,
  CHANGE_STATUS: (data: { geoPolicyStatus: string; geoPolicyId: string }) =>
    ({
      url: '/attributeRepository/geoPolicy/action/updateGeoPolicyStatus',
      method: CONSTANTS.HTTP_METHOD.PUT,
      data: data,
    }) as geoPolicyStatusChangeDefinitionModal,
  CREATE_POLICY: (data: GeoPolicyEditorSubmitModal) =>
    ({
      url: '/attributeRepository/geoPolicy/action/add',
      method: 'post',
      data: data,
    }) as GeoPolicyCRUDAPIDefinitionModal,
  EDIT_POLICY: (data: GeoPolicyEditorSubmitModal) =>
    ({
      url: '/attributeRepository/geoPolicy/action/update',
      method: 'patch',
      data: data,
    }) as GeoPolicyCRUDAPIDefinitionModal,
  GET_COUNTRY: () =>
    ({
      url: '/attributeRepository/countries/action/list?page=1&limit=500',
      method: 'get',
      data: '',
    }) as GeoPolicyCRUDAPIDefinitionModal,
}
export const ASSET_CREATION = {
  VIEW_ASSET_REPO: (contentId: string) => ({
    url: `/content/action/detail/${contentId}`,
    method: 'get',
    data: {},
  }),
  VIEW_ASSET_REPO_BASED_ON_CATEGORY: (contentId: string, metaCategory: string) => ({
    url: `/content/metaCategory/action/detail/${contentId}/${metaCategory}`,
    method: 'get',
    data: {},
  }),
  VIEW_MARK_DONE_STATUS: (contentId: string) => ({
    url: `/content/metaCategories/markDone/action/detail/${contentId}`,
    method: 'get',
    data: {},
  }),
  DELETE_ASSET_REPO: (contentId: string) => ({
    url: `/content/action/delete/${contentId}`,
    method: 'delete',
    data: {},
  }),
}
export const MASTERS_API = {
  LIST_MASTERS: (data: string) =>
    ({
      url: data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  CREATE_MASTERS: (data: CreateUserRequestModal) =>
    ({
      url: '/attributeRepository/systemConfigurations/action/createMaster',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as UserCRUDAPIDefinitionModal,
  LIST_MASTERS_CONFIGURATION: (data: string) =>
    ({
      url: '/attributeRepository/systemConfigurations/action/systemConfigurationList',
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
}
export const REPORT_COMPARISON = {
  COMPARISON_LIST: (data: string) =>
    ({
      url: '/comparisonReport/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  GET_MIGRATION: (data: any) =>
    ({
      url: `${process.env.REACT_APP_MIGRATION_URL}/update_content?content_id=${data?.contentId}&house_id=${data?.blitzHouseId}`,
      method: 'get',
      withoutBaseURL: true,
      headers: {
        Authorization: process.env.REACT_APP_MIGRATION_TOKEN,
      },
    }) as any,
}
export const ASSET_CURD_LINKING = {
  GET_DATA_BASED_ON_ID: (data: any) =>
    ({
      url: `/content/action/link/detail/${data}`,
      method: 'get',
      data: {},
    }) as assetCRUDAPIDefinitionModal,
  GET_LINKED_REF_DATA_BASED_ON_ID: (data: any) =>
    ({
      url: `/content/action/episode/detail/${data}`,
      method: 'get',
      data: {},
    }) as assetCRUDAPIDefinitionModal,
}
export const ASSET_CURD_API = {
  CREATE_ASSET: (data: any) =>
    ({
      url: `/content/metaCategory/${data?.metaCategory}`,
      method: 'patch',
      data: data,
    }) as assetCRUDAPIDefinitionModal,
  MARK_DONE: (data: any) =>
    ({
      url: `/content/metaCategory/markDone/${data?.metaCategory}`,
      method: 'patch',
      data: data,
    }) as assetCRUDAPIDefinitionModal,
  MARK_DONE_BATCH: (data: any) =>
    ({
      version: 'v2',
      url: '/content/metaCategories/markDone',
      method: 'post',
      data: { contentId: data.contentId },
    }) as any,
  CHECK_STATUS_MULTI_ASSET: (data: Record<string, any>) =>
    ({
      url: `/content/video/action/checkMultiAsset/detail/${data?.assetGroupId}/${data?.contentId}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as assetCRUDAPIDefinitionModal,
  IMAGE_CONTENT_TYPE: {
    CREATE_IMAGE_VARIANT: (data: any) =>
      ({
        url: '/content/image/action/add',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    UPDATE_IMAGE_VARIANT: (data: any) =>
      ({
        url: '/content/image/action/update',
        method: CONSTANTS.HTTP_METHOD.PATCH,
        data,
      }) as any,
    DELETE_IMAGE_VARIANT: (
      attributeName: any,
      variantId: any,
      contentId: any,
      targeting: any,
      segmentId: any,
    ) =>
      ({
        url: `/content/image/action/delete/${contentId}/${attributeName}/${variantId}`,
        method: CONSTANTS.HTTP_METHOD.DELETE,
        data: {
          targeting,
          segmentId,
        },
      }) as any,
    GENERATE_PRESIGNED_URL: (data: any) =>
      ({
        url: `/content/image/action/preSignedUrl?${data}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data,
      }) as any,
    GET_IMAGE_DETAILS: (contentId: any) =>
      ({
        url: `/content/image/action/detail/${contentId}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
    GENERATE_PRESIGNED_URL_BULK: (data: any) =>
      ({
        url: '/content/image/action/preSignedUrl',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    BULK_IMAGE_UPLOAD: (data: any) =>
      ({
        url: '/content/image/action/bulkImageUpload',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    GET_IMAGE_PREFIX_DETAILS: (contentId: any) =>
      ({
        url: '/content/image/action/getImageAttributePrefix',
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
  },

  VIDEO_CONTENT_TYPE: {
    GET_ASSET_DATA: (data: any) =>
      ({
        url: `/content/video/action/assetGroupDetail/${data?.contentId}/${data?.assetGroupTmpId}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
    GET_SUBTITLE_DATA: (data: any) =>
      ({
        url: `/content/video/action/subtitle/detail/${data}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
    CREATE_ASSET_GROUP: (data: any) =>
      ({
        url: '/content/video/action/createAssetGroup',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,

    UPDATE_ASSET_GROUP: (data: any) =>
      ({
        url: '/content/video/action/updateAssetGroup',
        method: CONSTANTS.HTTP_METHOD.PATCH,
        data,
      }) as any,
    UPLOAD_SUBTITLE: (data: any) =>
      ({
        url: '/content/video/action/subtitle/add',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    PREVIEW_VIDEO: (data: any) =>
      ({
        url: '/content/video/action/preview',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    FETCH_VALIDATE: (data: any) =>
      ({
        url: '/content/video/action/validateAssetGroup',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    TRANSCODING_VALIDATION: (data: any) =>
      ({
        url: '/content/video/action/transcode',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    WORKFLOW_STATUS: (data: any) =>
      ({
        url: `/content/video/action/assetGroupWorkflowStatus?${data}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
    GET_EXISTING_LIST: (data: any) =>
      ({
        url: `/content/video/action/listAssetGroup?${data}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data: {},
      }) as any,
    LIST_AUDIO_VIDEO: (data: any) =>
      ({
        url: '/content/video/action/searchFile',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    LIST_SUBTITLE: (data: any) =>
      ({
        url: '/content/video/action/searchSubtitleFile',
        method: CONSTANTS.HTTP_METHOD.POST,
        data,
      }) as any,
    DELETE_ASSET_GROUP: (data: any) =>
      ({
        url: '/content/video/action/deleteAssetGroup',
        method: CONSTANTS.HTTP_METHOD.PATCH,
        data,
      }) as any,
    GENERATE_SUBTITLE_PRESIGNED_URL: (data: any) =>
      ({
        url: `/content/video/action/preSignedUrl?${data}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data,
      }) as any,
    PREVIEW_URL_DRM_TYPE: (data: any) =>
      ({
        url: `/content/video/action/getPreviewUrl?${data}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data,
      }) as any,
    DRM_TYPE: (contentVersionId: string, data: any) =>
      ({
        url: `/content/video/action/transactionInput/detail/${contentVersionId}?${data}`,
        method: CONSTANTS.HTTP_METHOD.GET,
        data,
      }) as any,
  },
}
export const CONTENT_CRUD = {
  NESTED_VERSION_LIST: (blitzId: string) =>
    ({
      url: '/contentVersions/action/list?blitzId=' + blitzId,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  NESTED_VERSION_LIST_POST: (data: any) =>
    ({
      url: '/global-search/nested/action/list',
      method: 'post',
      data: data,
    }) as viewRoleAPIDefinationModal,
  LIST_ASSET: (data: string) =>
    ({
      url: '/content/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  LIST_ASSET_POST: (data: any) =>
    ({
      version: 'v1.1',
      url: '/global-search/action/list',
      method: 'post',
      data: data,
    }) as viewRoleAPIDefinationModal,
  LIST_ASSOCIATED_ASSET: (data: any) =>
    ({
      url: '/associatedContent/action/list',
      method: 'post',
      data: data,
    }) as viewRoleAPIDefinationModal,
  ASSET_COLUMN_LIST: () =>
    ({
      url: '/content/action/list/dropdown',
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  CLONE_ASSET: (data: cloneData) =>
    ({
      url: '/content/action/clone',
      method: 'post',
      data: data,
    }) as assetCloningApiModal,
  LINK_ASSET: (payload: linkpayload) =>
    ({
      url: '/content/associateContent/action/link',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: payload,
    }) as assetLinkApiModal,
  UNLINK_ASSET: (payload: linkpayload) =>
    ({
      url: '/content/associateContent/action/unlink',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: payload,
    }) as assetLinkApiModal,
  GET_CREATE_ASSET: (data: GetCreateAssetModal) =>
    ({
      url: '/content/action/add',
      method: 'post',
      data: data,
    }) as getCreateAssetApiModal,
}
export const AUDIT_LOGS = {
  LIST_USER_ROLE_LOG: (data: string) =>
    ({
      url: '/auditTrails/action/list' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as auditLogsApiModal,
  AUDIT_TRAILS_ARCHIVED_LOGS: (data: string, limit: number, page: number) =>
    ({
      url: `/content/archived/action/list/${data}?page=${page}&limit=${limit}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  CONTENT_DOWNLOAD: (data: string) =>
    ({
      url: '/content/preSignedUrl/' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as auditLogsApiModal,
}
export const WORKFLOW_API = {
  PUBLISH_CONTENT: (contentId: string) => ({
    url: '/content/action/publish',
    method: CONSTANTS.HTTP_METHOD.POST,
    data: { contentId },
  }),
  DEPUBLISH_CONTENT: (contentId: string) => ({
    url: '/content/action/dePublish',
    method: CONSTANTS.HTTP_METHOD.POST,
    data: { contentId },
  }),
  DEPENDENT_CONTENT_LIST: (versionId: string, blitzId: string) => ({
    url: '/content/dependentAssets/action/list/' + `${versionId}?blitzId=${blitzId}`,
    method: CONSTANTS.HTTP_METHOD.GET,
    data: {},
  }),
  VALIDATE_DRAFT_EXISTS: (
    versionId: string,
    blitzId: string,
    assetType: string,
    contentCategory: string,
    metaCategory: string,
    transactionId: string,
  ) => ({
    url:
      '/content/editable/action/detail/' +
      `${versionId}?blitzId=${blitzId}&assetType=${assetType}&contentCategory=${contentCategory}&metaCategory=${metaCategory}`,
    method: CONSTANTS.HTTP_METHOD.GET,
    data: {},
    headers: { metacategoryTxnId: transactionId },
  }),
  SCHEDULE_CREATE: (data: any) => ({
    url: '/content/schedule/action/add',
    method: CONSTANTS.HTTP_METHOD.POST,
    data: data,
  }),
  SCHEDULE_UPDATE: (data: any) => ({
    url: '/content/schedule/action/update',
    method: CONSTANTS.HTTP_METHOD.PATCH,
    data: data,
  }),
  SCHEDULE_DELETE: (scheduleId: string) => ({
    url: '/content/schedule/action/delete',
    method: CONSTANTS.HTTP_METHOD.DELETE,
    data: { scheduleId },
  }),
  SCHEDULE_LIST: (blitzId: string) => ({
    url: '/content/schedule/action/list/' + blitzId,
    method: CONSTANTS.HTTP_METHOD.GET,
    data: {},
  }),
  GET_OTHER_VERSIONS: (contentId: string) => ({
    url: '/content/versions/action/list/' + contentId,
    method: CONSTANTS.HTTP_METHOD.GET,
    data: {},
  }),
  CREATE_OVERIDE_VERSION: (contentId: string) => ({
    url: '/content/draftVersion/action/add',
    method: CONSTANTS.HTTP_METHOD.POST,
    data: { contentId },
  }),
}
export const MEDIA_INFO = {
  MEDIA_INFO_LIST: (data: string, refetch = false) =>
    ({
      url: `/content/video/action/${refetch ? 'reFetchMediaInfo' : 'mediaInfodetail'}` + '/' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as auditLogsApiModal,
  ADD_MEDIA_INFO: (data: any) =>
    ({
      url: '/content/video/action/addMediaInfoPlatformData',
      method: CONSTANTS.HTTP_METHOD.POST,
      data,
    }) as auditLogsApiModal,
  UPDATE_MEDIA_INFO: (data: any) =>
    ({
      url: '/content/video/action/updateMediaInfo',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data,
    }) as auditLogsApiModal,
  DELETE_MEDIA_INFO: (data: string, id: string) =>
    ({
      url: '/content/video/action/mediaInfoPlatformDelete/' + data + '/' + id,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as auditLogsApiModal,
}
export const HOME_PAGE = {
  GET_HOME_PAGE: (data: string) =>
    ({
      url: '/dashboard/action/detail' + '?' + data,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as auditLogsApiModal,
  GET_PINNED_SEARCH_LIST: () =>
    ({
      url: '/pinned-dashboard/action/countPinnedAssets',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as auditLogsApiModal,
  GET_PINNED_SEAECH: (data: string) =>
    ({
      url: `/pinned-dashboard/action/listPinnedAssets?id=${data}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as auditLogsApiModal,
  DELETE_PINNED_SEARCH: (id: string, isPinned: boolean) =>
    ({
      url: '/saveSearch/action/delete?saveSearchId=' + id + '&isPinnedToDashboard=' + isPinned,
      method: CONSTANTS.HTTP_METHOD.DELETE,
      data: {},
    }) as roleCRUDAPIDefinitionModal,
}
export const HOME = {
  RECENT_ACTIVITY: {
    url: '/homePage/action/recentActivity',
    method: CONSTANTS?.HTTP_METHOD.GET,
    data: {},
  } as { url: string; method: string; data: unknown },
}
export const MANAGETIP_APIS_CRUD = {
  GET_TIP: () => ({
    url: '/tooltip/action/list',
    method: 'get',
    data: {},
  }),

  TIP_UPDATE: (data: any) => ({
    url: '/tooltip/action/update',
    method: CONSTANTS.HTTP_METHOD.PATCH,
    data: data,
  }),
}

export const GLOBAL_SEARCH = {
  ATTRIBUTE_LIST: () =>
    ({
      url: '/global-search/attributes/action/list',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as attributeCRUDAPIDefinitionModal,
  ASSET_DEFINITION_LIST: () =>
    ({
      url: '/global-search/assetDefinition/action/list',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as attributeCRUDAPIDefinitionModal,
  ADVANCE_SEARCH: (data: any) =>
    ({
      version: 'v1.1',
      url: '/global-search/action/list',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as attributeCRUDAPIDefinitionModal,
  EXPORT_SEARCH: (data: any) =>
    ({
      url: '/global-search/export/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as attributeCRUDAPIDefinitionModal,
  EXPORT_SEARCH_LIST: () =>
    ({
      url: '/global-search/export/action/list',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as attributeCRUDAPIDefinitionModal,
  ADD_SAVE_SEARCH: (data: any) =>
    ({
      url: '/saveSearch/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as attributeCRUDAPIDefinitionModal,
}

export const REPORTS_CRUD = {
  REPORTS_GET_BY_ID: (id: any) =>
    ({
      url: `/inboundService/content/action/detail/${id}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as any,
  REPORTS_CONTENT_INGESTION_RETRIGGER: (data: any) =>
    ({
      url: '/partner/action/retrigger',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as any,
}

export const PARTNER_APIS = {
  GET_PARTNER_LIST: (data: string) =>
    ({
      url: '/partner/action/list' + '?' + data,
      method: 'get',
      data: {},
    }) as viewRoleAPIDefinationModal,
  VIEW_PARTNER: (id: string) =>
    ({
      url: '/partner/action/detail/' + id,
      method: 'get',
      data: {},
    }) as roleCRUDAPIDefinitionModal,
  CREATE_PARTNER: (data: CreateUserRequestModal) =>
    ({
      url: '/partner/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as UserCRUDAPIDefinitionModal,
  EDIT_PARTNER: (data: createRoleReqData) =>
    ({
      url: '/partner/action/update',
      method: CONSTANTS.HTTP_METHOD.PATCH,
      data: data,
    }) as any,
  PARTNER_STATUS: (data: { userId: string; userStatus: string }) =>
    ({
      url: '/partner/action/updatePartnerStatus',
      method: 'patch',
      data: data,
    }) as partnerStatusChangeDefinitionModal,
}
export const BULK_UPDATE_APIS = {
  CANCEL_JOB: (data: any) =>
    ({
      url: '/bulkUpdate/action/cancelJob',
      method: 'patch',
      data: data,
    }) as partnerStatusChangeDefinitionModal,
  GET_JOB_LIST: (data: any) =>
    ({
      url: '/content/bulkUpload/action/list',
      method: 'post',
      data: data,
    }) as partnerStatusChangeDefinitionModal,
  DOWNLOAD_JOB_CSV: (id: any) =>
    ({
      url: `/bulkUpdate/action/download/${id}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  DOWNLOD_CSV_DOWNLOAD: (attributes: TypeObject) =>
    ({
      url: '/bulkUpdate/csvTemplate/action/download',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: attributes,
    }) as viewRoleAPIDefinationModal,
}

export const BULK_UPDATE = {
  ADD_JOB: (data: any) =>
    ({
      url: '/content/bulkUpload/action/add',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as viewRoleAPIDefinationModal,
  UPDATE_JOB: (data: any) =>
    ({
      url: '/content/bulkUpload/action/update',
      method: CONSTANTS.HTTP_METHOD.POST,
      data: data,
    }) as viewRoleAPIDefinationModal,
  FILE_CONFIG: () =>
    ({
      url: '/bulkUpdate/configuration/action/list',
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  PRESIGNED_URL_UPLOAD_CSV: (data: any) =>
    ({
      url: `/bulkUpdate/action/preSignedUrl?key=${data.fileName}&jobId=${data.jobId}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  GET_JOB_DETAILS: (jobId: string) =>
    ({
      url: `/content/bulkUpload/action/detail/${jobId}`,
      method: CONSTANTS.HTTP_METHOD.GET,
      data: {},
    }) as viewRoleAPIDefinationModal,
  CANCEL_JOB: (data: any) =>
    ({
      url: '/bulkUpdate/action/cancelJob',
      method: 'patch',
      data: data,
    }) as partnerStatusChangeDefinitionModal,
}
