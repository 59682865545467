import { ContentCopy } from '@mui/icons-material'
import { Stack } from '@mui/material'

/**
 * AssetClone Component
 *
 * Renders Asset Clone components.
 *
 * @component
 * @param disabled - For disabled the click on clone.
 * @param index - The index of the asset version.
 * @param assetDetails - The details of the asset.
 * @param onClickClone - The click handler for asset version.
 * @returns {React.JSX.Element | null} JSX Element representing the AssetClone component.
 */

const AssetClone = ({
  disabled = false,
  index = 0,
  assetDetails = {},
  onClickClone,
}: {
  disabled?: boolean
  index?: number
  assetDetails?: Record<string, any>
  onClickClone?: (assetDetails: Record<string, any>, index: number) => void
}): React.JSX.Element | null => {
  return (
    <Stack
      data-testid='asset-clone-icon'
      onClick={(ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        if (!disabled && onClickClone) onClickClone(assetDetails, index)
      }}
    >
      <Stack className='list-icon'>
        <ContentCopy
          sx={
            disabled
              ? { color: '#d9d9d9', height: '16px', cursor: 'not-allowed' }
              : { color: '#402080', height: '16px' }
          }
        />
      </Stack>
    </Stack>
  )
}

export default AssetClone
