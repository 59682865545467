import { Grid } from '@mui/material'
import { CONSTANTS } from '../../Constants/Constant'
import { checkErrorData } from '../../Helpers/Util'
import MUIDateTimePicker from '../MUIDateTimePicker'
import { PARTNER_CONSTENT } from '../Partners/PartnersEditor/PartnersConstent'
import { MUIFieldWithoutControl, MUIMultiselectAsset } from '../Shared'

interface FormRenderProps {
  formFields: any[]
  onChange: (ev: any, index: number, item: any) => void
  mount: boolean
}

const FormRender = ({ formFields, onChange, mount }: FormRenderProps) => {
  return (
    <Grid container spacing={2}>
      {formFields.map((item, index: number) => {
        if (
          item.fieldType === CONSTANTS.FIELDTYPES.TEXT ||
          item.fieldType === CONSTANTS.FIELDTYPES.NUMBER
        ) {
          return (
            <Grid item xs={6} md={6} lg={6} xl={6} key={item.id}>
              <MUIFieldWithoutControl
                style={{
                  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                    {
                      '-webkit-appearance': 'none',
                    },
                }}
                name={item.fieldName}
                type={item.fieldType}
                error={
                  item.errorMessage ? { [item.fieldName]: { message: item.errorMessage } } : {}
                }
                onKeyDown={(ev: any) => {
                  if (item.fieldType === 'NUMBER') {
                    if (PARTNER_CONSTENT.AVOID_KEY_FOR_PHONE.includes(ev.key)) ev.preventDefault()
                  }
                }}
                onPaste={(ev: any) => {
                  if (item.fieldType === 'NUMBER') {
                    if (checkErrorData(ev.clipboardData.getData('text'))) ev.preventDefault()
                  }
                }}
                label={item.label}
                placeholder={item.label}
                value={item.value}
                onChange={(ev: any) => {
                  onChange(ev, index, item)
                }}
                required={item?.validations?.required}
              />
            </Grid>
          )
        } else if (item.fieldType === CONSTANTS.FIELDTYPES.DATETIME) {
          return (
            <Grid item xs={6} md={6} lg={6} xl={6} key={item.id}>
              <MUIDateTimePicker
                label={item?.label}
                type='datetime'
                views={CONSTANTS.DATE_TIME_FORMAT_VIEWS}
                name={item?.fieldName}
                error={
                  item.errorMessage ? { [item.fieldName]: { message: item.errorMessage } } : {}
                }
                value={item?.value ? new Date(item.value) : null}
                onChange={(ev: any) => onChange(ev, index, item)}
                required={item?.validations?.required}
              />
            </Grid>
          )
        } else if (item.fieldType === CONSTANTS.FIELDTYPES.SELECT) {
          return (
            <Grid item xs={6} md={6} lg={6} xl={6} key={item.id}>
              <MUIMultiselectAsset
                name={item?.fieldName}
                className='autocomple-class'
                multiple={false}
                label={item?.label}
                options={item?.options}
                error={
                  item.errorMessage ? { [item.fieldName]: { message: item.errorMessage } } : {}
                }
                onInputChange={(_ev: any, newValue: any) => {
                  if (mount) onChange(newValue, index, item)
                }}
                id={item?.fieldName}
                value={item.value}
                labelKey={'value'}
                required={item?.validations?.required}
              />
            </Grid>
          )
        } else {
          return null
        }
      })}
    </Grid>
  )
}

export default FormRender
