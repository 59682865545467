import { useEffect, useState } from 'react'
import { Edit } from '../../Assets/Images/svgs'
import { CircleMinus, CirclePlus } from '../../Components/Icons'
import { EyeIcon } from '../../Components/Icons/Bulb'
import { MUIAccordion, useDialog } from '../../Components/Shared'
import ConfirmDialog from '../../Components/Shared/Dialog/ConfirmDialog'
import { MANAGETIP_APIS_CRUD } from '../../Constants/ApiDefinations'
import { CONSTANTS } from '../../Constants/Constant'
import { formatTootipData } from '../../Helpers/ManageTips'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../Helpers/Permission'
import updateTagMetaData from '../../Helpers/updateTagMetaData'
import mainApiService from '../../Services'
import { useAppDispatch, useAppSelector } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import { getAppTooltips } from '../../Store/Slice/app-tooltips.slice'
import { setLoader } from '../../Store/Slice/loader.slice'
import AccessDenied from '../AccessDenied'
import './index.scss'

const ToolTipList = () => {
  const showDialog = useDialog()
  const dispatch = useAppDispatch()
  const { tooltips } = useAppSelector((state) => state.appTooltips)
  const [expanded, setExpanded] = useState<string>('')
  const [tooltipsData, setTooltipsData] = useState<any>([])
  const [tooltipSelected, setTooltipSelected] = useState<{
    action: string
    page: any
  } | null>(null)
  const accessWrite = checkPermission(PERMISSIONS.META_MASTER_MANAGE_TIPS, [
    PERMISSION_TYPE.CAN_WRITE,
  ])
  const accessRead = checkPermission(PERMISSIONS.META_MASTER_MANAGE_TIPS, [
    PERMISSION_TYPE.CAN_READ,
  ])

  useEffect(() => {
    if (accessRead) {
      getTipsData()
    }
  }, [])

  useEffect(() => {
    if (tooltips.length) {
      const tootipsData: any = formatTootipData(tooltips)
      setTooltipsData(Object.values(tootipsData))
    } else {
      setTooltipsData([])
    }
  }, [tooltips])

  const getTipsData = async () => {
    dispatch(setLoader(true))
    await dispatch(getAppTooltips() as any)
    dispatch(setLoader(false))
  }

  const handelViewTipDialog = (page: any) => {
    const extraUI = (
      <ul className='View-tooltip-list'>
        {page?.tooltipMessages?.map((message: string, index: number) => (
          <li key={'view_' + String(index)}>{message}</li>
        ))}
      </ul>
    )
    showDialog('confirm', {
      title: 'View Tooltip',
      icon: 'DialogViewIcon',
      btn1Label: 'Cancel',
      extraUI,
      width: '730px',
      onClose: () => {
        setTooltipSelected(null)
      },
    })
  }

  const addEditRemoveMessage = (action: string, index: number, value?: any) => {
    const tooltipSelectedCopy: any = JSON.parse(JSON.stringify(tooltipSelected))
    if (action === 'add') {
      tooltipSelectedCopy.page.tooltipMessages.push('')
    } else if (action === 'delete') {
      tooltipSelectedCopy.page.tooltipMessages.splice(index, 1)
    } else if (action === 'update') {
      tooltipSelectedCopy.page.tooltipMessages[index] = value
    }
    setTooltipSelected(tooltipSelectedCopy)
  }

  const createEditTip = async (): Promise<any> => {
    dispatch(setLoader(true))
    const tooltipSelectedCopy: any = JSON.parse(JSON.stringify(tooltipSelected))
    const { _id, module, page, tooltipMessages, sortOrder, status } = tooltipSelectedCopy.page
    const data = {
      module,
      page,
      sortOrder,
      status,
      tooltipsId: _id,
      tooltipMessages: tooltipMessages.filter((message: string) => message.trim().length),
    }
    const requestedData: any = MANAGETIP_APIS_CRUD.TIP_UPDATE(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    if (result.error) {
      dispatch(showAlert([true, result?.error?.message, 'error']))
      return
    }
    dispatch(showAlert([true, result.message, 'success']))
    setTooltipSelected(null)
    getTipsData()
  }

  useEffect(() => {
    updateTagMetaData({ title: 'Manage Tips' })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  if (!accessRead) {
    return <AccessDenied />
  }

  return (
    <div className={'managetipswrapper'}>
      <h4>Manage Tips</h4>
      {tooltipsData?.map((module: any) => (
        <MUIAccordion
          title={module.module}
          key={module.module}
          open={module.module === expanded}
          callback={() => {
            setExpanded(module.module === expanded ? '' : module.module)
          }}
        >
          <div className='accordianWrapper'>
            <div className='accordian-wrapper-head'>
              <span>Page</span>
              <span>Action</span>
            </div>
            <div className='accordian-wrapper-body'>
              {module.pages.map((page: any, index: number, arr: any) => (
                <div
                  key={page.page}
                  className={`d-flex justify-content-between   ${
                    index === arr.length - 1
                      ? 'accordian-wrapper-row-bottom'
                      : 'accordian-wrapper-row'
                  }`}
                >
                  <div className='p-10p'>{page.page}</div>
                  <div className='d-flex iconsSec p-10p'>
                    <div
                      data-testid='view-tip'
                      className='eyeicon'
                      onClick={() => {
                        handelViewTipDialog(page)
                      }}
                    >
                      <span className='listicons'>
                        <EyeIcon />
                      </span>
                    </div>
                    {accessWrite && (
                      <div
                        data-testid='edit-tip'
                        className='editicon'
                        onClick={() => {
                          if (page.tooltipMessages?.length) {
                            setTooltipSelected({ action: 'edit', page: page })
                          } else {
                            setTooltipSelected({
                              action: 'edit',
                              page: { ...page, tooltipMessages: [''] },
                            })
                          }
                        }}
                      >
                        <span>
                          <Edit />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </MUIAccordion>
      ))}
      {tooltipSelected && tooltipSelected.action === 'edit' && (
        <ConfirmDialog
          width={'720px'}
          open={true}
          title={'Add/Edit Tooltip'}
          description={''}
          icon={'DialogEditIcon'}
          btn1Label={CONSTANTS.BTN_LABEL_CANCEL}
          btn2Label={CONSTANTS.BTN_LABEL_SUBMIT}
          btn2Color={'primary'}
          extraUI={
            <form className='add-edit-tooltip'>
              {tooltipSelected.page?.tooltipMessages.map((message: string, index: number) => (
                <span className='d-flex mb-24p align-items-center' key={'message_' + String(index)}>
                  <input
                    data-testid={'tooltip-input'}
                    className='form-control'
                    type='text'
                    value={message}
                    onChange={(e: any) => addEditRemoveMessage('update', index, e.target.value)}
                  />
                  {index > 0 ? (
                    <span
                      onClick={() => addEditRemoveMessage('delete', index)}
                      data-testid={'delete-input'}
                    >
                      <CircleMinus />
                    </span>
                  ) : (
                    <span
                      onClick={() => addEditRemoveMessage('add', index)}
                      data-testid={'add-input'}
                    >
                      <CirclePlus />
                    </span>
                  )}
                </span>
              ))}
            </form>
          }
          onConfirm={() => {
            createEditTip()
          }}
          onClose={() => {
            setTooltipSelected(null)
          }}
        />
      )}
    </div>
  )
}

export default ToolTipList
