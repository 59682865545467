import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { parse } from 'date-fns'
import { useRef } from 'react'
import { CONSTANTS } from '../../Constants/Constant'

const MUIDateTimePicker = ({
  label,
  type,
  onChange,
  value,
  name,
  error,
  required = false,
  views,
  minutesStep = 5,
  disablePast = false,
}: {
  label: string
  type: string
  onChange: any
  value: any
  name: any
  error: any
  required?: boolean
  views?: any
  minutesStep?: number
  disablePast?: boolean
}) => {
  const dateRef: React.Ref<HTMLInputElement> = useRef(null)

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, format: string) => {
    event.preventDefault()
    const date = parse(event.clipboardData.getData('Text'), format, new Date())
    onChange(date)
  }

  return (
    <>
      {type === 'year' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format={CONSTANTS.DATE_YEAR_FORMAT}
            inputRef={dateRef}
            label={
              <span>
                {label}
                {required && <span style={{ color: '#D4351C' }}>*</span>}
              </span>
            }
            sx={{ width: '100%' }}
            onChange={onChange}
            value={value}
            views={views}
            disablePast={disablePast}
            slotProps={{
              textField: {
                error: !!error?.[name],
                inputProps: { id: name },
                onPaste: (e: React.ClipboardEvent<HTMLInputElement>) =>
                  handlePaste(e, CONSTANTS.DATE_YEAR_FORMAT),
                helperText: error?.[name]?.message as string,
                onFocus: () => {
                  if (dateRef?.current?.click) dateRef.current.click()
                },
              },
              field: { clearable: true, onClear: () => onChange(null) },
            }}
          />
        </LocalizationProvider>
      )}
      {type === 'date' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputRef={dateRef}
            format={CONSTANTS.DATE_FORMAT}
            disablePast={disablePast}
            label={
              <span>
                {label}
                {required && <span style={{ color: '#D4351C' }}>*</span>}
              </span>
            }
            sx={{ width: '100%' }}
            onChange={onChange}
            value={value}
            slotProps={{
              textField: {
                error: !!error?.[name],
                inputProps: { id: name },
                onPaste: (e: React.ClipboardEvent<HTMLInputElement>) =>
                  handlePaste(e, CONSTANTS.DATE_FORMAT),
                helperText: error?.[name]?.message as string,
                onFocus: () => {
                  if (dateRef?.current?.click) dateRef.current.click()
                },
                onBlur: (ev) => {
                  if (ev?.target?.value?.includes('0000')) {
                    onChange(null)
                  }
                },
              },
              field: { clearable: true, onClear: () => onChange(null) },
            }}
          />
        </LocalizationProvider>
      )}
      {type === 'time' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            inputRef={dateRef}
            disablePast={disablePast}
            views={views}
            label={
              <span>
                {label}
                {required && <span style={{ color: '#D4351C' }}>*</span>}
              </span>
            }
            format={CONSTANTS.TIME_FORMAT_3}
            ampm={false}
            sx={{ width: '100%' }}
            onChange={onChange}
            value={value}
            slotProps={{
              textField: {
                error: !!error?.[name],
                inputProps: { id: name },
                onPaste: (e: React.ClipboardEvent<HTMLInputElement>) =>
                  handlePaste(e, CONSTANTS.TIME_FORMAT_3),
                helperText: error?.[name]?.message as string,
                onFocus: () => {
                  if (dateRef?.current?.click) dateRef.current.click()
                },
                onBlur: (ev) => {
                  if (ev?.target?.value?.includes('0000')) {
                    onChange(null)
                  }
                },
              },
            }}
          />
        </LocalizationProvider>
      )}
      {type === 'datetime' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            inputRef={dateRef}
            timeSteps={{ minutes: minutesStep }}
            views={views}
            disablePast={disablePast}
            label={
              <span>
                {label}
                {required && <span style={{ color: '#D4351C' }}>*</span>}
              </span>
            }
            format={CONSTANTS.TIME_FORMAT_3}
            ampm={false}
            sx={{ width: '100%' }}
            onChange={onChange}
            value={value}
            slotProps={{
              textField: {
                error: !!error?.[name],
                inputProps: { id: name },
                onPaste: (e: React.ClipboardEvent<HTMLInputElement>) =>
                  handlePaste(e, CONSTANTS.TIME_FORMAT_3),
                helperText: error?.[name]?.message as string,
                onFocus: () => {
                  if (dateRef?.current?.click) dateRef.current.click()
                },
                onBlur: (ev) => {
                  if (ev?.target?.value?.includes('0000')) {
                    onChange(null)
                  }
                },
              },
              field: { clearable: true, onClear: () => onChange(null) },
            }}
          />
        </LocalizationProvider>
      )}
    </>
  )
}

export default MUIDateTimePicker
