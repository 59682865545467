const updateTagMetaData = ({
  title = '',
  description = 'Content management made simple and reliable.',
  keywords,
}: {
  title?: string
  description?: string
  keywords?: string
}) => {
  document.title = `${title ? `${title}` : 'Blitz'}`
  const metaDescription = document.querySelector('meta[name="description"]')
  if (metaDescription) {
    metaDescription.setAttribute('content', description)
  } else {
    const newMeta = document.createElement('meta')
    newMeta.name = 'description'
    newMeta.content = description
    document.head.appendChild(newMeta)
  }

  if (keywords !== undefined) {
    if (keywords) {
      const metaKeywords = document.querySelector('meta[name="keywords"]')
      if (metaKeywords) {
        metaKeywords.setAttribute('content', keywords)
      } else {
        const newMeta = document.createElement('meta')
        newMeta.name = 'keywords'
        newMeta.content = keywords
        document.head.appendChild(newMeta)
      }
    }
  } else {
    const metaKeywords: any = document.querySelector('meta[name="keywords"]')
    if (metaKeywords) {
      metaKeywords.parentNode.removeChild(metaKeywords)
    }
  }
}

export default updateTagMetaData
