import { EditOutlined, ExpandMore } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from '../../Constants'
import { getSubstring } from '../../Helpers/Util'
import { ChipButton, MUIBox } from '../Shared'
interface ViewPageModal {
  pageName?: string
  view?: any
  openDialog?: (arg?: any) => void
  dialogProps?: any
  editButtonRedirectionPath?: string
  viewData?: any[]
  secretButtonFunction?: () => void
  showSecret?: boolean
}
const DetailViewRender: React.FC<ViewPageModal> = ({
  pageName = '',
  view,
  openDialog,
  editButtonRedirectionPath,
  viewData = [],
  showSecret = false,
  secretButtonFunction = () => {
    return
  },
}: ViewPageModal) => {
  const navigate = useNavigate()
  return (
    <>
      {view?._id ? (
        <MUIBox>
          <div>
            <div className='heading-6 d-flex text-left rolebox__header-padding'>
              <div className='d-flex align-items-center'>{pageName}</div>
              <div className='ml-auto d-flex'>
                {showSecret && (
                  <div
                    className='mr-1'
                    onClick={() => secretButtonFunction()}
                    data-testid='secret__chip-btn'
                  >
                    <ChipButton type='primary' label='Re-generate Secret' />
                  </div>
                )}

                <div className='mr-1'>
                  <ChipButton
                    type={view?.status === 'ACTIVE' ? 'success' : 'secondary'}
                    dropdown
                    value={view?.status ?? 'ACTIVE'}
                    Icon={ExpandMore}
                    label={view?.status === 'ACTIVE' ? 'Active' : 'In Active'}
                    suffix
                    options={CONSTANTS.ROLE_STATUS}
                    action={openDialog}
                  />
                </div>
                <div
                  onClick={() => navigate(editButtonRedirectionPath as string)}
                  data-testid='edit__chip-btn'
                >
                  <ChipButton type='primary' Icon={EditOutlined} label='Edit' />
                </div>
              </div>
            </div>
            <div className={'hr'}></div>
            {viewData.map((item: any, inx: number) => {
              return (
                <div className='' key={inx}>
                  <Grid container>
                    <Grid item xs={6} md={3}>
                      <div className='sub-heading-6 view__role-list  text-left col34'>
                        {item?.label}
                      </div>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      <div
                        className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                        title={item?.isTitle && item?.value?.length > 40 ? item?.value : ''}
                      >
                        {item.label === 'Status' ? (
                          <Grid item xs={6} md={9}>
                            {item?.value === 'ACTIVE' && (
                              <ChipButton
                                type='success'
                                label='Active'
                                style={{ padding: '0px 8px' }}
                              />
                            )}
                            {item?.value === 'INACTIVE' && (
                              <ChipButton
                                type='secondary'
                                label='In Active'
                                style={{ padding: '0px 8px' }}
                              />
                            )}
                          </Grid>
                        ) : item?.isTitle ? (
                          getSubstring(item?.value, 40) || 'NA'
                        ) : (
                          item?.value || 'NA'
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )
            })}
          </div>
        </MUIBox>
      ) : (
        <></>
      )}
    </>
  )
}

export default DetailViewRender
