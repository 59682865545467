import { useEffect } from 'react'
import BulkUpdateComponent from '../../Components/BulkUpdate'
import { checkPermissionsBulkUpload } from '../../Helpers/BulkUpload'
import { PERMISSION_TYPE } from '../../Helpers/Permission'
import updateTagMetaData from '../../Helpers/updateTagMetaData'

const BulkUpdate: React.FC = () => {
  useEffect(() => {
    updateTagMetaData({ title: 'Bulk Update' })
    checkPermissionsBulkUpload([PERMISSION_TYPE.CAN_READ])
  }, [])

  return (
    <>
      <BulkUpdateComponent />
    </>
  )
}

export default BulkUpdate
