import { Container } from '@mui/material'
import React from 'react'
import AccessDeniedImage from '../../Assets/Images/access_denied.gif'
import './AccessDenied.scss'

const AccessDenied: React.FC = () => {
  return (
    <>
      <Container maxWidth='sm'>
        <div className='access__denied'>
          <img src={AccessDeniedImage} />
        </div>
      </Container>
      <Container>
        <p className='message'>
          You don&apos;t have access to this section.
          <br />
          Please reach out to your system administrator to get the access
        </p>
      </Container>
    </>
  )
}

export default React.memo(AccessDenied)
