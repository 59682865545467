import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AttributeEditor } from '../../../Components/AttributeRepository'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../../Helpers/Permission'
import { useAppDispatch } from '../../../Store/Hooks'
import { getDataSource } from '../../../Store/Slice/asset-definition.slice'
import {
  getAffiliatePartners,
  getFieldTypes,
  getNonComplexTypeAttributes,
} from '../../../Store/Slice/attribute.slice'

const CreateUpdateAttribute: React.FC = () => {
  const { CREATE, EDIT } = CONSTANTS.PAGE_TYPE
  const navigate = useNavigate()
  const accessRead = checkPermission(PERMISSIONS.META_MASTER_ATTRIBUTE_REPO, [
    PERMISSION_TYPE.CAN_READ,
  ])
  const accessWrite = checkPermission(PERMISSIONS.META_MASTER_ATTRIBUTE_REPO, [
    PERMISSION_TYPE.CAN_WRITE,
  ])

  const dispatch = useAppDispatch()
  const { attributePageType, attributeId } = useParams<{
    attributePageType: string | undefined
    attributeId: string | undefined
  }>()
  let pageType = attributePageType
  if (!attributeId) {
    pageType = CREATE
  }

  const getFormFieldDropdownValues = useCallback(async () => {
    const paramsData =
      'page=1&limit=1000&filterBy=fieldType&filterValue=COMPLEXTYPE&filterCondition=isNotEqualTo&sortyBy=fieldType&sortType=1'
    const promises = [
      dispatch(getFieldTypes() as any),
      dispatch(getDataSource() as any),
      dispatch(getNonComplexTypeAttributes(paramsData) as any),
      dispatch(getAffiliatePartners() as any),
    ]
    await Promise.allSettled(promises)
  }, [pageType, attributeId])

  useEffect(() => {
    if (pageType === CREATE || pageType === EDIT) {
      getFormFieldDropdownValues()
    }
    if (!accessWrite && !accessRead) {
      navigate(`${PAGES_ROUTES.AccessDenied}`)
    }
  }, [pageType, accessRead, accessWrite])

  return <AttributeEditor id={pageType} attributeId={attributeId} />
}

export default CreateUpdateAttribute
