import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { MASTERS_MANAGEMENT } from '../../Constants/ApiDefinations'
import { CreateMastersData, LanguagesListResponse, MastersUpdate } from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface LanguagesState {
  languageData: LanguagesListResponse | null
}

const initialState: LanguagesState = {
  languageData: null,
}

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    setLanguagesData: (state, action) => {
      state.languageData = action?.payload ? action.payload : null
    },
  },
})

export const changeLanguageStatus = createAsyncThunk(
  'languages/changeStatus',
  async (data: { id: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate = MASTERS_MANAGEMENT.LANGUAGES_CRUD.UPDATE_LANGUAGES(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const { setLanguagesData } = languagesSlice.actions

const languagesSelector = (state: any) => state.languages

export const selectLanguageData = createSelector(languagesSelector, (state) => state.languageData)

export const createLanguageService = createAsyncThunk(
  'languages/create',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate = MASTERS_MANAGEMENT.LANGUAGES_CRUD.CREATE_LANGUAGES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const editLanguageService = createAsyncThunk(
  'languages/editLanguage',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate = MASTERS_MANAGEMENT.LANGUAGES_CRUD.UPDATE_LANGUAGES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export default languagesSlice
