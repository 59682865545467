import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import * as React from 'react'
import UploadIcon from '../../Assets/Images/UploadBulk.png'
import CONSTANTS from '../../Constants'
import { TypeObject } from '../../Models'
import { MUIButton, MUIDialog, MUISelectWithoutControl } from '../Shared'

interface PropTypes {
  handleClose?: () => void
  handleSuccess?: (idx: number, imageAttr: string) => void
  open: boolean
  imageCategoryAttribute: any
  idx: number
  imgData?: TypeObject
}

const UpdateImageDialog: React.FC<PropTypes> = ({
  open,
  handleClose = () => {
    return
  },
  handleSuccess = (idx: number, imageAttr: string) => {
    return idx + imageAttr
  },
  imageCategoryAttribute,
  idx,
  imgData = { fieldName: '' },
}) => {
  const [imageAttribute, setImageAttribute] = React.useState('')
  const [attributes, setAttributes] = React.useState([])

  React.useEffect(() => {
    const attr = imageCategoryAttribute.find(
      (item: { metaCategory: string }) =>
        item.metaCategory === CONSTANTS.CATEGORIES.CONTENT_MEDIA_IMAGE,
    )
    setAttributes(attr?.metaAttributes ?? [])
  }, [imageCategoryAttribute])

  React.useEffect(() => {
    if (imgData?.currentTab === 0)
      setImageAttribute(imgData?.mappedImageData?.[idx]?.fieldName ?? '')
    else {
      setImageAttribute('')
    }
  }, [imgData, idx])

  return (
    <>
      <div>
        <MUIDialog open={open} handleClose={handleClose}>
          <div className='c-modal' style={{ width: '539px', height: '304px' }}>
            <DialogTitle>
              <img src={UploadIcon} alt='' />
            </DialogTitle>
            <DialogContent>
              <DialogContentText component={'div'} id='alert-dialog-slide-description'>
                <div className='col2 parag pt-1raph-bold-3 '>{'Edit Image Details'}</div>
                <div className='col34 paragraph-regular-5'>
                  <MUISelectWithoutControl
                    name='imageAttribute'
                    lable={'Image Attribute'}
                    options={attributes}
                    valueKey='fieldName'
                    labelKey='label'
                    onChange={(value: string) => setImageAttribute(value)}
                    value={imageAttribute}
                  />
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className='d-flex flex-wrap w-100 p-0 dialog-btns'>
                <div className='cancel-action-btn'>
                  <MUIButton
                    textTransform={'capitalize'}
                    variant='outlined'
                    size='large'
                    color={'secondary'}
                    type='button'
                    label={'Cancel'}
                    width={'100%'}
                    handleClickCB={() => handleClose()}
                  />
                </div>
                <div className={'accept-action-btn'}>
                  <MUIButton
                    textTransform={'capitalize'}
                    variant='contained'
                    size='large'
                    color='primary'
                    type='button'
                    handleClickCB={() => handleSuccess(idx, imageAttribute)}
                    label={'Submit'}
                    width={'100%'}
                  />
                </div>
              </div>
            </DialogActions>
          </div>
        </MUIDialog>
      </div>
    </>
  )
}

export default UpdateImageDialog
