import { Grid } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect } from 'react'
import { Control, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { PAGES_ROUTES, VALIDATIONS } from '../../../../Constants'
import { combineErrorMessage } from '../../../../Helpers/Util'
import { AffiliatePartnersListResponse } from '../../../../Models'
import { useAppDispatch, useAppSelector } from '../../../../Store/Hooks'
import {
  createAffiliatePartnerService,
  editAffiliatePartnerService,
  selectAffiliatePartnerData,
  setAffiliatePartnersData,
} from '../../../../Store/Slice/affiliate-partners.slice'
import { showAlert } from '../../../../Store/Slice/alert.slice'
import { MUIBox, MUIButton, MUIField, MUISelect } from '../../../Shared'
import styles from '../Masters.module.scss'

interface AffiliatePartnersCreateEditFormPropsInterface {
  mastersPageType: string | undefined
}
const AFFILIATE_PARTNER_CONTENTS = 'Affiliate Partner'
const status: any = [
  { value: 'ACTIVE', label: 'Active', type: 'status-active' },
  { value: 'INACTIVE', label: 'In Active', type: 'status-inactive' },
]

const AffiliatePartnersCreateEdit = ({
  mastersPageType,
}: AffiliatePartnersCreateEditFormPropsInterface) => {
  const selectAffiliatePartner = useAppSelector(selectAffiliatePartnerData)
  const selectedAffiliatedPartnerData: AffiliatePartnersListResponse | null =
    mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE ? selectAffiliatePartner : null
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {
      id: mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? selectedAffiliatedPartnerData?._id : '',
      status:
        mastersPageType === CONSTANTS.PAGE_TYPE.EDIT
          ? selectedAffiliatedPartnerData?.status
          : 'ACTIVE',
      value:
        mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? selectedAffiliatedPartnerData?.value : '',
      key: mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? selectedAffiliatedPartnerData?.key : '',
    },
  })

  useEffect(() => {
    // Update form values when selectedAffiliatedPartnerData changes
    setValue(
      'id',
      mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? selectedAffiliatedPartnerData?._id : '',
    )
    setValue(
      'status',
      mastersPageType === CONSTANTS.PAGE_TYPE.EDIT
        ? selectedAffiliatedPartnerData?.status
        : 'ACTIVE',
    )
    setValue(
      'value',
      mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? selectedAffiliatedPartnerData?.value : '',
    )
    setValue(
      'key',
      mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? selectedAffiliatedPartnerData?.key : '',
    )
  }, [selectedAffiliatedPartnerData, mastersPageType, setValue])

  const createAffiliatePartner = async (data: any) => {
    const reqData: any = {
      ...data,
    }
    const result = await dispatch(createAffiliatePartnerService(reqData) as any)
    return result
  }

  const editAffiliatePartner = async (data: any) => {
    const reqData: any = {
      ...data,
    }
    const result = await dispatch(editAffiliatePartnerService(reqData) as any)
    return result
  }

  const onSubmit = async (data: any) => {
    let result: any
    if (selectedAffiliatedPartnerData?._id && mastersPageType === CONSTANTS.PAGE_TYPE.EDIT) {
      result = await editAffiliatePartner(data)
    }
    if (mastersPageType === CONSTANTS.PAGE_TYPE.CREATE) {
      if (Object.prototype.hasOwnProperty.call(data, 'id')) {
        delete data?.id
      }
      result = await createAffiliatePartner(data)
    }
    const unWrapRes = unwrapResult(result)
    if (unWrapRes?.error) {
      dispatch(showAlert([true, combineErrorMessage(unWrapRes), 'error']))
      return
    }
    dispatch(showAlert([true, unWrapRes.message, 'success']))
    dispatch(setAffiliatePartnersData(null))
    navigate(
      `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.AffiliatePartners}`,
    )
  }

  return (
    <MUIBox>
      <div>
        <div className={`heading-6 d-flex text-left ${styles.masters__box__header__padding}`}>
          {AFFILIATE_PARTNER_CONTENTS}
        </div>
        <div className={styles.hr}></div>
        <div className={styles.mastersbox__content_padding}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={10} rowSpacing={4}>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIField
                  name='key'
                  label='Key'
                  required
                  type='text'
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIField
                  name='value'
                  label='Value'
                  required
                  type='text'
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUISelect
                  name='status'
                  lable={'Status'}
                  required
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  options={status}
                />
              </Grid>

              <Grid xs={12} item>
                <Grid container spacing={10}>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={6} className='d-flex justify-content-end'>
                    <MUIButton
                      disabled={!isValid}
                      size='large'
                      type={'submit'}
                      label={'Submit'}
                      variant='contained'
                      width={200}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </MUIBox>
  )
}

export default React.memo(AffiliatePartnersCreateEdit)
