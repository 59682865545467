function TabViewWithErrorIcon() {
  return (
    <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M49.747 0.65625H49.7456H6.25456H6.25336C3.66215 0.65625 1.55811 2.76456 1.55811 5.3527V34.8546C1.55811 37.4442 3.665 39.5511 6.25456 39.5511H6.74292C7.08887 39.5511 7.36909 39.2707 7.36909 38.9249C7.36909 38.5792 7.08876 38.2988 6.74292 38.2988H6.25456C4.35559 38.2988 2.81045 36.7536 2.81045 34.8548V10.7675H53.1896V34.8548C53.1896 36.7537 51.6444 38.2988 49.7454 38.2988H49.2571C48.9111 38.2988 48.6309 38.5792 48.6309 38.9249C48.6309 39.2707 48.9112 39.5511 49.2571 39.5511H49.7454C52.335 39.5511 54.4419 37.4442 54.4419 34.8546C54.4419 28.3118 54.4419 11.8179 54.4419 5.3527V5.35106C54.442 2.76238 52.3358 0.65625 49.747 0.65625ZM2.81067 9.51508V5.35106C2.81067 3.44848 4.36117 1.90859 6.25456 1.90859H49.7456C51.6445 1.90859 53.1897 3.45373 53.1897 5.3527V9.51508H2.81067Z'
        fill='#402080'
      />
      <path
        d='M7.70099 3.53906C6.50202 3.53906 5.52661 4.51425 5.52661 5.71322C5.52661 6.91219 6.50202 7.88737 7.70099 7.88737C8.89995 7.88737 9.87536 6.91219 9.87536 5.71322C9.87536 4.51425 8.89995 3.53906 7.70099 3.53906ZM7.70099 6.63492C7.19261 6.63492 6.77906 6.22148 6.77906 5.71311C6.77906 5.20473 7.19261 4.7913 7.70099 4.7913C8.20936 4.7913 8.62291 5.20473 8.62291 5.71311C8.62291 6.22148 8.20936 6.63492 7.70099 6.63492Z'
        fill='#CC8F00'
      />
      <path
        d='M13.3987 3.53906C12.1998 3.53906 11.2244 4.51425 11.2244 5.71322C11.2244 6.91219 12.1998 7.88737 13.3987 7.88737C14.5975 7.88737 15.5729 6.91219 15.5729 5.71322C15.5729 4.51425 14.5975 3.53906 13.3987 3.53906ZM13.3987 6.63492C12.8904 6.63492 12.4768 6.22148 12.4768 5.71311C12.4768 5.20473 12.8904 4.7913 13.3987 4.7913C13.9069 4.7913 14.3206 5.20473 14.3206 5.71311C14.3206 6.22148 13.9069 6.63492 13.3987 6.63492Z'
        fill='#CC8F00'
      />
      <path
        d='M19.096 3.53906C17.897 3.53906 16.9216 4.51425 16.9216 5.71322C16.9216 6.91219 17.897 7.88737 19.096 7.88737C20.295 7.88737 21.2704 6.91219 21.2704 5.71322C21.2704 4.51425 20.295 3.53906 19.096 3.53906ZM19.096 6.63492C18.5876 6.63492 18.1741 6.22148 18.1741 5.71311C18.1741 5.20473 18.5876 4.7913 19.096 4.7913C19.6044 4.7913 20.0179 5.20473 20.0179 5.71311C20.0179 6.22148 19.6044 6.63492 19.096 6.63492Z'
        fill='#CC8F00'
      />
      <path
        d='M49.0201 3.67188H32.4414C31.3161 3.67188 30.4004 4.58756 30.4004 5.7127C30.4004 6.83784 31.3161 7.75353 32.4414 7.75353H49.0201C50.1454 7.75353 51.0609 6.83784 51.0609 5.7127C51.0609 4.58756 50.1455 3.67188 49.0201 3.67188ZM49.0201 6.50119H32.4414C32.0067 6.50119 31.6527 6.14736 31.6527 5.7127C31.6527 5.27805 32.0066 4.92422 32.4414 4.92422H49.0201C49.4548 4.92422 49.8085 5.27805 49.8085 5.7127C49.8085 6.14736 49.4548 6.50119 49.0201 6.50119Z'
        fill='#CC8F00'
      />
      <path
        d='M43.9391 14.0156H12.0611C10.1655 14.0156 8.62305 15.5579 8.62305 17.4539V51.906C8.62305 53.8021 10.1655 55.3443 12.0611 55.3443H43.939C45.8347 55.3443 47.3771 53.802 47.3771 51.906V17.4539C47.3772 15.5578 45.8348 14.0156 43.9391 14.0156ZM46.1249 51.9059C46.1249 53.1114 45.1445 54.0918 43.9391 54.0918H12.0611C10.8558 54.0918 9.87539 53.1114 9.87539 51.9059V17.4538C9.87539 16.2483 10.8558 15.2679 12.0611 15.2679H43.939C45.1443 15.2679 46.1248 16.2483 46.1248 17.4538V51.9059H46.1249Z'
        fill='#402080'
      />
      <path
        d='M17.8616 19.6546C17.8616 18.3374 16.7901 17.2656 15.4729 17.2656C14.1575 17.2656 13.0874 18.3374 13.0874 19.6546C13.0874 20.9718 14.1575 22.0431 15.4729 22.0431C16.7901 22.0432 17.8616 20.9718 17.8616 19.6546ZM14.3396 19.6546C14.3396 19.028 14.848 18.518 15.4728 18.518C16.0994 18.518 16.6092 19.028 16.6092 19.6546C16.6092 20.2812 16.0994 20.7908 15.4728 20.7908C14.848 20.7908 14.3396 20.2812 14.3396 19.6546Z'
        fill='#CC8F00'
      />
      <path
        d='M20.3466 19.0727H28.0001C28.3461 19.0727 28.6263 18.7922 28.6263 18.4465C28.6263 18.1007 28.346 17.8203 28.0001 17.8203H20.3466C20.0007 17.8203 19.7205 18.1007 19.7205 18.4465C19.7205 18.7922 20.0007 19.0727 20.3466 19.0727Z'
        fill='#CC8F00'
      />
      <path
        d='M20.3466 21.4867H28.0001C28.3461 21.4867 28.6263 21.2063 28.6263 20.8605C28.6263 20.5148 28.346 20.2344 28.0001 20.2344H20.3466C20.0007 20.2344 19.7205 20.5148 19.7205 20.8605C19.7205 21.2062 20.0007 21.4867 20.3466 21.4867Z'
        fill='#CC8F00'
      />
      <g clipPath='url(#clip0_8961_32585)'>
        <path
          d='M28.5 50C34.8513 50 40 44.8513 40 38.5C40 32.1487 34.8513 27 28.5 27C22.1487 27 17 32.1487 17 38.5C17 44.8513 22.1487 50 28.5 50Z'
          fill='#E21B1B'
        />
        <path
          d='M39.9228 39.8173L33.5274 33.4219L23.7495 43.5595L30.0803 49.8902C35.2542 49.1787 39.3288 45.0275 39.9228 39.8173Z'
          fill='#D4351C'
        />
        <path
          d='M32.9194 43.6914C32.6894 43.6914 32.4597 43.6038 32.284 43.4284L23.5771 34.7211C23.2259 34.37 23.2259 33.8014 23.5771 33.4507C23.9282 33.0999 24.4967 33.0996 24.8474 33.4507L33.5547 42.158C33.9058 42.5091 33.9058 43.0776 33.5547 43.4284C33.379 43.6038 33.1494 43.6914 32.9194 43.6914Z'
          fill='white'
        />
        <path
          d='M24.2119 43.6916C23.9819 43.6916 23.7523 43.6039 23.5766 43.4285C23.2255 43.0774 23.2255 42.5089 23.5766 42.1581L32.2839 33.4508C32.635 33.0997 33.2035 33.0997 33.5543 33.4508C33.905 33.8019 33.9054 34.3705 33.5543 34.7212L24.8473 43.4285C24.6719 43.6039 24.4419 43.6916 24.2119 43.6916Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_8961_32585'>
          <rect width='23' height='23' fill='white' transform='translate(17 27)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TabViewWithErrorIcon
