const ErrorIcon = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none'>
      <path
        d='M16.5787 3.83967C15.0028 2.26378 12.8257 1.28906 10.421 1.28906C5.6115 1.28906 1.71265 5.18792 1.71265 9.9974C1.71265 12.4021 2.68736 14.5792 4.26326 16.1551M16.5787 3.83967C18.1546 5.41557 19.1293 7.59266 19.1293 9.9974C19.1293 14.8069 15.2305 18.7057 10.421 18.7057C8.01624 18.7057 5.83916 17.731 4.26326 16.1551M16.5787 3.83967L4.26326 16.1551'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default ErrorIcon
