import CSVIcon from '../../Assets/Images/svgs/csvIcon.svg'
import CONSTANTS from '../../Constants'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import CrossIcon from '../Icons/Cross'
import DownloadIcon from '../Icons/DownloadIcon2'
import { ChipButton } from '../Shared'

interface CSVProgressProps {
  status: string
  fileName: string
  fileSize: string
  progress?: number
  file?: any
  handleCancelUpload: () => void
}

const CSVProgress: React.FC<CSVProgressProps> = ({
  status,
  fileName,
  fileSize,
  progress = 0,
  file,
  handleCancelUpload,
}: CSVProgressProps) => {
  const dispatch = useAppDispatch()
  const onDownload = () => {
    if (!file) {
      return
    }
    const fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.download = file.name

    link.click()
    URL.revokeObjectURL(fileURL)

    dispatch(showAlert([true, 'File downloaded successfully', 'success']))
  }

  return (
    <div className='bulk__update-file-add'>
      <div className='bulk__update-file-add-text'>File Added</div>
      <div
        className={`bulk__update-file-add-file ${
          status === CONSTANTS.FILE_STATUS.SUCCESS && 'success'
        }`}
      >
        <div className='bulk__update-file-add-file-img'>
          <img src={CSVIcon} alt='csvIcon' />
        </div>
        <div className='bulk__update-file-add-file-details'>
          <div className=''>
            <div className='d-flex'>
              <div
                className={`${
                  status === CONSTANTS.FILE_STATUS.IN_PROGRESS ? 'full-width' : ''
                } file__details-name-text`}
              >
                <div className='file-name'>{fileName}</div>
                <div className='file-size'>{fileSize} MB</div>
              </div>

              <div
                className='ml-auto cursor-pointer cross_icon'
                data-testid={'cancel__upload-cross-icon'}
                onClick={handleCancelUpload}
              >
                <CrossIcon />
              </div>
            </div>
            {status === CONSTANTS.FILE_STATUS.SUCCESS && (
              <div
                className='ml-auto download__btn'
                data-testid='download-btn'
                onClick={onDownload}
              >
                <ChipButton type='light' Icon={DownloadIcon as any} label='Download' />
              </div>
            )}
          </div>
          {status === CONSTANTS.FILE_STATUS.IN_PROGRESS && (
            <div className='file_details-loader'>
              <div className='file_details-loader-fill' style={{ width: `${progress}%` }}></div>
            </div>
          )}
          {status === CONSTANTS.FILE_STATUS.SUCCESS && (
            <div className='file__success'>Upload Success</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CSVProgress
