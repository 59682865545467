/* eslint-disable react/prop-types */
import { Search } from '@mui/icons-material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SonyHDLogo from '../../../Assets/Images/sonyHDlogo.png'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import { USER_MANAGEMENT_CRUD } from '../../../Constants/ApiDefinations'
import { getLocalStorage } from '../../../Helpers/Storage'
import { logout } from '../../../Helpers/Util'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { setLoader } from '../../../Store/Slice/loader.slice'
import SettingIcon from '../../Icons/SettingIcon'
import { AppBar, StyledMenu } from '../MUI-Drawer/MUI-Drawer-Style'
import { BlitzLogo } from '../MUI-Drawer/MUI-Drawer.svgIcons'
import './header.scss'

const Header: React.FC<{ setOpen?: any; drawerState?: boolean; sidebar?: boolean }> = ({
  setOpen = () => {
    return
  },
  drawerState = false,
  sidebar = true,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElForSavesecrch, setAnchorElForSavesecrch] = useState<boolean>(false)
  const openMenu = Boolean(anchorEl)
  const [userName, setUserName] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [savedSearches, setSavedSearches] = useState<any[]>([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { state } = useLocation()

  useEffect(() => {
    const userData = getLocalStorage(CONSTANTS.LOCAL_KEYS.USER_DATA) as any
    const { userName = '' } = userData
    if (userName) {
      setUserName(userName)
    }
  }, [drawerState])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setAnchorElForSavesecrch(false)
        setSavedSearches([])
      }
    }

    if (anchorElForSavesecrch) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [anchorElForSavesecrch])
  const dataLimitForSearch = 'page=1&limit=3'
  const getSaveSearchListFunction = async () => {
    dispatch(setLoader(true))
    const requestedData: any = USER_MANAGEMENT_CRUD.SAVE_SEARCH_LIST(dataLimitForSearch)
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      const tempData: any[] = []
      result?.data?.records?.forEach((ele: any) => {
        tempData.push({ id: ele._id, name: ele.name })
      })
      setSavedSearches(tempData)
      dispatch(setLoader(false))
    } else {
      setSavedSearches([])
      dispatch(setLoader(false))
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (searchInput.trim().length > 2) {
        navigate(`/home/asset-state-details/${PAGES_ROUTES.AdvancedSearch}?search=${searchInput}`)
        setSearchInput('')
      }
    }
  }

  return (
    <AppBar position='fixed' open={drawerState} style={{ backgroundColor: '#191D23' }}>
      <Toolbar className='justify-content-between'>
        <div className='d-flex'>
          {sidebar && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => {
                setOpen(!drawerState)
              }}
              edge='start'
              sx={{
                marginRight: 2,
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            src={SonyHDLogo}
            style={{ width: '32px', height: '32px' }}
            alt='sony-logo'
            onClick={() => navigate('/home')}
            className='cursor-pointer'
          />
          <Typography
            component='div'
            sx={{ flexGrow: 1, marginLeft: 2, cursor: 'pointer' }}
            onClick={() => navigate('/home')}
          >
            <BlitzLogo />
          </Typography>
        </div>
        <div className='advanced-search'>
          <TextField
            className='global-search-input'
            id='outlined-search'
            autoComplete='off'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchInput(e.target.value)
            }}
            value={searchInput}
            onFocus={() => {
              setAnchorElForSavesecrch(true)
              getSaveSearchListFunction()
            }}
            onKeyDown={handleEnterKeyPress}
            placeholder={'Search by title and id'}
            InputProps={{
              id: 'searchId',
              startAdornment: (
                <InputAdornment position='start'>
                  <Search style={{ color: '#B1B4B6' }} />
                </InputAdornment>
              ),
            }}
            type='search'
          />
          {anchorElForSavesecrch && savedSearches.length ? (
            <div
              className='savesearch-popup-wrapper'
              ref={dropdownRef}
              onMouseDown={(e) => {
                e.stopPropagation()
              }}
            >
              <div
                onClick={() => {
                  setAnchorElForSavesecrch(true)
                }}
                className='d-flex justify-content-between align-items-center savesearch-popup-header'
              >
                <div className='heading-6'>Saved Result</div>
                <u
                  className='col1 cursor-pointer'
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setAnchorElForSavesecrch(false)
                    setSavedSearches([])
                    navigate('/home/asset-state-details/save-search', {
                      state: {
                        pathName: window.location.href.split(window.location.host)[1],
                        state: state,
                      },
                    })
                  }}
                >
                  View all
                </u>
              </div>
              <div className='search-list'>
                {savedSearches.map((search: any, index: any) => {
                  return (
                    <div
                      key={search?.id}
                      data-testid={`savesearch-${index}`}
                      className='heading-search cursor-pointer'
                      onClick={(e: any) => {
                        e.stopPropagation()
                        setAnchorElForSavesecrch(false)
                        setSavedSearches([])
                        navigate(
                          `/home/asset-state-details/${PAGES_ROUTES.AdvancedSearch}?search`,
                          {
                            state: { id: search?.id },
                          },
                        )
                      }}
                    >
                      {index + 1}. {search.name}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
          <Tooltip
            title='Advance Search'
            placement='bottom-start'
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  padding: '4px 8px 4px 8px',
                  fontSize: '12px',
                  fontWeight: '500',
                  color: '#402080',
                  bgcolor: '#ECE9F2',
                  '& .MuiTooltip-arrow': {
                    color: '#ECE9F2',
                  },
                },
              },
            }}
            data-testid={'open_drawer_test_id'}
            sx={{
              marginRight: 2,
            }}
          >
            <div
              data-testid='advance_btn'
              className='search-advanced-btn'
              onClick={() =>
                navigate(
                  `/home/asset-state-details/${PAGES_ROUTES.AdvancedSearch}?search=${searchInput}&advanceSearch=1`,
                )
              }
            >
              <SettingIcon />
            </div>
          </Tooltip>
        </div>
        {!!userName && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ fontSize: '14px', fontWeight: '600' }}>{userName}</Typography>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            <span>
              <span onClick={handleClick} data-testid={'account-dropdown'}>
                <KeyboardArrowDownIcon />
              </span>
              <StyledMenu
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
              >
                <MenuItem onClick={logout} disableRipple>
                  Logout
                </MenuItem>
              </StyledMenu>
            </span>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
