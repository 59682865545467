function CardIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_2054_8639)'>
        <path
          d='M14 9.5H17C17.55 9.5 18 9.05 18 8.5C18 7.95 17.55 7.5 17 7.5H14C13.45 7.5 13 7.95 13 8.5C13 9.05 13.45 9.5 14 9.5ZM14 16.5H17C17.55 16.5 18 16.05 18 15.5C18 14.95 17.55 14.5 17 14.5H14C13.45 14.5 13 14.95 13 15.5C13 16.05 13.45 16.5 14 16.5ZM19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21ZM7 11H10C10.55 11 11 10.55 11 10V7C11 6.45 10.55 6 10 6H7C6.45 6 6 6.45 6 7V10C6 10.55 6.45 11 7 11ZM7 7H10V10H7V7ZM7 18H10C10.55 18 11 17.55 11 17V14C11 13.45 10.55 13 10 13H7C6.45 13 6 13.45 6 14V17C6 17.55 6.45 18 7 18ZM7 14H10V17H7V14Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_2054_8639'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CardIcon
