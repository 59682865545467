import { format } from 'date-fns'
import { CONSTANTS } from '../Constants/Constant'
import { filterConditions } from '../Constants/Constant/ListingPageConstant/ContentListingConstants'
import ListingColumns from '../Constants/Constant/Schema/managContent.json'
import AssetStatusListingCols from '../Constants/Constant/Schema/manageStatusListing.json'

interface MetaAttributeObj {
  id: string
  label: string
  dataType?: string
  fieldType: string
  fieldName: string
  isMultiple?: boolean
  assetCategory?: string
  assetType?: string
  metaCategory?: string
  sort?: boolean
  filter?: boolean
  position?: number
  viewInListing?: boolean
  class?: string
  createdAt?: string
  filterValueType?: string
  filterValueCondition?: Record<string, any>[]
  content?: boolean
}

interface HeadersObj {
  [key: string]: MetaAttributeObj
}

const processMetaAttributes = (element: any, headersObj: HeadersObj): void => {
  const listingColumnsCopy: any = JSON.parse(JSON.stringify(ListingColumns))
  element?.attributes?.forEach((attribute: any) => {
    attribute?.metaAttributes?.forEach((metaAttribute: any) => {
      const key = `metaAttributes.${metaAttribute?.fieldName}`
      const columnDef = listingColumnsCopy[metaAttribute.fieldName]
      if (!headersObj.key && columnDef?.column) {
        const { filterValueType, filterValueCondition } = getFilterOptions(columnDef, metaAttribute)
        const metaAttributeObj: MetaAttributeObj = {
          id: key, // To read data from rows
          label: metaAttribute?.label,
          dataType: metaAttribute?.dataType,
          fieldType: metaAttribute?.fieldType,
          fieldName: metaAttribute?.fieldName,
          isMultiple: metaAttribute?.isMultiple,
          sort: columnDef.sorting,
          filter: columnDef.filter,
          assetCategory: element?.assetCategory,
          assetType: element?.assetType,
          metaCategory: attribute?.metaCategory,
          position: columnDef?.position,
          class: element?.class || '',
          filterValueType,
          filterValueCondition,
        }
        headersObj[key] = { ...metaAttributeObj, viewInListing: columnDef.columnDefault }
      }
    })
  })
}

/**
 * Process meta attributes for the home page.
 * This function processes the given meta attributes and constructs the headers object.
 * @param {Array} attributes - The array of meta attributes to process.
 * @param {Object} headersObj - The headers object to populate.
 * @returns {void}
 */
export const processMetaAttributesForHomePage = (attributes: any, headersObj: HeadersObj): void => {
  const listingColumnsCopy: any = JSON.parse(JSON.stringify(AssetStatusListingCols))
  attributes?.forEach((metaAttribute: any) => {
    const key = metaAttribute?.fieldName
    const columnDef = listingColumnsCopy[metaAttribute.fieldName]
    if (!headersObj.key && columnDef?.column) {
      const { filterValueType, filterValueCondition } = getFilterOptions(columnDef, metaAttribute)
      const metaAttributeObj: MetaAttributeObj = {
        id: columnDef.key || key,
        label: metaAttribute?.label,
        dataType: metaAttribute?.dataType,
        fieldType: metaAttribute?.fieldType,
        fieldName: metaAttribute?.fieldName,
        isMultiple: metaAttribute?.isMultiple,
        sort: columnDef.sorting,
        filter: columnDef.filter,
        assetCategory: '',
        assetType: '',
        metaCategory: '',
        position: columnDef?.position,
        class: '',
        filterValueType,
        filterValueCondition,
      }
      headersObj[key] = { ...metaAttributeObj, viewInListing: columnDef.columnDefault }
    }
  })
}

export const processRecords = (
  records: any[],
  contentCategory: string | undefined,
  headersObj: HeadersObj,
): void => {
  records.forEach((element) => {
    if (contentCategory?.toUpperCase() === element?.contentCategory) {
      processMetaAttributes(element, headersObj)
    }
  })
}

export const processRecordsAdvanceSearch = (
  records: any[],
  attributes: any,
  headersObj: HeadersObj,
  attributeFilter = [],
): void => {
  processMetaAttributesAdvanceSearch(attributes, headersObj, attributeFilter)
}

const processMetaAttributesAdvanceSearch = (
  attributes: any,
  headersObj: HeadersObj,
  attributeFilter = [],
): void => {
  const filters = attributeFilter.map(
    (filter: { filterBy: string }) =>
      `${
        filter.filterBy?.includes('metaAttributes.')
          ? filter.filterBy
          : 'metaAttributes.' + filter.filterBy
      }`,
  )

  const listingColumnsCopy: any = { ...ListingColumns }
  attributes?.forEach((metaAttribute: any) => {
    const key = `metaAttributes.${metaAttribute?.fieldName}`
    const columnDef = listingColumnsCopy[metaAttribute.fieldName]
    if (!headersObj.key && columnDef?.column) {
      const metaAttributeObj: MetaAttributeObj = {
        id: columnDef.key || key,
        label: metaAttribute?.label,
        dataType: metaAttribute?.dataType,
        fieldType: metaAttribute?.fieldType,
        fieldName: metaAttribute?.fieldName,
        isMultiple: metaAttribute?.isMultiple,
        sort: columnDef.sorting,
        filter: columnDef.filter,
        assetCategory: '',
        assetType: '',
        metaCategory: '',
        position: columnDef?.position,
        class: '',
        content: false,
      }

      headersObj[key] = {
        ...metaAttributeObj,
        viewInListing: columnDef.columnDefault
          ? columnDef.columnDefault
          : filters.some((item) => item === key),
        filter: false,
      }
    }
  })
}

export const getTableDataForAssetList = (headers: any, showVersionIcon: boolean) => {
  if (headers.length > 0) {
    if (showVersionIcon) {
      return [
        showVersionIcon
          ? { id: 'versionCount', fieldName: 'versionCount', label: '     ', sort: false }
          : {},
        { id: 'clone', fieldName: 'clone', label: '     ', sort: false },
        ...headers.filter((item: any) => item.viewInListing),
      ]
    } else {
      return [
        { id: 'clone', fieldName: 'clone', label: '     ', sort: false },
        ...headers.filter((item: any) => item.viewInListing),
      ]
    }
  }
  return []
}

export const parsedVersionDataForAS = (versionData: any, record: any) => {
  return versionData?.data?.records
    ?.map((version: any) => {
      if (version._id === record._id) return null
      const metaAttributes = version.ContentMetaCategory.reduce(
        (acc: any, attr: any) => ({ ...acc, ...attr.attributes }),
        {},
      )
      delete version.ContentMetaCategory
      return { ...version, metaAttributes }
    })
    .filter(Boolean)
}

export const getThumbnailFromImageDetails = (imageDetails: any) => {
  if (imageDetails?.landscapeThumb?.[0]?.imageKey) {
    return CONSTANTS.CLOUDFRONT_BASE_URL + '/' + imageDetails.landscapeThumb[0].imageKey
  }
  return ''
}

/**
 *
 * @param columnDef Defination of column
 * @param metaAttribute Details of attribute
 * @returns filterValueType and filterValueCondition for filtering the result
 */
export const getFilterOptions = (columnDef: any, metaAttribute: any) => {
  let filterValueType = '',
    filterValueCondition: any = []
  if (columnDef.filter) {
    if (metaAttribute?.fieldType === CONSTANTS.FIELDTYPES.DATETIME) {
      filterValueType = CONSTANTS.FIELDTYPES.DATETIME
      filterValueCondition = filterConditions[CONSTANTS.FIELDTYPES.DATETIME]
    } else if (metaAttribute?.dataType === CONSTANTS.FIELDTYPES.BOOLEAN) {
      filterValueType = CONSTANTS.FIELDTYPES.BOOLEAN
      filterValueCondition = filterConditions[CONSTANTS.FIELDTYPES.BOOLEAN]
    } else if (metaAttribute?.fieldType === CONSTANTS.FIELDTYPES.NUMBER) {
      filterValueType = CONSTANTS.FIELDTYPES.NUMBER
      filterValueCondition = filterConditions[CONSTANTS.FIELDTYPES.NUMBER]
    } else if (metaAttribute?.fieldType === CONSTANTS.FIELDTYPES.SELECT) {
      filterValueType = CONSTANTS.FIELDTYPES.TEXT
      filterValueCondition = filterConditions[CONSTANTS.FIELDTYPES.SELECT]
    } else {
      filterValueType = CONSTANTS.FIELDTYPES.TEXT
      filterValueCondition = filterConditions[CONSTANTS.FIELDTYPES.TEXT]
    }
  }
  return { filterValueType, filterValueCondition }
}

/**
 * Function to create request data
 * @param contentCategory selected category
 * @param selectedFilters selected filters
 * @param stateDetailsPage selecte page type
 * @returns list api data
 */
export const getGlobalSearchParams = ({
  contentCategory,
  selectedFilters,
  stateDetailsPage,
}: {
  contentCategory: string
  selectedFilters: any
  stateDetailsPage: boolean
}) => {
  const listingColumnsCopy: any = JSON.parse(JSON.stringify(ListingColumns))
  const { search, state, sort, sortType, limit, pageNumber, filters } = selectedFilters
  let params: any = {
    limit,
    page: pageNumber,
    sort: [],
    contentFilters: [],
    attributeFilters: [],
    selectAttributes: {},
  }
  if (search) {
    params = { ...params, titleOrId: search }
  }
  if (contentCategory || state) {
    if (!stateDetailsPage && contentCategory) {
      params.contentFilters.push({
        filterBy: 'contentCategory',
        filterValue: contentCategory.toUpperCase(),
        filterCondition: 'equals',
      })
    }
    if (state.length) {
      params.contentFilters.push({
        filterBy: 'state',
        filterValue: state.length > 1 ? state : state[0],
        filterCondition: state.length > 1 ? 'in' : 'equals',
      })
    }
  }
  if (sort) {
    const { type } = listingColumnsCopy[sort] || { type: '' }
    params.sort.push({
      sortType: Number(sortType),
      sortBy: sort,
      sortIn: type === 'Metadata' ? 'attribute' : 'content',
    })
  }
  if (filters?.length) {
    const appliedFilters = filters.filter(
      (item: any) =>
        typeof item.filterValue === 'boolean' ||
        item.filterValue ||
        item.filterCondition === 'null',
    )
    if (appliedFilters.length) {
      appliedFilters.forEach((filter: any) => {
        const filterCopy = { ...filter }
        if (filterCopy.filterBy === 'assetTypeD') {
          filterCopy.filterBy = 'assetType'
        }
        const { type } = listingColumnsCopy[filterCopy.filterBy] || { type: 'Blitz Property' }
        if (filterCopy.filterCondition === 'in') {
          filterCopy.filterValue = filterCopy.filterValue
            .split(',')
            .map((item: string) => item.trim())
        }
        if (type === 'Metadata') {
          if (filterCopy.filterCondition === 'null') {
            params.attributeFilters.push({
              ...filterCopy,
              filterValue: null,
              filterCondition: 'equals',
            })
          } else {
            params.attributeFilters.push(filterCopy)
          }
        } else {
          if (filterCopy.filterCondition === 'null') {
            params.contentFilters.push({
              ...filterCopy,
              filterValue: null,
              filterCondition: 'equals',
            })
          } else {
            params.contentFilters.push(filterCopy)
          }
        }
      })
    }
  }
  return params
}

/**
 * Function to format date
 * @param value value to be formatted
 * @param dateFormat date format
 * @returns
 */
export const formatListingDate = (value: string | Date, dateFormat: string) => {
  try {
    return format(new Date(value), dateFormat)
  } catch (_err) {
    return 'NA'
  }
}

/**
 * Function to format nested records for tabel listing
 * @param records List for records
 */
export const formatNestedRecords = (records: any) => {
  records.forEach((record: any) => {
    record['metaAttributes'] = {}
    record?.metaCategories?.forEach((item: any) => {
      record.metaAttributes = { ...record.metaAttributes, ...item }
    })
  })
}

export const removeDuplicatesByKey = (array: any, key: string) => {
  return array.reduce((accumulator: any, current: any) => {
    const existingItem = accumulator.find((item: any) => item[key] === current[key])
    if (!existingItem) {
      accumulator.push(current)
    }
    return accumulator
  }, [])
}

export const sortByKey = (array: any, key: string) => {
  return array.sort((a: any, b: any) => {
    if (a[key] < b[key]) return -1
    if (a[key] > b[key]) return 1
    return 0
  })
}

export const filterAssetTypesList = (
  records: any[],
  contentCategoryF?: string | undefined,
): any[] => {
  const filteredRecords = records
    .map(({ _id, assetType, assetCategory, contentCategory }) => ({
      _id,
      assetType,
      assetCategory,
      contentCategory,
      label: assetType,
    }))
    .filter(
      ({ contentCategory }) =>
        !contentCategoryF || contentCategory?.toLowerCase() === contentCategoryF.toLowerCase(),
    )
  return sortByKey(removeDuplicatesByKey(filteredRecords, 'assetType'), 'assetType')
}

export const updateHeadersArrayWithSaved = (headersArray: any, savedHeaders: any) => {
  if (savedHeaders?.length) {
    const savedHeadersObj = savedHeaders.reduce((prev: any, next: any) => {
      return { ...prev, [next.fieldName]: !!next.viewInListing }
    }, {})

    headersArray.forEach((element: any) => {
      element.viewInListing = !!savedHeadersObj[element.fieldName]
    })
  }
}

export const updateAssetTypesArrayWithSaved = (assetTypesCopy: any, savedAssetTypes: any) => {
  const savedAssetTypesObj = savedAssetTypes.reduce((prev: any, next: any) => {
    return { ...prev, [next.assetType]: !!next.select }
  }, {})

  assetTypesCopy.forEach((element: any) => {
    element.select = !!savedAssetTypesObj[element.assetType]
  })
}
