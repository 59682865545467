import { TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { MUIFieldModal } from '../../../Models'

const MUIField: React.FC<MUIFieldModal> = ({
  control,
  name,
  id,
  type,
  error,
  label,
  rules,
  required = false,
  readonly = false,
  disabled = false,
  startAdornment,
  endAdornment,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <TextField
          label={
            <span>
              {label}
              {required && <span style={{ color: '#D4351C' }}>*</span>}
            </span>
          }
          placeholder={label}
          variant='outlined'
          data-testid={id ? id : name}
          inputProps={{ id: name, maxLength: props.maxLength }}
          fullWidth
          InputProps={{
            readOnly: readonly,
            disabled: disabled,
            startAdornment: startAdornment,
            endAdornment: endAdornment,
          }}
          {...props}
          {...field}
          type={type}
          error={!!error?.[name]}
          helperText={error?.[name]?.message as string}
        />
      )}
    />
  )
}

export default MUIField
