import { format, isAfter, isBefore, isEqual } from 'date-fns'
import { SYSTEM_CONFIG_APIS, WORKFLOW_API } from '../Constants/ApiDefinations'
import { CONSTANTS } from '../Constants/Constant'
import { TypeObject } from '../Models'
import mainApiService from '../Services'
import { store } from '../Store'
import { showAlert } from '../Store/Slice/alert.slice'
import { nullValuesPresent, saveTransactionIDInStorage } from './Util'

interface masterType {
  key: string
  value: string
}

export const areDatesEqual = (date1: any, date2: any) =>
  format(date1, 'yyyy-MM-dd"T"HH:mm:ss.SSSxxx') === format(date2, 'yyyy-MM-dd"T"HH:mm:ss.SSSxxx')

export const checkScheduleStatus = (schedules: Array<any>, dateTimeToCheck: any) => {
  const now = new Date()
  const filteredSchedules = schedules.filter((schedule) =>
    isAfter(new Date(schedule.scheduleTime), now),
  )
  const dateTimeToCheckDate = new Date(dateTimeToCheck)

  const pastSchedules = filteredSchedules.filter((schedule) =>
    isBefore(new Date(schedule.scheduleTime), dateTimeToCheckDate),
  )
  const currentSchedules = filteredSchedules.filter((schedule) =>
    isEqual(new Date(schedule.scheduleTime), dateTimeToCheckDate),
  )
  const futureSchedules = filteredSchedules.filter((schedule) =>
    isAfter(new Date(schedule.scheduleTime), dateTimeToCheckDate),
  )

  let scheduleRes: any = false
  if (currentSchedules.length) {
    scheduleRes = CONSTANTS.MESSAGES.SCHEDULE_SAME_EXIST
  } else if (pastSchedules.length && futureSchedules.length) {
    scheduleRes = CONSTANTS.MESSAGES.SCHEDULE_BOTH_EXIST
  } else if (pastSchedules.length) {
    scheduleRes = CONSTANTS.MESSAGES.SCHEDULE_EARLIER_EXIST
  } else if (futureSchedules.length) {
    scheduleRes = CONSTANTS.MESSAGES.SCHEDULE_FUTURE_EXIST
  }

  return scheduleRes
}

export const validateContentCanBeEdited = async (
  contentId: string,
  blitzId: string,
  contentCategory?: string,
  metaCategory?: string,
  assetType?: string,
) => {
  try {
    const requestedData = WORKFLOW_API.VALIDATE_DRAFT_EXISTS(
      contentId,
      blitzId,
      assetType as string,
      contentCategory as string,
      metaCategory as string,
      saveTransactionIDInStorage(contentId, metaCategory as string),
    )
    const result: any = await mainApiService(requestedData)
    if (result.data) {
      if (result.data?.canArchieved) {
        store.dispatch(
          showAlert([
            true,
            'Please refresh the page. Current asset status is changed to archive.',
            'error',
          ]),
        )
        return 'archived'
      }
      return result.data.canEdit
    }
    return null
  } catch (error) {
    return null
  }
}

export const checkDraftBeforeEditingMode = async (
  contentDetails: any,
  metaCategory: any,
  dispatchFn: any,
  setLoaderFn: any,
  navigateFn: any,
  showDialogFn: any,
  showAlertFn: any,
  blockNavigation?: boolean,
) => {
  try {
    const { _id, contentId, contentCategory, assetCategory, assetType, state } = contentDetails

    const navUrl = `/asset/${'update'}/${_id}/${assetType}/${contentCategory}/${assetCategory}/${metaCategory}`
    dispatchFn(setLoaderFn(true))
    const canEdit = await validateContentCanBeEdited(
      _id,
      contentId,
      contentCategory,
      metaCategory,
      assetType,
    )
    if (canEdit === 'archived') {
      dispatchFn(setLoaderFn(false))
      return false
    }

    const isCurrentDraft = checkContentStateIsDraftReady(state)

    if ((canEdit && isCurrentDraft) || state === CONSTANTS.ASSET_STATUS.CREATED) {
      if (!blockNavigation) {
        saveTransactionIDInStorage(_id, metaCategory)
        navigateFn(navUrl)
      }
      dispatchFn(setLoaderFn(false))
      return true
    } else {
      showDialogFn('confirm', {
        title: CONSTANTS.MESSAGES[canEdit ? 'DRAFT_CREATE' : 'DRAFT_UPDATE'],
        description: CONSTANTS.MESSAGES[canEdit ? 'EDIT_DRAFT_NOT_EXIST' : 'EDIT_DRAFT_EXIST'],
        icon: canEdit ? 'DeleteRoleWarIcon' : 'DeleteRoleIcon',
        btn1Label: CONSTANTS.BTN_LABEL_CANCEL,
        btn2Label: CONSTANTS.BTN_LABEL_PROCEED,
        btn2Color: canEdit ? 'warning' : 'error',
        onConfirm: async () => {
          const requestedData: any = WORKFLOW_API.CREATE_OVERIDE_VERSION(_id)
          dispatchFn(setLoaderFn(true))
          const result = await mainApiService(requestedData)
          dispatchFn(setLoaderFn(false))
          if (result?.data?._id) {
            dispatchFn(
              showAlertFn([
                true,
                CONSTANTS.MESSAGES[canEdit ? 'DRAFT_CREATED' : 'DRAFT_UPDATED'],
                'success',
              ]),
            )
            saveTransactionIDInStorage(_id, metaCategory)
            navigateFn(
              `/asset/${'view'}/${result?.data
                ?._id}/${assetType}/${contentCategory}/${assetCategory}/${metaCategory}`,
            )
          } else if (result.error && result.error.message) {
            dispatchFn(showAlertFn([true, result.error.message, 'error']))
          } else {
            dispatchFn(showAlertFn([true, CONSTANTS.MESSAGES.API_ERROR, 'error']))
          }
        },
        onClose: () => {
          console.log('close')
        },
      })
      dispatchFn(setLoaderFn(false))
      return false
    }
  } catch (error) {
    dispatchFn(setLoaderFn(false))
    return false
  }
}

export const checkContentStateIsDraft = (contentState: string | undefined) => {
  return (
    contentState === CONSTANTS.ASSET_STATUS.CREATED ||
    contentState === CONSTANTS.ASSET_STATUS.DRAFT ||
    contentState === CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE
  )
}

export const checkContentStateIsDraftReady = (contentState: string | undefined) => {
  return (
    checkContentStateIsDraft(contentState) ||
    contentState === CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_PUBLISHING
  )
}

export const customSort = (dataArray: any, sortKeys: any, sortKey: any = '') => {
  return dataArray.sort((a: any, b: any) => {
    const indexA = sortKeys.indexOf(sortKey ? a[sortKey] : a)
    const indexB = sortKeys.indexOf(sortKey ? b[sortKey] : b)

    if (indexA === -1 && indexB === -1) {
      return 0
    } else if (indexA === -1) {
      return 1
    } else if (indexB === -1) {
      return -1
    }

    return indexA - indexB
  })
}

export const insertAtPositionInArray = (array: any, elementToInsert: any, position: number) => {
  const newArray = array.slice()
  newArray.splice(position - 1, 0, elementToInsert)
  return newArray
}

export const checkForRolePermissions = (tollgate: any, metaCategory: any) => {
  if (localStorage?.getItem(CONSTANTS.LOCAL_KEYS.ROLE_DATA)) {
    const permissions = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_KEYS.ROLE_DATA) as string)
    const metaCategories = permissions?.find((item: any) => item.key === 'TOLLGATES')
    const permission = metaCategories?.submodules?.find((item: any) => item?.key === metaCategory)
    if (!tollgate) return false
    if (tollgate) {
      if (permission?.permission?.canSkipValidation) {
        return false
      }
      if (permission?.permission?.canStateTransition) {
        return 'skip'
      }
      if (
        !permission?.permission?.canStateTransition &&
        !permission?.permssion?.canSkipValidation
      ) {
        return true
      }
      return 'skip'
    }
  } else {
    return 'skip'
  }
}

export const checkForRightsPermissions = (metaCategory: any) => {
  if (localStorage?.getItem(CONSTANTS.LOCAL_KEYS.ROLE_DATA)) {
    const permissions = JSON.parse(localStorage.getItem(CONSTANTS.LOCAL_KEYS.ROLE_DATA) as string)
    const metaCategories = permissions?.find((item: any) => item.key === 'META_CATEGORIES')
    const permission = metaCategories?.submodules?.find((item: any) => item?.key === metaCategory)

    if (permission?.permission?.canRead && permission?.permission?.canWrite) {
      return true
    }
    if (!permission?.permission?.canWrite) {
      return false
    }
  } else {
    return false
  }
}

export const removeLastSlashes = (input: string, count: number) => {
  const parts = input.split('/')
  const result = parts.slice(0, -count).join('/')
  return result
}

export const sortedData = (data: masterType[]) => {
  return data?.sort((a, b) => {
    const aStartsWithNumber = /^\d/.test(a.value)
    const bStartsWithNumber = /^\d/.test(b.value)

    if (aStartsWithNumber && !bStartsWithNumber) {
      return 1
    } else if (!aStartsWithNumber && bStartsWithNumber) {
      return -1
    } else {
      return a.value.localeCompare(b.value)
    }
  })
}

const ensureArrayLength = (array: any[], length: number) => {
  while (array.length < length) {
    array.push({})
  }
}

export const extractMessages = (obj: any, parentKey?: string) => {
  const result: any = {}
  let messages: string[] = []
  for (const attrKey in obj) {
    let tempMessages: string[] = []
    if (typeof obj[attrKey] === 'object') {
      if ('message' in obj[attrKey]) {
        messages.push(`${parentKey ?? attrKey} ${obj[attrKey]['message']}`)
        tempMessages.push(`${parentKey ?? attrKey} ${obj[attrKey]['message']}`)
      }
      const extractedMessages: TypeObject = extractMessages(obj[attrKey], parentKey ?? attrKey)
      messages = messages.concat(extractedMessages.messages)
      tempMessages = tempMessages.concat(extractedMessages.messages)
    }

    const objKey = parentKey ?? attrKey
    result[objKey] =
      (result[objKey] ? result[objKey] : '') +
      (!result[objKey] ? tempMessages.join('\n') : '\n' + tempMessages.join('\n'))
  }
  return { messages, result }
}

export const handleMarkDoneErrors = (array: string[]): any => {
  const result: any = {}
  array.forEach((item: string) => {
    const errorMatches = item.match(/"([^"]+)"/)
    if (errorMatches) {
      const keys = errorMatches[1].split('.')
      let currentObj: any = result
      keys.forEach((key: string, index: number, array: string[]) => {
        const matches = key.match(/(.+?)\[(\d+)\]/)
        const isArrayElement = matches !== null
        const keyWithoutArray = isArrayElement ? matches[1] : key
        if (!currentObj[keyWithoutArray] && errorMatches) {
          if (index === array.length - 1) {
            currentObj[keyWithoutArray] =
              keyWithoutArray + '' + (item?.split?.(errorMatches[0])[1] ?? '')
          } else {
            currentObj[keyWithoutArray] = isArrayElement ? [] : {}
          }
        }
        if (isArrayElement) {
          const arrayIndex = parseInt(matches[2], 10)
          ensureArrayLength(currentObj[keyWithoutArray], arrayIndex + 1)
          currentObj = currentObj[keyWithoutArray][arrayIndex]
        } else {
          currentObj = currentObj[keyWithoutArray]
        }
      })
    }
  })
  try {
    const reslut2 = convertMessagesToKeyValue(result)
    const result3: any = {}
    for (const key in reslut2) {
      const parts = key.split('.')
      if (!result3[parts[0]]) {
        result3[parts[0]] = {}
      }
      if (parts.length > 2) {
        result3[parts[0]][parts[1]] = `${parts.slice(1, -1).join(' ')} ${reslut2[key]}`
      } else {
        result3[parts[0]][parts[1]] = reslut2[key]
      }
    }
    return result3
  } catch (error) {
    return result
  }
}

const convertMessagesToKeyValue = (obj: any, prefix = '') => {
  const result: any = {}
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      result[prefix + key] = obj[key]
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any, index: number) => {
        const newPrefix = `${prefix}${key}.[${index}].`
        Object.assign(result, convertMessagesToKeyValue(item, newPrefix))
      })
    } else if (typeof obj[key] === 'object') {
      Object.assign(result, convertMessagesToKeyValue(obj[key], `${prefix}${key}.`))
    }
  }
  return result
}

export const replaceIfStartsWith = (
  originalString: string,
  searchString: string,
  replacement: string,
) => {
  if (originalString.startsWith(searchString)) {
    return replacement + originalString.slice(searchString.length)
  }
  return originalString
}

export const updateErrorMessagesByFieldLabel = (
  errorsObj: any = {},
  fields: any = [],
  attributes: TypeObject[] = [],
) => {
  const updatedErrors: any = []
  try {
    const fieldsLinear = fields?.reduce((prev: any, cur: any) => {
      prev[cur.fieldName] = cur.label
      return prev
    }, {})

    const handleErrorObj = (errorsObjCopy: any) => {
      for (const fieldName in errorsObjCopy) {
        const error = errorsObjCopy[fieldName]
        if (fieldsLinear[fieldName]) {
          const label = fieldsLinear[fieldName]
          if (typeof error === 'string') {
            const updatedError = replaceIfStartsWith(error, fieldName, label)
            updatedErrors.push(replaceFieldNameWithLabel(attributes, updatedError))
          } else if (typeof error === 'object' && Array.isArray(error)) {
            error.forEach((errorObjFromArray: any) => {
              for (const key in errorObjFromArray) {
                handleErrorObj({ [fieldName]: `${fieldName} ${errorObjFromArray[key]}` })
              }
            })
          }
        } else {
          updatedErrors.push(replaceFieldNameWithLabel(attributes, error))
        }
      }
    }
    handleErrorObj({ ...errorsObj })
  } catch (error) {
    console.log(error)
  }
  return updatedErrors
}

export const updateValueMasters = (data: any, key: any) => {
  const countries = data.find((item: any) => item?.key === key)
  const countriesUpdated = {
    key: countries?.key,
    data: countries?.data.map((item: any) => ({
      ...item,
      value: `${item.key}  ${item.value}`,
    })),
  }

  const countryIndex = data.findIndex((item: any) => item.key === key)

  const updatedData: any = [...data]
  updatedData[countryIndex] = countriesUpdated

  return updatedData
}

export const updateCategoriesBasedOnAttributes = (categories: any, attributes: any) => {
  return categories.filter((category: any) =>
    attributes.some(
      (attribute: any) =>
        attribute.metaCategoryKey === category.metaCategoryKey &&
        attribute.metaAttributes.length > 0,
    ),
  )
}
export const validateOtherAndDependentChild = (validationData: TypeObject, versionId: string) => {
  let otherVersions = false,
    dependentChilds = false
  if (validationData[1]?.data?.length) {
    dependentChilds = Boolean(
      validationData[1].data.filter((item: any) => !item?.result?.isStandAlonePublishing)?.length,
    )
  }
  if (validationData[0]?.payload?.data?.length) {
    otherVersions = !!validationData[0].payload.data.filter(
      (version: any) => version._id !== versionId,
    ).length
  }
  return { otherVersions, dependentChilds }
}

export const getDePublishDialogDetails = (errorType: number) => {
  let btn1Label, btn2Label, description
  if (errorType === 1) {
    btn1Label = CONSTANTS.BTN_LABEL_CANCEL
    btn2Label = CONSTANTS.BTN_LABEL_PROCEED
    description = CONSTANTS.MESSAGES.DE_PUBLISH_VERSIONS_EXIST
  } else if (errorType === 2) {
    btn1Label = CONSTANTS.BTN_LABEL_OKEY
    description = CONSTANTS.MESSAGES.DE_PUBLISH_LINKED_EXIST
  } else if (errorType === 3) {
    btn1Label = CONSTANTS.BTN_LABEL_CANCEL
    btn2Label = CONSTANTS.BTN_LABEL_YES
    description = CONSTANTS.MESSAGES.DE_PUBLISH_CONFIRM
  }
  return { btn1Label, btn2Label, description }
}

export const openAssetInNewTab = (item: any) => {
  const { _id, assetType, contentCategory, assetCategory } = item
  const url = process.env.REACT_APP_URL
  window.open(
    `${url}/asset/view/${_id}/${assetType}/${contentCategory}/${assetCategory}/${CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY}`,
    '_blank',
  )
}

export const handleComplexFieldErrors = (errorsArray: any) => {
  const pathArr: any = []

  errorsArray.forEach((error: any) => {
    const matches = error.match(/^"([^"]+)"\s(.+)$/)
    if (matches) {
      const path = parseErrorPath(matches[1])
      const message = matches[2].trim()
      pathArr.push([...path, message])
    }
  })

  const errors = buildNestedObject(pathArr)
  return errors
}

function parseErrorPath(path: string) {
  const segments = path
    .replace(/\[(\w+)\]/g, '.$1')
    .split('.')
    .filter(Boolean)
  return segments.length > 0 ? segments : []
}

function buildNestedObject(arr: any) {
  const result = {}

  arr.forEach((path: string) => {
    let current: any = result

    for (let i = 0; i < path.length; i++) {
      const key = path[i]
      const isLast = i === path.length - 1

      if (isLast) {
        if (typeof current.message === 'undefined') {
          current.message = key
        } else {
          if (!current.errors) {
            current.errors = []
          }
          const errorIndex = parseInt(key, 10)
          if (!isNaN(errorIndex)) {
            current.errors[errorIndex] = current.errors[errorIndex] || {}
            current.errors[errorIndex].message = path[i + 1]
          } else {
            current[key] = path[i + 1]
          }
        }
      } else {
        if (!isNaN(parseInt(key, 10))) {
          if (!current.errors) {
            current.errors = []
          }
          const errorIndex = parseInt(key, 10)
          current.errors[errorIndex] = current.errors[errorIndex] || {}
          current = current.errors[errorIndex]
        } else {
          current[key] = current[key] || {}
          current = current[key]
        }
      }
    }
  })

  return result
}

export const updateCategoriesValues = (arr: any, attributesData: any) => {
  const metaAttributes = [...arr]

  metaAttributes.forEach((element: any, index: any) => {
    if (element.fieldType !== CONSTANTS.FIELDTYPES.COMPLEXTYPE) {
      metaAttributes[index].attribute.value = attributesData?.[element.fieldName]
    }

    if (element.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE && !element.addMore) {
      element.attribute.attributeRef.forEach((attrChild: any, attrChildIdx: number) => {
        metaAttributes[index].attribute.attributeRef[attrChildIdx].attribute.value =
          attributesData?.[element.fieldName]?.[attrChild.fieldName]
      })
    }
    if (element.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE && element.addMore) {
      element.attribute.forEach((child: any, childIdx: number) => {
        child.attributeRef.forEach((attrChild: any, attrChildIdx: number) => {
          metaAttributes[index].attribute[childIdx].attributeRef[attrChildIdx].attribute.value =
            attributesData?.[element.fieldName]?.[childIdx]?.[attrChild.fieldName]
        })
      })
    }
  })

  return metaAttributes
}

const getValues = (
  attribute: {
    defaultValue: string | number | boolean
    inheritanceRule: string
    isMultiple: boolean
    fieldType: string
    fieldName: string
    options: TypeObject[]
  },
  value: string | number | boolean,
  detailViewState: TypeObject,
) => {
  if (detailViewState?.data?.state === CONSTANTS.ASSET_STATUS.CREATED) {
    if (attribute?.isMultiple && attribute?.fieldType === CONSTANTS.FIELDTYPES.SELECT) {
      const dv = attribute.defaultValue as string | string[]
      return !dv ? value : Array.isArray(dv) ? dv : dv.split(',').map((item) => item?.trim())
    } else if (attribute && attribute?.inheritanceRule === 'NONE') {
      return attribute.defaultValue ? attribute.defaultValue : value
    } else {
      return value
    }
  } else {
    if (attribute?.fieldName === 'packageId') {
      const masterPackageValue =
        detailViewState?.data?.ContentMetaCategory?.[0]?.attributes?.[
          CONSTANTS.PACKAGE_MASTER_KEY
        ] ?? null

      if (masterPackageValue) {
        const changedPackageKey = attribute.options.find(
          (item: any) => item.value === masterPackageValue,
        )
        if (changedPackageKey) {
          return changedPackageKey?.key
        }
      }
      return value
    } else {
      if (typeof value === 'boolean')
        if (value) return true
        else return false
      else return value
    }
  }
}

export const getDisplayAttributesData = (
  contentData: TypeObject,
  markDoneStatus: TypeObject[],
  complexArray: TypeObject[],
  detailViewState: TypeObject,
  attributes: TypeObject,
  metaCategory: string,
) => {
  if (contentData) {
    const contentDisplayData: any = {
      metaCategoryLabel: markDoneStatus.find(
        (item: any) =>
          item?.metaCategoryKey ===
          (contentData?.metaCategoryKey || CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY),
      )?.metaCategoryName,
      ...contentData,
      metaCategory: contentData?.metaCategory ?? '',
      metaAttributes: (() => {
        if (contentData?.metaAttributes?.length) {
          return contentData.metaAttributes
            .filter((cItem: any) => !complexArray.some((dItem: any) => cItem._id === dItem._id))
            .map((contentMetaAttribute: any) => ({
              attribute: (() => {
                if (contentMetaAttribute?.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE) {
                  if (contentMetaAttribute?.addMore) {
                    if (
                      attributes?.[contentMetaAttribute?.fieldName]?.length &&
                      Array.isArray(attributes?.[contentMetaAttribute.fieldName])
                    ) {
                      return attributes?.[contentMetaAttribute.fieldName].map(
                        (attribute: any, index: number) => ({
                          ...contentMetaAttribute,
                          value: getValues(contentMetaAttribute, attribute ?? '', detailViewState),
                          _id: (contentMetaAttribute.fieldName + index).toString(),
                          attributeRef: contentMetaAttribute.attributeRef.map(
                            (attRef: any, attrefIdx: number) => {
                              const attributeRefParent = contentData.metaAttributes.find(
                                (attributeReference: any) =>
                                  attributeReference?._id === attRef?._id,
                              )
                              return {
                                attribute: {
                                  ...attRef,
                                  ...attributeRefParent,
                                  _id: attRef.feildName + index.toString() + attrefIdx.toString(),
                                  value: getValues(
                                    attributeRefParent,
                                    attribute?.[attributeRefParent?.fieldName] ?? '',
                                    detailViewState,
                                  ),
                                },
                                label: attributeRefParent?.label,
                                fieldName: attributeRefParent?.fieldName,
                                fieldType: attributeRefParent?.fieldType,
                                sortOrder: attRef.sortOrder,
                                addMore: attributeRefParent?.addMore,
                                isRequired: attributeRefParent?.isRequired,
                              }
                            },
                          ),
                        }),
                      )
                    } else {
                      return [
                        {
                          ...contentMetaAttribute,
                          value: getValues(
                            contentMetaAttribute,
                            attributes?.[contentMetaAttribute.fieldName] ?? '',
                            detailViewState,
                          ),
                          attributeRef: contentMetaAttribute.attributeRef.map((attRef: any) => {
                            const attributeRefParent = contentData.metaAttributes.find(
                              (attribute: any) => attribute?._id === attRef?._id,
                            )
                            return {
                              attribute: {
                                ...attRef,
                                ...attributeRefParent,
                                value: getValues(
                                  attributeRefParent,
                                  attributes?.[contentMetaAttribute.fieldName]?.[
                                    attributeRefParent.fieldName
                                  ] ?? '',
                                  detailViewState,
                                ),
                              },
                              label: attributeRefParent?.label,
                              fieldName: attributeRefParent?.fieldName,
                              fieldType: attributeRefParent?.fieldType,
                              sortOrder: attRef.sortOrder,
                              addMore: attributeRefParent?.addMore,
                              isRequired: attributeRefParent?.isRequired,
                            }
                          }),
                        },
                      ]
                    }
                  } else {
                    return {
                      ...contentMetaAttribute,
                      value: getValues(
                        contentMetaAttribute,
                        attributes?.[contentMetaAttribute.fieldName] ?? '',
                        detailViewState,
                      ),
                      attributeRef: contentMetaAttribute?.attributeRef
                        ? contentMetaAttribute.attributeRef.map((attRef: any) => {
                            const attributeRefParent = contentData.metaAttributes.find(
                              (attributeReference: any) => attributeReference?._id === attRef?._id,
                            )
                            return {
                              attribute: {
                                ...attRef,
                                ...attributeRefParent,
                                value: getValues(
                                  attributeRefParent,
                                  attributes?.[contentMetaAttribute.fieldName]?.[
                                    attributeRefParent.fieldName
                                  ] ?? '',
                                  detailViewState,
                                ),
                              },
                              label: attributeRefParent?.label,
                              fieldName: attributeRefParent?.fieldName,
                              fieldType: attributeRefParent?.fieldType,
                              sortOrder: attRef.sortOrder,
                              addMore: attributeRefParent?.addMore,
                              isRequired: attributeRefParent?.isRequired,
                            }
                          })
                        : [],
                    }
                  }
                } else {
                  return {
                    ...contentMetaAttribute,
                    value: getValues(
                      contentMetaAttribute,
                      attributes?.[contentMetaAttribute.fieldName] ?? '',
                      detailViewState,
                    ),
                    detailViewState,
                  }
                }
              })(),
              fieldName: contentMetaAttribute?.fieldName,
              fieldType: contentMetaAttribute?.fieldType,
              label: contentMetaAttribute?.label,
              sortOrder: contentMetaAttribute?.sortOrder,
              addMore: contentMetaAttribute?.addMore,
              isRequired: contentMetaAttribute?.isRequired,
            }))
        } else {
          return []
        }
      })(),
    }

    return contentDisplayData
  } else {
    const d: any = {
      metaCategoryLabel: markDoneStatus.find(
        (item: any) =>
          item?.metaCategoryKey === (metaCategory || CONSTANTS.CATEGORIES.DESCRIPTIVE_METADATA),
      )?.metaCategoryName,

      metaCategory: metaCategory ?? '',
      metaAttributes: [],
    }

    return d
  }
}

export const getComplexAttributes = (contentData: TypeObject) => {
  return contentData?.metaAttributes?.length
    ? contentData?.metaAttributes
        ?.filter((item: any) => item.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE)
        .flatMap((item: any) => item.attributeRef)
    : []
}

export const getMastersForSelect = async (
  attributes: any,
  metaCategory: string,
  masters: any,
  setLoader: any,
) => {
  const data: any = attributes
    ? attributes.find(
        (x: any) =>
          x?.metaCategory === (metaCategory ?? CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY),
      )
    : []

  if (data?.metaAttributes?.length) {
    const sortedData = [...data.metaAttributes].sort((a: any, b: any) => {
      if (a.sortOrder < b.sortOrder) return -1
      if (a.sortOrder > b.sortOrder) return 1
      return 0
    })

    setLoader(true)
    const dataWithMasters = await handleMastersForSelect(sortedData, data, masters)
    setLoader(false)

    return dataWithMasters
  }
}

export const handleMastersForSelect = async (data: any, category: any, masters: any) => {
  const sysConfigKeys = data
    ?.filter((item: any) => item?.master?.includes?.('SystemConfigurations'))
    ?.map((item: any) => item?.master?.split('/')?.[1]?.trim())

  const sysConfigReqData = SYSTEM_CONFIG_APIS.LIST_MASTERS(sysConfigKeys.join(','))

  let systemConfigs: any
  if (sysConfigKeys?.length) systemConfigs = await mainApiService(sysConfigReqData)

  const updatedData = await Promise.all(
    data.map(async (item: any) => {
      if (item.fieldType === CONSTANTS.FIELDTYPES.SELECT) {
        if (item.fieldType === CONSTANTS.FIELDTYPES.SELECT) {
          if (item?.master) {
            if (item?.master?.includes('SystemConfigurations')) {
              const masterData = systemConfigs?.data ?? []
              const masterBasedOnKey = masterData.find(
                (itemc: any) => itemc?.configKey === item?.master?.split('/')?.[1]?.trim(),
              )
              return {
                ...item,
                options:
                  sysConfigKeys?.length === 1
                    ? sortedData(systemConfigs.data)
                    : sortedData(masterBasedOnKey?.configValue) ?? [],
              }
            } else {
              const itemMaster = masters.find((im: any) => im?.key === item.master)
              return {
                ...item,
                options: itemMaster?.data ?? [],
              }
            }
          } else {
            return {
              ...item,
              options:
                item?.fieldType === CONSTANTS.FIELDTYPES.SELECT &&
                item?.dataType === CONSTANTS.ASSET_CREATION_OBJ.BOOLEAN
                  ? [
                      { key: true, value: 'Yes' },
                      { key: false, value: 'No' },
                    ]
                  : item.options.map((item: any) => ({ key: item, value: item })),
            }
          }
        }
      }
      return item
    }),
  )

  return { ...category, metaAttributes: updatedData }
}

/**
 * Get the status of recent activities.
 * @function getTooltipMessage
 * @param {boolean} publishingAccess - Published property in tollgates ( Roles and Permission is true or not )
 * @param {boolean} standAloneDisable - property is boolean shows standalone published is true and parent is published or not.
 * @returns {string} - message to show on tooltip.
 */
export const getTooltipMessage = (publishingAccess: boolean, standAloneDisable: boolean) => {
  return !publishingAccess
    ? CONSTANTS.MESSAGES.PUBLISH_DISABLE_MESSAGE
    : standAloneDisable
    ? CONSTANTS.MESSAGES.STANDALONE_DISABLE_MESSAGE
    : ''
}

/**
 * Function to replace fieldName by label
 * @param {array} attributes list of all the attributes
 * @param {string} message message from which fieldName will be replaced by label
 * @returns  {string} update messaged with all the fieldName replaced by label
 */
const replaceFieldNameWithLabel = (attributes: TypeObject[], message: string) => {
  const msg = message
  const labels = attributes.reduce((acc, attribute) => {
    acc[attribute.fieldName] = attribute.label
    return acc
  }, {})
  const regex = new RegExp(`${attributes.map((attr) => attr.fieldName).join('|')}`, 'g')

  return msg.replaceAll(regex, (match) => labels[match])
}

/**
 * @param {string} idxMsg - error message which is on root field
 * @param {string} nestedMsg - error message nested in root field coma separated
 * @returns {string} - returns combination of rootMessage and nestedMessage
 */
export const returnErrorMessage = (
  idxMsg: string,
  nestedMsg: string,
  attribute: TypeObject,
  attributes: TypeObject[],
) => {
  const errorMsg = (nestedMsg ? idxMsg + '\n ' : idxMsg) + (nestedMsg ? nestedMsg : '')
  const errorMsgWithLabel = replaceFieldNameWithLabel(attributes, errorMsg)
  return errorMsgWithLabel ? `${attribute?.label} ${errorMsgWithLabel}` : ''
}

/**
 * Get the status of recent activities.
 * @function getTooltipMessage
 * @param {object} attr - attribute of related meta category
 * @param {number} idx - index of complex type object to show the validation
 * @param {string} type - type of field complex, normal or add more
 * @param {object} attrChild - complex type child attribute
 * @param {object} complexFieldErrors - Errors return for field type in particular meta category
 * @param {array} attributes - retrurn list of attributes with fieldname and label
 * @returns {string} - return error for individual field
 */
export const buildErrorObject = (
  attr: any,
  idx: number,
  attrChild: any,
  type: string,
  complexFieldErrors: TypeObject,
  attributes: TypeObject[],
) => {
  /**
   * @param {boolean} nested - true / false if message object is on root field or nested data
   * @param {string|object}messages - to return the message as string for root field and object for index field
   * @param {object} attribute - attribute on which that message is applied
   * @returns {string} - return error messages in string format
   */
  const parseErrorData = (nested: boolean, messages: any) => {
    if (nested) {
      return messages
        ?.filter((messages: { message: string }) => messages?.message?.trim())
        .map((messages: { message: string; label: string }) => messages?.message ?? '')
        .join('\n')
    } else {
      return messages ?? ''
    }
  }

  if (type === CONSTANTS.ASSET_CREATION_OBJ.ADDMORE) {
    const rootMsg = parseErrorData(
      false,
      complexFieldErrors?.[attr.fieldName]?.errors?.[idx]?.[attrChild.fieldName]?.message,
    )
    const nestedMsg = parseErrorData(
      true,
      complexFieldErrors?.[attr.fieldName]?.errors?.[idx]?.[attrChild.fieldName]?.errors,
    )
    return returnErrorMessage(rootMsg, nestedMsg, attrChild, attributes)
  } else if (type === CONSTANTS.ASSET_CREATION_OBJ.COMPLEX) {
    const rootMsg = parseErrorData(
      false,
      complexFieldErrors?.[attr.fieldName]?.[attrChild.fieldName]?.message,
    )
    const nestedMsg = parseErrorData(
      true,
      complexFieldErrors?.[attr.fieldName]?.[attrChild.fieldName]?.errors,
    )
    return returnErrorMessage(rootMsg, nestedMsg, attrChild, attributes)
  } else if (type === CONSTANTS.ASSET_CREATION_OBJ.NORMAL) {
    const rootMsg = parseErrorData(false, complexFieldErrors?.[attr.fieldName]?.message)
    const nestedMsg = parseErrorData(true, complexFieldErrors?.[attr.fieldName]?.errors)
    return returnErrorMessage(rootMsg, nestedMsg, attr, attributes)
  } else {
    return ''
  }
}

/**
   * @param {object} inputData - The JSON which user entered in JSON Editor
   * @param {string} complexFieldKey - name of the field (complex fieldName)
   * @returns {string} - returns data in below format no matter whatever data coming from field and its important for conversion
   * In case of Add More = true
   *
   * {
      [complexFieldKey]: [
        {
          [complexFieldKey.childFieldName1]: [
            "_af",
            "_teal",
            "_ax"
          ],
          [complexFieldKey.childFieldName2]: 500
        },
        {
          [complexFieldKey.childFieldName1]: [
            "_af",
            "_ax"
          ],
          [complexFieldKey.childFieldName2]: 600
        }
      ]
    }
    *
    * In case of Add More = false
    *
    * {
        [complexFieldKey]: {
            [complexFieldKey.childFieldName1]: [
              "_af",
              "_teal",
              "_ax"
            ],
            [complexFieldKey.childFieldName2]: 500
          }
      }
    *
    *
   */
export const convertJsonToFieldObject = (inputData: any, complexFieldKey: string) => {
  const dataObject: any = { [complexFieldKey]: [] }

  if (complexFieldKey === CONSTANTS.JSON_EDITOR_FIELDS.countryRating) {
    for (const key in inputData) {
      const dataObj = { ratingForCountry: key, countryForRating: inputData?.[key]?.split(',') }
      if (Array.isArray(dataObj.countryForRating)) {
        dataObj.countryForRating = dataObj.countryForRating.map((element) => {
          return `_${element.replace('_', '')}`.toLowerCase()
        })
      }
      dataObject[complexFieldKey].push(dataObj)
    }
  }
  const isPreviewDurationNumber = (value: any) => {
    if (value === '' || value >= 0 || /^-?\d+(\.\d+)?$/.test(value)) {
      return true
    }
    return false
  }
  if (complexFieldKey === CONSTANTS.JSON_EDITOR_FIELDS.countryPreview) {
    const otherSchema = inputData?.filter(
      (item: any) =>
        !Object.prototype.hasOwnProperty.call(item, 'preview_duration') ||
        !Object.prototype.hasOwnProperty.call(item, 'countries') ||
        !isPreviewDurationNumber(item.preview_duration),
    )

    if (otherSchema?.length) {
      return { error: 'JSON value is not consistent with the attribute definition' }
    }
    for (const previewObj of inputData) {
      const dataObj = {
        previewDuration: previewObj?.preview_duration ?? '',
        previewCountry: previewObj?.countries?.split(','),
      }
      if (Array.isArray(dataObj.previewCountry)) {
        dataObj.previewCountry = dataObj.previewCountry.map((element) => {
          return `_${element.replace('_', '')}`.toLowerCase()
        })
      }
      dataObject[complexFieldKey].push(dataObj)
    }
  }
  if (complexFieldKey === CONSTANTS.JSON_EDITOR_FIELDS.isAutoPlayTrailer) {
    const otherSchema =
      Object.prototype.hasOwnProperty.call(inputData, 'disabled_country') &&
      Object.prototype.hasOwnProperty.call(inputData, 'disabled_platform')

    if (!otherSchema) {
      return { error: 'JSON value is not consistent with the attribute definition' }
    }

    const dataObj = {
      disabledCountry: inputData?.disabled_country?.split(','),
      disabledPlatform: inputData?.disabled_platform?.split(','),
    }
    if (Array.isArray(dataObj.disabledCountry)) {
      dataObj.disabledCountry = dataObj.disabledCountry.map((element) => {
        return `_${element.replace('_', '')}`.toLowerCase()
      })
    }
    dataObject[complexFieldKey] = dataObj
  }
  return dataObject
}

/**
   * @param {object} data - This data will be the data returned by above function convertJsonToFieldObject() given below
   * In case of Add More = true
   *
   * {
      [complexFieldKey]: [
        {
          [complexFieldKey.childFieldName1]: [
            "_af",
            "_teal",
            "_ax"
          ],
          [complexFieldKey.childFieldName2]: 500
        },
        {
          [complexFieldKey.childFieldName1]: [
            "_af",
            "_ax"
          ],
          [complexFieldKey.childFieldName2]: 600
        }
      ]
    }
    *
    * In case of Add More = false
    *
    * {
        [complexFieldKey]: {
            [complexFieldKey.childFieldName1]: [
              "_af",
              "_teal",
              "_ax"
            ],
            [complexFieldKey.childFieldName2]: 500
          }
      }
    *
    *
   * @param {string} complexFieldKey - name of the field (complex fieldName)
   * @returns {object} - return object based on the field the object data is mentioned in the story https://sony-liv.atlassian.net/browse/BLITZ-4036
   */
export const convertFieldObjectToJson = (data: any, complexFieldKey: string) => {
  const dataObject: any = data[complexFieldKey]
  let fieldData: any = {}
  if (complexFieldKey === CONSTANTS.JSON_EDITOR_FIELDS.countryRating) {
    const obj: any = {}
    for (const i of dataObject) {
      obj[i?.['ratingForCountry'] ?? ''] = i?.countryForRating ? i.countryForRating.join(',') : ''
    }
    fieldData = obj
  }
  if (complexFieldKey === CONSTANTS.JSON_EDITOR_FIELDS.countryPreview) {
    fieldData = dataObject.map((item: any) => ({
      countries: item?.previewCountry ? item.previewCountry.join(',') : '',
      ['preview_duration']: item.previewDuration,
    }))
  }
  if (complexFieldKey === CONSTANTS.JSON_EDITOR_FIELDS.isAutoPlayTrailer) {
    const dataObj = {
      ['disabled_platform']: data?.disabledPlatform ? data.disabledPlatform.join(',') : '',
      ['disabled_country']: data?.disabledCountry ? data.disabledCountry.join(',') : '',
    }
    fieldData = dataObj
  }
  return fieldData
}

export const processChecklistMarkDoneStatus = (
  markDoneStatus: any[],
  result: any,
  metaAttributesData: any,
  attributes: any,
) => {
  const markData: any = []
  const status: any = []
  const markDoneErrorsInCategory: any[] = []

  markDoneStatus.forEach((ele: any) => {
    result?.data?.forEach((item: any) => {
      if (ele?.metaCategoryKey === item.metaCategory) {
        if (item.errors.length) {
          const complexErrors: any = handleComplexFieldErrors(item.errors ?? [])
          const errorObject = extractMessages(complexErrors[item.metaCategory])
          item.errors = updateErrorMessagesByFieldLabel(
            errorObject.result || {},
            metaAttributesData[item.metaCategory],
            attributes,
          )
          status.push(item.errors)
        }
        markDoneErrorsInCategory.push({
          metaCategory: item.metaCategory,
          markDoneStatus: item.errors?.length
            ? CONSTANTS.MARK_DONE_STATUS.FAILED
            : CONSTANTS.MARK_DONE_STATUS.PASS,
        })
        markData.push({ ...ele, ...item })
      }
    })
  })
  return { status, markDoneErrorsInCategory, markData }
}
export const validateOptions = (
  contentCategoryData: any,
  keyIndex: number,
  inputData: any,
  key: string,
  addMore: boolean,
) => {
  const returnErrorBasedOnFields = (values: any, attributeRefArr: any) => {
    for (const keyValues of values) {
      for (const key in keyValues) {
        const findAttref = attributeRefArr.find((item: any) => item?.fieldName === key)
        const options = findAttref?.attribute?.options ?? []
        if (options?.length) {
          if (typeof keyValues[key] === 'object') {
            for (const value of keyValues[key]) {
              const av = options.find((item: any) => item?.key === value)
              if (!av && value) {
                return { error: 'JSON value is not consistent with the attribute definition' }
              }
            }
          } else {
            const av = options.find((item: any) => item?.key === keyValues[key])
            if (!av && keyValues[key]) {
              return {
                error: 'JSON value is not consistent with the attribute definition',
              }
            }
          }
        }
      }
    }
  }
  if (contentCategoryData?.metaAttributes?.[keyIndex]?.attribute) {
    const attributeRefArr: any = addMore
      ? contentCategoryData?.metaAttributes?.[keyIndex]?.attribute?.[0]?.attributeRef ?? []
      : contentCategoryData?.metaAttributes?.[keyIndex]?.attribute?.attributeRef ?? []
    const values = inputData[key]
    if (Array.isArray(values)) {
      return returnErrorBasedOnFields(values, attributeRefArr)
    } else {
      return returnErrorBasedOnFields([values], attributeRefArr)
    }
  }
}

export const updateContentCategory = (
  fieldType: string,
  value: any,
  type: string,
  idx: number,
  comIdx: number,
  addMoreIdx: number,
  content: any,
) => {
  const contentData: any = { ...content }
  if (type === CONSTANTS.ASSET_CREATION_OBJ.COMPLEX) {
    if (fieldType === CONSTANTS.ASSET_CREATION_OBJ.SELECT) {
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          contentData.metaAttributes[idx].attribute.attributeRef[comIdx].attribute.value =
            value.map((item: any) => item.key)
        } else {
          contentData.metaAttributes[idx].attribute.attributeRef[comIdx].attribute.value =
            value?.key ?? ''
        }
      }
    } else {
      contentData.metaAttributes[idx].attribute.attributeRef[comIdx].attribute.value = value
    }
  } else if (type === CONSTANTS.ASSET_CREATION_OBJ.ADDMORE) {
    if (fieldType === CONSTANTS.ASSET_CREATION_OBJ.SELECT) {
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          contentData.metaAttributes[idx].attribute[addMoreIdx].attributeRef[
            comIdx
          ].attribute.value = value.map((item) => item.key)
        } else {
          contentData.metaAttributes[idx].attribute[addMoreIdx].attributeRef[
            comIdx
          ].attribute.value = value?.key
        }
      }
    } else {
      contentData.metaAttributes[idx].attribute[addMoreIdx].attributeRef[comIdx].attribute.value =
        value
    }
  } else if (fieldType === CONSTANTS.FIELDTYPES.YEAR) {
    if (value instanceof Date) {
      const valueInYear = value?.getFullYear()
      contentData.metaAttributes[idx].attribute.value = `${valueInYear}`
    } else {
      contentData.metaAttributes[idx].attribute.value = value
    }
  } else {
    if (fieldType === CONSTANTS.ASSET_CREATION_OBJ.SELECT) {
      if (typeof value === 'object') {
        if (contentData.metaAttributes[idx].attribute?.fieldName === 'geoPolicyGroupName') {
          const applicableGeoPolicy: number = contentData.metaAttributes.findIndex(
            (el: any) => el.fieldName === 'applicableGeoPolicy',
          )

          if (applicableGeoPolicy !== -1) {
            contentData.metaAttributes[applicableGeoPolicy].attribute.value =
              contentData.metaAttributes[applicableGeoPolicy].attribute.options
                .filter((item: any) =>
                  item.geoPolicyGroupName === value?.key ? value?.key : false,
                )
                .map((item: any) => item.key)
          }

          contentData.metaAttributes[idx].attribute.value = value?.key ?? ''
        } else if (contentData.metaAttributes[idx].attribute?.fieldName === 'applicableGeoPolicy') {
          const geoPolicyGroupNameIdx: number = contentData.metaAttributes.findIndex(
            (el: any) => el.fieldName === 'geoPolicyGroupName',
          )

          contentData.metaAttributes[idx].attribute.value = value.map((item: any) => item.key)

          if (geoPolicyGroupNameIdx !== -1) {
            const geoPolicyGroup =
              value?.filter((item: any) => item.geoPolicyGroupName)?.[0]?.geoPolicyGroupName ?? null

            const notAvailable = value.find((item: any) => !item.geoPolicyGroupName)

            if (notAvailable) contentData.metaAttributes[geoPolicyGroupNameIdx].attribute.value = ''
            else {
              contentData.metaAttributes[geoPolicyGroupNameIdx].attribute.value =
                geoPolicyGroup ?? ''
            }

            const groupNameOptions = contentData.metaAttributes[idx].attribute.options.filter(
              (item: any) => item.geoPolicyGroupName === geoPolicyGroup,
            )
            if (groupNameOptions?.length) {
              if (groupNameOptions?.length !== value?.length) {
                contentData.metaAttributes[geoPolicyGroupNameIdx].attribute.value = ''
              }
            }
          }
        } else if (contentData.metaAttributes[idx].attribute?.fieldName === 'packageId') {
          const packageMasterKey: number = contentData.metaAttributes.findIndex(
            (el: any) => el.fieldName === CONSTANTS.PACKAGE_MASTER_KEY,
          )

          if (packageMasterKey !== -1) {
            contentData.metaAttributes[packageMasterKey].attribute.value = value?.value ?? ''
          }

          contentData.metaAttributes[idx].attribute.value = value?.key ?? ''
        } else {
          if (Array.isArray(value)) {
            contentData.metaAttributes[idx].attribute.value = value.map((item: any) => item.key)
          } else {
            contentData.metaAttributes[idx].attribute.value = value?.key ?? ''
          }
        }
      }
    } else if (contentData.metaAttributes[idx].fieldName === 'releaseDate') {
      const releaseYearIDX: number = contentData.metaAttributes.findIndex(
        (el: any) => el.fieldName === 'releaseYear',
      )
      const releaseYear = value?.getFullYear()
      // since releaseYearIDX can be zero, checking specificaly for -1
      if (releaseYearIDX !== -1 && releaseYear) {
        contentData.metaAttributes[releaseYearIDX].attribute.value = `${releaseYear}`
      }
      contentData.metaAttributes[idx].attribute.value = value
    } else {
      contentData.metaAttributes[idx].attribute.value = value
    }
  }
  return contentData
}

export const getTabsMetaCateories = (metaCategory: string, attributes: any) => {
  const tabsData: any = CONSTANTS.METADATA_TABS
  const tab = tabsData?.[metaCategory]?.tabs ?? false

  const fieldAvailable = attributes.some((item: any) => {
    return tabsData?.[metaCategory]?.fieldNames.includes(item.fieldName)
  })

  if (fieldAvailable) return tab
  else {
    return false
  }
}

export const setFieldsByTabs = (fieldName: string, metadataTab: number, metaCategory: string) => {
  const tabsData: any = CONSTANTS.METADATA_TABS
  if (metadataTab === 0) {
    return !tabsData?.[metaCategory]?.fieldNames?.includes(fieldName)
  }
  return tabsData?.[metaCategory]?.fieldNames?.includes(fieldName)
}

export const getValuesAttributeField = (value: any, item: any) => {
  if (
    item?.fieldType === CONSTANTS.FIELDTYPES.SELECT &&
    item?.dataType === CONSTANTS.ASSET_CREATION_OBJ.BOOLEAN
  ) {
    if (value === true) {
      return true
    } else if (value === false) {
      return false
    } else {
      return null
    }
  } else {
    return value || null
  }
}

export const validateEmptyValue = (value: any): boolean => {
  return Array.isArray(value) && value.length > 0 && Object.keys(value[0]).length > 0
}

export const prepareAttributesData = (accessDenied: boolean, categoryContent: any) => {
  const attr: any = {}
  if (!accessDenied) {
    if (categoryContent?.metaAttributes && categoryContent?.metaAttributes.length > 0) {
      for (const metaAttribute of categoryContent.metaAttributes) {
        if (metaAttribute?.fieldType !== CONSTANTS.FIELDTYPES.COMPLEXTYPE) {
          if (metaAttribute?.fieldName) {
            attr[metaAttribute.fieldName] =
              metaAttribute.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE
                ? {}
                : getValuesAttributeField(metaAttribute?.attribute?.value, metaAttribute.attribute)
          }
        } else {
          if (
            metaAttribute?.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE &&
            metaAttribute?.addMore &&
            metaAttribute?.fieldName
          ) {
            const attributeValue = metaAttribute.attribute.map((attrItem: any) => {
              const addMoreItemRef: any = {}
              for (const metaAttrChlild of attrItem.attributeRef) {
                if (metaAttrChlild?.fieldName)
                  addMoreItemRef[metaAttrChlild.fieldName] = getValuesAttributeField(
                    metaAttrChlild?.attribute?.value,
                    metaAttrChlild.attribute,
                  )
              }
              const cuePointCondition = nullValuesPresent(addMoreItemRef) ? {} : addMoreItemRef
              return attrItem?.fieldName === CONSTANTS?.CUE_POINT_LIST
                ? cuePointCondition
                : addMoreItemRef
            })
            const copyattributeValue = attributeValue?.filter(
              (item: any) => Object.keys(item)?.length,
            )

            if (copyattributeValue && validateEmptyValue(copyattributeValue))
              attr[metaAttribute.fieldName] = copyattributeValue
            else attr[metaAttribute.fieldName] = []
          }

          if (
            metaAttribute?.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE &&
            !metaAttribute?.addMore &&
            metaAttribute?.fieldName
          ) {
            if (!(metaAttribute.fieldName in attr)) {
              attr[metaAttribute.fieldName] = {}
            }
            for (const metaAttrChlild of metaAttribute.attribute.attributeRef) {
              if (metaAttrChlild?.fieldName)
                attr[metaAttribute.fieldName][metaAttrChlild.fieldName] = getValuesAttributeField(
                  metaAttrChlild?.attribute?.value,
                  metaAttrChlild.attribute,
                )
            }
          }
        }
      }
    }
  } else {
    return {}
  }

  return attr
}

export const validateForEmptyValues = (value: any) => {
  return value === '' || value === null || value === undefined || value?.length === 0
}

export const getMarkDoneStatus = (
  attr: any,
  metaCategory: string,
  asset: any,
  detailViewState: any,
) => {
  const m: any = metaCategory
  const tollgate = asset?.tollGate?.[m]
  const permissionsCheck: any = checkForRolePermissions(tollgate, metaCategory)
  if (permissionsCheck !== 'skip') {
    // setMarkAsDone(permissionsCheck)
    return permissionsCheck
  }
  if (tollgate) {
    const isRequiredData = attr ? attr.filter((item: any) => item?.isRequired) : []

    let fieldCheck = false
    let attributeCheck = false
    let addMoreAttrCheck = false
    for (const attr of isRequiredData) {
      if (attr.fieldType !== CONSTANTS.FIELDTYPES.COMPLEXTYPE) {
        if (validateForEmptyValues(attr?.attribute?.value ?? '')) {
          fieldCheck = true
        }
      }

      if (attr.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE && attr?.addMore) {
        const isReqDataComplex = attr.attribute
          .flatMap((item: any) => [...item.attributeRef])
          .filter((itemC: any) => itemC?.isRequired)
        for (const attrChild of isReqDataComplex) {
          if (validateForEmptyValues(attrChild?.attribute?.value ?? '')) {
            addMoreAttrCheck = true
          }
        }
      }

      if (attr.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE && !attr?.addMore) {
        const isReqDataComplex = attr.attribute.attributeRef.filter(
          (itemC: any) => itemC?.isRequired,
        )
        for (const attrChild of isReqDataComplex) {
          if (
            !attrChild?.attribute?.value ||
            attrChild?.attribute?.value === '' ||
            JSON.stringify(attrChild?.attribute?.value) === '[]'
          ) {
            attributeCheck = true
          }
        }
      }
    }
    return fieldCheck || attributeCheck || addMoreAttrCheck
    // setMarkAsDone(fieldCheck || attributeCheck || addMoreAttrCheck)
  } else {
    return detailViewState?.data?.state === 'CREATED'
    // setMarkAsDone(detailViewState?.data?.state === 'CREATED')
  }
}

export const handleErrors = (array: any, metaCategory: any): any => {
  const result: any = {}

  array.forEach((item: any) => {
    const matchs = item.match(/"([^"]+)"/)
    const keys = matchs[1].split('.')
    let currentObj: any = result

    keys.forEach((key: any, index: any, array: any) => {
      const matches = key.match(/(.+?)\[(\d+)\]/) // NOSONAR
      const isArrayElement = matches !== null
      const keyWithoutArray = isArrayElement ? matches[1] : key
      if (!currentObj[keyWithoutArray]) {
        if (index === array.length - 1) {
          currentObj[keyWithoutArray] = keyWithoutArray + ' ' + item?.split?.(matchs[0])[1] ?? ''
        } else {
          currentObj[keyWithoutArray] = isArrayElement ? [] : {}
        }
      }
      if (isArrayElement) {
        const arrayIndex = parseInt(matches[2])
        ensureArrayLength(currentObj[keyWithoutArray], arrayIndex + 1)
        currentObj = currentObj[keyWithoutArray][arrayIndex]
      } else {
        currentObj = currentObj[keyWithoutArray]
      }
    })
  })

  if (result?.[metaCategory as string]) {
    const validation = { ...result?.[metaCategory as string] }
    const validationKeys = Object.entries(validation)
    if (validationKeys?.length) {
      document.getElementById(validationKeys[0][0])?.focus()
    }
  }
  return result
}
