const AssetImageIcon = () => {
  return (
    <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3005_11990)'>
        <path
          d='M26.9167 4.25H7.08333C5.525 4.25 4.25 5.525 4.25 7.08333V26.9167C4.25 28.475 5.525 29.75 7.08333 29.75H26.9167C28.475 29.75 29.75 28.475 29.75 26.9167V7.08333C29.75 5.525 28.475 4.25 26.9167 4.25ZM25.5 26.9167H8.5C7.72083 26.9167 7.08333 26.2792 7.08333 25.5V8.5C7.08333 7.72083 7.72083 7.08333 8.5 7.08333H25.5C26.2792 7.08333 26.9167 7.72083 26.9167 8.5V25.5C26.9167 26.2792 26.2792 26.9167 25.5 26.9167ZM19.21 18.1475L15.8808 22.4258L13.6708 19.7625C13.3875 19.4083 12.8492 19.4225 12.5658 19.7767L10.1008 22.9358C9.7325 23.4033 10.0725 24.0833 10.6533 24.0833H23.375C23.9558 24.0833 24.2958 23.4175 23.9417 22.95L20.3292 18.1475C20.06 17.7792 19.4933 17.7792 19.21 18.1475Z'
          fill='#505152'
        />
      </g>
      <defs>
        <clipPath id='clip0_3005_11990'>
          <rect width='34' height='34' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AssetImageIcon
