import { Box, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { MUITabsModel } from '../../../Models'

const MUITabs: React.FC<MUITabsModel> = ({
  listing = false,
  tabs = [],
  currentTab = '',
  handleChange = () => {
    return
  },
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        aria-label='secondary tabs example'
        className={`${listing ? 'tab-c' : ''}`}
      >
        {tabs.map((tab) => (
          <Tab
            data-testid={tab.id ? tab.id : tab.label}
            value={tab.value}
            key={tab.value}
            label={tab.label}
            sx={{
              fontWeight: 600,
              textTransform: 'capitalize',
              background: '#ffff',
              height: '38px',
            }}
            disabled={tab.disable}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default MUITabs
