/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import CONSTANTS from '../../Constants'
import { ROLE_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations'
import {
  createRoleReqData,
  roleCRUDAPIDefinitionModal,
  roleModulesCheckboxKeys,
  roleModulesModal,
  roleStateModal,
  roleStatusChangeDefinitionModal,
  viewRoleAPIDefinationModal,
} from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

const initialState: roleStateModal = {
  roleModules: [] as roleModulesModal[],
  role: null,
  roles: [],
}

const checkAllModules = (indx: number, arr: roleModulesModal[]): boolean => {
  const submodules = arr[indx]?.submodules

  if (!submodules) {
    return false
  }

  return submodules.every((submodule) => {
    const permissions = submodule.permission
    return permissions && Object.values(permissions).every((value) => value === true)
  })
}

function isOdd(number: number) {
  return number % 2 !== 0
}

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload.data.records
    },
    setInitialRoles: (state) => {
      state.roleModules = []
      state.role = null
      state.roles = []
    },
    selectAllModule: (state, action) => {
      const { idx, type, value } = action.payload
      state.roleModules[idx].selectAll = value
      state.roleModules[idx].submodules = state.roleModules[idx].submodules.map((item) => ({
        ...item,
        permission:
          type === CONSTANTS.ROLE_EDITOR_MODULE_KEYS.BLITZ_MODULE
            ? { canAccess: value }
            : type === CONSTANTS.ROLE_EDITOR_MODULE_KEYS.META_CATEGORIES ||
              type === CONSTANTS.ROLE_EDITOR_MODULE_KEYS.META_MASTER
            ? { canRead: value, canWrite: value }
            : {
                canSkipValidation: false,
                canStateTransition: value,
              },
      }))
    },
    selectHandleModule: (state, action) => {
      const {
        idx,
        subIdx,
        value,
        key,
      }: {
        idx: number
        subIdx: number
        value: boolean
        key: roleModulesCheckboxKeys
      } = action.payload

      if (!(key === 'canSkipValidation' || key === 'canStateTransition')) {
        state.roleModules[idx].submodules[subIdx].permission[key] = value
      } else {
        if (key === 'canSkipValidation') {
          state.roleModules[idx].submodules[subIdx].permission.canSkipValidation = value
          state.roleModules[idx].submodules[subIdx].permission.canStateTransition = false
        } else {
          state.roleModules[idx].submodules[subIdx].permission.canSkipValidation = false
          state.roleModules[idx].submodules[subIdx].permission.canStateTransition = value
        }
      }

      state.roleModules[idx].selectAll = checkAllModules(idx, state.roleModules)
    },
    setRoleModules: (state, action) => {
      if (action?.payload?.data?.length) {
        state.roleModules = action.payload.data.map((item: roleModulesModal) => ({
          ...item,
          selectAll: false,
        }))
      }
      state.roleModules.forEach((permission, index) => {
        if (isOdd(permission.submodules.length)) {
          state.roleModules[index].submodules.push({
            key: '',
            displayName: '',
            permission: { canAccess: false },
          })
        }
      })
    },
    setRole: (state, action) => {
      const { data } = action.payload
      if (!data.error) {
        state.role = data
        state.roleModules = data.permissions.map((item: roleModulesModal, idx: number) => ({
          ...item,
          selectAll: checkAllModules(idx, data.permissions),
        }))

        state.roleModules.forEach((permission, index) => {
          if (isOdd(permission.submodules.length)) {
            state.roleModules[index].submodules.push({
              key: '',
              displayName: '',
              permission: { canAccess: false },
            })
          }
        })
      }
    },
  },
})

export const {
  selectAllModule,
  selectHandleModule,
  setRole,
  setRoleModules,
  setInitialRoles,
  setRoles,
} = roleSlice.actions

export const createRoleService = createAsyncThunk(
  'role/createRole',
  async (payload: createRoleReqData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: roleCRUDAPIDefinitionModal = ROLE_MANAGEMENT_CRUD.CREATE_ROLE(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const deleteRole = createAsyncThunk('role/deleteRole', async (id: string, { dispatch }) => {
  dispatch(setLoader(true))
  const requestedData: roleCRUDAPIDefinitionModal = ROLE_MANAGEMENT_CRUD.DELETE_ROLE(id)
  const data = await mainApiService(requestedData)
  dispatch(setLoader(false))

  return data
})

export const viewRoleService = createAsyncThunk(
  'role/viewRole',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal = ROLE_MANAGEMENT_CRUD.VIEW_ROLE(id)
    const result = await mainApiService(requestedData)
    dispatch(setRole(result))
    dispatch(setLoader(false))
    return result
  },
)

export const getPermissions = createAsyncThunk(
  'role/getPermissions',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: viewRoleAPIDefinationModal = ROLE_MANAGEMENT_CRUD.PERMISSIONS_LIST()
    const result = await mainApiService(requestedData)
    dispatch(setRoleModules(result))

    dispatch(setLoader(false))
  },
)

export const editRoleService = createAsyncThunk(
  'role/editRole',
  async (payload: createRoleReqData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: roleCRUDAPIDefinitionModal = ROLE_MANAGEMENT_CRUD.EDIT_ROLE(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)

export const changeStatus = createAsyncThunk(
  'role/changeStatus',
  async (data: { roleId: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: roleStatusChangeDefinitionModal = ROLE_MANAGEMENT_CRUD.CHANGE_STATUS(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)

export const getRolesService = createAsyncThunk('role/getRoles', async (payload, { dispatch }) => {
  dispatch(setLoader(true))
  const requestedData: viewRoleAPIDefinationModal = ROLE_MANAGEMENT_CRUD.GET_ROLES()
  const result = await mainApiService(requestedData)
  result.data.records.push({
    roleName: 'None',
    _id: 'none',
    status: '',
  })
  dispatch(setRoles(result))
  dispatch(setLoader(false))
})

export type createRoleServiceType = typeof createRoleService

const roleSelector = (state: { role: roleStateModal }) => state.role

export const selectRoleModules = createSelector(roleSelector, (state) => state.roleModules)
export const selectRole = createSelector(roleSelector, (state) => state.role)
export const selectRoles = createSelector(roleSelector, (state) => state.roles)

export default roleSlice
