const WatchPlusIcon = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_22985_48231)'>
        <path
          d='M7.49964 6.00007H6.49964V5.00007C6.49964 4.72507 6.27464 4.50007 5.99964 4.50007C5.72464 4.50007 5.49964 4.72507 5.49964 5.00007V6.00007H4.49964C4.22464 6.00007 3.99964 6.22507 3.99964 6.50007C3.99964 6.77507 4.22464 7.00007 4.49964 7.00007H5.49964V8.00007C5.49964 8.27507 5.72464 8.50007 5.99964 8.50007C6.27464 8.50007 6.49964 8.27507 6.49964 8.00007V7.00007H7.49964C7.77464 7.00007 7.99964 6.77507 7.99964 6.50007C7.99964 6.22507 7.77464 6.00007 7.49964 6.00007ZM10.5896 2.50507L9.04964 1.22507C8.83964 1.05007 8.52464 1.07507 8.34464 1.29007C8.16964 1.50007 8.19964 1.81507 8.40964 1.99507L9.94464 3.27507C10.1546 3.45007 10.4696 3.42507 10.6496 3.21007C10.8296 3.00007 10.7996 2.68507 10.5896 2.50507ZM2.04964 3.27507L3.58464 1.99507C3.79964 1.81507 3.82964 1.50007 3.64964 1.29007C3.47464 1.07507 3.15964 1.05007 2.94964 1.22507L1.40964 2.50507C1.19964 2.68507 1.16964 3.00007 1.34964 3.21007C1.52464 3.42507 1.83964 3.45007 2.04964 3.27507ZM5.99964 2.00007C3.51464 2.00007 1.49964 4.01507 1.49964 6.50007C1.49964 8.98507 3.51464 11.0001 5.99964 11.0001C8.48464 11.0001 10.4996 8.98507 10.4996 6.50007C10.4996 4.01507 8.48464 2.00007 5.99964 2.00007ZM5.99964 10.0001C4.06964 10.0001 2.49964 8.43007 2.49964 6.50007C2.49964 4.57007 4.06964 3.00007 5.99964 3.00007C7.92964 3.00007 9.49964 4.57007 9.49964 6.50007C9.49964 8.43007 7.92964 10.0001 5.99964 10.0001Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_22985_48231'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WatchPlusIcon
