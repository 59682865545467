import CONSTANTS from '../../../../Constants'
import {
  checkIsPartnerContentItemFailed,
  checkIsPartnerParentsFailed,
} from '../../../../Helpers/CommonListingHelper'
import {
  getRowDetailById,
  setcontentIngestionRetrigger,
} from '../../../../Store/Slice/reports.slice'
import { EyeIcon } from '../../../Icons/Bulb'
import ChipButton from '../../ChipButton'

const commonListingReportActionBtn = (dispatch: any, item: any, label: string, rowsData: any) => {
  return (
    <div className='d-flex'>
      {item?.blitzContentVersionId &&
        item?.requestedObj?.assetCategory &&
        item?.requestedObj?.assetType &&
        item?.requestedObj?.contentCategory && (
          <div
            onClick={() => {
              window.open(
                `/asset/view/${item?.blitzContentVersionId}/${item.requestedObj.assetType}/${item.requestedObj.contentCategory}/${item.requestedObj.assetCategory}/${CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY}`,
                '_blank',
              )
            }}
            className='cursor-pointer mr-1'
            data-testid='edit__chip-btn'
            style={{ width: 'fit-content' }}
          >
            <ChipButton type='primary' style={{ gap: 0 }} label={''} Icon={EyeIcon as any} />
          </div>
        )}

      {(checkIsPartnerContentItemFailed(item) || checkIsPartnerParentsFailed(item, rowsData)) && (
        <div
          onClick={() => {
            if (label === 'Details') {
              dispatch(getRowDetailById(item?._id) as any)
            }
            if (label === 'Re-trigger') {
              dispatch(
                setcontentIngestionRetrigger({
                  show: true,
                  data: item || {},
                }) as any,
              )
            }
          }}
          className='cursor-pointer'
          data-testid='edit__chip-btn'
          style={{ width: 'fit-content' }}
        >
          <ChipButton type='primary' label={label} />
        </div>
      )}

      {!(
        item?.blitzContentVersionId &&
        item?.requestedObj?.assetCategory &&
        item?.requestedObj?.assetType &&
        item?.requestedObj?.contentCategory
      ) &&
        !checkIsPartnerContentItemFailed(item) &&
        !checkIsPartnerParentsFailed(item, rowsData) && <>NA</>}
    </div>
  )
}

export default commonListingReportActionBtn
