import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AUDIT_LOGS,
  SYSTEM_CONFIG_APIS,
  USER_MANAGEMENT_CRUD,
} from '../../Constants/ApiDefinations'
import { AuditLogsState } from '../../Models/AuditLogsModal'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

const initialState: AuditLogsState = {
  auditLogsList: [],
  auditLogsCategory: [],
  userList: [],
}

const auditLogsSlice = createSlice({
  name: 'auditLogs',
  initialState,
  reducers: {
    setAuditLogsList: (state, action: PayloadAction<any[]>) => {
      state.auditLogsList = action.payload
    },
    setAuditLogsCategory: (state, action: PayloadAction<any[]>) => {
      state.auditLogsCategory = action.payload
    },
    setUserList: (state, action: PayloadAction<any[]>) => {
      state.userList = action.payload
    },
  },
})

export const { setAuditLogsList, setAuditLogsCategory, setUserList } = auditLogsSlice.actions

export const getAuditLogsData = createAsyncThunk(
  'auditLogs/getAuditLogsData',
  async (data: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = AUDIT_LOGS.LIST_USER_ROLE_LOG(data)
    try {
      const response = await mainApiService(requestedData)
      const records = response?.data || []
      dispatch(setAuditLogsList(records))
      dispatch(setLoader(false))
      return records
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
  },
)
export const getAuditLogsCategoryData = createAsyncThunk(
  'auditLogs/getAuditLogsCategoryData',
  async (data: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = SYSTEM_CONFIG_APIS.LIST_MASTERS(data)
    try {
      const response = await mainApiService(requestedData)
      const records = response?.data || []
      dispatch(setAuditLogsCategory(records))
      dispatch(setLoader(false))
      return records
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
  },
)

export const getUserListData = createAsyncThunk(
  'auditLogs/getUserListData',
  async (data: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = USER_MANAGEMENT_CRUD.LIST_USER(data)
    try {
      const response = await mainApiService(requestedData)
      const records = response?.data || []
      dispatch(setUserList(records))
      dispatch(setLoader(false))
      return records
    } catch (error) {
      dispatch(setLoader(false))
      throw error
    }
  },
)

const auditLogsSelector = (state: { auditLogs: AuditLogsState }) => state.auditLogs

export const selectAuditLogsList = createSelector(auditLogsSelector, (state) => state.auditLogsList)
export const selectAuditLogsCategory = createSelector(
  auditLogsSelector,
  (state) => state.auditLogsCategory,
)
export const selectUserList = createSelector(auditLogsSelector, (state) => state.userList)

export default auditLogsSlice
