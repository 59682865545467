import { Button, Grid } from '@mui/material'
import EditIcon from '../../Assets/Images/svgs/edit'

import axios from 'axios'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CONSTANTS from '../../Constants'
import { BULK_UPDATE, BULK_UPDATE_APIS } from '../../Constants/ApiDefinations'
import { logger } from '../../Helpers'
import { fileValidation, removeSubAttributes, updateJobService } from '../../Helpers/BulkUpload'
import { combineErrorMessage } from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import { setLoader } from '../../Store/Slice/loader.slice'
import DownloadIcon from '../Icons/DownloadIcon2'
import { ChipButton, MUIBox, MUIButton } from '../Shared'
import CSVSelector from '../Shared/CSVSelector'
import CSVProgress from './CSVProgress'
import ScheduleBulkUpdate from './ScheduleBulkUpdate'

interface BulkUpdateEditorProps {
  openCloseDrawer: (value: boolean) => void
  jobDetails: TypeObject
  handleDialog: any
  handleCloseDialog: any
  refresh: any
}

const BulkUpdateEditor: React.FC<BulkUpdateEditorProps> = ({
  openCloseDrawer,
  jobDetails,
  handleDialog,
  handleCloseDialog,
  refresh,
}: BulkUpdateEditorProps) => {
  const [showScheduleBulkUpdate, setShowScheduleBulkUpdate] = useState<boolean>(false)
  const [csvInfo, setCsvInfo] = useState<any>({})
  const [uploadProgress, setUploadProgress] = useState<any>(0)
  const [pathUrl, setPathUrl] = useState('')
  const [uploadController, setUploadController] = useState<any>(null)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { pageType, id } = useParams<{ pageType: string; id: string }>()

  const handleCancelUpload = () => {
    uploadController?.abort()
    setCsvInfo({})
    setUploadProgress(0)
    setPathUrl('')
    const changeInput: any = document?.getElementById('react-csv-reader-input')
    if (changeInput) {
      changeInput.files = null
      changeInput.value = null
    }
  }

  const downloadTemplate = async () => {
    dispatch(setLoader(true))

    const attributes = removeSubAttributes(jobDetails?.attributes)

    const reqData: any = BULK_UPDATE_APIS.DOWNLOD_CSV_DOWNLOAD({
      attributes: attributes,
    })

    const res: any = await mainApiService(reqData)

    if (res?.error) {
      dispatch(showAlert([true, combineErrorMessage(res), 'error']))
      dispatch(setLoader(false))
      return
    }

    const blob = new Blob([res], { type: 'text/csv' })

    const url: any = URL.createObjectURL(blob)
    const link: any = document.createElement('a')
    link.href = url
    link.setAttribute('download', `BulkUpdateTemplate_${jobDetails?.jobName}`)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
    if (URL?.revokeObjectURL) URL.revokeObjectURL(url)
    dispatch(setLoader(false))
    dispatch(showAlert([true, 'Template downloaded successfully', 'success']))
  }

  const uploadCsv = async (presignedUrl: string, key: string, originalFile: File) => {
    if (uploadController) {
      uploadController.abort()
    }
    const controller = new AbortController()
    setUploadController(controller)

    try {
      await axios.put(presignedUrl, originalFile, {
        signal: controller.signal,
        headers: {
          upload: true,
          'Content-Type': 'text/csv',
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setUploadProgress(percentCompleted)
          if (percentCompleted === 100) {
            setCsvInfo((prev: any) => ({ ...prev, status: CONSTANTS.FILE_STATUS.SUCCESS }))
          }
        },
      })
      const filePathUrl = `/bulk_update/${id}/${key}`
      setPathUrl(filePathUrl)
      return filePathUrl
    } catch (error) {
      logger.error(error)
    }
  }

  const handleChange = async (csvData: any, fileInfo: any, originalFile: any) => {
    handleCancelUpload()
    const validFile = await fileValidation(
      csvData,
      jobDetails,
      fileInfo,
      handleDialog,
      handleCloseDialog,
    )

    if (!validFile) {
      return
    }

    const fileKey = `${id}_${new Date().getTime()}_${fileInfo.name}`
    const reqDataPresignedUrl = BULK_UPDATE.PRESIGNED_URL_UPLOAD_CSV({
      fileName: fileKey,
      jobId: id,
    })

    const resultPresignedUrl: any = await mainApiService(reqDataPresignedUrl)

    if (resultPresignedUrl?.error) {
      dispatch(showAlert([true, combineErrorMessage(resultPresignedUrl), 'error']))
      return
    }

    const assetCount =
      csvData?.filter((item: TypeObject) => item[CONSTANTS.BULK_UPDATE_ATTRIBUTE_LABEL.BLITZ_ID])
        ?.length ?? 0

    setCsvInfo({
      csvData,
      fileInfo,
      originalFile,
      status: CONSTANTS.FILE_STATUS.IN_PROGRESS,
      assetCount,
    })

    await uploadCsv(resultPresignedUrl?.data?.url, fileKey, originalFile)
  }

  const updateJob = async () => {
    const requestedData: TypeObject = {
      jobId: id,
      filePath: pathUrl,
    }

    if (csvInfo?.assetCount) requestedData.assetCount = csvInfo.assetCount

    const result = await updateJobService(requestedData)
    if (result && pageType === CONSTANTS.PAGE_TYPE.EDIT) {
      navigate('/bulk-update/view/' + id)
    }
    handleCloseDialog()
    refresh()
  }

  return (
    <MUIBox>
      <div>
        <div className='heading-6 d-flex text-left rolebox__header-padding'>
          <div>{jobDetails?.jobName}</div>
          <div className='ml-auto d-flex'>
            <div className='ml-auto'>
              <Button
                data-testid='edit__chip-btn'
                variant='outlined'
                startIcon={<EditIcon />}
                onClick={() => openCloseDrawer(true)}
                sx={{ textTransform: 'capitalize', marginLeft: '20px', height: '26px' }}
              >
                Edit
              </Button>
            </div>
            <div
              className='ml-auto ml-1'
              data-testid={'download__template_id'}
              onClick={downloadTemplate}
            >
              <ChipButton type='primary' Icon={DownloadIcon as any} label='Download Template' />
            </div>
          </div>
        </div>
        <div className={'hr bulk__update'}></div>
        <div className='d-flex text-left rolebox__header-padding'>
          <Grid container>
            <Grid item xs={12} sm={3} className='col34 paragraph-regular-5'>
              Attribute
            </Grid>
            <Grid item xs={12} sm={9} className='col36 sub-heading-6'>
              {(() => jobDetails?.attributes?.map((attr: TypeObject) => attr.label).join(', '))()}
            </Grid>
          </Grid>
        </div>
        <div className={'hr bulk__update'}></div>
        <div className='rolebox__header-padding'>
          <Grid container direction='row' justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12} sm={12} md={9} className=''>
              <div className='mt-1'>
                <CSVSelector disabled={uploadProgress > 0} handleChange={handleChange} />
              </div>
            </Grid>
            {csvInfo?.originalFile && (
              <Grid item xs={12} sm={12} md={9}>
                <CSVProgress
                  status={csvInfo?.status}
                  file={csvInfo?.originalFile}
                  fileName={csvInfo?.fileInfo?.name}
                  fileSize={(csvInfo?.fileInfo?.size / (1024 * 1024)).toFixed(4)}
                  progress={uploadProgress}
                  handleCancelUpload={handleCancelUpload}
                />
              </Grid>
            )}
          </Grid>
        </div>
        {showScheduleBulkUpdate ? (
          <ScheduleBulkUpdate
            id={id as string}
            jobDetails={jobDetails}
            pathUrl={pathUrl}
            pageType={pageType as string}
            handleShow={(value: boolean) => setShowScheduleBulkUpdate(value)}
            handleCloseDialog={handleCloseDialog}
            handleDialog={handleDialog}
            refresh={refresh}
            assetCount={csvInfo?.assetCount}
          />
        ) : (
          <div className='rolebox__header-padding mt-1 mb-1'>
            <Grid container spacing={4}>
              <Grid item xs={6} className=''>
                <MUIButton
                  width={'100%'}
                  type='button'
                  label='Update Now'
                  variant='contained'
                  color='primary'
                  height='50px'
                  handleClickCB={() =>
                    handleDialog(
                      CONSTANTS.BULK_UPDATE_CONFIRM_DIALOG_HEAIDNG,
                      CONSTANTS.BULK_UPDATE_CONFIRM_DIALOG_DESC,
                      updateJob,
                      handleCloseDialog,
                    )
                  }
                  disabled={!pathUrl}
                />
              </Grid>
              <Grid item xs={6} className=''>
                <MUIButton
                  width={'100%'}
                  type='button'
                  label='Update Later'
                  handleClickCB={() => setShowScheduleBulkUpdate(true)}
                  variant='outlined'
                  disabled={!pathUrl}
                  color='primary'
                  height='50px'
                />
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </MUIBox>
  )
}

export default BulkUpdateEditor
