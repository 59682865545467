import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { MUIButton, MUIDialog, ModalIcon } from '../'

export interface ConfirmDialogProps {
  open: boolean
  title: string
  description: string
  icon?: string
  btn1Label: string
  btn1Color?: 'primary' | 'secondary' | 'warning' | 'error'
  btn1Variant?: 'contained' | 'outlined'
  btn2Label: string
  btn2Color?: 'primary' | 'secondary' | 'warning' | 'error'
  btn2Variant?: 'contained' | 'outlined'
  btn2Disabled?: boolean
  onCloseDialog?: () => void
  onConfirm: () => void
  onClose: () => void
  extraUI?: any
  width?: any
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  title,
  description,
  icon,
  btn1Label,
  btn1Color = 'secondary',
  btn1Variant = 'outlined',
  btn2Label,
  btn2Color = 'primary',
  btn2Variant = 'contained',
  btn2Disabled = false,
  onCloseDialog,
  onConfirm,
  onClose,
  extraUI,
  width = '354px',
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }
    if (onCloseDialog) {
      onCloseDialog()
    }
  }

  const handleClose = () => {
    if (onCloseDialog) {
      onCloseDialog()
    }
    if (onClose) {
      onClose()
    }
  }
  return (
    <MUIDialog open={open} handleClose={handleClose}>
      <div className='c-modal' style={{ width, minWidth: '100%' }}>
        {icon && (
          <DialogTitle>
            <ModalIcon IconName={icon} />
          </DialogTitle>
        )}
        <DialogContent>
          {(title || description) && (
            <DialogContentText component={'div'} id='alert-dialog-slide-description'>
              <div className='col2 parag pt-1raph-bold-3 '>{title}</div>
              <div className='col34 paragraph-regular-5'>
                {description}
                <span className='3E3F40 paragraph-bold-5 '></span>
              </div>
            </DialogContentText>
          )}
          {extraUI}
        </DialogContent>
        <DialogActions>
          <div className={`d-flex flex-wrap w-100 p-0 ${btn2Label && btn1Label && 'dialog-btns'} `}>
            {btn1Label && (
              <div className={`cancel-action-btn ${!btn2Label && 'w-100'}`}>
                <MUIButton
                  variant={btn1Variant}
                  size='large'
                  color={btn1Color}
                  type='button'
                  label={btn1Label}
                  width={'100%'}
                  handleClickCB={handleClose}
                />
              </div>
            )}
            {btn2Label && (
              <div className={`accept-action-btn ${!btn1Label && 'w-100'}`}>
                <MUIButton
                  disabled={btn2Disabled}
                  variant={btn2Variant}
                  size='large'
                  color={btn2Color}
                  type='button'
                  label={btn2Label}
                  width={'100%'}
                  handleClickCB={handleConfirm}
                />
              </div>
            )}
          </div>
        </DialogActions>
      </div>
    </MUIDialog>
  )
}

export default ConfirmDialog
