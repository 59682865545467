export const dayOption = [
  { key: 'Sun', value: 'sun' },
  { key: 'Mon', value: 'mon' },
  { key: 'Tue', value: 'tue' },
  { key: 'Wed', value: 'wed' },
  { key: 'Thu', value: 'thu' },
  { key: 'Fri', value: 'fri' },
  { key: 'Sat', value: 'sat' },
]
export const CountryOption = [
  { key: 'India', value: 'India' },
  { key: 'Pakistan', value: 'Pakistan' },
  { key: 'USA', value: 'USA' },
  { key: 'Australia', value: 'Australia' },
]
