import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../../Constants'
import { MASTERS_MANAGEMENT } from '../../../../Constants/ApiDefinations'
import { getPageTitle, objectToQueryString } from '../../../../Helpers/Util'
import updateTagMetaData from '../../../../Helpers/updateTagMetaData'
import {
  BreadcrumsModel,
  DownstreamPlatformsListApiResponse,
  DownstreamPlatformsListResponse,
  DownstreamPlatformsListingQueryParams,
  DownstreamPlatformsPropsType,
  ViewMastersAPIDefinition,
} from '../../../../Models'
import mainApiService from '../../../../Services'
import { useAppDispatch, useAppSelector } from '../../../../Store/Hooks'
import {
  selectDownstreamPlatformData,
  setDownstreamPlatformsData,
} from '../../../../Store/Slice/downstreamPlatforms.slice'
import MastersEditorLayout from '../Layouts/MastersEditorLayout'
import DownstreamPlatformsCreateEdit from './DownstreamPlatformsCreateEdit'

const DOWNSTREAM_PLATFORMS_TEXT = 'Downstream Platform'
const DownstreamPlatformsEditor = ({
  mastersPageType,
  mastersId,
}: DownstreamPlatformsPropsType) => {
  const selectDownstreamPlatform = useAppSelector(selectDownstreamPlatformData)
  console.log(selectDownstreamPlatform, 'selectDownstreamPlatform')
  const dispatch = useAppDispatch()
  const selectedDownstreamPlatform: DownstreamPlatformsListResponse | null =
    mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE ? selectDownstreamPlatform : null
  const navigate = useNavigate()
  useEffect(() => {
    if (
      mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE &&
      (!selectedDownstreamPlatform?._id || selectedDownstreamPlatform?._id !== mastersId)
    ) {
      ;(async () => {
        // Call list API
        const listData = await getDownstreamPlatformsListingFunction({ page: 1, limit: 1000000 })
        // find selected record based on id
        if (mastersId) {
          const selectedDownstreamPlatformData = listData.find((item) => item?._id === mastersId)
          if (!selectedDownstreamPlatformData?._id) {
            // navigate to list
            navigate(
              `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.DownstreamPlatforms}`,
            )
          } else {
            dispatch(setDownstreamPlatformsData(selectedDownstreamPlatformData))
          }
        }
      })()
    }
  }, [])

  const getDownstreamPlatformsListingFunction = async (
    options: DownstreamPlatformsListingQueryParams,
  ) => {
    const paramsData = objectToQueryString(options)
    const requestedData: ViewMastersAPIDefinition =
      MASTERS_MANAGEMENT.DOWNSTREAM_PLATFORMS_CRUD.LIST_DOWNSTREAM_PLATFORMS(paramsData)
    const result: any = await mainApiService(requestedData)
    const tempData: DownstreamPlatformsListResponse[] = []

    if (result?.responseCode === 200) {
      result?.data?.records?.forEach((ele: DownstreamPlatformsListApiResponse) => {
        const createdBy = ele?.createdBy?.userEmail
        const updatedBy = ele?.updatedBy?.userEmail
        tempData.push({
          ...ele,
          createdBy,
          updatedBy,
        })
      })
    }
    return tempData
  }

  const onClickBackNavigation = (e: { preventDefault: () => void }) => {
    e?.preventDefault()
    navigate(
      `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.DownstreamPlatforms}`,
    )
  }

  useEffect(() => {
    updateTagMetaData({ title: `Manage Masters: ${DOWNSTREAM_PLATFORMS_TEXT}` })
    return () => {
      updateTagMetaData({})
    }
  }, [])
  return (
    <MastersEditorLayout
      mastersPageType={mastersPageType}
      pageText={DOWNSTREAM_PLATFORMS_TEXT}
      onClickBackNavigation={onClickBackNavigation}
      breadcrumbs={
        BREADCRUMS.CREATE_DOWNSTREAM_PLATFORMS(
          mastersPageType,
          getPageTitle(mastersPageType as string),
        ) as BreadcrumsModel[]
      }
    >
      {mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE && selectedDownstreamPlatform?._id && (
        <DownstreamPlatformsCreateEdit mastersPageType={mastersPageType} />
      )}
      {mastersPageType === CONSTANTS.PAGE_TYPE.CREATE && (
        <DownstreamPlatformsCreateEdit mastersPageType={mastersPageType} />
      )}
    </MastersEditorLayout>
  )
}

export default React.memo(DownstreamPlatformsEditor)
