import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../../Constants'
import { MASTERS_MANAGEMENT } from '../../../../Constants/ApiDefinations'
import { getPageTitle, objectToQueryString } from '../../../../Helpers/Util'
import updateTagMetaData from '../../../../Helpers/updateTagMetaData'
import {
  BreadcrumsModel,
  EntitlementPackagesListApiResponse,
  EntitlementPackagesListResponse,
  EntitlementPackagesListingQueryParams,
  EntitlementPackagesPropsType,
  ViewMastersAPIDefinition,
} from '../../../../Models'
import mainApiService from '../../../../Services'
import { useAppDispatch, useAppSelector } from '../../../../Store/Hooks'
import {
  selectEntitlementPackageData,
  setEntitlementPackagesData,
} from '../../../../Store/Slice/entitlementPackages.slice'
import MastersEditorLayout from '../Layouts/MastersEditorLayout'
import EntitlementPackagesCreateEdit from './EntitlementPackagesCreateEdit'

const ENTITLEMENT_PACKAGES_TEXT = 'Entitlement Package'
const EntitlementPackagesEditor = ({
  mastersPageType,
  mastersId,
}: EntitlementPackagesPropsType) => {
  const dispatch = useAppDispatch()
  const selectEntitlementPackage = useAppSelector(selectEntitlementPackageData)
  const selectedEntitlementPackage: EntitlementPackagesListResponse | null =
    mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE ? selectEntitlementPackage : null
  const navigate = useNavigate()
  useEffect(() => {
    if (
      mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE &&
      (!selectedEntitlementPackage?._id || selectedEntitlementPackage?._id !== mastersId)
    ) {
      ;(async () => {
        // Call list API
        const listData = await getEntitlementPackagesListingFunction({ page: 1, limit: 1000000 })
        // find selected record based on id
        console.log(mastersId, 'mastersId')
        if (mastersId) {
          const selectedEntitlementPackageData = listData.find((item) => item?._id === mastersId)
          if (!selectedEntitlementPackageData?._id) {
            // navigate to list
            navigate(
              `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.EntitlementPackages}`,
            )
          } else {
            dispatch(setEntitlementPackagesData(selectedEntitlementPackageData))
          }
        }
      })()
    }
  }, [])

  const getEntitlementPackagesListingFunction = async (
    options: EntitlementPackagesListingQueryParams,
  ) => {
    const paramsData = objectToQueryString(options)
    const requestedData: ViewMastersAPIDefinition =
      MASTERS_MANAGEMENT.ENTITLEMENT_PACKAGES_CRUD.LIST_ENTITLEMENT_PACKAGES(paramsData)
    const result: any = await mainApiService(requestedData)
    const tempData: EntitlementPackagesListResponse[] = []

    if (result?.responseCode === 200) {
      result?.data?.records?.forEach((ele: EntitlementPackagesListApiResponse) => {
        const createdBy = ele?.createdBy?.userEmail
        const updatedBy = ele?.updatedBy?.userEmail
        tempData.push({
          ...ele,
          createdBy,
          updatedBy,
        })
      })
    }
    return tempData
  }

  const onClickBackNavigation = (e: { preventDefault: () => void }) => {
    e?.preventDefault()
    navigate(
      `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.EntitlementPackages}`,
    )
  }

  useEffect(() => {
    updateTagMetaData({ title: `Manage Masters: ${ENTITLEMENT_PACKAGES_TEXT}` })
    return () => {
      updateTagMetaData({})
    }
  }, [])
  return (
    <MastersEditorLayout
      mastersPageType={mastersPageType}
      pageText={ENTITLEMENT_PACKAGES_TEXT}
      onClickBackNavigation={onClickBackNavigation}
      breadcrumbs={
        BREADCRUMS.CREATE_ENTITLEMENT_PACKAGES(
          mastersPageType,
          getPageTitle(mastersPageType as string),
        ) as BreadcrumsModel[]
      }
    >
      {mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE && selectedEntitlementPackage?._id && (
        <EntitlementPackagesCreateEdit mastersPageType={mastersPageType} />
      )}
      {mastersPageType === CONSTANTS.PAGE_TYPE.CREATE && (
        <EntitlementPackagesCreateEdit mastersPageType={mastersPageType} />
      )}
    </MastersEditorLayout>
  )
}

export default React.memo(EntitlementPackagesEditor)
