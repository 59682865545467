const RecentActivityHeaderIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='12' fill='#ECE9F2' />
      <circle cx='15' cy='15' r='3' fill='#24BFF3' />
      <path
        d='M9.37508 7.6276V6.46094M14.6251 7.6276V6.46094M16.9584 10.2526V9.3776C16.9584 8.41111 16.1749 7.6276 15.2084 7.6276H8.79175C7.82525 7.6276 7.04175 8.41111 7.04175 9.3776V15.2109C7.04175 16.1774 7.82525 16.9609 8.79175 16.9609H10.2501M14.9167 13.6068V14.919L15.9376 15.9401M13.6894 11.9559C15.3262 11.2779 17.202 12.0554 17.8801 13.6921C18.5581 15.3289 17.7806 17.2046 16.1438 17.8826C14.507 18.5606 12.6312 17.7831 11.9532 16.1464C11.2757 14.5102 12.0527 12.6339 13.6894 11.9559Z'
        stroke='#402080'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default RecentActivityHeaderIcon
