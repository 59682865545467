import { MoreVertOutlined, Search, SortByAlpha } from '@mui/icons-material'
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { getSubstring, replaceUnwanted } from '../../../Helpers/Util'
import useDebounce from '../../../Hooks/useDebounce'
import { UserMuiTableModal } from '../../../Models/ComponentModel'
import { BackButton, MUIButton, MUIGrid, MUIPagination, SimpleDropdown } from '../../Shared'
import { DescendingIcon } from '../../Shared/MUI-Table/MUI-Table.svgicons'

const ListingComponent: React.FC<UserMuiTableModal> = ({
  showTable = true,
  columns,
  tableRows = [],
  tableTitle,
  headerBtnLable = 'Add new',
  headerBtnVarient = 'contained',
  createButtonLable = 'Create',
  createButtonClick,
  searchFunction,
  handleRowClick,
  paginationOption = [],
  dataCount = 0,
  searchPlaceholder = 'Search',
  showCreateButton = true,
  showSearchField = true,
  isRowClickable = true,
  isCellClickable = true,
  showBackButton = false,
  handelBackButton,
  showHeaderInfo = [],
}) => {
  const countryList = useSelector((state: any) => state.geoPolicy.country)

  const [pageData, setPageData] = useState({
    pageCount: 10,
    pageNumber: 1,
  })
  const [colData, setColData] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const [anchorEl, setanchorEl] = useState(null)
  const [searchInput, setsearchInput] = useState<string>('')
  const [filterValue] = useState<{
    filterBY: string
    filterText: string
  }>({ filterBY: '', filterText: '' })
  const [mounted, setmounted] = useState(false)
  const [selectedId, setselectedId] = useState<number | string>('')
  const [selectedCol, setSelectedCol] = useState({
    selectedCol: '',
    selectedOptionType: 0,
  })
  const { state } = useLocation()

  const handleSortIconClick = (event: React.MouseEvent<HTMLSpanElement>, colName: string) => {
    event.preventDefault()
    if (selectedCol.selectedCol !== colName) {
      setColData((pre) => ({
        ...pre,
        selectedCol: colName as string,
        selectedOptionType: 0,
      }))
      setanchorEl(event.currentTarget as any)
    } else {
      setColData(() => ({
        ...selectedCol,
        selectedCol: colName as string,
      }))
      setanchorEl(event.currentTarget as any)
    }
  }

  const onMenuClose = (clearCol = false) => {
    setanchorEl(null)
    if (clearCol) {
      setColData({
        selectedCol: '',
        selectedOptionType: 0,
      })
    }
  }

  const tablePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    event.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: page }))
  }

  const handlePerPageView = (e: any) => {
    e.preventDefault()
    setPageData((pre) => ({ ...pre, pageNumber: 1, pageCount: e.target.value }))
  }

  const searchValue = useDebounce(searchInput, 1000)

  useEffect(() => {
    if (searchFunction && anchorEl === null) {
      searchFunction(
        searchValue,
        selectedCol.selectedOptionType,
        selectedCol.selectedCol,
        filterValue,
        1,
        pageData.pageCount,
      )
      setPageData((pre) => ({ ...pre, pageNumber: 1 }))
    }
  }, [searchValue, selectedCol, filterValue, pageData.pageCount, countryList])

  useEffect(() => {
    if (searchFunction && mounted) {
      searchFunction(
        searchValue,
        selectedCol.selectedOptionType,
        selectedCol.selectedCol,
        filterValue,
        pageData.pageNumber,
        pageData.pageCount,
      )
    }
    setmounted(true)
  }, [pageData.pageNumber])

  const handleClearSort = () => {
    setanchorEl(null)
    setColData({
      ...colData,

      selectedOptionType: 0,
    })
    setanchorEl(null)
    onMenuClose()
    setSelectedCol({
      ...colData,
      selectedOptionType: 0,
    })
  }

  return (
    <MUIGrid
      className='interFont'
      style={{
        width: '100%',
        backgroundColor: '#f5f4f4',
        padding: '20px 24px',
      }}
    >
      <Grid className='interFont c-table'>
        <MUIGrid container className='d-flex justify-content-between align-items-center'>
          <MUIGrid item md={6} lg={6}>
            {showBackButton && (
              <BackButton
                label={`${tableTitle}`}
                className='col73'
                handleOnClick={handelBackButton}
              />
            )}
            {!showBackButton && tableTitle && <p className='listing__title'>{tableTitle}</p>}
          </MUIGrid>

          <MUIGrid item md={6} lg={6}>
            <MUIGrid container className='d-flex justify-content-end align-items-center'>
              <MUIGrid display='flex' direction='row' gap={3}>
                {showSearchField && (
                  <TextField
                    className='c-input listing-search'
                    id='outlined-search'
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setsearchInput(e.target.value)
                    }}
                    placeholder={searchPlaceholder}
                    inputProps={{
                      'data-testid': replaceUnwanted(searchPlaceholder),
                    }}
                    InputProps={{
                      id: 'searchId',
                      startAdornment: (
                        <>
                          <InputAdornment position='start'>
                            <Search style={{ color: '#B1B4B6' }} />
                          </InputAdornment>
                        </>
                      ),
                    }}
                    type='search'
                  />
                )}
                {showCreateButton && showTable && (
                  <MUIButton
                    id={replaceUnwanted(
                      headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable,
                    )}
                    className='create__btn'
                    variant={headerBtnVarient}
                    label={headerBtnLable === 'Add new' ? createButtonLable : headerBtnLable}
                    type='button'
                    data-testid='create-btn-click'
                    handleClickCB={createButtonClick}
                  />
                )}
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        {showHeaderInfo && (
          <>
            <MUIGrid container className='d-flex justify-content-between align-items-center'>
              {showHeaderInfo}
            </MUIGrid>
          </>
        )}
        {showTable ? (
          <TableContainer
            className='border-6 mt-2 shadow-table'
            style={{ backgroundColor: '#ffff' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {columns?.map((column: any, ind: any) => {
                    return (
                      <TableCell key={ind} align='center'>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'space-between',
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                        >
                          <div className='interFont'>{column.label}</div>
                          {column.sort && (
                            <div
                              data-testid='openfilter'
                              id='openFilter'
                              className='m-4 d-flex align-items-center cursor-pointer'
                              onClick={(event) => {
                                handleSortIconClick(event, column.id)
                              }}
                            >
                              <MoreVertOutlined
                                style={{
                                  fontSize: '18px',
                                  fill:
                                    column.id === selectedCol.selectedCol &&
                                    (selectedCol.selectedOptionType == 1 ||
                                      selectedCol.selectedOptionType == -1)
                                      ? '#402080'
                                      : '#b1b4b6',
                                }}
                              />
                            </div>
                          )}
                          {column.id === colData.selectedCol && (
                            <Menu
                              className='border-12 c-sort'
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => onMenuClose(true)}
                            >
                              <div className='d-flex justify-content-between align-items-center'>
                                <Typography>Sort By</Typography>
                                <span
                                  className={
                                    colData.selectedOptionType == 1 ||
                                    colData.selectedOptionType == -1
                                      ? 'clear-all'
                                      : 'clear-all-disabled'
                                  }
                                  data-testid='clear-all-sort'
                                  onClick={
                                    colData.selectedOptionType == 1 ||
                                    colData.selectedOptionType == -1
                                      ? handleClearSort
                                      : () => {
                                          return false
                                        }
                                  }
                                >
                                  Clear Filter
                                </span>
                              </div>
                              <RadioGroup
                                aria-label='options'
                                name='options'
                                value={colData.selectedOptionType}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setColData({
                                    ...colData,

                                    selectedOptionType: parseInt(event.target.value),
                                  })
                                  setanchorEl(null)
                                  onMenuClose()
                                  setSelectedCol({
                                    ...colData,
                                    selectedOptionType: parseInt(event.target.value),
                                  })
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  className='d-flex justify-content-between align-items-center'
                                  onClick={() => {
                                    onMenuClose()
                                  }}
                                >
                                  <FormControlLabel
                                    labelPlacement='start'
                                    value={1}
                                    label={
                                      <div className='d-flex w-100 align-item-center justify-content-center'>
                                        <div className='d-flex align-items-center'>
                                          <SortByAlpha style={{ fontSize: '18px' }} />
                                        </div>
                                        <div className='ml-8p'>
                                          <span className='font-12p'>Ascending</span>
                                        </div>
                                      </div>
                                    }
                                    control={<Radio className='ml-auto' />}
                                  />
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    paddingLeft: 0,
                                  }}
                                  className='d-flex justify-content-between align-items-center'
                                  onClick={() => {
                                    onMenuClose()
                                  }}
                                >
                                  <FormControlLabel
                                    labelPlacement='start'
                                    value={-1}
                                    label={
                                      <div className='d-flex w-100 align-item-center justify-content-center'>
                                        <div className='d-flex align-items-center'>
                                          <DescendingIcon />
                                        </div>
                                        <div className='ml-8p'>
                                          <span className='font-12p'>Descending</span>
                                        </div>
                                      </div>
                                    }
                                    control={<Radio />}
                                  />
                                </MenuItem>
                              </RadioGroup>
                            </Menu>
                          )}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableRows?.length > 0
                  ? tableRows?.map((row, ind) => {
                      // for masters URL is already available in CONSTANTS
                      const url: string = row?.url ?? ''
                      // for everything else passing from the parent component
                      const rowURL: string = row?.rowURL ?? url ?? ''
                      const rowState: string = row?.rowState ?? url ?? ''
                      return (
                        <TableRow
                          component={rowURL ? Link : TableRow}
                          to={rowURL ? rowURL : ''}
                          {...(rowURL && rowState
                            ? { state: JSON.parse(JSON.stringify(rowState)) }
                            : {})}
                          key={ind}
                          className={'table-link ' + (isRowClickable ? 'cursor - pointer' : '')}
                          onMouseEnter={() => {
                            setselectedId(ind)
                          }}
                          onMouseLeave={() => {
                            setselectedId('')
                          }}
                          onClick={() => {
                            if (handleRowClick) handleRowClick(row)
                          }}
                          data-testid='table-row-click'
                        >
                          {columns?.map((column, index) => {
                            const value: string | number | boolean | ReactNode = row[column.id]
                            return (
                              <TableCell
                                key={index}
                                className={selectedId === ind ? 'bg-col4' : ''}
                              >
                                <div
                                  className={`${
                                    isCellClickable ? 'cursor-pointer' : ''
                                  } paragraph-regular-6 interFont`}
                                  data-testid='tablerow'
                                  title={
                                    (typeof value === 'string' || Array?.isArray(value)) &&
                                    value?.length > 40
                                      ? value.toString()
                                      : ''
                                  }
                                >
                                  {typeof value === 'string' || Array?.isArray(value)
                                    ? getSubstring(value.toString(), 40)
                                    : value}
                                </div>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  : null}
              </TableBody>
            </Table>
            {!tableRows.length && (
              <Typography
                className='d-flex justify-content-center'
                style={{ padding: '50px' }}
                data-testid={`div_${replaceUnwanted(tableTitle)}_no_data_found`}
              >
                No Data Found
              </Typography>
            )}
          </TableContainer>
        ) : (
          <></>
        )}

        {showTable ? (
          <MUIGrid
            container
            className='d-flex align-items-center justify-content-between mt-2 c-pagination'
          >
            {dataCount > 0 && (
              <MUIGrid item direction='row' className='d-flex align-items-center col1'>
                <SimpleDropdown
                  selectId='pageId'
                  options={paginationOption}
                  handleChange={handlePerPageView}
                  value={pageData.pageCount}
                />

                <Typography paddingX={3}>
                  Showing {pageData.pageCount > dataCount ? dataCount : tableRows.length} of{' '}
                  {dataCount} records
                </Typography>
              </MUIGrid>
            )}
            <MUIGrid item>
              {tableRows.length && dataCount > 0 ? (
                <MUIPagination
                  testId='userPagination'
                  onChange={tablePaginationChange}
                  count={Math.ceil(dataCount / Number(pageData.pageCount))}
                  page={pageData.pageNumber}
                />
              ) : (
                <></>
              )}
            </MUIGrid>
          </MUIGrid>
        ) : (
          <></>
        )}
      </Grid>
    </MUIGrid>
  )
}

export default ListingComponent
