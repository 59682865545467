import { Box } from '@mui/material'
import AssetImageIcon from '../../Assets/Images/svgs/asset-image-icon'

/**
 * AssetImage Component
 *
 * Renders an image based on the provided imageUrl and optional imageClass.
 *
 * @component
 * @param imageUrl - The URL of the image as string.
 * @param imageClass='' - Optional CSS class for styling the image as string.
 * @returns {React.JSX.Element | null} JSX Element representing the AssetImage component, or null if imageUrl is not provided.
 */

const AssetImage = ({
  imageUrl,
  imageClass = '',
}: {
  imageUrl: string
  imageClass?: string
}): React.JSX.Element | null => {
  return (
    <div className='list_image'>
      {imageUrl ? (
        <img
          alt=''
          src={imageUrl}
          className={imageClass}
          data-testid='asset_list_no_image'
          style={{ width: '40px', height: '40px', display: 'flex', borderRadius: '4px' }}
        />
      ) : (
        <Box
          sx={{
            width: '40px',
            height: '40px',
            background: '#E7E8E8',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          data-testid='asset_list_image'
        >
          <AssetImageIcon />
        </Box>
      )}
    </div>
  )
}

export default AssetImage
