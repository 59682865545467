const ConfirmIcon = () => {
  return (
    <svg width='23' height='22' viewBox='0 0 23 22' fill='none'>
      <path
        d='M14.1707 8.70573L10.0457 13.7474L8.2124 11.9141M20.1291 10.9974C20.1291 15.8069 16.2302 19.7057 11.4207 19.7057C6.61126 19.7057 2.7124 15.8069 2.7124 10.9974C2.7124 6.18792 6.61126 2.28906 11.4207 2.28906C16.2302 2.28906 20.1291 6.18792 20.1291 10.9974Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ConfirmIcon
