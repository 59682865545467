function DeleteIcon() {
  return (
    <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.164062' width='28' height='28' rx='4' fill='#FBEBE8' />
      <g clipPath='url(#clip0_3283_21769)'>
        <path
          d='M16.1439 9.02022L16.2903 9.16667H16.4974H18.1641C18.2546 9.16667 18.3308 9.24281 18.3308 9.33333C18.3308 9.42386 18.2546 9.5 18.1641 9.5H10.1641C10.0736 9.5 9.99744 9.42386 9.99744 9.33333C9.99744 9.24281 10.0736 9.16667 10.1641 9.16667H11.8308H12.0379L12.1843 9.02022L12.6577 8.54689L12.3041 8.19333L12.6577 8.54689C12.685 8.51958 12.7316 8.5 12.7708 8.5H15.5574C15.5967 8.5 15.6432 8.51958 15.6705 8.54689L16.0201 8.19731L15.6705 8.54689L16.1439 9.02022ZM11.4974 19.5C11.0402 19.5 10.6641 19.1239 10.6641 18.6667V12C10.6641 11.5428 11.0402 11.1667 11.4974 11.1667H16.8308C17.288 11.1667 17.6641 11.5428 17.6641 12V18.6667C17.6641 19.1239 17.288 19.5 16.8308 19.5H11.4974ZM16.1641 11.5H12.1641C11.5213 11.5 10.9974 12.0239 10.9974 12.6667V18C10.9974 18.6428 11.5213 19.1667 12.1641 19.1667H16.1641C16.8069 19.1667 17.3308 18.6428 17.3308 18V12.6667C17.3308 12.0239 16.8069 11.5 16.1641 11.5Z'
          fill='#D4351C'
          stroke='#D4351C'
        />
      </g>
      <defs>
        <clipPath id='clip0_3283_21769'>
          <rect width='16' height='16' fill='white' transform='translate(6.16406 6)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default DeleteIcon
