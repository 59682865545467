import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import rightsExpired from '../../Assets/Images/svgs/rightExpired.svg'
import CONSTANTS from '../../Constants'
import { getAssetStatusBackgroundColor, getStatus } from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import AssetStatusIcon from '../Icons/AssetStatusIcon'
import ConfirmIcon from '../Icons/ConfirmIcon'
import { DePublishIcon } from '../Icons/DePublishIcon'
import EditIcon from '../Icons/EditIcon'
import ErrorIcon from '../Icons/ErrorIcon'
import LeftArrowIcon from '../Icons/LeftArrowIcon'
import RightArrowIcon from '../Icons/RightArrowIcon'
import TimerIcon from '../Icons/TimerIcon'
import UmbrellaIcon from '../Icons/UmbrellaIcon'
import WriteEditIcon from '../Icons/WriteEditIcon'
import style from '../Labels/AssetStateLabel.module.scss'
import './slick-min.scss'
import './slick-theme-min.scss'

/**
 * AssetStatus Component
 *
 * Renders Asset Clone and Count components.
 *
 * @component
 * @param HomepageData - Getting Asset status data getting from api with key name assetStatus an array that containing count and state data
 * @returns {React.JSX.Element | null} JSX Element representing the AssetCloneVersion component.
 */

interface HomepageProps {
  homepageData?: TypeObject
}

const AssetStatus: React.FC<HomepageProps> = ({ homepageData }: HomepageProps) => {
  const navigate = useNavigate()
  const count = homepageData?.assetStatus?.length || 0
  const settings = {
    speed: 500,
    slidesToShow: count > 6 ? 6 : count,
    slidesToScroll: count > 6 ? 6 : count,
    useCSS: true,
    infinite: false,
    nextArrow: (
      <span>
        <RightArrowIcon />
      </span>
    ),
    prevArrow: (
      <span>
        <LeftArrowIcon />
      </span>
    ),
  }
  const getIcon = (status: string) => {
    switch (status) {
      case CONSTANTS.ASSET_STATUS.PUBLISHED:
        return <ConfirmIcon />
      case CONSTANTS.ASSET_STATUS.DRAFT:
        return <EditIcon />
      case CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_PUBLISHING:
        return <ThumbUpOutlinedIcon />
      case CONSTANTS.ASSET_STATUS.SCHEDULE_PUBLISH:
        return <TimerIcon />
      case CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC:
        return <UmbrellaIcon />
      case CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE:
        return <WriteEditIcon />
      case CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED:
        return <img src={rightsExpired} />
      case CONSTANTS.ASSET_STATUS.DEPUBLISHED:
        return <DePublishIcon />
      default:
        return <ErrorIcon />
    }
  }
  return (
    <div className='asset-status-slider'>
      <h3>
        <AssetStatusIcon />
        Asset State
      </h3>
      {homepageData?.assetStatus?.length ? (
        <Slider {...settings}>
          {homepageData?.assetStatus?.length &&
            homepageData?.assetStatus?.map((item: any, ind: number) => {
              return (
                <div
                  key={ind}
                  data-testid={`assetCard${ind}`}
                  onClick={() => {
                    navigate(`/home/asset-state-details/${item?.state}`)
                  }}
                  className='cursor-pointer'
                >
                  <div className={` ${style[item?.state]} box-item`}>
                    <span
                      className='status-icon'
                      style={{
                        backgroundColor: getAssetStatusBackgroundColor(item?.state),
                        color: '#ffff',
                      }}
                    >
                      {getIcon(item?.state)}
                    </span>
                    <div className='status-val'>{item.count}</div>
                    <div className='status-text'>{getStatus(item.state) || item.state}</div>
                  </div>
                </div>
              )
            })}
        </Slider>
      ) : (
        <></>
      )}
    </div>
  )
}

export default AssetStatus
