import React from 'react'
import { useParams } from 'react-router-dom'
import { DownstreamPlatforms as DownstreamPlatformsEditor } from '../../../../Components/Organisms/Masters'

const DownstreamPlatformsAddEdit = () => {
  const { downstreamPlatformsPageType, downstreamPlatformsId } = useParams()

  return (
    <DownstreamPlatformsEditor
      mastersPageType={downstreamPlatformsPageType}
      mastersId={downstreamPlatformsId}
    />
  )
}

export default React.memo(DownstreamPlatformsAddEdit)
