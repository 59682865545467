import { Grid } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import React from 'react'
import { Control, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { VALIDATIONS } from '../../../../Constants'
import { combineErrorMessage, getMasterPayload } from '../../../../Helpers/Util'
import { useAppDispatch } from '../../../../Store/Hooks'
import { showAlert } from '../../../../Store/Slice/alert.slice'
import {
  createMasterByKey,
  updateMasterByKey,
} from '../../../../Store/Slice/system-configuration.slice'
import { MUIBox, MUIButton, MUIField } from '../../../Shared'
import styles from '../Masters.module.scss'

interface SystemConfigurationFormPropsInterface {
  mastersPageType: string
  masterObj: any
  mastersId?: string
  editSystemConfig?: any
}

const SystemConfigurationForm = ({
  mastersPageType,
  masterObj,
  mastersId,
  editSystemConfig,
}: SystemConfigurationFormPropsInterface) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {
      value: mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? editSystemConfig?.value : '',
      key: mastersPageType === CONSTANTS.PAGE_TYPE.EDIT ? editSystemConfig?.key : '',
    },
  })

  const onSubmit = async (data: any) => {
    let result: any
    if (mastersPageType === CONSTANTS.PAGE_TYPE.CREATE) {
      const payload = getMasterPayload(masterObj.key, data)
      result = await dispatch(createMasterByKey(payload) as any)
    }
    if (mastersPageType === CONSTANTS.PAGE_TYPE.EDIT && mastersId) {
      let payload: any = getMasterPayload(masterObj.key, data)
      payload = {
        ...payload,
        systemConfigId: mastersId,
      }
      result = await dispatch(updateMasterByKey(payload) as any)
    }
    const unwrappableRes = unwrapResult(result)
    if (unwrappableRes?.error) {
      dispatch(showAlert([true, combineErrorMessage(unwrappableRes), 'error']))
      return
    }
    dispatch(showAlert([true, unwrappableRes.message, 'success']))
    navigate(`${masterObj?.url}`)
  }

  return (
    <MUIBox>
      <div>
        <div className={`heading-6 d-flex text-left ${styles.masters__box__header__padding}`}>
          {`${masterObj?.name}`}
        </div>
        <div className={styles.hr}></div>
        <div className={styles.mastersbox__content_padding}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={10} rowSpacing={4}>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIField
                  name='key'
                  label='Key'
                  required
                  type='text'
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIField
                  name='value'
                  label='Value'
                  required
                  type='text'
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={100}
                />
              </Grid>
              <Grid xs={12} item>
                <Grid container spacing={10}>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={6} className='d-flex justify-content-end'>
                    <MUIButton
                      disabled={!isValid}
                      size='large'
                      type={'submit'}
                      label={'Submit'}
                      variant='contained'
                      width={200}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </MUIBox>
  )
}

export default React.memo(SystemConfigurationForm)
