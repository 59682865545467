import { redirect } from 'react-router-dom'
import CONSTANTS from '../Constants'
import { BULK_UPDATE } from '../Constants/ApiDefinations'
import { BLITZ_ID } from '../Constants/Constant'
import { TypeObject } from '../Models'
import mainApiService from '../Services'
import { store } from '../Store'
import { showAlert } from '../Store/Slice/alert.slice'
import { PERMISSIONS, checkPermission } from './Permission'
import { combineErrorMessage, getSelectedAttributeDetails } from './Util'

export const removeSelectedAttributeDetails = (newValues: any, selectedAttributes: any) => {
  const notPresentInNewValues = selectedAttributes.filter(
    (attribute: TypeObject) =>
      !newValues.some((newValue: TypeObject) => newValue.fieldName === attribute.fieldName),
  )

  const matchingObjects: TypeObject[] = [...newValues]

  for (const attr of notPresentInNewValues) {
    if (attr?.attributeRef?.length) {
      for (const attRef of attr.attributeRef) {
        const matchingDataObjectIdx = matchingObjects.findIndex(
          (ele: any) => ele._id === attRef._id,
        )
        matchingObjects.splice(matchingDataObjectIdx, 1)
      }
    }

    matchingObjects.forEach((ele: TypeObject, idx: number) => {
      if (ele?.attributeRef?.length && ele?.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE) {
        const attrefEle = ele.attributeRef.find((item: any) => item._id === attr._id)
        if (attrefEle) {
          matchingObjects[idx].attributeRef.forEach((matchObj: TypeObject) => {
            const matchInMatchObjectIdx = matchingObjects.findIndex(
              (item: any) => item._id === matchObj._id,
            )
            matchingObjects.splice(matchInMatchObjectIdx, 1)
          })
          matchingObjects.splice(idx, 1)
        }
      }
    })
  }

  return matchingObjects
}

export const handleAttributesCreateJob = (
  newValues: TypeObject[],
  reason: string,
  attributes: TypeObject[],
  selectedAttributes: TypeObject[],
) => {
  if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.INPUT) {
    return
  }
  if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.CLEAR) {
    return { selectedAttributes: [], disabledLabel: [] }
  }
  if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.SELECTOPTION) {
    const copyArr = [...newValues]
    const latestAttribute = copyArr[copyArr.length - 1]
    if (
      latestAttribute?.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE &&
      latestAttribute?.attributeRef?.length
    ) {
      copyArr.push(...getSelectedAttributeDetails(latestAttribute.attributeRef, attributes))
    }
    const disabledLabels = copyArr.map((item: TypeObject) => item.label)

    return { selectedAttributes: copyArr, disabledLabel: disabledLabels }
  }
  if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.REMOVEOPTION) {
    const copyArr: TypeObject[] = []
    const copySelectedAttr: TypeObject[] = [...selectedAttributes]
    copyArr.push(...removeSelectedAttributeDetails(newValues, copySelectedAttr))
    const disabledLabels = copyArr.map((item: TypeObject) => item.label)
    return { selectedAttributes: copyArr, disabledLabel: disabledLabels }
  }
  return { selectedAttributes: [], disabledLabel: [] }
}

export const getDisabledLabels = (attributes: TypeObject[], disabledLabels: string[]) => {
  const complexTypeAttributes: TypeObject[] = attributes.filter(
    (item: TypeObject) => item.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE,
  )
  const disabledAttrSet = new Set([...disabledLabels])
  for (const attr of complexTypeAttributes) {
    for (const attRef of attr.attributeRef) {
      const matchingAttr = attributes.find((item: TypeObject) => item?._id === attRef._id)
      if (matchingAttr) {
        disabledAttrSet.add(matchingAttr?.label as string)
      }
    }
  }

  return Array.from(disabledAttrSet)
}

export const checkPermissionsBulkUpload = (permissionType: string[]) => {
  const accessWrite = checkPermission(PERMISSIONS.BULK_UPDATE, permissionType)
  if (!accessWrite) {
    redirect('/access-denied')
  }
}

export const getDataOfCsvBasedOnHeaders = (csvData: TypeObject[], jobDetails: TypeObject) => {
  const excludedFields = [...CONSTANTS.BULK_UPDATE_EXCLUDED_ATTRIBUTE].map((item) =>
    item.toLowerCase(),
  )
  const attributesAvailableInComplexFields = getComplexAttributesFromJobDetails(jobDetails)

  const keysOfCsvHeaders = Object.keys(csvData?.[0] ?? {})?.filter((header) => !!header)

  const notPresentAsPerData: string[] = []

  if (keysOfCsvHeaders?.length) {
    const attributesMap = new Set(
      jobDetails?.attributes?.map((attr: any) => attr.label.toLowerCase()),
    )

    for (const key of keysOfCsvHeaders) {
      if (
        !attributesMap.has(key?.trim()?.toLowerCase()) &&
        !attributesAvailableInComplexFields.has(key?.trim()?.toLowerCase()) &&
        !excludedFields.includes(key?.toLowerCase())
      ) {
        notPresentAsPerData.push(key?.trim()?.toLowerCase())
      }
    }
    return notPresentAsPerData
  }

  return notPresentAsPerData
}

export const updateJobService = async (jobData: any, showError = true, showSuccess = true) => {
  const requestedData = BULK_UPDATE.UPDATE_JOB(jobData)

  const result: any = await mainApiService(requestedData)
  if (result?.error && showError) {
    store.dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    return false
  }

  if (showSuccess) store.dispatch(showAlert([true, result?.message, 'success']))

  return result
}

const getComplexAttributesFromJobDetails = (jobDetails: TypeObject) => {
  const complexTypeAttributes: TypeObject[] =
    jobDetails?.attributes?.filter(
      (item: TypeObject) => item.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE,
    ) ?? []

  const attributesAvailableInComplexFields = new Set()

  for (const attr of complexTypeAttributes) {
    for (const attRef of attr.attributeRef) {
      const matchingAttr = jobDetails?.attributes?.find(
        (item: TypeObject) => item?._id === attRef._id,
      )
      if (matchingAttr) {
        attributesAvailableInComplexFields.add(matchingAttr?.label as string)
      }
    }
  }

  return attributesAvailableInComplexFields
}

export const fileValidation = async (
  csvData: TypeObject[],
  jobDetails: TypeObject,
  fileInfo: TypeObject,
  handleDialog: any,
  handleCloseDialog: any,
) => {
  const csvHeaders = csvData?.[0] ?? {}
  const attributesAvailableInComplexFields = getComplexAttributesFromJobDetails(jobDetails)
  if (fileInfo?.type !== 'text/csv') {
    handleDialog(
      CONSTANTS.UPLOAD_FAILED_HEADING,
      'Incorrect file format. Only CSV files are allowed.',
      handleCloseDialog,
      null,
      'error',
      'DeleteRoleIcon',
    )
    return false
  }

  const csvDataFile = csvData?.filter(
    (item) => item[CONSTANTS.BULK_UPDATE_ATTRIBUTE_LABEL.BLITZ_ID],
  )

  if (!csvDataFile?.length) {
    handleDialog(
      CONSTANTS.UPLOAD_FAILED_HEADING,
      'No records found.',
      handleCloseDialog,
      null,
      'error',
      'DeleteRoleIcon',
    )
    return false
  }

  const findDuplicatesRows = (arr: any[]) => {
    try {
      const duplicates: any[] = arr.filter(
        (item, index, self) => self.findIndex((t) => t[BLITZ_ID] === item[BLITZ_ID]) !== index,
      )
      const duplicateIds = duplicates.map((item) => item['Blitz ID'])
      const uniqDuplicates = [...new Set(duplicateIds)]
      return uniqDuplicates.join(', ')
    } catch (error) {
      return 'Error in finding of Ids'
    }
  }

  const duplicatesBlitzIds = findDuplicatesRows(csvDataFile)

  if (duplicatesBlitzIds?.length) {
    handleDialog(
      CONSTANTS.UPLOAD_FAILED_HEADING,
      `Duplicate records found for Blitz Id: ${duplicatesBlitzIds}`,
      handleCloseDialog,
      null,
      'error',
      'DeleteRoleIcon',
    )
    return false
  }

  let optionalCount = 0
  for (const opt of [...CONSTANTS.OPTIONAL_ATTRIBUTES_BULK_UPDATE]) {
    if (!csvData?.[0]?.[opt]) {
      optionalCount++
    }
  }

  const jobAttributesLength =
    jobDetails?.attributes?.length -
    attributesAvailableInComplexFields.size +
    [...CONSTANTS.BULK_UPDATE_EXCLUDED_ATTRIBUTE].length -
    optionalCount

  const filteredCsvHeaders = Object.keys(csvHeaders)?.filter((header) => !!header)

  if (jobAttributesLength !== filteredCsvHeaders.length) {
    handleDialog(
      CONSTANTS.UPLOAD_FAILED_HEADING,
      'Column headers are incorrect. Headers should be the same as the templates.',
      handleCloseDialog,
      null,
      'error',
      'DeleteRoleIcon',
    )
    return false
  }

  const notPresentAsPerData: any = getDataOfCsvBasedOnHeaders(csvData, jobDetails)
  if (notPresentAsPerData?.length) {
    const message = 'Column headers are incorrect. Headers should be the same as the templates.'
    handleDialog(
      CONSTANTS.UPLOAD_FAILED_HEADING,
      message,
      handleCloseDialog,
      null,
      'error',
      'DeleteRoleIcon',
    )
    return false
  }

  const reqData = BULK_UPDATE.FILE_CONFIG()
  const config: TypeObject = await mainApiService(reqData)

  if (config?.error) {
    store.dispatch(showAlert([true, combineErrorMessage(config), 'error']))
    return false
  }

  if (config?.data?.configs?.maxFileSize < fileInfo?.size / 1024) {
    handleDialog(
      CONSTANTS.UPLOAD_FAILED_HEADING,
      CONSTANTS.UPLOAD_FAILED_FILE_SIZE_DESC(config?.data?.configs?.maxFileSize),
      handleCloseDialog,
      null,
      'error',
      'DeleteRoleIcon',
    )
    return false
  }

  if (config?.data?.configs?.maxContentAllowed <= csvData?.length) {
    handleDialog(
      CONSTANTS.UPLOAD_FAILED_HEADING,
      CONSTANTS.UPLOAD_FAILED_CONTENT_DESC(config?.data?.configs?.maxContentAllowed),
      handleCloseDialog,
      null,
      'error',
      'DeleteRoleIcon',
    )
    return false
  }

  return true
}

export const removeSubAttributes = (attributes: TypeObject[]) => {
  const complexAttr = attributes.filter(
    (item) => item.fieldType === CONSTANTS.FIELDTYPES.COMPLEXTYPE,
  )
  const attRefMap = new Map()
  const attributeMap = new Map(attributes.map((item) => [item._id, item]))
  for (const attRef of complexAttr) {
    const fieldAttribute = attRef?.attributeRef ?? []
    for (const attRefField of fieldAttribute) {
      const field = attributes.find((item) => item?._id === attRefField?._id)
      if (field) {
        attRefMap.set(attRefField?._id, field)
      }
    }
  }

  for (const [key, value] of attRefMap) {
    if (attributeMap.has(key) && attributeMap.get(key) === value) {
      attributeMap.delete(key)
    }
  }

  return Array.from(attributeMap)
    .map((item) => item[1])
    .map((item) => item.fieldName)
}

export const isJobCancelledBeforeStart = (view: any) => {
  let jobCancelledBeforeStart = false
  if (
    view?.status === 'COMPLETED' &&
    view?.action === 'CANCELED' &&
    !(view?.failureCount >= 0 || view?.successCount >= 0)
  ) {
    jobCancelledBeforeStart = true
  }
  return jobCancelledBeforeStart
}
