import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  cancelBulkUpdate: {
    show: false,
    data: {},
  },
}
const bulkUploadSlice = createSlice({
  name: 'bulkUpdate',
  initialState,
  reducers: {
    setcancelBulkUpdate: (state, action) => {
      const { show = false, data = {} } = action.payload
      state.cancelBulkUpdate = {
        ...state.cancelBulkUpdate,
        show,
        data,
      }
    },
  },
})
const bulkUpdatelector = (state: { bulkUpdate: any }) => state.bulkUpdate
export const selectBulkUploadSlice = createSelector(
  bulkUpdatelector,
  (state) => state.cancelBulkUpdate,
)
export const { setcancelBulkUpdate } = bulkUploadSlice.actions
export default bulkUploadSlice
