const TimerIcon = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none'>
      <path
        d='M10.4207 5.8724V9.9974L13.1707 12.7474M19.1291 9.9974C19.1291 14.8069 15.2302 18.7057 10.4207 18.7057C5.61126 18.7057 1.7124 14.8069 1.7124 9.9974C1.7124 5.18792 5.61126 1.28906 10.4207 1.28906C15.2302 1.28906 19.1291 5.18792 19.1291 9.9974Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default TimerIcon
