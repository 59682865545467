function AccordianOpenIcon() {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='28' height='28' rx='4' fill='#F3F2F1' />
      <path
        d='M11.4134 12.195L14.0001 14.7817L16.5867 12.195C16.8467 11.935 17.2667 11.935 17.5267 12.195C17.7867 12.455 17.7867 12.875 17.5267 13.135L14.4667 16.195C14.2067 16.455 13.7867 16.455 13.5267 16.195L10.4667 13.135C10.2067 12.875 10.2067 12.455 10.4667 12.195C10.7267 11.9417 11.1534 11.935 11.4134 12.195Z'
        fill='#402080'
      />
    </svg>
  )
}

export default AccordianOpenIcon
