import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../../Constants'
import { MASTERS_MANAGEMENT } from '../../../../Constants/ApiDefinations'
import { getPageTitle, objectToQueryString } from '../../../../Helpers/Util'
import updateTagMetaData from '../../../../Helpers/updateTagMetaData'
import {
  BreadcrumsModel,
  LanguagesListApiResponse,
  LanguagesListResponse,
  LanguagesListingQueryParams,
  LanguagesPropsType,
  ViewMastersAPIDefinition,
} from '../../../../Models'
import mainApiService from '../../../../Services'
import { useAppDispatch, useAppSelector } from '../../../../Store/Hooks'
import { selectLanguageData, setLanguagesData } from '../../../../Store/Slice/languages.slice'
import MastersEditorLayout from '../Layouts/MastersEditorLayout'
import LanguagesCreateEdit from './LanguagesCreateEdit'

const LANGUAGE_TEXT = 'Language'
const LanguagesEditor = ({ mastersPageType, mastersId }: LanguagesPropsType) => {
  const selectLanguage = useAppSelector(selectLanguageData)
  const dispatch = useAppDispatch()
  const selectedLanguage: LanguagesListResponse | null =
    mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE ? selectLanguage : null
  const navigate = useNavigate()
  useEffect(() => {
    if (
      mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE &&
      (!selectedLanguage?._id || selectedLanguage?._id !== mastersId)
    ) {
      ;(async () => {
        // Call list API
        const listData = await getLanguagesListingFunction({ page: 1, limit: 1000000 })
        // find selected record based on id
        if (mastersId) {
          const selectedLanguageData = listData.find((item) => item?._id === mastersId)
          if (!selectedLanguageData?._id) {
            // navigate to list
            navigate(
              `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Languages}`,
            )
          } else {
            dispatch(setLanguagesData(selectedLanguageData))
          }
        }
      })()
    }
  }, [])

  const getLanguagesListingFunction = async (options: LanguagesListingQueryParams) => {
    const paramsData = objectToQueryString(options)
    const requestedData: ViewMastersAPIDefinition =
      MASTERS_MANAGEMENT.LANGUAGES_CRUD.LIST_LANGUAGES(paramsData)
    const result: any = await mainApiService(requestedData)
    const tempData: LanguagesListResponse[] = []

    if (result?.responseCode === 200) {
      result?.data?.records?.forEach((ele: LanguagesListApiResponse) => {
        const createdBy = ele?.createdBy?.userEmail
        const updatedBy = ele?.updatedBy?.userEmail
        tempData.push({
          ...ele,
          createdBy,
          updatedBy,
        })
      })
    }
    return tempData
  }

  const onClickBackNavigation = (e: { preventDefault: () => void }) => {
    e?.preventDefault()
    navigate(`/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Languages}`)
  }

  useEffect(() => {
    updateTagMetaData({ title: `Manage Masters: ${LANGUAGE_TEXT}` })
    return () => {
      updateTagMetaData({})
    }
  }, [])
  return (
    <MastersEditorLayout
      mastersPageType={mastersPageType}
      pageText={LANGUAGE_TEXT}
      onClickBackNavigation={onClickBackNavigation}
      breadcrumbs={
        BREADCRUMS.CREATE_LANGUAGES(
          mastersPageType,
          getPageTitle(mastersPageType as string),
        ) as BreadcrumsModel[]
      }
    >
      {mastersPageType !== CONSTANTS.PAGE_TYPE.CREATE && selectedLanguage?._id && (
        <LanguagesCreateEdit mastersPageType={mastersPageType} />
      )}
      {mastersPageType === CONSTANTS.PAGE_TYPE.CREATE && (
        <LanguagesCreateEdit mastersPageType={mastersPageType} />
      )}
    </MastersEditorLayout>
  )
}

export default React.memo(LanguagesEditor)
