import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { MASTERS_MANAGEMENT } from '../../Constants/ApiDefinations'
import { CreateMastersData, EntitlementPackagesListResponse, MastersUpdate } from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface EntitlementPackagesState {
  entitlementPackageData: EntitlementPackagesListResponse | null
}

const initialState: EntitlementPackagesState = {
  entitlementPackageData: null,
}

const entitlementPackagesSlice = createSlice({
  name: 'entitlementPackages',
  initialState,
  reducers: {
    setEntitlementPackagesData: (state, action) => {
      state.entitlementPackageData = action?.payload ? action.payload : null
    },
  },
})

export const changeEntitlementPackageStatus = createAsyncThunk(
  'entitlementPackages/changeStatus',
  async (data: { id: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.ENTITLEMENT_PACKAGES_CRUD.UPDATE_ENTITLEMENT_PACKAGES(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const { setEntitlementPackagesData } = entitlementPackagesSlice.actions

const entitlementPackagesSelector = (state: any) => state.entitlementPackages

export const selectEntitlementPackageData = createSelector(
  entitlementPackagesSelector,
  (state) => state.entitlementPackageData,
)

export const createEntitlementPackageService = createAsyncThunk(
  'entitlementPackages/create',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.ENTITLEMENT_PACKAGES_CRUD.CREATE_ENTITLEMENT_PACKAGES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const editEntitlementPackageService = createAsyncThunk(
  'entitlementPackages/editEntitlementPackage',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.ENTITLEMENT_PACKAGES_CRUD.UPDATE_ENTITLEMENT_PACKAGES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export default entitlementPackagesSlice
