import { FormControl } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { MUIAutocompleteModel } from '../../../Models'

const MUIAutocomplete: React.FC<MUIAutocompleteModel> = ({
  label,
  options,
  onInputChange,
  onChange,
  helperText,
  error,
  hanldeGetOptionLabel,
  readOnly = false,
  disabled = false,
  maxHeight,
  forcePopupIcon,
}) => {
  const [inputValue, setInputValue] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpen(true)
    }
  }

  const handleInputChange = (event: any, newInputValue: any) => {
    setInputValue(newInputValue)
    onInputChange(newInputValue)
    if (newInputValue.length > 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  const handleOnChange = (event: any, value: any, reason: string) => {
    if (reason === 'selectOption') {
      onChange(value, reason)
      setOpen(false)
    }
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        forcePopupIcon={forcePopupIcon}
        id='combo-box-demo'
        open={open}
        onOpen={handleOpen}
        noOptionsText={'Attribute not found'}
        options={options}
        fullWidth
        getOptionLabel={hanldeGetOptionLabel}
        onInputChange={handleInputChange}
        onChange={handleOnChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ children: null as React.ReactNode }}
            label={label}
            helperText={helperText}
            error={error}
            fullWidth
          />
        )}
        readOnly={readOnly}
        ListboxProps={{ style: { maxHeight: maxHeight } }}
      />
    </FormControl>
  )
}

export default MUIAutocomplete
