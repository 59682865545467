const LeftArrowIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M10 13.3307L4.66667 7.99741L10 2.66406'
        stroke='#151D48'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LeftArrowIcon
