export default {
  _id: '6576b5ef38fd83d2b4da5e8c',
  version: '1',
  assetType: 'EPISODE',
  assetCategory: 'TV_SHOWS',
  contentCategory: 'CONTENT_ITEMS',
  tollGate: {
    DESCRIPTIVE_METADATA: true,
    RIGHTS_AND_AVAILABILITY: true,
    SEO_METADATA: true,
    ADVERTISEMENT_METADATA: true,
    BUSINESS_RULES: true,
    TECHNICAL_METADATA: true,
    CONTENT_MEDIA_IMAGE: true,
    CONTENT_MEDIA_VIDEO: true,
    TRANSLATIONS: true,
    SPORTS_METADATA: true,
    MISCELLANEOUS_METADATA: true,
    QC_PROCESS: true,
    PUBLISHING: true,
    RIGHTS_EXPIRED: true,
    DEPUBLISH: true,
  },
  metaCategories: [],
  allowedParents: ['show', 'season', 'episodicShow', 'episodicRange'],
  isStandAlonePublishing: true,
  attributes: [
    {
      metaCategory: 'DESCRIPTIVE_METADATA',
      metaAttributes: [
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'assetTitle',
          isRequired: true,
          isMultiple: false,
          label: '',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'seasonNumber',
          isRequired: true,
          isMultiple: false,
          label: 'season Number',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter Season Number',
          description: 'Season Number',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'episodeNumber',
          isRequired: true,
          isMultiple: false,
          label: 'Episode Number',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter episode Number',
          description: 'Episode Number',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'episodicRange',
          isRequired: true,
          isMultiple: false,
          label: 'Episodic Range ',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter Episodic Range',
          description: 'Episodic Range',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'extendedEpisodeNumber',
          isRequired: true,
          isMultiple: false,
          label: 'Extended Episodic Number ',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Extended episode Number',
          description: 'Extended Episode Number',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'seasonNumber',
          isRequired: true,
          isMultiple: false,
          label: 'season Number',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter Season Number',
          description: 'Season Number',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'language',
          isRequired: true,
          isMultiple: false,
          label: 'Language',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select Language',
          description: 'Default language for the content',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'time',
          dataType: 'string',
          fieldName: 'duration',
          isRequired: true,
          isMultiple: false,
          label: 'Content Duration ',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter Duration',
          description: 'Actual duration of the video to display on FE',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'broadcastChannel',
          isRequired: true,
          isMultiple: true,
          label: 'Broadcast Channel',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Broadcast Channel',
          description: 'Broadcast Channel ',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'keyWords',
          isRequired: true,
          isMultiple: false,
          label: 'Search KeyWords',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: 'Search keyWords for the content',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {
            tagInput: true,
          },
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'longSummary',
          isRequired: true,
          isMultiple: false,
          label: 'longSummary',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'shortSummary',
          isRequired: true,
          isMultiple: false,
          label: 'shortSummary',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'countryOfOrigin',
          isRequired: false,
          isMultiple: false,
          label: 'Country Of Origin',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: 'countryList',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'mood',
          isRequired: false,
          isMultiple: true,
          label: 'Mood',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'theme',
          isRequired: false,
          isMultiple: true,
          label: 'Theme',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'languageofProduction',
          isRequired: false,
          isMultiple: false,
          label: 'Language of Production',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'subCategory',
          isRequired: false,
          isMultiple: false,
          label: 'Content Sub Category',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'audioLanguages',
          isRequired: false,
          isMultiple: true,
          label: 'audioLanguages',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: 'audioLanguageList',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'highlightText',
          isRequired: false,
          isMultiple: false,
          label: 'highlightText',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'reminderbuttonVisibility',
          isRequired: false,
          isMultiple: false,
          label: 'reminderbutton_visibility',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'team',
          isRequired: false,
          isMultiple: false,
          label: 'Team',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'playerName',
          isRequired: false,
          isMultiple: false,
          label: 'playerName',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'liveContentId',
          isRequired: false,
          isMultiple: false,
          label: 'liveContentId',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: ' ',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isSearchable',
          isRequired: false,
          isMultiple: false,
          label: 'Is Searchable',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'liveLabelDisplay',
          isRequired: false,
          isMultiple: false,
          label: 'liveLabelDisplay',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'IMDB',
          isRequired: false,
          isMultiple: false,
          label: 'IMDB',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'overlayVisibility',
          isRequired: false,
          isMultiple: false,
          label: 'Overlay Visibility',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'texarea',
          dataType: 'string',
          fieldName: 'setting',
          isRequired: false,
          isMultiple: false,
          label: 'Setting',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'sonylivExclusive',
          isRequired: false,
          isMultiple: false,
          label: 'sonylivExclusive',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'sonylivOriginal',
          isRequired: false,
          isMultiple: false,
          label: 'sonylivOriginal',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'era',
          isRequired: false,
          isMultiple: false,
          label: 'Era',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {
            tagInput: true,
          },
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'oneLineDesc',
          isRequired: false,
          isMultiple: false,
          label: 'oneLineDesc',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'oneLineIconUrl',
          isRequired: false,
          isMultiple: false,
          label: 'oneLineIconUrl',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          validations: {
            custom: 'validUrl',
          },
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'releaseFrequency',
          isRequired: false,
          isMultiple: false,
          label: 'releaseFrequency',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'releaseDate',
          isRequired: false,
          isMultiple: false,
          label: 'Release date',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'titleBrief',
          isRequired: false,
          isMultiple: false,
          label: 'Title Brief',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'extraTitle',
          isRequired: false,
          isMultiple: false,
          label: 'Extra Title',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'kidsSafe',
          isRequired: false,
          isMultiple: false,
          label: 'kidsSafe',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'trayCustomFilter',
          isRequired: false,
          isMultiple: false,
          label: 'Tray Custom Filter',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.653Z',
          updatedAt: '2023-12-11T07:10:39.653Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isPlayTrailer',
          isRequired: false,
          isMultiple: false,
          label: 'Is Play Trailer',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isAutoPlayTrailer',
          isRequired: false,
          isMultiple: false,
          label: 'Is Auto Play Trailer',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isAutoRedirect',
          isRequired: false,
          isMultiple: false,
          label: 'is Auto Redirect',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'JSON',
          fieldName: 'celebrity Metadata',
          isRequired: false,
          isMultiple: false,
          label: 'Celebrity Meta Data',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'JSON',
          fieldName: 'celebrityCountry',
          isRequired: false,
          isMultiple: false,
          label: 'Celebrity Country',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'upcomingContentLanguages',
          isRequired: false,
          isMultiple: true,
          label: 'Upcoming Content Language',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'channels',
          isRequired: false,
          isMultiple: false,
          label: 'Channels',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'upcomming',
          isRequired: false,
          isMultiple: false,
          label: 'upcomming',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'genre',
          isRequired: true,
          isMultiple: true,
          label: 'Genre',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: ' ',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'subGenre',
          isRequired: true,
          isMultiple: true,
          label: 'Sub Genre',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'castRole',
          isRequired: true,
          isMultiple: false,
          label: 'Cast Role',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: 'Cast Role',
          master: 'castRole',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea4',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'actualName',
          isRequired: true,
          isMultiple: false,
          label: 'Actual Name',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: 'Actual name of the Cast',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea5',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'characterName',
          isRequired: true,
          isMultiple: false,
          label: 'Character Name',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea5',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'hierarchy',
          isRequired: true,
          isMultiple: false,
          label: 'Cast Hierarchy',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea5',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'complexType',
          dataType: 'JSON',
          fieldName: 'castAndCrew',
          isRequired: true,
          isMultiple: true,
          label: 'Cast And Crew',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'descriptiveMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea7',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5e8d',
    },
    {
      metaCategory: 'RIGHTS_AND_AVAILABILITY',
      metaAttributes: [
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'region',
          isRequired: true,
          isMultiple: false,
          label: 'region',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select region',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea9',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'regionCountry',
          isRequired: true,
          isMultiple: true,
          label: 'regionCountry',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          validations: {
            custom: '',
          },
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5beaa',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'complexType',
          dataType: 'JSON',
          fieldName: 'countryPreview',
          isRequired: true,
          isMultiple: true,
          label: 'Country Preview',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea7',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'availableWatchDays',
          isRequired: true,
          isMultiple: false,
          label: 'availableWatchDays',
          isB2B: false,
          defaultValue: 30,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          validations: {
            minValue: 1,
          },
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'maxDownloadAllowedCount',
          isRequired: true,
          isMultiple: false,
          label: 'Max Download Allowed Count',
          isB2B: false,
          defaultValue: 100,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'downloadedContentExpiryHoursAfterFirstWatch',
          isRequired: true,
          isMultiple: false,
          label: 'Downloaded Content Expiry Hours After First Watch',
          isB2B: false,
          defaultValue: 72,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'snpTag',
          isRequired: true,
          isMultiple: false,
          label: 'SNP Tags',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {
            tagInput: true,
          },
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isSubLicenceable',
          isRequired: true,
          isMultiple: false,
          label: '',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'allowDownload',
          isRequired: true,
          isMultiple: false,
          label: 'Allow Download',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'contentProvider',
          isRequired: true,
          isMultiple: false,
          label: 'Content Provider',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'downloadBlockedCountries',
          isRequired: true,
          isMultiple: false,
          label: 'Download Blocked Countries',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'lastBroadcastedDate',
          isRequired: true,
          isMultiple: false,
          label: 'Last Broadcasted Date',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'distribution',
          isRequired: true,
          isMultiple: false,
          label: 'Distribution',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'countryEntitlement',
          isRequired: true,
          isMultiple: true,
          label: 'Country Entitlement',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea9',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'timeDiffInMinutes',
          isRequired: true,
          isMultiple: true,
          label: 'Time Diff In Minutes',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5beaa',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'complexType',
          dataType: 'JSON',
          fieldName: 'countryEntitlementSwitch',
          isRequired: true,
          isMultiple: true,
          label: 'Country Entitlement Switch',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea7',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'applicableGeoPolicy',
          isRequired: true,
          isMultiple: true,
          label: 'Applicable Geo Policy',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea9',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'AvailabilityStartTime',
          isRequired: true,
          isMultiple: false,
          label: 'Availability Start Time',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'Datetime',
          dataType: 'date',
          fieldName: 'availabilityEndTime',
          isRequired: true,
          isMultiple: false,
          label: 'Availability End Time',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'date',
          dataType: 'date',
          fieldName: 'originalAirDateTime',
          isRequired: true,
          isMultiple: false,
          label: 'Original Air DateTime',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'parentalRating',
          isRequired: true,
          isMultiple: true,
          label: 'parental Rating',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: 'parentalrating',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'ageRating',
          isRequired: true,
          isMultiple: false,
          label: 'Age Rating',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea9',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'ageCountry',
          isRequired: true,
          isMultiple: true,
          label: 'Age Country',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5beaa',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'complexType',
          dataType: 'JSON',
          fieldName: 'countryRating',
          isRequired: true,
          isMultiple: true,
          label: 'Country Rating',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea7',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'properties',
          isRequired: true,
          isMultiple: false,
          label: 'Properties',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select properties',
          description: 'Properties for the content',
          master: 'countryList',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'rightsAvailability',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5ec4',
    },
    {
      metaCategory: 'BUSINESS_RULES',
      metaAttributes: [
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'package',
          isRequired: true,
          isMultiple: false,
          label: 'Package',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'businessRules',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'svodCountries',
          isRequired: true,
          isMultiple: true,
          label: 'SVOD Countries',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select Countries',
          description: 'SVOD Countries for the content',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'businessRules',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'partnerHoldbackList',
          isRequired: true,
          isMultiple: true,
          label: 'Partner Holdback List',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select Partners',
          description: 'Partner Holdback List',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'businessRules',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'entitlement',
          isRequired: true,
          isMultiple: false,
          label: 'entitlement',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select Partners',
          description: 'Partner Holdback List',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'businessRules',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'number',
          dataType: 'number',
          fieldName: 'previewDuration',
          isRequired: true,
          isMultiple: false,
          label: 'previewDuration',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'businessRules',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea9',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'previewCountry',
          isRequired: true,
          isMultiple: true,
          label: 'previewCountry',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'businessRules',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5beaa',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'complexType',
          dataType: 'JSON',
          fieldName: 'countryPreview',
          isRequired: true,
          isMultiple: true,
          label: 'countryPreview',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select countryPreview',
          description: 'Country Rating for the content',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'businessRules',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea7',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5ede',
    },
    {
      metaCategory: 'SEO_METADATA',
      metaAttributes: [
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'seoTag',
          isRequired: true,
          isMultiple: false,
          label: 'SEO Tags',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          validations: {
            regex: '/^[a-zA-Z]+$/',
          },
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {
            tagInput: true,
          },
          metaCategory: 'SEO_METADATA',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'seoDescription',
          isRequired: false,
          isMultiple: false,
          label: 'SEO Description',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'seoTitle',
          isRequired: false,
          isMultiple: false,
          label: 'SEO Title',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'sameas',
          isRequired: false,
          isMultiple: false,
          label: 'Sameas',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'idEidr',
          isRequired: false,
          isMultiple: false,
          label: 'ID EIDR',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'idImdb',
          isRequired: false,
          isMultiple: false,
          label: 'ID IMDB',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'idWikipedia',
          isRequired: false,
          isMultiple: false,
          label: 'ID Wikipedia',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'idWikiData',
          isRequired: false,
          isMultiple: false,
          label: 'ID WIKIDATA',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'ifEnableImdbRating',
          isRequired: false,
          isMultiple: false,
          label: 'IF Enable IMDB Rating',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isMasterDefault',
          isRequired: false,
          isMultiple: false,
          label: 'Is Master Default',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'seoCanonicalUrlTags',
          isRequired: false,
          isMultiple: false,
          label: 'Seo Canonical Url Tags',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isPublishToWatchFeed',
          isRequired: false,
          isMultiple: false,
          label: 'Is Publish To Watch Feed',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'seoMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5ee6',
    },
    {
      metaCategory: 'ADVERTISEMENT_METADATA',
      metaAttributes: [
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'advertising',
          isRequired: false,
          isMultiple: false,
          label: 'Advertising',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isSponsoredAdAvailable',
          isRequired: false,
          isMultiple: false,
          label: 'Is Sponsored Ad Available',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'svodAdvertsinig',
          isRequired: true,
          isMultiple: false,
          label: 'SVOD Advertsinig',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'sponsorContent',
          isRequired: true,
          isMultiple: false,
          label: 'Sponsor Content',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'stickyAdsFlag',
          isRequired: true,
          isMultiple: false,
          label: 'Sticky Ads Flag',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'adClusterId',
          isRequired: true,
          isMultiple: false,
          label: 'Ad Cluster Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'adTag',
          isRequired: true,
          isMultiple: false,
          label: '',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'svodIsSponsorAdAvailable',
          isRequired: false,
          isMultiple: false,
          label: 'SVOD Is Sponsor Ad Available',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'JSON',
          fieldName: 'contextualCuePoints',
          isRequired: false,
          isMultiple: false,
          label: 'Contextual Cue Points',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'adsBannerVisibility',
          isRequired: false,
          isMultiple: false,
          label: 'Ads Banner Visibility',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'advertisementMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5ef3',
    },
    {
      metaCategory: 'TECHNICAL_METADATA',
      metaAttributes: [
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'onAir',
          isRequired: true,
          isMultiple: false,
          label: 'On Air',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'encrypted',
          isRequired: true,
          isMultiple: false,
          label: 'Encrypted',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'fileKey',
          isRequired: true,
          isMultiple: false,
          label: 'File Key',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'daiAssetKey',
          isRequired: false,
          isMultiple: false,
          label: 'Dai Asset Key',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'ssaiPartners',
          isRequired: false,
          isMultiple: false,
          label: 'SSAI Partners',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'mpmId',
          isRequired: false,
          isMultiple: false,
          label: 'Mpm Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'contentId',
          isRequired: false,
          isMultiple: false,
          label: 'Content Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'active Platforms',
          isRequired: true,
          isMultiple: true,
          label: 'Active Platforms',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'drmVideoKid',
          isRequired: true,
          isMultiple: false,
          label: 'DRM Video Kid',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'segmentId',
          isRequired: false,
          isMultiple: true,
          label: 'Segment Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'parentId',
          isRequired: false,
          isMultiple: false,
          label: 'Parent Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'technicalMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5efe',
    },
    {
      metaCategory: 'SPORTS_METADATA',
      metaAttributes: [
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'matchId',
          isRequired: true,
          isMultiple: false,
          label: 'Match Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'homeTeam',
          isRequired: false,
          isMultiple: false,
          label: 'Home Team',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'awayTeam',
          isRequired: false,
          isMultiple: false,
          label: 'Away Team',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'venueName',
          isRequired: false,
          isMultiple: false,
          label: 'Venue Name',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'matchStartTime',
          isRequired: true,
          isMultiple: false,
          label: 'Match Start Time',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isTimeLineMakerNew',
          isRequired: false,
          isMultiple: false,
          label: 'Is Timeline Maker New',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'venueId',
          isRequired: false,
          isMultiple: false,
          label: 'Venue Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'venue',
          isRequired: false,
          isMultiple: false,
          label: 'Venue',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'sonyMatchId',
          isRequired: false,
          isMultiple: false,
          label: 'Sony Match Id',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'tlMaker',
          isRequired: false,
          isMultiple: false,
          label: 'Tl Maker',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'adapterSetName',
          isRequired: false,
          isMultiple: false,
          label: 'Adapter Set Name',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'adapterName',
          isRequired: false,
          isMultiple: false,
          label: 'Adapter Name',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'sportsMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5f0a',
    },
    {
      metaCategory: 'MISCELLANEOUS_METADATA',
      metaAttributes: [
        {
          fieldType: 'textarea',
          dataType: 'JSON',
          fieldName: 'upiPayload',
          isRequired: false,
          isMultiple: true,
          label: 'UPI Payload',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Enter UPI Payload',
          description: 'UPI Payload',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'enableCommunication',
          isRequired: false,
          isMultiple: false,
          label: 'Enable Communication',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'JSON',
          fieldName: 'communication',
          isRequired: false,
          isMultiple: false,
          label: 'Communication',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'JSON',
          fieldName: 'custom Bands',
          isRequired: false,
          isMultiple: false,
          label: 'Custom Bands',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'customAction',
          isRequired: false,
          isMultiple: false,
          label: 'Custom Action',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'creatives',
          isRequired: false,
          isMultiple: false,
          label: 'Creatives',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'contentCluster',
          isRequired: false,
          isMultiple: false,
          label: 'Content Cluster',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'freePreviewMultistreamTag',
          isRequired: false,
          isMultiple: false,
          label: 'Free Preview Multistream Tag',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'JSON',
          fieldName: 'upiPayload',
          isRequired: false,
          isMultiple: false,
          label: 'Upi Payload',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isTestAsset',
          isRequired: false,
          isMultiple: false,
          label: 'Is Test Asset',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'shareSubtitleToPartner',
          isRequired: false,
          isMultiple: false,
          label: 'Share Subtitle To Partner',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'integratedSplashAvailable',
          isRequired: false,
          isMultiple: false,
          label: 'Integrated Splash Available',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'miscellaneousMetadata',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5f17',
    },
    {
      metaCategory: 'CONTENT_MEDIA_IMAGE',
      metaAttributes: [
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'landscapeThumb',
          isRequired: false,
          isMultiple: true,
          label: 'Landscape Thumb',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Upload landscape thumb',
          description: 'Landscape thumb image',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          additionalProperties: {
            preview: true,
          },
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'landscapeThumb',
          isRequired: true,
          isMultiple: false,
          label: 'Landscape Thumb',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'tvBackgroundImage',
          isRequired: true,
          isMultiple: false,
          label: 'Tv Background Image',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadBackground',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Background',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadBackgroundMobile',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Background Mobile',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadForeground',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Foreground',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadForegroundMobile',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Foreground Mobile',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLogo',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Logo',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'circularImage',
          isRequired: true,
          isMultiple: false,
          label: 'Circular Image',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'portraitThumb',
          isRequired: true,
          isMultiple: false,
          label: 'Portrait Thumb',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'squareThumb',
          isRequired: true,
          isMultiple: false,
          label: 'Square Thumb',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'detailCoverBG',
          isRequired: false,
          isMultiple: false,
          label: 'Detail Cover BG',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'detailCoverBGMobile',
          isRequired: false,
          isMultiple: false,
          label: 'Detail Cover BG Mobile',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'detailCoverBgV2',
          isRequired: true,
          isMultiple: false,
          label: 'Detail Cover Bg V2',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'detailCoverBgTvV2',
          isRequired: true,
          isMultiple: false,
          label: 'Detail Cover Bg Tv V2',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'detailCoverBgMobileV2',
          isRequired: true,
          isMultiple: false,
          label: 'Detail Cover Bg Mobile V2',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'detailsLogoImage',
          isRequired: true,
          isMultiple: false,
          label: 'Details Logo Image',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLogoSmall',
          isRequired: true,
          isMultiple: false,
          label: 'Metahead Logo Small',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLogoLarge',
          isRequired: true,
          isMultiple: false,
          label: 'masthead Logo Large',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadMobileV2',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Mobile V2',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLargeV2',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Large V2',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadLogoRectangular',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Logo Rectangular',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadMobileV1',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Mobile V1',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadWebV1',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Web v1',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'mastheadTabV1',
          isRequired: true,
          isMultiple: false,
          label: 'Masthead Tab v1',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'image1920x1920Clean',
          isRequired: true,
          isMultiple: false,
          label: 'Image 1920x1920 Clean',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'image1920x548Clean',
          isRequired: true,
          isMultiple: false,
          label: 'Image 1920x548 Clean',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'image1920x1080Clean',
          isRequired: true,
          isMultiple: false,
          label: 'Image 1920x1080 Clean',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'image600x900Clean',
          isRequired: true,
          isMultiple: false,
          label: 'Image 600x900 Clean',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'file',
          dataType: 'string',
          fieldName: 'poster',
          isRequired: false,
          isMultiple: false,
          label: 'Poster',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaImage',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5f24',
    },
    {
      metaCategory: 'CONTENT_MEDIA_VIDEO',
      metaAttributes: [
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'eventStartDateTime',
          isRequired: true,
          isMultiple: false,
          label: 'Event Start Datetime',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: 'Event Start Datetime',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea5',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'eventEndDateTime',
          isRequired: true,
          isMultiple: false,
          label: 'Event End Date Time',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea5',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'cuePointType',
          isRequired: true,
          isMultiple: false,
          label: 'Cue Point Type',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: 'Cue Point Type for the content',
          master: 'cuePointType',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea4',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'cueStartDateTime',
          isRequired: true,
          isMultiple: false,
          label: 'Cue Point Start Date Time',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: 'Cue Point Start Date Time for the content',
          master: '',
          options: [],
          validations: {
            custom: 'customFuncToValidateDatetime',
          },
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea5',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'datetime',
          dataType: 'datetime',
          fieldName: 'cueEndDateTime',
          isRequired: true,
          isMultiple: false,
          label: 'Cue Point End Date Time',
          isB2B: true,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: 'Cue Point End Date Time for the content',
          master: '',
          options: [],
          validations: {
            custom: 'customFuncToValidateDatetime',
          },
          sortOrder: 2,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea5',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'complexType',
          dataType: 'JSON',
          fieldName: 'cuePoints',
          isRequired: true,
          isMultiple: true,
          label: 'CuePoints',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: 'Select cue_points',
          description: 'cue_points for the content',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea7',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'spriteImageURL',
          isRequired: true,
          isMultiple: false,
          label: 'Sprite Image URL',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'TvSpriteImageURL',
          isRequired: true,
          isMultiple: false,
          label: 'Tv Sprite Image URL',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'complexType',
          dataType: 'JSON',
          fieldName: 'subtitleLanguageFiles',
          isRequired: false,
          isMultiple: true,
          label: 'subtitle Language Files',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'subtitleLanguage',
          isRequired: false,
          isMultiple: true,
          label: 'subtitle Language',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.654Z',
          updatedAt: '2023-12-11T07:10:39.654Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'subtitleLanguageFile',
          isRequired: false,
          isMultiple: true,
          label: 'Subtitle Language File',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isDVR',
          isRequired: false,
          isMultiple: false,
          label: 'Is DVR',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'select',
          dataType: 'JSON',
          fieldName: 'dvrBlockedCountries',
          isRequired: false,
          isMultiple: true,
          label: 'DVR Blocked Countries',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'dvrUrl',
          isRequired: false,
          isMultiple: false,
          label: 'DVR Url',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'daiAssetKeyHisfp',
          isRequired: false,
          isMultiple: false,
          label: 'Dai Asset Key Hisfp',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'nonDrmHls',
          isRequired: false,
          isMultiple: false,
          label: 'Non DRM HLS',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'nonDrmDash',
          isRequired: false,
          isMultiple: false,
          label: 'Non DRM DASH',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'daiAssetKeyDash',
          isRequired: false,
          isMultiple: false,
          label: 'Dai Asset Key Dash',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'daiAssetKeyWm',
          isRequired: false,
          isMultiple: false,
          label: 'Dai Asset Key Wm',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'switch',
          dataType: 'boolean',
          fieldName: 'isWatermarked',
          isRequired: false,
          isMultiple: false,
          label: 'IsWaterMarked',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'watermarkPartner',
          isRequired: false,
          isMultiple: false,
          label: 'Watermark Partner',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'maximumVideoQuality',
          isRequired: true,
          isMultiple: false,
          label: 'Maximum Video Quality',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'maximumAudioQuality',
          isRequired: true,
          isMultiple: false,
          label: 'Maximum Audio Quality',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'select',
          dataType: 'string',
          fieldName: 'maximumResolution',
          isRequired: true,
          isMultiple: false,
          label: 'Maximum Resolution',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'subtitleFileName',
          isRequired: false,
          isMultiple: false,
          label: 'Subtitle File Name',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'text',
          dataType: 'string',
          fieldName: 'subtitleFileURL',
          isRequired: false,
          isMultiple: false,
          label: 'Subtitle File URL',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
        {
          fieldType: 'textarea',
          dataType: 'string',
          fieldName: 'audio Languages',
          isRequired: true,
          isMultiple: false,
          label: 'Audio Languages',
          isB2B: false,
          defaultValue: null,
          affiliatePartner: [],
          inheritanceRule: 'NONE',
          placeholder: '',
          description: '',
          master: '',
          options: [],
          sortOrder: 1,
          createdBy: '653a3a3ed3f6eaf4a9c88f0f',
          updatedBy: '653a3a3ed3f6eaf4a9c88f0f',
          metaCategory: 'contentMediaVideo',
          isMultilingual: false,
          status: 'ACTIVE',
          _id: '5d7a1f3a59f60c7a88b5bea3',
          createdAt: '2023-12-11T07:10:39.655Z',
          updatedAt: '2023-12-11T07:10:39.655Z',
        },
      ],
      _id: '6576b5ef38fd83d2b4da5f43',
    },
  ],
  createdBy: {
    userEmail: 'ritu.arora@kellton.com',
  },
  updatedBy: {
    userEmail: 'ritu.arora@kellton.com',
  },
  status: 'ACTIVE',
  __v: 0,
  createdAt: '2023-12-11T07:10:39.652Z',
  updatedAt: '2023-12-11T07:10:39.652Z',
}

export const ADD_ASSET_MOCK = {
  message: 'Content created successfully!',
  responseCode: 200,
  data: {
    blitzId: '1090467022',
    legacyHouseId: null,
    assetType: 'BEHIND_THE_SCENES',
    contentId: 1090467022,
    assetCategory: 'CONTENT_ITEM',
    contentCategory: 'MOVIES',
    state: 'CREATED',
    parentId: '1090466701',
    createdBy: '659e489c81ca34d2450b0750',
    updatedBy: '659e489c81ca34d2450b0750',
    status: 'ACTIVE',
    isDeleted: false,
    _id: '65e0206293b81d3f01142771',
    createdAt: '2024-02-29T06:12:50.227Z',
    updatedAt: '2024-02-29T06:12:50.227Z',
    __v: 0,
  },
  error: null,
}
