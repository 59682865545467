function LeftNavIcon() {
  return (
    <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_4186_27812)'>
        <circle cx='38' cy='18' r='18' fill='#E2DEEC' />
        <circle cx='38' cy='18' r='17.5' stroke='#402080' />
      </g>
      <path
        d='M36.5483 21.3871V19.2128H43.7943C44.4575 19.2128 45 18.6662 45 17.9981C45 17.3301 44.4575 16.7835 43.7943 16.7835H36.5483V14.6092C36.5483 14.0626 35.8973 13.7954 35.5235 14.1841L32.1718 17.573C31.9427 17.8159 31.9427 18.1925 32.1718 18.4354L35.5235 21.8243C35.8973 22.2009 36.5483 21.9337 36.5483 21.3871Z'
        fill='#402080'
      />
      <defs>
        <filter
          id='filter0_d_4186_27812'
          x='0'
          y='0'
          width='76'
          height='76'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='4'
            operator='erode'
            in='SourceAlpha'
            result='effect1_dropShadow_4186_27812'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='12' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.219608 0 0 0 0 0.247059 0 0 0 0 0.262745 0 0 0 0.08 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4186_27812' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4186_27812'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}

export default LeftNavIcon
