import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { MASTERS_MANAGEMENT } from '../../Constants/ApiDefinations'
import { AffiliatePartnersListResponse, CreateMastersData, MastersUpdate } from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface AffiliatePartnersState {
  affiliatePartnerData: AffiliatePartnersListResponse | null
}

const initialState: AffiliatePartnersState = {
  affiliatePartnerData: null,
}

const affiliatePartnersSlice = createSlice({
  name: 'affiliatePartners',
  initialState,
  reducers: {
    setAffiliatePartnersData: (state, action) => {
      state.affiliatePartnerData = action?.payload ? action.payload : null
    },
  },
})

export const changeAffiliatePartnerStatus = createAsyncThunk(
  'affiliatePartners/changeStatus',
  async (data: { id: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.AFFILIATE_PARTNERS_CRUD.UPDATE_AFFILIATE_PARTNERS(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return result
  },
)

export const { setAffiliatePartnersData } = affiliatePartnersSlice.actions

const affiliatePartnersSelector = (state: any) => state.affiliatePartners

export const selectAffiliatePartnerData = createSelector(
  affiliatePartnersSelector,
  (state) => state.affiliatePartnerData,
)

export const createAffiliatePartnerService = createAsyncThunk(
  'affiliatePartners/create',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.AFFILIATE_PARTNERS_CRUD.CREATE_AFFILIATE_PARTNERS(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const editAffiliatePartnerService = createAsyncThunk(
  'affiliatePartners/editAffiliatePartner',
  async (payload: CreateMastersData, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: MastersUpdate =
      MASTERS_MANAGEMENT.AFFILIATE_PARTNERS_CRUD.UPDATE_AFFILIATE_PARTNERS(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export default affiliatePartnersSlice
