function DownloadIcon() {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6667 6.66667V10C10.6667 10.3667 10.3667 10.6667 10 10.6667H2C1.63333 10.6667 1.33333 10.3667 1.33333 10V6.66667C1.33333 6.3 1.03333 6 0.666667 6C0.3 6 0 6.3 0 6.66667V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6.66667C12 6.3 11.7 6 11.3333 6C10.9667 6 10.6667 6.3 10.6667 6.66667ZM6.66667 6.44667L7.92 5.19333C8.18 4.93333 8.6 4.93333 8.86 5.19333C9.12 5.45333 9.12 5.87333 8.86 6.13333L6.46667 8.52667C6.20667 8.78667 5.78667 8.78667 5.52667 8.52667L3.13333 6.13333C2.87333 5.87333 2.87333 5.45333 3.13333 5.19333C3.39333 4.93333 3.81333 4.93333 4.07333 5.19333L5.33333 6.44667V0.666667C5.33333 0.3 5.63333 0 6 0C6.36667 0 6.66667 0.3 6.66667 0.666667V6.44667Z'
        fill='#402080'
      />
    </svg>
  )
}
export default DownloadIcon
